import React, { Component } from 'react';
import CityName from '../../CityName';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import Specs from '../transport/Specs';
import moment from 'moment';
import { provincesList } from '../../globals';
import ItemSet from '../transport/ItemSet';
import { BUNDLED_STATUS } from '../../globals';

class StopItem extends Component {
  render() {
    const stop = this.props.stop[this.props.type];
    const title = this.getTitle(stop);
    const address = this.getFullAddress(stop.address, this.props.countries);
    const isStripe = this.props.index % 2 !== 0 ? 'pickup' : 'delivery';
    const extraInformation = _get(stop, 'details.instructions', false);
    const deltaVolume = Math.round(this.props.stop.delta_volume_m3 * 100) / 100;
    const totalVolume = Math.round(this.props.stop.total_volume_m3 * 100) / 100;
    const confirmed = this.isConfirmed(stop.item_sets[0].state, this.props.type);
    return (
      <div
        key={`stop-item-${this.props.index}`}
        className={'stop-card-view address-details address-' + isStripe}
        onClick={() => this.props.toggleStopDetails(true, this.props.type, this.props.stop)}
      >
        <div>
          <div style={{ float: 'right' }}>
            <p style={{ textAlign: 'right' }}>{this.props.stop.distance_km} km</p>
            <h5>
              {confirmed && (
                <Translate id={`request_flow.state.${this.props.type === 'pickup' ? 'picked_up' : 'delivered'}`} />
              )}
            </h5>
          </div>
          <CityName>
            Stop {this.props.index}: {address}
          </CityName>
        </div>

        <p>
          {moment(this.props.stop.proposed_datetime_period['start']).format('HH:mm')} -{' '}
          {moment(this.props.stop.proposed_datetime_period['end']).format('HH:mm')}
        </p>
        <p>
          <Translate id={`transport_request.${this.props.type}`} />: {title}
        </p>
        <p className={'show-print'}>
          {_get(stop, 'contact.phone', '')} <br />
          {_get(stop, 'contact.email', '')}
        </p>
        <p>
          <Translate id={`bundled.essentials.${this.props.type}.loading`} />: {this.props.type === 'pickup' && '+'}
          {deltaVolume}m<sup>3</sup> | <Translate id={'bundled.essentials.in_vehicle'} />: {totalVolume}
          <sup>3</sup>
        </p>
        <div className={'row detail-signature'}>
          <div className={'col-8'}>
            <ItemSet
              hideTotalSize={true}
              hideDescription={true}
              hideImages={true}
              itemSets={_get(stop, 'item_sets', [])}
            />
            {extraInformation && (
              <>
                <h6>
                  <Translate id={'bundled.essentials.extra_information'} />:
                </h6>
                <p>{extraInformation}</p>
              </>
            )}
          </div>
          <div className={'col-4 align-bottom hide-print'}>
            <span className={'more-stop-details'}>
              <p>
                <Translate id={'bundled.essentials.more_details'} />
              </p>
            </span>
          </div>
          <div className="col-4 accept-stop show-print" style={{ marginTop: '-45px' }}>
            <b>
              <Translate id={'transport_request.sign'} />
            </b>
            <div className={'sign-name'}>
              {_get(stop, 'contact.first_name', '')} &nbsp;
              {_get(stop, 'contact.last_name', '')}
              <br />
              <br />
            </div>
            <div className={'sign-here'} />
          </div>
        </div>
        <Specs stop={stop} />
      </div>
    );
  }
  isConfirmed(status, type) {
    if (type === 'pickup') {
      return [
        BUNDLED_STATUS.PICKED_UP,
        BUNDLED_STATUS.PICKUP_CONFIRMED_BY_DRIVER,
        BUNDLED_STATUS.DELIVERY_CONFIRMED_BY_DRIVER,
        BUNDLED_STATUS.DELIVERY_CONFIRMED_BY_CUSTOMER,
        BUNDLED_STATUS.DELIVERED,
      ].includes(status);
    }
    if (type === 'delivery') {
      return [BUNDLED_STATUS.DELIVERED, BUNDLED_STATUS.DELIVERY_CONFIRMED_BY_DRIVER].includes(status);
    }
    return false;
  }
  getTitle(stop) {
    const titles = stop.item_sets.map(itemSet => {
      if (itemSet.title !== null) {
        return itemSet.title;
      } else {
        return itemSet.items.map(item => item.title);
      }
    });
    return titles.join(',');
  }
  getProvinceAbbr(administrativeArea) {
    const target = provincesList.filter(province => province.name === administrativeArea);
    if (target.length > 0) {
      return target[0].abbreviation;
    }
    return administrativeArea;
  }
  getFullAddress(address, countries) {
    let countryCode = _get(address, 'country', '');
    // if country was an IRI then fetch the country code from state
    if (countryCode.indexOf('countries/') > -1) {
      countryCode = this.getCountryCode(countries, countryCode);
    }
    const provinceAbbr = this.getProvinceAbbr(address.administrative_area);

    const addressItems = [address.locality];
    if (provinceAbbr) {
      addressItems.push(provinceAbbr);
    }
    addressItems.push(countryCode);

    return addressItems.join(', ');
  }
  getCountryCode(countries, id) {
    if (countries && id) {
      const country = countries.filter(country => country['@id'] === id);
      if (country.length > 0) {
        return country[0].code;
      }
    }
    return '';
  }
}

export default StopItem;
