const defaultState = {
  isFetching: false,
  submitCounter: 0,
  routes: [],
};

export const types = {
  SUBMIT_PREFERRED_ROUTE_FORM_START: 'SUBMIT_PREFERRED_ROUTE_FORM_START',
  SUBMIT_PREFERRED_ROUTE_FORM_SUCCESS: 'SUBMIT_PREFERRED_ROUTE_FORM_SUCCESS',
  SUBMIT_PREFERRED_ROUTE_FORM_ERROR: 'SUBMIT_PREFERRED_ROUTE_FORM_ERROR',
  DELETE_PREFERRED_ROUTE_START: 'DELETE_PREFERRED_ROUTE_START',
  DELETE_PREFERRED_ROUTE_SUCCESS: 'DELETE_PREFERRED_ROUTE_SUCCESS',
  FETCH_PREFERRED_ROUTES: 'FETCH_PREFERRED_ROUTES',
  FETCH_PREFERRED_ROUTES_SUCCESS: 'FETCH_PREFERRED_ROUTES_SUCCESS',
  FETCH_PREFERRED_ROUTES_ERROR: 'FETCH_PREFERRED_ROUTES_ERROR',
};

export const isSubmitting = state => state.submitCounter - 1 > 0;

const preferredRoutes = (state = defaultState, action) => {
  switch (action.type) {
    case types.SUBMIT_PREFERRED_ROUTE_FORM_SUCCESS:
      return {
        ...state,
        routes: [
          ...state.routes,
          {
            id: action.id,
            from: action.from,
            to: action.to,
            transport_type: action.transport_type,
          },
        ],
        submitCounter: state.submitCounter - 1,
      };
    case types.SUBMIT_PREFERRED_ROUTE_FORM_START:
      return {
        ...state,
        routes: [...state.routes],
        submitCounter: state.submitCounter + 1,
      };
    case types.SUBMIT_PREFERRED_ROUTE_FORM_ERROR:
      return {
        ...state,
        routes: [...state.routes],
        submitCounter: state.submitCounter - 1,
      };
    case types.DELETE_PREFERRED_ROUTE_SUCCESS:
      return {
        ...state,
        routes: state.routes.filter(route => route.id !== action.id),
      };
    case types.FETCH_PREFERRED_ROUTES:
      return {
        ...state,
        isFetching: true,
        routes: [...state.routes],
      };
    case types.FETCH_PREFERRED_ROUTES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        routes: action.payload,
      };
    case types.FETCH_PREFERRED_ROUTES_ERROR:
      return state;
    default:
      return state;
  }
};

export default preferredRoutes;
