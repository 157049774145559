import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { SectionWrapper } from '../../layout/SectionWrapper';
import StatusLabel from '../../../StatusLabel';
import Heading from '../../../Heading';
import Medal from '../../../icons/Medal';
import CriteriaVueLists from './CriteriaVueLists';

class CriteriaVue extends Component {
  render() {
    const brengerLevel = 'vue';
    return (
      <div>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg profile-status-wrapper'}
          centerContent={true}
        >
          <StatusLabel status={'title'} />
          <Heading size={'h3'}>
            <Translate id={'status.' + brengerLevel} />
          </Heading>
          <div>
            <Translate id={'profile_page.intro.' + brengerLevel} />
          </div>
          <Medal type={'platinum'} animated={true} />
        </SectionWrapper>
        <div className={'main-centered-content'}>
          <CriteriaVueLists />
        </div>
      </div>
    );
  }
}

export default CriteriaVue;
