import { connect } from 'react-redux';
import SearchList from '../../components/search/SearchList';
import {
  onTransportJobClick,
  selectTransportJob,
  deselectTransportJob,
  fetchTransportJobsStart,
  setSearchSorting,
  toggleSearchFilters,
} from '../../actions/search';
import { hyphensToCamelCase } from '../../Utils';
import moment from 'moment';
import { getSelectedTransportJobId } from '../../reducers/search';
import * as appUtils from '../../Utils';
import _get from 'lodash/get';

// TODO: write unit test for this func
const getFilteredTransportJobs = (transportJobs, filters) => {
  let filteredTransportJobs = transportJobs;
  filteredTransportJobs =
    filters.date !== '' ? filteredTransportJobs.filter(item => dateFilter(item, filters.date)) : filteredTransportJobs;
  filteredTransportJobs = filteredTransportJobs.filter(item => countryFilter(item, filters.countryPickup, 'pickup'));
  filteredTransportJobs = filteredTransportJobs.filter(item =>
    countryFilter(item, filters.countryDelivery, 'delivery')
  );
  filteredTransportJobs = regionFilter(filteredTransportJobs, filters.countryPickup, filters.regionPickup, 'pickup');
  filteredTransportJobs = regionFilter(
    filteredTransportJobs,
    filters.countryDelivery,
    filters.regionDelivery,
    'delivery'
  );
  filteredTransportJobs = filteredTransportJobs.filter(item => specialFilter(item, filters.special));

  filteredTransportJobs = filteredTransportJobs.filter(item =>
    applyFilter(hyphensToCamelCase(_get(item, 'pickups[0].details.situation', '')), filters.pickupSituation)
  );

  filteredTransportJobs = filteredTransportJobs.filter(item => !filters.bundled || item.bundled);

  filteredTransportJobs = filteredTransportJobs.filter(
    item => !filters.responded || typeof item.own_transport_job_account_link !== 'undefined'
  );
  filteredTransportJobs = filteredTransportJobs.filter(item => !filters.prepaid || item.eligible_for_delivery);
  return filteredTransportJobs;
};

const dateFilter = (item, date) => {
  const pickupDaysOfItem = _get(item, 'pickups[0].available_datetime_periods', []).map(period => moment(period.start));
  date = moment(date);
  if (pickupDaysOfItem.length === 0) {
    const deliveryDaysOfItem = _get(item, 'deliveries[0].available_datetime_periods', []).map(period =>
      moment(period.start)
    );
    if (deliveryDaysOfItem.length === 0) {
      return true;
    } else {
      const lastDeliveryDay = deliveryDaysOfItem.slice(-1)[0];
      return lastDeliveryDay.isSameOrAfter(date, 'day');
    }
  }

  for (const pickupDayOfItem of pickupDaysOfItem) {
    if (pickupDayOfItem.isSame(date, 'day')) {
      return true;
    }
  }
  return false;
};

export const specialFilter = (item, filters) => {
  if (Object.values(filters).every(item => !item)) {
    return true;
  }
  if (
    filters['twoPeople'] &&
    (_get(item.pickups[0], 'details.carrying_help', '') !== 'needed' ||
      _get(item.deliveries[0], 'details.carrying_help', '') !== 'needed')
  ) {
    return true;
  }
  if (
    filters['elevator'] &&
    (_get(item.pickups[0], 'details.elevator', '') || _get(item.deliveries[0], 'details.elevator', ''))
  ) {
    return true;
  }
  if (
    filters['veryHeavy'] &&
    (_get(item.pickups[0], 'details.help_equipment', '') !== null ||
      _get(item.deliveries[0], 'details.help_equipment', '') !== null)
  ) {
    return true;
  }
  return false;
};

const applyFilter = (itemProp, filter) => {
  return filter[itemProp] || Object.values(filter).every(item => !item);
};

const regionFilter = (transportJobs, countryFilter, regionFilter, status) => {
  let filteredTransportJobs = transportJobs;
  const unfilteredCountries = Object.keys(countryFilter).filter(countryCode => {
    return countryFilter[countryCode];
  });
  for (let unfilteredCountry of unfilteredCountries) {
    if (!regionFilter[unfilteredCountry] || Object.values(regionFilter[unfilteredCountry]).every(item => !item)) {
      continue;
    }
    filteredTransportJobs = filteredTransportJobs.filter(item =>
      applyRegionFilter(item, unfilteredCountry, regionFilter[unfilteredCountry], status)
    );
  }
  return filteredTransportJobs;
};

export const applyRegionFilter = (item, unfilteredCountry, regionFilter, status) => {
  const region = _get(
    item,
    status === 'pickup' ? 'pickups[0].address.administrative_area' : 'deliveries[0].address.administrative_area',
    ''
  );
  const countryCode = _get(
    item,
    status === 'pickup' ? 'pickups[0].address.country' : 'deliveries[0].address.country',
    ''
  );
  if (!region) {
    return false;
  }
  if (applyFilter(region, regionFilter)) {
    return true;
  }
  if (countryCode) {
    return unfilteredCountry.toLowerCase() !== countryCode.toLowerCase();
  }
  return false;
};

export const countryFilter = (item, countryFilter, status) => {
  let countryCode = _get(
    item,
    status === 'pickup' ? 'pickups[0].address.country' : 'deliveries[0].address.country',
    ''
  );
  if (countryCode) {
    countryCode = countryCode.toLowerCase();
  } else {
    countryCode = 'other';
  }
  let mainCountries = Object.keys(countryFilter).filter(key => key !== 'other');
  return applyFilter(countryCode, countryFilter) || (countryFilter['other'] && !mainCountries.includes(countryCode));
};

const mapStateToProps = state => ({
  transportJobs: getFilteredTransportJobs(state.search.transportJobs, state.search.filters),
  renderType: 'search',
  selectedTransportJobId: getSelectedTransportJobId(state),
  sort: state.search.sort,
  filtersOpen: state.search.filtersOpen,
  filters: state.search.filters,
  isFetching: state.search.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchTransportJobs: () => dispatch(fetchTransportJobsStart()),
  handleToggleFilters: () => dispatch(toggleSearchFilters()),
  handleSearchSorting: event => dispatch(setSearchSorting(event.target.value)),
  onTransportJobClick: id => dispatch(onTransportJobClick(id, 'search')),
  deselectTransportJob: () => {
    dispatch(deselectTransportJob);
    appUtils.deActivateSingleView();
  },
  selectTransportJob: uuid => {
    dispatch(selectTransportJob(uuid));
    appUtils.activateSingleView();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
