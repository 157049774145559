import React, { Component } from 'react';
import { TJAL_STATUS } from '../../globals';
import * as appUtils from '../../Utils';
import { Translate } from 'react-localize-redux';
import { Col } from '../../Grid';
import { whatsapp } from '../../UtilsPhone';
import VisibleRouteButton from '../../containers/planning/VisibleRouteButton';
import IconPhone from '../../icons/IconPhone';
import IconWhatsapp from '../../icons/IconWhatsapp';
import IconMail from '../../icons/IconMail';
import _get from 'lodash/get';

class PlanningActions extends Component {
  render() {
    const phoneAvailable = _get(this.props.stop, 'contact.phone', false);
    const emailAvailable = _get(this.props.stop, 'contact.email', false);
    return (
      <div className={'address-details address-pickup'}>
        {this.props.isOffer &&
          window.location.pathname.indexOf('planning') > -1 &&
          this.props.status === TJAL_STATUS.ACCEPTED && (
            <Col xs="12" extraClasses="address-actions hide-print">
              <VisibleRouteButton
                phone={this.props.stop.contact.phone}
                address={appUtils.getHouseAddress(this.props.stop.address)}
                googleFormat={this.getGoogleFormat(this.props.stop)}
                direction={this.props.type}
                person={this.props.stop.contact.first_name}
                stopId={this.props.stop['@id'].split('/').pop()}
              />
              {phoneAvailable && (
                <a
                  className={'address-action action-email'}
                  href={`tel: ${this.props.stop.contact.phone}`}
                  target={'_blank'}
                >
                  <IconPhone />
                  <span className={'action-label'}>
                    <Translate id={'communication.call'} />
                  </span>
                </a>
              )}
              {phoneAvailable && (
                <a
                  className={'address-action action-email'}
                  href={whatsapp(this.props.stop.contact.phone)}
                  target={'_blank'}
                >
                  <IconWhatsapp />
                  <span className={'action-label'}>
                    <Translate id={'communication.whatsapp'} />
                  </span>
                </a>
              )}
              {emailAvailable && (
                <a
                  className={'address-action action-email'}
                  href={`mailto: ${this.props.stop.contact.email}`}
                  target={'_blank'}
                >
                  <IconMail />
                  <span className={'action-label'}>
                    <Translate id={'communication.email'} />
                  </span>
                </a>
              )}
            </Col>
          )}
        {this.props.status !== TJAL_STATUS.ACCEPTED && this.props.status !== TJAL_STATUS.DELIVERED && (
          <Col xs="12" extraClasses="address-actions hide-print">
            <Translate id={'transport_request.more_info_when_accepted'} />
          </Col>
        )}
      </div>
    );
  }
  getGoogleFormat(stop) {
    let house_number = _get(stop, 'address.line2', null);
    if (!house_number || house_number === 'null') {
      house_number = '';
    }
    return !('line1' in stop.address)
      ? stop.address.locality
      : `${stop.address.line1} ${house_number}, ${stop.address.postal_code}, ${stop.address.locality}`;
  }
}

export default PlanningActions;
