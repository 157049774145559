import React, { Component } from 'react';
import { EditableFieldList } from '../EditableFieldList';
import { Translate } from 'react-localize-redux';
import { Schema } from './Schema';
import { updateUser, updateAddress, createAddress, updateBankAccount, createBankAccount } from '../../../reducers/user';
import { SectionWrapper } from '../../layout/SectionWrapper';
import { missingImageSrc } from '../../../globals';
import StatusLabel from '../../../StatusLabel';
import Heading from '../../../Heading';
import { EXTRA_SERVICES } from '../../../OnboardBrengerApp';
import { Col, Row } from '../../../Grid';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validations: {
        entry: [],
      },
      account: {
        id: this.props.user.account['@id'],
        name: this.props.user.account.name,
        address: this.props.address,
        vat_number: this.props.user.account.vat_number,
        coc_number: this.props.user.account.coc_number,
        phone: this.props.user.phone,
        aboutUs: this.props.user.account.about_us,
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        email: this.props.user.email,
        current_password: '',
        new_password: '',
        bank_account: this.props.bankAccount, //should change to props.bankAccount
        line1: (this.props.address && this.props.address.line1) || '',
        postal_code: this.props.address && this.props.address.postal_code,
        locality: this.props.address && this.props.address.locality,
        country: this.props.address && this.props.address.country,
        IBAN: (this.props.bankAccount && this.props.bankAccount.account_number) || '',
        extra_services: this.props.user.account.extra_services || ['blankets'],
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(key, value) {
    let account = this.state.account;
    account[key] = value;
    this.setState({ account: account });
    this.validateOnce(key, account);
  }
  validateOnce(param, account) {
    try {
      Schema()
        .validateAt(param, account)
        .then(resp => resp.json())
        .catch(e => {
          if (e.errors) this.validationFailed(e.errors[0], param);
          else this.validationSuccess(param);
        });
    } catch (e) {
      //console.log(e);
    }
  }
  validationFailed(msg, param) {
    let newValidations = this.state.validations;
    newValidations.entry[param] = msg;
    this.setState({
      validations: newValidations,
    });
  }
  validationSuccess(param) {
    let validations = this.state.validations;
    if (param in validations.entry) {
      delete validations.entry[param];
      this.setState({
        validations: validations,
      });
    }
  }
  getCountryIRI(id) {
    if (this.props.countries) {
      const country = this.props.countries.filter(country => country.id === id);
      if (country && country.length === 1) {
        return country[0]['@id'];
      }
    }
    return null;
  }
  updateUser(type, callback) {
    const user = {
      email: this.state.account.email,
      first_name: this.state.account.first_name,
      last_name: this.state.account.last_name,
      phone: this.state.account.phone,
    };
    updateUser(user, this.props.user['@id'])
      .then(resp => callback(type, resp, 'ok'))
      .catch(err => callback(type, err, 'err'));
  }
  updateAccount(type, callback) {
    const account = {
      name: this.state.account.name,
      vat_number: this.state.account.vat_number,
      coc_number: this.state.account.coc_number,
      phone: this.state.account.phone,
      aboutUs: this.state.account.aboutUs,
      extra_services: this.state.account.extra_services,
    };
    updateUser(account, this.props.user.account['@id'])
      .then(resp => callback(type, resp, 'ok'))
      .catch(err => callback(type, err, 'err'));
  }
  createAddress(callback) {
    const address = {
      line1: this.state.account.line1,
      postal_code: this.state.account.postal_code,
      locality: this.state.account.locality,
      country: this.getCountryIRI(this.state.account.country),
    };
    createAddress(address)
      .then(resp => callback('success', resp, 'ok'))
      .catch(err => callback('error', err, 'err'));
  }

  createBankAccount(callback) {
    createBankAccount({
      account_number: this.state.account.IBAN,
    })
      .then(resp => callback('success', resp, 'ok'))
      .catch(err => callback('error', err, 'err'));
  }

  updateAddress(type, callback) {
    const address = {
      line1: this.state.account.line1,
      postal_code: this.state.account.postal_code,
      locality: this.state.account.locality,
      country: this.getCountryIRI(this.state.account.country),
    };
    if (this.props.user.account.address && this.props.user.account.address.length > 0) {
      updateAddress(address, this.props.user.account.address)
        .then(resp => callback(type, resp, 'ok'))
        .catch(err => callback(type, err, 'err'));
    } else {
      createAddress(address)
        .then(resp => callback(type, resp, 'ok'))
        .catch(err => callback(type, err, 'err'));
    }
  }
  updateBankAccount(type, callback) {
    const data = {
      account_holder_name: this.state.account.name,
      account_holder_type: 'business',
      account: this.state.account.id,
      account_number: this.state.account.IBAN,
      currency: 'EUR',
    };
    if (this.props.bankAccount && typeof this.props.bankAccount['@id'] !== 'undefined') {
      updateBankAccount(data, this.props.bankAccount['@id'])
        .then(resp => callback(type, resp, 'ok'))
        .catch(err => callback(type, err, 'err'));
    } else {
      createBankAccount(data)
        .then(resp => callback(type, resp, 'ok'))
        .catch(err => callback(type, err, 'err'));
    }
  }

  onExtraServiceChange(service) {
    let accountServices = this.state.account.extra_services;
    if (accountServices.includes(service)) {
      accountServices.pop(service);
    } else {
      accountServices.push(service);
    }
    this.setState(
      {
        account: {
          ...this.state.account,
          extra_services: accountServices,
        },
      },
      this.updateAccount('extra_services', (type, resp, status) => {
        if (status === 'ok') {
          console.log('good');
        } else {
          console.log('no good');
        }
      })
    );
  }

  onSubmit(type, callback) {
    switch (type) {
      case 'user':
        this.updateUser(type, callback);
        break;
      case 'account':
        this.updateAccount(type, callback);
        break;
      case 'address':
        this.updateAddress(type, callback);
        break;
      case 'bank':
        this.updateBankAccount(type, callback);
        break;
      default:
        this.updateUser(type, callback);
        break;
    }
  }

  render() {
    let _country = this.state.account.country;
    if (_country && _country.indexOf('countries/') > -1) {
      _country = _country.split('countries/')[1];
    }
    return (
      <div>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg profile-info-wrapper'}
          centerContent={true}
        >
          <div className={'profile-heading'}>
            <input
              style={{ display: 'none' }}
              type={'file'}
              onChange={e => this.props.uploadProfileImage(e.currentTarget.files[0])}
              id={'file-profile-image'}
              name={'file-profile-image'}
            />
            <label for={'file-profile-image'}>
              <img
                alt="profile-pic"
                className={'profile-heading-image'}
                src={this.props.user.profile_image || missingImageSrc}
              />
            </label>
            <div className={'profile-heading-text-wrapper'}>
              <StatusLabel status={!this.props.user.is_suspended ? 'active' : 'suspended'} />
              <Heading size={'h3'}>{this.props.user.account.name}</Heading>
              <p>{this.props.address && (this.props.address.line1 || '')}</p>
            </div>
            <label for={'file-profile-image'} className={'edit-image'}>
              <Translate id={'form.edit'} />
            </label>
          </div>
        </SectionWrapper>

        <div className={'main-centered-content'}>
          <p>
            <b>
              <Translate id={'profile_page.details.public_profile'} />
            </b>
          </p>
          <EditableFieldList
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            type={'account'}
            validations={this.state.validations}
            items={[
              {
                label: 'profile_page.details.account_name',
                value: this.state.account.name || '',
                field: 'name',
                withLabel: false,
                type: 'text',
              },
              {
                label: 'profile_page.details.account_description',
                value: this.state.account.aboutUs || '',
                field: 'aboutUs',
                withLabel: false,
                type: 'textarea',
              },
            ]}
          />
          <p>
            <b>
              <Translate id={'profile_page.extra_services.title'} />
            </b>
          </p>
          <div className={'pb-1'}>
            <Row>
              {Object.keys(EXTRA_SERVICES).map((service, index) => {
                return (
                  <Col key={index} xs={12} md={6}>
                    <div className={'input-row'}>
                      <div className={'input-element type-check pb-0-5'}>
                        <input
                          id={'extra_services_' + service}
                          name={'extra_services_' + service}
                          data-service-name={service}
                          type={'checkbox'}
                          onChange={event => this.onExtraServiceChange(event.target.getAttribute('data-service-name'))}
                          checked={this.state.account.extra_services.includes(service)}
                        />
                        <label htmlFor={'extra_services_' + service}>
                          <Translate id={'form.fields.account.extra_services.' + service} />
                        </label>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <p>
            <b>
              <Translate id={'profile_page.details.personal_details'} />
            </b>
          </p>
          {
            <EditableFieldList
              validations={this.state.validations}
              onChange={this.onChange}
              onSubmit={this.onSubmit}
              type={'user'}
              items={[
                {
                  field: 'first_name',
                  label: 'profile_page.details.first_name',
                  value: this.state.account.first_name,
                  withLabel: true,
                  type: 'text',
                },
                {
                  field: 'last_name',
                  label: 'profile_page.details.last_name',
                  value: this.state.account.last_name,
                  withLabel: true,
                  type: 'text',
                },
                {
                  field: 'email',
                  label: 'profile_page.details.email',
                  value: this.state.account.email,
                  withLabel: true,
                  type: 'email',
                },
                {
                  label: 'profile_page.details.phone',
                  value: this.state.account.phone,
                  withLabel: true,
                  type: 'tel',
                  field: 'phone',
                },
              ]}
            />
          }
          <p>
            <b>
              <Translate id={'profile_page.details.address_details'} />
            </b>
          </p>
          <EditableFieldList
            validations={this.state.validations}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            type={'address'}
            items={[
              {
                field: 'line1',
                label: 'profile_page.details.company_address',
                value: this.state.account.line1,
                withLabel: true,
                type: 'text',
              },
              {
                field: 'postal_code',
                label: 'profile_page.details.company_postal_code',
                value: this.state.account.postal_code,
                withLabel: true,
                type: 'text',
              },
              {
                field: 'locality',
                label: 'profile_page.details.company_locality',
                value: this.state.account.locality,
                withLabel: true,
                type: 'text',
              },
              {
                field: 'country',
                label: 'profile_page.details.company_country',
                value: _country,
                data: this.props.countries,
                withLabel: true,
                type: 'select',
              },
            ]}
          />

          <p>
            <b>
              <Translate id={'profile_page.details.company_details'} />
            </b>
          </p>

          <EditableFieldList
            validations={this.state.validations}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            type={'account'}
            items={[
              {
                field: 'coc_number',
                label: 'profile_page.details.chamber_of_commerce_registration',
                value: this.state.account.coc_number,
                withLabel: true,
                type: 'number',
              },
              {
                field: 'vat_number',
                label: 'profile_page.details.vat_number',
                value: this.state.account.vat_number,
                withLabel: true,
                type: 'text',
              },
            ]}
          />
          <p>
            <b>
              <Translate id={'profile_page.details.bank_details'} />
            </b>
          </p>
          <EditableFieldList
            onChange={this.onChange}
            validations={this.state.validations}
            onSubmit={this.onSubmit}
            type={'bank'}
            items={[
              {
                label: 'profile_page.details.iban',
                value: this.state.account.IBAN,
                field: 'IBAN',
                withLabel: true,
                type: 'bank_account',
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default Details;
