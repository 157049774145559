import React from 'react';
import '../css/Progress-steps.css';

export const ProgressSteps = props => {
  return (
    <div className={'progress-steps'}>
      {Array(props.totalSections)
        .fill('')
        .map((el, index) => (
          <div
            className={
              'progress-step' +
              (index + 1 < props.currentSection ? ' is-done' : '') +
              (index + 1 === props.currentSection ? ' is-active' : '')
            }
            key={'progress_step_' + index}
          >
            {index + 1}
          </div>
        ))}
      / {props.totalSections}
    </div>
  );
};
