import React, { Component } from 'react';
import StopItem from './StopItem';
import _get from 'lodash/get';
import { priceLayout } from '../../Utils';
import '../../css/bundled.css';
import '../../css/SingleViewInner.css';
import moment from 'moment';
import StatusLabel from '../../StatusLabel';
import { Translate } from 'react-localize-redux';
import FlashMessage from '../../FlashMessage';
import { translate } from '../../UtilsTranslation';

class StopOverview extends Component {
  render() {
    const stops = _get(this.props, 'stops', []);
    const maxItemLength = this.getMaxItemLength(stops);
    const totalDistance = stops[stops.length - 1].distance_km;
    const totalDuration = this.getTotalDuration(this.props.transport);
    const totalReward = this.getReward(this.props.transport);
    const startDateTime = _get(stops[0], 'proposed_datetime_period.start', false);
    const carryingHelps = this.getCarryingHelps(this.props.stops);
    return (
      <div className={'bundled-stops'}>
        <div className={'list-stop-summary'}>
          <h4 className={'text--primary'} style={{ textTransform: 'capitalize' }}>
            {startDateTime &&
              moment(startDateTime)
                .format('dd D MMM')
                .slice(0, -1)}
            : {this.props.transport.stop_count} Stops
          </h4>
          <h5>#{this.props.shortId}</h5>
          <p>
            <Translate id={`bundled.details.max_load`} />: {this.props.transport.max_load} m<sup>3</sup>
          </p>
          <p>
            <Translate id={'bundled.details.max_item_length'} />: min. {maxItemLength} cm
          </p>
          <p style={{ textTransform: 'capitalize' }}>
            <Translate id={`bundled.details.distance`} />: {totalDistance} km
          </p>
          <p>
            <Translate id={`bundled.details.duration_include_stops`} />: {totalDuration} uur
          </p>
          <p className={'hide-print'}>
            <Translate id={`bundled.details.reward`} />: &euro; {totalReward}
          </p>
          <div className={'stop-card-specs'}>
            {!this.props.transport.business && (
              <div className="request-spec-main">
                <StatusLabel status={'business'} />
              </div>
            )}
            {carryingHelps.map((help, i) => (
              <div className="request-spec-main" key={i}>
                <div className={'request-label'}>
                  <span>
                    <Translate id={`stops.details.carrying_help.${help}`} />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={'list-stops-details'}>
          {stops.map((stop, index) => (
            <div key={`stop-card-${index + 1}`}>
              <StopItem
                isOffer={this.props.isOffer}
                index={`${index + 1}`}
                toggleStopDetails={this.props.toggleStopDetails}
                type={_get(stop, 'pickup', false) ? 'pickup' : 'delivery'}
                proposedDatetimePeriod={stop.proposed_datetime_period}
                countries={this.props.countries}
                stop={stop}
              />
            </div>
          ))}
        </div>
        <div className={'list-stops-total'}>
          <h4>
            <Translate id={`bundled.details.total`} />
          </h4>
          <p style={{ textTransform: 'capitalize' }}>
            <Translate id={`bundled.details.distance`} />: {totalDistance} km
          </p>
          <p>
            <Translate id={`bundled.details.duration_include_stops`} />: {totalDuration} uur
          </p>
          <p className={'hide-print'}>
            <Translate id={`bundled.details.reward`} />: &euro; {totalReward}
          </p>
        </div>
        <div className={'bundled-status'}>
          {this.props.isBundledDelivered && (
            <FlashMessage message={String(translate('bundled.offer.confirmation_succeed'))} type={'success'} />
          )}
        </div>
      </div>
    );
  }
  getReward(transport) {
    return priceLayout(transport.total_payout ? transport.total_payout : transport.reward);
  }
  getCarryingHelps(stops) {
    const specs = [];
    stops.forEach(stop => {
      const type = _get(stop, 'pickup', false) ? 'pickup' : 'delivery';
      const help = _get(stop[type], 'details.carrying_help', false);
      if (help && specs.indexOf(help) === -1 && help !== 'not_needed') {
        specs.push(help);
      }
    });
    return specs;
  }
  getTotalDuration(transport) {
    if (_get(transport, 'total_time_range.start', false) && _get(transport, 'total_time_range.end', false)) {
      return `${moment(transport.total_time_range.start).format('HH:mm')} - ${moment(
        transport.total_time_range.end
      ).format('HH:00')}`;
    }
    return '';
  }
  getMaxItemLength(stops) {
    let max = 0;
    stops.forEach(stop => {
      const stopType = _get(stop, 'pickup', false) ? 'pickup' : 'delivery';
      const itemSets = _get(stop, `${stopType}.item_sets`, []);
      itemSets.forEach(itemSet => {
        _get(itemSet, 'items', []).forEach(item => {
          if (Math.max(...[item.length, item.width, item.height]) > max) {
            max = item.length;
          }
        });
      });
    });
    return max;
  }
}

export default StopOverview;
