import { connect } from 'react-redux';
import RenderMaps from '../../components/maps/RenderMaps';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    single: ownProps.single,
    distance: ownProps.distance,
    uuid: ownProps.uuid,
    translate: getTranslate(state.localize),
  };
};

export default connect(mapStateToProps, {})(RenderMaps);
