import React from 'react';
import '../css/Form.css';

class InputText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
    };
  }
  render() {
    return (
      <div
        className={
          'input-element input-comp type-text input-float' +
          (this.props.value ? ' input--has-value' : '') +
          (this.state.focus ? ' input--has-focus' : '')
        }
      >
        <input
          type={'text'}
          id={this.props.name}
          {...this.props}
          onFocus={(e, arg) => {
            if (this.props.onFocus) {
              this.props.onFocus(e, arg);
            }
            this.setState({ focus: true });
          }}
          onBlur={(e, arg) => {
            if (this.props.onBlur) {
              this.props.onBlur(e, arg);
            }
            this.setState({ focus: false });
          }}
        />
        <label htmlFor={this.props.name}>{this.props.label}</label>
        {this.props.errors && (
          <div className={'error-message'}>
            <p>{this.props.errors}</p>
          </div>
        )}
        {!this.props.errors && this.props.success && (
          <div className={'success-message'}>
            <p>{this.props.success}</p>
          </div>
        )}
      </div>
    );
  }
}
export default InputText;
