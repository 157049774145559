import { connect } from 'react-redux';
import Conversation from '../../Conversation';
import { setSelectedTab } from '../../actions/planning/SingleViewActions';
import { fetchMessagesStart } from '../../actions/conversations/index';
import { getSelectedOffer } from '../../reducers/offers';
import { getMessagesByOffer, getSelectedTab } from '../../reducers/conversations';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
  const selectedOffer = getSelectedOffer(state);
  return {
    uuid: selectedOffer['@id'],
    user: selectedOffer.driver_user,
    status: selectedOffer.transport_job_details.state,
    selectedTab: getSelectedTab(state),
    transport_job: selectedOffer.transport_job_details,
    messagesByOffer: getMessagesByOffer(state),
    translate: getTranslate(state.localize),
  };
};

const mapDispatchToProps = dispatch => ({
  handleTab: tab => dispatch(setSelectedTab(tab)),
  fetchMessages: uuid => dispatch(fetchMessagesStart(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
