import React, { PureComponent } from 'react';
import { Col } from '../../Grid.js';
import IconSmile from '../../icons/IconSmile';
import moment from 'moment';
import '../../css/OneItem.css';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import BundledItemReward from '../bundled/BundledItemReward';
import BundledItemSpecs from '../bundled/BundledItemSpecs';
import ItemAddress from '../transport/ItemAddress';

class SearchBundledItem extends PureComponent {
  render() {
    const transport = this.props.item;
    const uuid = transport['@id'].split('transport_jobs/')[1];
    const timePeriod = this.getTimePeriod(transport);
    const startTime = _get(transport, 'first_pickup_date_time_period.start', false);
    return (
      <div
        className={'card one-item-container bundle-job' + (this.props.isSelected ? ' is-selected' : '')}
        onClick={() => this.props.onItemClick(uuid, this.props.renderType)}
      >
        <div className={'one-item-inner type-' + this.props.renderType} id={this.props.item['@id']}>
          <div className={'dropdown-toggle'}>
            <IconSmile />
          </div>
          <div className="row address-details address-pickup">
            <ItemAddress stop={{ address: transport.first_stop_address }} />
            <Col xs="5" extraClasses={'bundled-date-time-col'}>
              <div className="date" style={{ textTransform: 'capitalize' }}>
                {startTime && moment(startTime).format('dd D MMM')}
              </div>
              <div className={'time-window'}>± {timePeriod}</div>
              <div className={'max-load'}>
                <Translate id="bundled.details.max_load_abbr" />: {transport.max_load} m<sup>3</sup>
              </div>
            </Col>
          </div>
          <p>{transport.stop_count} tussenstops</p>
          <div className="row address-details address-delivery">
            <ItemAddress stop={{ address: transport.last_stop_address }} />
          </div>
          <BundledItemSpecs
            transport={transport}
            isPrepaid={transport.business}
            isBusiness={transport.eligible_for_delivery}
          />
          <BundledItemReward transport={transport} isSelected={this.props.isSelected} renderType={'search'} />
        </div>
      </div>
    );
  }
  getTimePeriod(transport) {
    if (_get(transport, 'total_time_range.start', false) && _get(transport, 'total_time_range.end', false)) {
      return `${moment(transport.total_time_range.start).format('HH:mm')} - ${moment(
        transport.total_time_range.end
      ).format('HH:00')}`;
    }
    return '';
  }
}

export default SearchBundledItem;
