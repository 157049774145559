import React, { Component } from 'react';

import './css/Card.css';
import Heading from './Heading';
import RenderRequestSpecIcon from './icons/RenderRequestSpecIcon';

class card extends Component {
  render() {
    return this.cardLayout();
  }
  cardLayout() {
    if (this.props.stickyHeader) {
      return [
        <h3 key="1" className="sticky-card-heading">
          {this.headerContent()}
        </h3>,
        <div
          key="2"
          id={this.props.id}
          className={'card has-sticky-heading ' + (this.props.sizeclass ? this.props.sizeclass : 'col-12')}
        >
          <div className="card-body">{this.props.children}</div>
          {this.props.footer && <div className="card-footer">{this.props.footer}</div>}
        </div>,
      ];
    }
    return (
      <div
        id={this.props.id}
        className={
          'card ' +
          (this.props.sizeclass ? this.props.sizeclass : 'col-12') +
          ' ' +
          (this.props.extraClasses ? this.props.extraClasses : '')
        }
      >
        {this.props.header && (
          <div className="card-header">
            <Heading size="h4">{this.headerContent()}</Heading>
          </div>
        )}
        <div className="card-body">{this.props.children}</div>
        {this.props.footer && <div className="card-footer">{this.props.footer}</div>}
      </div>
    );
  }
  headerContent() {
    if (this.props.icons) {
      return [<span key={1}>{this.props.header}</span>, this.addIcons()];
    } else {
      return <span>{this.props.header}</span>;
    }
  }
  addIcons() {
    return [
      this.props.icons.map((icon, index) => {
        return <RenderRequestSpecIcon key={index + 1} icondata={icon} />;
      }),
    ];
  }
}

export default card;
