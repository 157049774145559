import { connect } from 'react-redux';
import {
  createSortedDayListFromOffers,
  deselectOffer,
  fetchOffersStart,
  onOfferClick,
  selectOffer,
} from '../../actions/planning';
import PlanningList from '../../components/planning/PlanningList';
import { getCursorDate, getPlanningFilters, getSelectedOfferId, isFetchingOffers } from '../../reducers/offers';
import { togglePlanningFilters } from '../../actions/planning';
import * as appUtils from '../../Utils';
import { TJAL_STATUS } from '../../globals';

const getFilteredOffers = (offers, filters) => {
  //Filter by status of offer
  return offers.filter(offer => filters.state[offer.state] || TJAL_STATUS.DELIVERED === offer.state);
};

const storeOffersByDay = offers => {
  // Init variables
  let dataSegments = {};

  // What dates we have, today or newer (sorted from old to new)
  const sortedDayList = createSortedDayListFromOffers(offers);

  // Filter the data segments against the list of days we want to show
  for (let day of sortedDayList) {
    dataSegments[day] = offers.filter(item => {
      if (item.state === TJAL_STATUS.DELIVERED) {
        return null;
      }
      return (
        item.pickup_commitments[0].committed_datetime_period.start.format('YYYYMMDD') === day ||
        item.delivery_commitments[0].committed_datetime_period.start.format('YYYYMMDD') === day
      );
    });
  }
  return dataSegments;
};

const mapStateToProps = state => {
  const offersState = state.planning.offers;
  return {
    id: 'side-bar',
    dataSegments: storeOffersByDay(getFilteredOffers(offersState.items, getPlanningFilters(state))),
    renderType: 'planning',
    offers: getFilteredOffers(offersState.items, getPlanningFilters(state)),
    selectedUUID: getSelectedOfferId(state),
    isSubmitting: offersState.isSubmitting,
    isFetching: isFetchingOffers(state),
    cursorDate: getCursorDate(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOffers: () => dispatch(fetchOffersStart()),
  handleToggleFilters: () => dispatch(togglePlanningFilters()),
  onOfferClick: (id, sectionName) => dispatch(onOfferClick(id, sectionName)),
  selectOffer: id => dispatch(selectOffer(id)),
  deselectOffer: () => {
    dispatch(deselectOffer());
    appUtils.deActivateSingleView();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanningList);
