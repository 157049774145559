import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SectionWrapper } from '../../components/layout/SectionWrapper';
import { Col, Row } from '../../Grid';
import { CardStats } from './CardStats';
import { Translate } from 'react-localize-redux';
import * as Criteria from './CriteriaApp';
import * as info from './InfoApp';
import { CardImage } from './CardImage';
import CirclePerson from '../../icons/CirclePerson';
import CircleBus from '../../icons/CircleBus';
import Heading from '../../Heading';
import * as appUtils from '../../Utils';
import { getUserDetails } from '../../reducers/user';
import { setDocumentTitle } from '../../Utils';
import { translate } from '../../UtilsTranslation';
import { SECTIONS } from './InfoApp';
import _get from 'lodash/get';

const SITEPREFIX = appUtils.getPathForApp();

class ProfileDashboard extends Component {
  componentDidMount() {
    setDocumentTitle(translate('menu.dashboard'));
  }
  getSummOfJobs(summary) {
    if (Object.keys(summary).length === 0) {
      return 0;
    }
    if (summary.hasOwnProperty('count')) {
      return summary.count;
    }
    // we dont have count, so fallback to a simple sum function
    return Object.keys(summary)
      .map(key => {
        return summary[key];
      })
      .reduce((a, b) => a + b, 0);
  }
  render() {
    let firstName = this.props.user.first_name;
    let userLevel =
      this.props.user.progress.level === 'bronze' || this.props.user.progress.level === 'green'
        ? 'green'
        : this.props.user.progress.level;

    return (
      <div>
        <SectionWrapper background={'primary'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
          <Row>
            <Col xs="12" extraClasses={'text-center dashboard-opening'}>
              <div className={'hello-sayer'}>
                <div className={'text-balloon'}>
                  <Translate id={'profile_page.salutation'} />
                </div>
                <div className={'text-balloon text-balloon-name'}>{firstName}</div>
                <div className={'person-wrapper'}>
                  <CirclePerson />
                </div>
              </div>
              <Heading size={'h4'}>
                <Translate id={'profile_page.salutation'} /> {firstName}
              </Heading>
              <p>
                <Translate id={'profile_page.intro.dashboard'} />
              </p>
            </Col>
            <Col xs="12">
              <CardStats
                preText={
                  <b>
                    <Translate
                      id={'loyalty.introduction'}
                      data={{ level: String(translate('level.' + userLevel).toLowerCase()) }}
                    />
                  </b>
                }
                medal={userLevel === 'green' ? undefined : userLevel}
                subText={
                  <p>
                    <Translate
                      id={'loyalty.introduction_sub'}
                      data={{ level: String(translate('level.' + userLevel).toLowerCase()) }}
                    />
                  </p>
                }
                href={Criteria.SECTIONS.default.path}
              />
            </Col>
            <Col xs="6">
              <CardStats
                bignumber={{
                  value: this.props.user.activity_summary.num_transport_job_account_links_by_status.all.accepted || 0,
                }}
                subText={<Translate id={'status.planned'} />}
                href={SITEPREFIX + '/planning'}
              />
            </Col>
            <Col xs="6">
              <CardStats
                bignumber={{
                  value: this.props.user.activity_summary.num_transport_job_account_links_by_status.all.pending || 0,
                }}
                subText={<Translate id={'profile_page.intro.still_open'} />}
                href={SITEPREFIX + '/search'}
              />
            </Col>
            <Col xs="6">
              <CardStats
                bignumber={{
                  value: this.props.user.progress.review_summary.all.average || 0,
                }}
                subText={<Translate id={'dashboard.stats.average_all_reviews'} />}
                href={SECTIONS.reviews.path}
              />
            </Col>
            <Col xs="6">
              <CardStats
                bignumber={{
                  value: this.props.user.progress.review_summary.last_25.average || 0,
                }}
                subText={<Translate id={'dashboard.stats.average_last_x_reviews'} data={{ count: 25 }} />}
                href={SECTIONS.reviews.path}
              />
            </Col>
            <Col xs="6">
              <CardStats
                bignumber={{
                  value: this.props.user.activity_summary.num_transport_job_account_links_by_status.all.delivered,
                }}
                subText={<Translate id={'dashboard.stats.number_of_all_transports'} />}
                href={SITEPREFIX + '/delivered'}
              />
            </Col>
            <Col xs="6">
              <CardStats
                bignumber={{
                  value: this.getSummOfJobs(
                    this.props.user.activity_summary.num_transport_job_account_links_by_status.last_90_days
                  ),
                }}
                subText={<Translate id={'dashboard.stats.number_of_last_x_days_transports'} data={{ days: 90 }} />}
                href={SITEPREFIX + '/delivered'}
              />
            </Col>
            {_get(this.props.user, 'has_professional_profile', false) && (
              <Col xs="12">
                <CardStats
                  preText={
                    <b>
                      <Translate id={'dashboard.profile_picture.heading'} />
                    </b>
                  }
                  subText={<Translate id={'dashboard.profile_picture.yes'} />}
                  href={SECTIONS.default.path}
                />
              </Col>
            )}
            <Col xs="12">
              <CardImage
                href={info.SECTIONS.default.path}
                title={<Translate id={'profile_page.settings_title'} />}
                subText={<Translate id={'profile_page.settings_sub'} />}
                cardStyle={'primary'}
                image={<CircleBus />}
              />
            </Col>
          </Row>
        </SectionWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: getUserDetails(state),
});

export default connect(mapStateToProps)(ProfileDashboard);
