import React, { Component } from 'react';
import { TJAL_STATUS } from '../../globals';
import { Col, Row } from '../../Grid';
import Heading from '../../Heading';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';

class Signature extends Component {
  render() {
    return (
      <>
        {this.props.status === TJAL_STATUS.ACCEPTED && (
          <Col extraClasses="accept-delivery show-print">
            <Heading size="h4">
              <Translate id={'transport_request.sign'} />
            </Heading>
            <Row>
              <Col>
                <div className={'sign-name'}>
                  {_get(this.props.transport, 'pickups[0].contact.first_name', '')} &nbsp;
                  {_get(this.props.transport, 'pickups[0].contact.last_name', '')}
                </div>
                <div className={'sign-here'} />
              </Col>
              <Col>
                <div className={'sign-name'}>
                  {_get(this.props.transport, 'deliveries[0].contact.first_name', '')} &nbsp;
                  {_get(this.props.transport, 'deliveries[0].contact.last_name', '')}
                </div>
                <div className={'sign-here'} />
              </Col>
            </Row>
          </Col>
        )}
      </>
    );
  }
}

export default Signature;
