import moment from 'moment';
import _get from 'lodash/get';
import { Translate } from 'react-localize-redux';
import React from 'react';

const transformToFutureOnlyDatetimePeriods = (datetimePeriods, offset = moment.duration(0)) => {
  let offsetInFuture = moment().add(offset);
  return datetimePeriods
    .map(item => {
      if (moment(item.start).isBefore(offsetInFuture)) {
        if (moment(item.end).isAfter(offsetInFuture)) {
          item.start = roundToNearestHalfHour(offsetInFuture);
          return item;
        } else {
          return false;
        }
      } else {
        return item;
      }
    })
    .filter(item => {
      return item;
    });
};

const transformDatetimePeriodsToMoments = datetimePeriods => {
  if (datetimePeriods.length === 0) {
    return datetimePeriods;
  }
  const periods = datetimePeriods
    //Filtering past days for performance
    .filter(period => {
      return moment(period.start).isSameOrAfter(moment(), 'day');
    })
    //transform to moments
    .map(period => ({
      start: moment(period.start),
      end: moment(period.end),
    }));
  return periods.sort(function(a, b) {
    if (a.start.isBefore(b.start)) {
      return -1;
    }
    if (b.start.isBefore(a.start)) {
      return 1;
    }
    return 0;
  });
};

const roundToNearestHalfHour = momentToRound => {
  const remainder = 30 - (momentToRound.minute() % 30);

  return momentToRound
    .clone()
    .add(remainder, 'minutes')
    .seconds(0)
    .milliseconds(0);
};

const filterShortDatetimePeriods = (datetimePeriods, minDuration = moment.duration(1, 'hours')) => {
  return datetimePeriods.filter(item => {
    return moment.duration(moment(item.end).diff(moment(item.start))) >= minDuration;
  });
};

const addAmountToMoment = (momentObject, amount) => {
  if (amount >= 0) {
    return momentObject.clone().add(amount, 'days');
  } else {
    return momentObject.clone().subtract(-1 * amount, 'days');
  }
};

const getAvailableDateimePeriodForStop = (stop, sameOrAfter) => {
  for (let period of stop.available_datetime_periods) {
    if (moment(period.start).isSameOrAfter(moment(sameOrAfter, 'day'))) {
      return period;
    }
  }
  return false;
};

const displayAvailableDatetimePeriodForStop = (stop, sameOrAfter) => {
  let period = getAvailableDateimePeriodForStop(stop, sameOrAfter);

  if (period !== false) {
    return `${moment(period.start).format('H:mm')} - ${moment(period.end).format('H:mm')}`;
  }

  return 'No available period for this date';
};

const initialMoment = () => {
  const appEnv = process.env.REACT_APP_ENV || '';
  moment.now = appEnv.includes('production')
    ? function() {
        return +new Date();
      }
    : function() {
        let currentDatetime = new Date();
        return +new Date(
          2018,
          6,
          12,
          currentDatetime.getHours(),
          currentDatetime.getMinutes(),
          currentDatetime.getSeconds(),
          0
        );
      };
  return moment();
};

const getBundledTimePeriod = transport => {
  const stops = _get(transport, `stops`, []);
  if (stops.length > 0) {
    const start = moment.unix(stops[0]['arrival_time_seconds']);
    //round the end time to the neareast hour
    const end = moment.unix(stops[stops.length - 1]['departure_time_seconds']).add(2, 'hours');
    return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  }
  return '';
};

const determineTimeDisplay = (transport, date, stopType) => {
  if (typeof date === 'undefined') {
    return '';
  }
  if (transport.hasOwnProperty(`${stopType}_commitments`)) {
    if (date.isSame(transport[`${stopType}_commitments`][0].committed_datetime_period.start, 'day')) {
      return (
        moment(transport[`${stopType}_commitments`][0].committed_datetime_period.start).format('HH:mm') +
        '-' +
        moment(transport[`${stopType}_commitments`][0].committed_datetime_period.end).format('HH:mm')
      );
    } else {
      return <Translate id={'datetime.other_day'} />;
    }
  } else {
    let datetimePeriods = _get(
      transport,
      stopType === 'pickup' ? 'pickups[0].available_datetime_periods' : 'deliveries[0].available_datetime_periods',
      []
    );
    if (datetimePeriods.length === 0) {
      return <Translate id={'transport_request.propose'} />;
    }
    let dateTimeperiodsArr = datetimePeriods.map((datetimePeriod, index) => {
      return moment(datetimePeriod.start).format('DD-MM HH:mm');
    });
    return dateTimeperiodsArr[0] + (dateTimeperiodsArr.length > 1 ? '+' : '');
  }
};

/** Exports **/
export {
  determineTimeDisplay,
  getBundledTimePeriod,
  transformToFutureOnlyDatetimePeriods,
  transformDatetimePeriodsToMoments,
  filterShortDatetimePeriods,
  addAmountToMoment,
  getAvailableDateimePeriodForStop,
  displayAvailableDatetimePeriodForStop,
  initialMoment,
};
