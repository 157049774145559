import React, { Component } from 'react';
import { Button } from '../../Button';
import { Translate } from 'react-localize-redux';
import Modal from '../../Modal';
import history from '../../History';
import { SECTIONS as OnboardingSections } from '../../OnboardBrengerApp';
import { BECOME_A_DRIVER_INFO_URL } from '../../Utils';

class PreviewModal extends Component {
  gotoRegistration() {
    history.push(OnboardingSections.step_one.path);
  }

  render() {
    const more_info_link = (
      <a href={BECOME_A_DRIVER_INFO_URL}>
        <Translate id={'preview.popup.link_text'} />
      </a>
    );
    return (
      <Modal header={<Translate id={'preview.popup.title'} />} open={this.props.open}>
        <p>
          <Translate id={'preview.popup.text'} data={{ more_info_link: more_info_link }} />
        </p>
        <div className={'btn-wrapper btn-wrapper--centered'}>
          <Button buttonStyle={'primary'} onClick={this.gotoRegistration}>
            <Translate id={'preview.popup.button'} />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default PreviewModal;
