import React, { Component } from 'react';
import '../../css/Maps.css';
import LocationMarker from './LocationMarker';
import GoogleMap from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import IconSmile from '../../icons/IconSmile';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import moment from 'moment';

function createMapOptions(maps) {
  return {
    disableDefaultUI: true,
    styles: styledMapType,
  };
}

export class renderMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routePolylines: [],
      pickupCoords: [],
      deliveryCoords: [],
      renderFlag: 0,
    };
  }
  componentWillMount() {
    let tmp = [];
    tmp[this.props.uuid] = this.props.single.route;
    this.setState({ routePolylines: tmp });
  }
  renderPolylines(map, maps) {
    const routes = this.props.single.route;
    const polyline = routes.points;
    if (typeof polyline === 'undefined') {
      return null;
    }
    const coords = polyline.map(coord => {
      if (_get(coord, 'latitude', false)) {
        return { lat: coord.latitude, lng: coord.longitude };
      } else {
        return { lat: coord[0], lng: coord[1] };
      }
    });
    if (maps && coords.length > 2) {
      let routePolyline = new maps.Polyline({
        path: coords,
        geodesic: true,
        strokeColor: '#0073fa',
        strokeOpacity: 1.0,
        strokeWeight: 4,
        zIndex: 10,
      });
      routePolyline.setMap(map);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.single.route.distance !== prevProps.single.route.distance) {
      this.setState({ renderFlag: 1 });
      setTimeout(() => {
        this.setState({ renderFlag: 0 });
      }, 1000);
    }
  }
  render() {
    const routes = this.props.single.route;

    const pickup = routes.points[0];
    const delivery = routes.points[routes.points.length - 1];

    const { center, zoom } = this.getZoomCenter(this.props.single.route);
    const hasPolyline = _get(routes, 'points[0]', false) && this.state.renderFlag === 0;
    return (
      <div className={'dynamic-maps-wrap hide-print'}>
        <div id={'map'} className={'render-maps'}>
          <span className={'loader'}>
            <IconSmile spinning="true" /> <Translate id={'location.loading_maps'} />
          </span>
          <div className={'distance-lable' + (hasPolyline ? ' isVisible' : '')}>
            {Math.floor(this.props.distance)} km
          </div>
          {hasPolyline && (
            <GoogleMap
              bootstrapURLKeys={{ key: 'AIzaSyBwVhBOJxllD3rGUO0lLB6epMwKA6rXrDQ', language: '' }}
              center={center}
              zoom={zoom}
              options={createMapOptions}
              margin={[30, 30, 30, 30]}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => this.renderPolylines(map, maps)}
            >
              <LocationMarker
                direction={'pickup'}
                textLabel={this.getTime('pickup')}
                lat={_get(pickup, 'latitude', false) ? pickup.latitude : pickup[0]}
                lng={_get(pickup, 'longitude', false) ? pickup.longitude : pickup[1]}
              />
              <LocationMarker
                direction={'delivery'}
                textLabel={this.getTime('delivery')}
                lat={_get(delivery, 'latitude', false) ? delivery.latitude : delivery[0]}
                lng={_get(delivery, 'longitude', false) ? delivery.longitude : delivery[1]}
              />
            </GoogleMap>
          )}
        </div>
      </div>
    );
  }
  getZoomCenter(route) {
    if (!_get(route, 'points[0]', false)) {
      return { center: { lat: 52.55498, lng: 5.91721 }, zoom: 11 }; // default return, should be locale based
    }

    let lats = [];
    let lngs = [];

    if (_get(route.points[0], 'latitude', false)) {
      route.points.map(point => {
        lats.push(point.latitude);
        lngs.push(point.longitude);
        return null;
      });
    } else {
      route.points.map(point => {
        lats.push(point[0]);
        lngs.push(point[1]);
        return null;
      });
    }

    // Set bounds to calculate area to be shown
    const bounds = {
      ne: {
        lat: Math.max(...lats),
        lng: Math.max(...lngs),
      },
      sw: {
        lat: Math.min(...lats),
        lng: Math.min(...lngs),
      },
    };
    // Set default to smallest screen size
    const size = {
      width: 300, // Map width in pixels
      height: 190, // Map height in pixels
    };

    return fitBounds(bounds, size);
  }
  getTime(direction) {
    const start = _get(this.props.single, direction + '.start', false);
    const end = _get(this.props.single, direction + '.end', false);
    if (start && end) {
      return start.format('HH:mm') + ' - ' + end.format('HH:mm');
    }
    let available_datetime_periods = _get(
      this.props.single,
      direction === 'pickup' ? 'pickups[0].available_datetime_periods' : 'deliveries[0].available_datetime_periods',
      []
    );

    if (available_datetime_periods.length > 1) {
      return this.props.translate('transport_request.' + direction);
    }

    if (direction === 'pickup' && _get(this.props.single, 'pickups[0].available_datetime_periods[0].start', false)) {
      return (
        moment(this.props.single.pickups[0].available_datetime_periods[0].start).format('HH:mm') +
        ' - ' +
        moment(this.props.single.pickups[0].available_datetime_periods[0].end).format('HH:mm')
      );
    }

    if (
      direction === 'delivery' &&
      _get(this.props.single, 'deliveries[0].available_datetime_periods[0].start', false)
    ) {
      return (
        moment(this.props.single.deliveries[0].available_datetime_periods[0].start).format('HH:mm') +
        ' - ' +
        moment(this.props.single.deliveries[0].available_datetime_periods[0].end).format('HH:mm')
      );
    }

    const fromTime = _get(this.props.single, 'first_' + direction + '.start', false)
      ? this.props.single['first_' + direction].start.format('HH:mm')
      : '';
    const toTime = _get(this.props.single, 'first_' + direction + '.end', false)
      ? this.props.single['first_' + direction].end.format('HH:mm')
      : '';

    return fromTime + ' - ' + toTime;
  }
}

export default renderMaps;

const styledMapType = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#aad2e3',
      },
      {
        visibility: 'on',
      },
    ],
  },
];
