import React from 'react';
import '../css/IconStyle.css';

const IconAuction = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 115.2 84">
      <path
        d="M108,53.3c-1.3-2-3.7-3.1-6-2.9L77,9.5c1.3-2,1.5-4.6,0.2-6.7c0,0,0-0.1-0.1-0.1c-1.9-2.9-5.8-3.8-8.7-2L42.4,16.5
	c-2.9,1.9-3.8,5.8-2,8.8c1.2,1.9,3.3,2.9,5.4,2.9c0.2,0,0.4,0,0.6,0l9.2,15.1L7.4,72.7C7,73,6.6,73.4,6.5,73.9
	c-0.1,0.5,0,1.1,0.2,1.5l4.5,7.4c0.4,0.6,1,1,1.7,1c0.4,0,0.7-0.1,1-0.3l48.2-29.5l9.2,15c-1.3,2-1.4,4.7-0.1,6.8
	c1.2,1.9,3.3,2.9,5.4,2.9c1.1,0,2.3-0.3,3.3-0.9L105.9,62C108.9,60.2,109.8,56.4,108,53.3z M13.6,79.1l-2.5-4l46.5-28.4l2.5,4
	L13.6,79.1z M43.8,23.2c-0.7-1.1-0.3-2.6,0.7-3.3l26-15.8c0.4-0.2,0.8-0.3,1.2-0.3c0.8,0,1.6,0.4,2.1,1.1C74.4,6,74,7.3,73,8.1
	l-7.4,4.5L47.1,23.9C46,24.5,44.5,24.2,43.8,23.2z M67.3,16.2l6.6-4.1l24.3,39.7L74.5,66.4L50.2,26.7L67.3,16.2z M103.9,58.6
	C103.9,58.6,103.9,58.6,103.9,58.6L78,74.5c-1.1,0.7-2.6,0.3-3.3-0.7c-0.6-1-0.3-2.4,0.5-3.1l26.2-16c0.3-0.2,0.7-0.3,1.1-0.3
	c0.8,0,1.6,0.4,2,1C105.2,56.5,104.9,58,103.9,58.6z"
      />
    </svg>
  );
};

export default IconAuction;
