import * as yup from 'yup';
import { phoneRegExp } from '../../UtilsPhone';
import { translate } from '../../UtilsTranslation';

const SUFFIX = 'form.fields.';

export const Schema = () => {
  return yup.object().shape({
    first_name: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.first_name.label') })),
    last_name: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.last_name.label') })),
    email: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.email.label') }))
      .email(translate(SUFFIX + 'user.email.valid')),
    phone: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.phone.label') }))
      .matches(phoneRegExp, translate(SUFFIX + 'user.phone.valid')),
    id_card: yup
      .mixed()
      .test(
        'fileAvailable',
        translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.id_card.label') }),
        value => value && value.size > 0
      )
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.id_card.label') })),
    profile_image: yup
      .mixed()
      .test(
        'fileAvailable',
        translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.profile_image.label') }),
        value => value && value.size > 0
      )
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.profile_image.label') })),
    new_password: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'user.password.label') }))
      .min(8, translate(SUFFIX + 'user.password.valid')),
    timezone: yup.string(),
    gender: yup.string(),
    name: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'account.account_name.label') })),
    about_me: yup.string(), //user
    about_us: yup.string(), //account
    date_of_birth: yup.date(),
    coc_number: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'account.coc_number.label') })),
    vat_number: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'account.vat_number.label') }))
      .min(8, translate(SUFFIX + 'account.vat_number.valid')),
    line1: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'address.line1.label') })),
    line2: yup.string(),
    state: yup.string(),
    account_type: yup.string(),
    entity_type: yup.string(),
    locality: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'address.locality.label') })),
    postal_code: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'address.postal_code.label') })),
    house_number: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'address.house_number.label') })),
    country: yup.string(),
    currency: yup.string(),
    account_number: yup
      .string()
      .required(
        translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'bank_account.account_number.label') })
      ),
    bank_account_name: yup.string(),
    account_holder_name: yup
      .string()
      .required(
        translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'bank_account.account_holder_name.label') })
      ),
    account_holder_type: yup.string(),
  });
};

export const VehicleSchema = () => {
  return yup.object().shape({
    image: yup.string(),
    licensePlate: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'vehicles.plate_number.label') })),
    make: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'vehicles.brand.label') })),
    model: yup
      .string()
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'vehicles.model_number.label') })),
    loading_dimensions_length_cm: yup
      .number()
      .typeError(translate(SUFFIX + 'default.number', { field: translate(SUFFIX + 'vehicles.size_length.label') }))
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'vehicles.size_length.label') })),
    loading_dimensions_width_cm: yup
      .number()
      .typeError(translate(SUFFIX + 'default.number', { field: translate(SUFFIX + 'vehicles.size_width.label') }))
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'vehicles.size_width.label') })),
    loading_dimensions_height_cm: yup
      .number()
      .typeError(translate(SUFFIX + 'default.number', { field: translate(SUFFIX + 'vehicles.size_width.label') }))
      .required(translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'vehicles.size_height.label') })),
    loading_weight_limit_kg: yup
      .number()
      .typeError(
        translate(SUFFIX + 'default.number', { field: translate(SUFFIX + 'vehicles.max_load_capacity.label') })
      )
      .required(
        translate(SUFFIX + 'default.required', { field: translate(SUFFIX + 'vehicles.max_load_capacity.label') })
      ),
  });
};
