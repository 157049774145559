import React, { Component } from 'react';
import _get from 'lodash/get';
import Specs from './Specs';
import { Translate } from 'react-localize-redux';
import CityName from '../../CityName';
import * as appUtils from '../../Utils';
import DateTime from './DateTime';
import moment from './StopDetails';

class StopCard extends Component {
  render() {
    const details = this.props.isOffer ? this.props.single.transport_job_details : this.props.transport;
    const stop = this.props.type === 'pickup' ? details.pickups[0] : details.deliveries[0];
    const dateTimePeriods = this.props.getDateTimePeriods(this.props.transport, this.props.type);
    const administrativeArea = _get(stop, 'address.administrative_area', '');
    const country = _get(stop, 'address.country', '');
    const locality = _get(stop, 'address.locality', '');
    return (
      <div
        className={'stop-card-view address-details address-' + this.props.type}
        onClick={() => this.props.toggleStopDetails(true, this.props.type)}
      >
        {stop && (
          <>
            <div>
              <CityName>
                <Translate id={`transport_request.${this.props.type}`} />
                :&nbsp;
                {locality} {administrativeArea} {country}
              </CityName>
              <div className={'show-print'}>{this.props.isOffer && appUtils.getHouseAddress(stop.address)}</div>
              <div className={'show-print'}>
                {this.props.isOffer && (
                  <>
                    <p>
                      <Translate id={'form.login.contact'} />
                      :&nbsp;
                      {_get(stop, 'contact.first_name', '')}
                      &nbsp;
                      {_get(stop, 'contact.last_name', '')}
                    </p>
                    <p>{_get(stop, 'contact.phone', '')}</p>
                  </>
                )}
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-8'}>
                {/* available date time options */}
                <p>
                  <DateTime dateTimePeriods={dateTimePeriods} />
                </p>
                {_get(stop, 'details.instructions') && (
                  <div>
                    <h6>
                      <Translate id={'search.detail.extra_information'} />
                    </h6>
                    <p>{stop.details.instructions}</p>
                  </div>
                )}
              </div>
              <div className={'col-4'}>
                <span className={'more-stop-details hide-print'}>
                  <p>
                    <Translate id={'bundled.essentials.more_details'} />
                  </p>
                </span>
              </div>
            </div>

            {/* extra services and specs label */}
            <Specs stop={stop} />
          </>
        )}
      </div>
    );
  }
  formatTime(start, end) {
    if (!start.isValid()) {
      return '';
    }
    return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
  }
  formatDate(start) {
    if (!start.isValid()) {
      return '';
    }
    // return dateformat without dot at the end
    return appUtils.stripLastCharacter(moment(start).format('dd DD MMM')).toUpperCase();
  }
}

export default StopCard;
