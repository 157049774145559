import React, { Component } from 'react';
import '../../css/Card-route.css';
import { Translate } from 'react-localize-redux';
import { Button } from '../../Button';
import IconTrash from '../../icons/IconTrash';

class RouteCard extends Component {
  constructor(props) {
    super(props);
    this.preferred_route = React.createRef();
    this.preferred_route_wrapper = React.createRef();
  }

  deleteRoute() {
    // handle animation
    this.preferred_route.current.style.width = this.preferred_route.current.offsetWidth + 'px';
    this.preferred_route_wrapper.current.classList.add('delete');
    setTimeout(
      function() {
        this.props.onDelete(this.props.id);
      }.bind(this),
      2000
    );
  }

  componentDidMount() {
    this.preferred_route_wrapper.current.classList.add('loaded');
  }

  render() {
    return (
      <div className={'col col-12 col-sm-6 col-lg-4 card-route-wrapper'} ref={this.preferred_route_wrapper}>
        <div className={'card card-route'} ref={this.preferred_route}>
          <div className={'content-wrap'}>
            <div className={'location location-a'}>{this.props.from}</div>
            <div className={'location-arrow-container'}>
              <div className={'location-arrow'} />
            </div>
            <div className={'location location-b'}>{this.props.to}</div>
          </div>
          <div className={'card-actions card-footer'}>
            <Button buttonStyle={'tertiary'} onClick={() => this.deleteRoute()}>
              <Translate id={'profile_page.preferred_routes.delete'} />
              <IconTrash />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default RouteCard;
