const defaultState = {
  isFetching: false,
  error: '',
  list: [],
  total: 0,
};

export const types = {
  ADD_VEHICLE_START: 'ADD_VEHICLE_START',
  ADD_VEHICLE_ERROR: 'ADD_VEHICLE_ERROR',
  ADD_VEHICLE_SUCCESS: 'ADD_VEHICLE_SUCCESS',
  FETCH_VEHICLES_START: 'FETCH_VEHICLES_START',
  FETCH_VEHICLES_ERROR: 'FETCH_VEHICLES_ERROR',
  FETCH_VEHICLES_SUCCESS: 'FETCH_VEHICLES_SUCCESS',
  REMOVE_VEHICLE_START: 'REMOVE_VEHICLE_START',
};

export const getVehicles = state => {
  return state.vehicles.list;
};

export const actions = {
  removeVehicle: id => ({ type: types.REMOVE_VEHICLE_START, payload: id }),
  addVehicle: vehicle => ({ type: types.ADD_VEHICLE_START, payload: vehicle }),
  addVehicleFailure: err => ({ type: types.ADD_VEHICLE_ERROR, payload: err }),
  addVehicleSuccess: () => ({ type: types.ADD_VEHICLE_SUCCESS }),
  fetchVehicles: () => ({ type: types.FETCH_VEHICLES_START }),
  fetchVehiclesFailure: () => ({ type: types.FETCH_VEHICLES_ERROR }),
  fetchVehiclesSuccess: payload => ({ type: types.FETCH_VEHICLES_SUCCESS, payload }),
};

const vehicles = (state = defaultState, action) => {
  switch (action.type) {
    case types.REMOVE_VEHICLE_START:
      const filteredList = state.list.filter(item => item['@id'] !== action.payload);
      return {
        ...state,
        list: filteredList,
        total: filteredList.length,
      };
    case types.ADD_VEHICLE_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        error: '',
        isFetching: false,
      };
    case types.ADD_VEHICLE_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case types.FETCH_VEHICLES_START:
      return {
        ...state,
        error: '',
        isFetching: true,
        list: state.list,
      };
    case types.FETCH_VEHICLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload['hydra:member'],
        total: action.payload['hydra:totalItems'],
      };
    case types.FETCH_VEHICLES_ERROR:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
        total: 0,
      };
    default:
      return state;
  }
};

export default vehicles;
