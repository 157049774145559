import React, { Component } from 'react';

import './css/Tooltip.css';
import IconInfo from './icons/IconInfo';

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.tooltip = React.createRef();
    this.tooltipBody = React.createRef();
  }
  componentDidMount() {
    const viewportOffset = this.tooltipBody.current.getBoundingClientRect();
    this.addClassTooltip(viewportOffset.x);
  }
  render() {
    return (
      <div className={'dd_tooltip dd_tooltip-' + this.props.color} ref={this.tooltip}>
        <IconInfo color={this.props.color} />
        <div className={'dd_tooltip-body'} ref={this.tooltipBody}>
          {this.props.children}
        </div>
      </div>
    );
  }
  addClassTooltip(positionX) {
    const viewportSpace = 150;
    if (window.innerWidth - positionX < viewportSpace) {
      this.tooltip.current.classList.add('dd_tooltip-left');
    }
    if (positionX < viewportSpace) {
      this.tooltip.current.classList.add('dd_tooltip-right');
    }
  }
}

export default Tooltip;
