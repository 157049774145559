import { getTranslate } from 'react-localize-redux';
import { store } from './index';
import _get from 'lodash/get';
import commonTranslations from './translations/common.nl-NL';
import driverTranslations from './translations/frontend.nl-NL';

export const translate = (translateKey, data = {}) => {
  return getTranslate(store.getState().localize)(translateKey, data);
};

/**
 * report any missing translation as error to sentry
 * @param translationId
 * @param languageCode
 * @returns {string}
 */
export const onMissingTranslation = ({ translationId, languageCode }) => {
  // Handle frontend behaviour
  if (process.env.REACT_APP_ENV === 'dev') {
    return `(!) KEY:${translationId} LANG: ${languageCode}`;
  } else {
    return '';
  }
};

/**
 * @param lang {string}
 * @param callback {function}
 * @returns all translations data
 */
export const getTranslationData = (lang, callback) => {
  callback(commonTranslations, lang);
  callback(driverTranslations, lang);
  fetch(`https://api.brenger.nl/i18n/translations/${lang}?target=js`)
    .then(resp => resp.json())
    .then(resp => {
      if (_get(resp, 'common.client', false)) {
        callback(resp.common.client, lang);
      }
      if (_get(resp, 'driver', false)) {
        callback(resp.driver, lang);
      }
      if (_get(resp, 'customer', false)) {
        callback(resp.customer, lang);
      }
    });
};
