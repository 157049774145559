import React, { Component } from 'react';
import IconCross from '../../icons/IconCross';
import Heading from '../../Heading';
import { Button } from '../../Button';
import '../../css/Filter-list.css';
import '../../css/Form.css';
import DatePicker from '../../forms/DatePicker';
import moment from 'moment';
import { Translate } from 'react-localize-redux';

class DeliveredFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: this.props.filters.dateFrom,
      dateTo: this.props.filters.dateTo,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSetFilter = this.handleSetFilter.bind(this);
  }

  handleSubmit() {
    this.props.submitFilters({
      ...this.state,
    });
  }

  handleSetFilter(name, date) {
    this.setState({
      [name]: date,
    });
  }

  render() {
    return (
      <div className={'filter-list overlay' + (this.props.open ? ' is-open' : '')}>
        <div className={'filter-list-content'}>
          <div className={'filter-list-heading'}>
            <Heading size="h4">
              <Translate id={'search.filters.title'} />
            </Heading>
            <div className={'filter-list-close'} onClick={() => this.props.handleToggleFilters()}>
              <IconCross />
            </div>
          </div>
          <div className="filter-list-body">
            <div className="input-row">
              <b>
                <Translate id={'search.filters.date_header_plural'} />:
              </b>
            </div>
            <div className="input-row">
              <div className={'input-element type-text'}>
                <b>
                  <Translate id={'datetime.from'} />:
                </b>
                <DatePicker
                  name={'date-picker-from'}
                  handleChange={date => this.handleSetFilter('dateFrom', moment(date))}
                  value={this.state.dateFrom}
                />
              </div>
              {!this.props.filters.dateFrom.isSame(this.state.dateFrom) && (
                <div
                  className={'remove-date-filter'}
                  onClick={() => this.handleSetFilter('dateFrom', this.props.filters.dateFrom)}
                >
                  <IconCross />
                </div>
              )}
            </div>
            <div className="input-row">
              <div className={'input-element type-text'}>
                <b>
                  <Translate id={'datetime.until'} />:
                </b>
                <DatePicker
                  name={'date-picker-to'}
                  handleChange={date => this.handleSetFilter('dateTo', moment(date))}
                  value={this.state.dateTo}
                />
              </div>
              {!this.props.filters.dateTo.isSame(this.state.dateTo) && (
                <div
                  className={'remove-date-filter'}
                  onClick={() => this.handleSetFilter('dateTo', this.props.filters.dateTo)}
                >
                  <IconCross />
                </div>
              )}
            </div>
          </div>
          <div className="filter-list-footer filter-toggle-wrap">
            <Button buttonStyle={'secondary'} onClick={() => this.handleSubmit()}>
              <Translate id={'search.filters.show_results_no_count'} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveredFilters;
