import React from 'react';
import '../css/IconStyle.css';

const IconPalletJack = () => {
  return (
    <svg viewBox={'0 0 450 450'} className={'icon-pallet-jack'}>
      <g>
        <g>
          <path d="M231.051,215.952H98.066V79h55.63L231.051,215.952z M118.917,195.101h76.41l-53.798-95.25h-22.612V195.101z" />
        </g>
        <g>
          <path
            d="M490,335.683H341.4c-1.425,0-2.587-1.16-2.587-2.585V87.706h-20.851v196.362L172.261,37.065H63.221v202.223h-8.69
                        c-24.577,0-44.568,19.991-44.568,44.563v63.129C3.643,357.495,0,369.793,0,382.93c0,38.602,31.404,70.005,70.006,70.005
                        c38.602,0,70.005-31.404,70.005-70.005v-0.001h68.753v0.001c0,38.602,31.404,70.005,70.006,70.005
                        c38.602,0,70.005-31.404,70.005-70.005c0-9.336-1.844-18.246-5.178-26.395H490V335.683z M70.006,432.084
                        c-27.103,0-49.155-22.052-49.155-49.154c0-27.103,22.052-49.155,49.155-49.155c27.102,0,49.154,22.052,49.154,49.155
                        C119.161,410.032,97.109,432.084,70.006,432.084z M211.937,362.078H136.84c-8.895-28.45-35.491-49.155-66.834-49.155
                        c-14.51,0-28.002,4.439-39.193,12.028V283.85c0-13.073,10.639-23.712,23.717-23.712h29.541V57.916h76.318l157.573,267.035
                        c-11.19-7.588-24.682-12.027-39.192-12.027C247.428,312.923,220.831,333.628,211.937,362.078z M278.771,432.084
                        c-27.102,0-49.155-22.052-49.155-49.154c0-27.103,22.053-49.155,49.155-49.155c27.102,0,49.154,22.052,49.154,49.155
                        C327.926,410.032,305.873,432.084,278.771,432.084z"
          />
          <path
            d="M373.629,320.05h95.764c9.546,0,17.308-7.763,17.308-17.307v-67.924c0-9.545-7.763-17.307-17.308-17.307h-95.764
                        c-9.545,0-17.308,7.763-17.308,17.307v67.924C356.321,312.287,364.084,320.05,373.629,320.05z M377.172,238.362h88.678v60.837
                        h-88.678V238.362z"
          />
          <path
            d="M373.629,204.718h95.764c9.546,0,17.308-7.763,17.308-17.307v-67.925c0-9.544-7.763-17.307-17.308-17.307h-95.764
                        c-9.545,0-17.308,7.763-17.308,17.307v67.925C356.321,196.955,364.084,204.718,373.629,204.718z M377.172,123.029h88.678v60.837
                        h-88.678V123.029z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconPalletJack;
