import React from 'react';
import { Button } from '../../Button';
import { Translate } from 'react-localize-redux';
import * as globals from '../../globals';
import Modal from '../../Modal';
import { translate } from '../../UtilsTranslation';
import { SECTIONS } from '../../App';

const SuspendedModal = () => {
  return (
    <Modal open={true} header={<Translate id={'transport_request.messages.suspended_heading'} />}>
      <Translate
        id={'transport_request.messages.suspended'}
        data={{
          planningPath: SECTIONS.default.path,
          accountmanager: translate(globals.driverAccountManager),
          phoneNumber: '<a href="tel:' + globals.driverHelpDeskNumber + '">' + globals.driverHelpDeskNumber + '</a>',
        }}
        options={{ renderInnerHtml: true }}
      />
      <div className={'modal-footer'}>
        <Button href={globals.generalContactUrl} target={'_blank'}>
          <Translate id={'communication.contact_us'} />
        </Button>
      </div>
    </Modal>
  );
};

export default SuspendedModal;
