import React, { Component } from 'react';
import '../../css/Tabs.css';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { setDocumentTitle } from '../../Utils';

class NavigationTabs extends Component {
  constructor(props) {
    super(props);
    this.tabIndicator = React.createRef();
    this.state = {
      doctitle: '',
    };
  }

  handleActiveState(itemName = false) {
    if (this.tabIndicator.current) {
      const parentTabs = this.tabIndicator.current.parentNode;
      let activeTab = parentTabs.getElementsByClassName('is-active');
      if (itemName !== false) {
        activeTab = parentTabs.getElementsByClassName('tab-' + itemName);
      }
      let width = 0,
        offset = 0,
        doctitle = '';
      if (activeTab.length !== 0) {
        width = activeTab[0].offsetWidth;
        offset = activeTab[0].offsetLeft;
        doctitle = activeTab[0].textContent;
      }

      this.setState({
        doctitle: doctitle,
      });

      this.tabIndicator.current.setAttribute(
        'style',
        `
        width: ${width}px;
        transform: translate3d(${offset}px, 0,0);
      `
      );
    }
  }

  componentDidMount() {
    // unfortunately the delay is necessary when it is being redirected to default section.
    // example '/profile/non-existing-bla-bla' => 'profile/status'
    setTimeout(
      function() {
        this.handleActiveState();
      }.bind(this),
      100
    );
    window.onpopstate = () => {
      this.handleActiveState();
    };
    window.onresize = () => {
      this.handleActiveState();
    };
  }

  render() {
    if (this.props.withDoctitle) {
      setDocumentTitle(
        this.state.doctitle === ''
          ? this.props.translate('profile_page.title')
          : this.state.doctitle + ' ' + this.props.translate('profile_page.title')
      );
    }
    return (
      <div className="dd-tabs sticky hide-print animated">
        <div className={'tab-indicator'} ref={this.tabIndicator}>
          &nbsp;
        </div>
        {this.props.header && (
          <h1 className={'tabs-header'}>
            <Translate id={this.props.header} />
          </h1>
        )}
        {this.props.subheader && (
          <p className={'tabs-sub-header'}>
            <Translate id={this.props.subheader} />
          </p>
        )}
        <div className={'tabs-navigation'}>
          {this.props.navItems.map((item, index) => {
            return (
              <NavLink
                key={index}
                className={'tab tab-' + item.name}
                activeClassName="is-active"
                to={item.path}
                onClick={() => this.handleActiveState(item.name)}
              >
                <Translate id={item.name} />
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }
}

export default NavigationTabs;
