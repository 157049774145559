import React, { Component } from 'react';
import { Col } from '../../Grid';
import _get from 'lodash/get';
import { getProvinceAbbr } from '../../UtilsAddress';

class ItemAddress extends Component {
  render() {
    return (
      <Col xs="6">
        <div className="city-name">
          <span className="short">
            {`${_get(this.props.stop, 'address.locality', '')}, `}
            {getProvinceAbbr(_get(this.props.stop, 'address.administrative_area', ''))}
          </span>
        </div>
      </Col>
    );
  }
}

export default ItemAddress;
