import { types as searchTypes } from '../../reducers/search';

export const toggleFilters = () => ({ type: searchTypes.TOGGLE_SEARCH_FILTERS });
export const setSearchFilter = filter => ({ type: searchTypes.SET_SEARCH_FILTER, filter });
export const setSearchSorting = sortType => ({ type: searchTypes.SET_SEARCH_SORTING, sortType });
export const setCityFilter = (filter, uuid) => ({
  type: searchTypes.SET_CITY_FILTER,
  data: { filter: filter, uuid: uuid },
});
export const handleToggleFilters = () => {
  return dispatch => {
    dispatch(toggleFilters());
  };
};

export const handleSearchFilters = filter => {
  return dispatch => {
    dispatch(setSearchFilter(filter));
  };
};

export const handleCityFilters = (filter, uuid) => {
  return dispatch => {
    dispatch(setCityFilter(filter, uuid));
  };
};

export const handleSearchSorting = sortType => {
  return dispatch => {
    dispatch(setSearchSorting(sortType));
  };
};
