import { connect } from 'react-redux';
import PreviewList from '../../components/preview/PreviewList';
import { fetchTransportJobsStart } from '../../actions/search';

import { onTransportJobClick, selectTransportJob, deselectTransportJob } from '../../actions/preview';

import * as appUtils from '../../Utils';

const mapStateToProps = state => ({
  transportJobs: state.search.transportJobs,
  renderType: 'preview',
  isFetching: state.search.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchTransportJobs: () => dispatch(fetchTransportJobsStart()),
  onTransportJobClick: (id, renderType) => dispatch(onTransportJobClick(id, renderType)),
  deselectTransportJob: () => {
    dispatch(deselectTransportJob);
    appUtils.deActivateSingleView();
  },
  selectTransportJob: uuid => {
    dispatch(selectTransportJob(uuid));
    appUtils.activateSingleView();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewList);
