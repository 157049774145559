import React, { Component } from 'react';
import moment from 'moment';
import { missingImageSrc } from '../../globals';
import * as utilMessages from '../../UtilsMessages';
import StatusLabel from '../../StatusLabel';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';

class ConversationItem extends Component {
  getDisplayName(item) {
    if (item.job['@type'] === 'CustomerMessage') {
      return utilMessages.displayName(item.job.customer);
    } else if (item.job['@type'] === 'DriverMessage') {
      return utilMessages.displayName(item.job.author);
    } else {
      return item.transport.pickups[0].address.locality + ' - ' + item.transport.deliveries[0].address.locality;
    }
  }
  render() {
    if (!_get(this.props.conversation, 'job.updated_at', false)) {
      return <div />;
    }
    return (
      <div
        className={
          'card conversation-item' +
          (this.props.isBundled ? ' is-bundled ' : '') +
          (this.props.isSelected ? ' is-active' : '')
        }
        onClick={() => this.props.onClick(this.props.conversation.job.transport_job_account_link['@id'])}
        id={this.props.conversation.job['@id']}
      >
        <div className={'profile-pic-wrap'}>
          <div
            className="profile-picture"
            style={{
              backgroundImage: 'url(' + missingImageSrc + ')',
            }}
          />
        </div>
        <div className="message-teas-wrap">
          <div className={'conversation-timestamp'}>
            {utilMessages.formatTimestamp(moment(this.props.conversation.job.updated_at))}
          </div>
          <div className={'conversation-title'}>{this.getDisplayName(this.props.conversation)}</div>
          <p
            dangerouslySetInnerHTML={utilMessages.createMarkup(
              utilMessages.cleanMessage(this.props.conversation.job.body)
            )}
          />
          {
            <div className={'conversation-item-footer'}>
              {this.props.isBundled && <StatusLabel status={'bundle'} />}
              <StatusLabel status={this.props.conversation.job.transport_job_account_link.state} />
              {this.props.isNew && (
                <div className={'is-new-label'}>
                  <Translate id="status.new" />
                </div>
              )}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ConversationItem;
