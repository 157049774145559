import { connect } from 'react-redux';
import RouteButton from '../../components/planning/RouteButton';
import { postGeoLocationForEta } from '../../actions/planning/SingleViewActions';
import { actions } from '../../reducers/user';

const mapStateToProps = (state, ownProps) => ({
  direction: ownProps.direction,
  googleFormat: ownProps.googleFormat,
  address: ownProps.address,
  person: ownProps.person,
  locationPreference: state.user.preferences.location,
  stopId: ownProps.stopId,
});

const mapDispatchToProps = dispatch => ({
  postGeoLocationForEta: stop_id => dispatch(postGeoLocationForEta(stop_id)),
  startUserLocationPreference: () =>
    dispatch(
      actions.startLocationPreferences(geoPermission => dispatch(actions.setLocationPreferences(geoPermission)))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteButton);
