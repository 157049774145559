import { types as conversationTypes } from '../../reducers/conversations';
import * as appUtils from '../../Utils';
import history from '../../History';

const SITEPREFIX = appUtils.getPathForApp();

export const toggleCommentSubmit = () => ({ type: conversationTypes.TOGGLE_COMMENT_SUBMIT });
export const fetchMessagesStart = offerId => ({ type: conversationTypes.FETCH_MESSAGES_START, offerId });
export const fetchListMessagesStart = () => ({ type: conversationTypes.FETCH_LIST_MESSAGES_START });
export const fetchListMessagesStop = () => ({ type: conversationTypes.FETCH_LIST_MESSAGES_STOP });
export const fetchMessagesSuccess = (uuid, payload) => ({
  type: conversationTypes.FETCH_MESSAGES_SUCCESS,
  uuid,
  payload,
});
export const fetchMessagesError = error => ({ type: conversationTypes.FETCH_MESSAGES_ERROR, error });
export const setInboxMessages = messages => ({ type: conversationTypes.SET_DRIVER_MESSAGES, messages });
export const markMessageAsRead = (offerId, messageId, updateConversations = false) => ({
  type: conversationTypes.MARK_MESSAGE_READ_START,
  offerId,
  messageId,
  updateConversations,
});
export const markMessageAsReadSuccess = id => ({ type: conversationTypes.MARK_MESSAGE_READ_SUCCESS, id });
export const selectConversation = id => ({ type: conversationTypes.SELECT_CONVERSATION, id });
export const setSelectedMessages = (id, messages) => ({
  type: conversationTypes.PUT_SELECTED_MESSAGES,
  payload: { id, messages },
});
export const deselectConversation = () => ({ type: conversationTypes.DESELECT_CONVERSATION });
export const updateChatSession = job => ({ type: conversationTypes.UPDATE_CHAT_SESSION, payload: { job } });

export const onConversationClick = id => ({
  type: conversationTypes.OPEN_CHAT_SESSION,
  id,
});

export const closeConversation = () => {
  appUtils.deActivateSingleView();
  return dispatch => {
    dispatch(deselectConversation());
    let link = SITEPREFIX + '/messages/';
    history.push(link);
  };
};
