import { connect } from 'react-redux';
import Layout from '../../components/routePlanner/Layout';
import { fetchTransportJobsStart } from '../../actions/search';
import { handleToggleFilters, handleSearchSorting, handleCityFilters } from '../../actions/search/Filters';
import { hyphensToCamelCase } from '../../Utils';
import moment from 'moment';
import { getSelectedTransportJobId } from '../../reducers/search';
import * as appUtils from '../../Utils';
import _get from 'lodash/get';

const getFilteredTransportJobs = (transportJobs, filters) => {
  let filteredTransportJobs = transportJobs;

  filteredTransportJobs =
    filters.date !== '' ? filteredTransportJobs.filter(item => dateFilter(item, filters.date)) : filteredTransportJobs;
  filteredTransportJobs = filteredTransportJobs.filter(item => applyFilter(item.size_type.system_name, filters.size));
  filteredTransportJobs = filteredTransportJobs.filter(item => countryFilter(item, filters.countryPickup, 'pickup'));
  filteredTransportJobs = filteredTransportJobs.filter(item =>
    countryFilter(item, filters.countryDelivery, 'delivery')
  );
  filteredTransportJobs = regionFilter(filteredTransportJobs, filters.countryPickup, filters.regionPickup, 'pickup');
  filteredTransportJobs = regionFilter(
    filteredTransportJobs,
    filters.countryDelivery,
    filters.regionDelivery,
    'delivery'
  );
  filteredTransportJobs = filteredTransportJobs.filter(item => specialFilter(item, filters.special));
  filteredTransportJobs = filteredTransportJobs.filter(item =>
    applyFilter(hyphensToCamelCase(_get(item, 'pickups[0].situation.system_name', '')), filters.pickupSituation)
  );
  filteredTransportJobs = filteredTransportJobs.filter(item => !filters.responded || item.offers.length === 0);
  filteredTransportJobs = filteredTransportJobs.filter(item => !filters.prepaid || item.is_paid_in_advance);

  return filteredTransportJobs;
};

const dateFilter = (item, date) => {
  const pickupDaysOfItem = item.pickups[0].available_datetime_periods.map(period => moment(period.start));
  date = moment(date);
  if (pickupDaysOfItem.length === 0) {
    const deliveryDaysOfItem = item.deliveries[0].available_datetime_periods.map(period => moment(period.start));
    if (deliveryDaysOfItem.length === 0) {
      return true;
    } else {
      const lastDeliveryDay = deliveryDaysOfItem.slice(-1)[0];
      return lastDeliveryDay.isSameOrAfter(date, 'day');
    }
  }

  for (const pickupDayOfItem of pickupDaysOfItem) {
    if (pickupDayOfItem.isSame(date, 'day')) {
      return true;
    }
  }
  return false;
};

const specialFilter = (item, specialFilter) => {
  if (Object.values(specialFilter).every(item => !item)) {
    return true;
  }
  for (let specialRequirement of item.special_requirements) {
    if (applyFilter(hyphensToCamelCase(specialRequirement.system_name), specialFilter)) {
      return true;
    }
  }
  return false;
};

const applyFilter = (itemProp, filter) => {
  return filter[itemProp] || Object.values(filter).every(item => !item);
};

const regionFilter = (transportJobs, countryFilter, regionFilter, status) => {
  let filteredTransportJobs = transportJobs;
  const unfilteredCountries = Object.keys(countryFilter).filter(countryCode => {
    return countryFilter[countryCode];
  });
  for (let unfilteredCountry of unfilteredCountries) {
    if (!regionFilter[unfilteredCountry] || Object.values(regionFilter[unfilteredCountry]).every(item => !item)) {
      continue;
    }
    filteredTransportJobs = filteredTransportJobs.filter(item =>
      applyRegionFilter(item, unfilteredCountry, regionFilter[unfilteredCountry], status)
    );
  }
  return filteredTransportJobs;
};

const applyRegionFilter = (item, unfilteredCountry, regionFilter, status) => {
  const region = status === 'pickup' ? item.pickups[0].region : item.deliveries[0].region;
  const countryCode = status === 'pickup' ? item.pickups[0].country_code : item.deliveries[0].country_code;
  if (!region) {
    return false;
  }

  if (applyFilter(region, regionFilter)) {
    return true;
  }

  return unfilteredCountry !== countryCode.toLowerCase();
};

const countryFilter = (item, countryFilter, status) => {
  const countryCode =
    status === 'pickup' ? item.pickups[0].country_code.toLowerCase() : item.deliveries[0].country_code.toLowerCase();
  let mainCountries = Object.keys(countryFilter).filter(key => key !== 'other');
  return applyFilter(countryCode, countryFilter) || (countryFilter['other'] && !mainCountries.includes(countryCode));
};

const mapStateToProps = state => ({
  transportJobs: getFilteredTransportJobs(state.search.transportJobs, state.search.filters),
  renderType: 'search',
  commonRoutes: state.search.commonRoutes,
  selectedTransportJobId: getSelectedTransportJobId(state),
  sort: state.search.sort,
  filtersOpen: state.search.filtersOpen,
  filters: state.search.filters,
  citySortedItems: state.search.citySortedItems,
  isFetching: state.search.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchTransportJobs: () => {
    dispatch(fetchTransportJobsStart());
    appUtils.deActivateSingleView();
  },
  handleToggleFilters: () => dispatch(handleToggleFilters()),
  handleCityFilters: (filters, uuid) => dispatch(handleCityFilters(filters, uuid)),
  handleSearchSorting: event => dispatch(handleSearchSorting(event.target.value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
