import React from 'react';
import ReactAutocomplete from 'react-autocomplete';
import _get from 'lodash/get';
import IconInfo from '../icons/IconInfo';
import IconSmile from '../icons/IconSmile';
import '../css/Form-autocomplete.css';
import { Translate } from 'react-localize-redux';
import IconMarker from '../icons/IconMarker';
import { GEO_LOCATION_API } from '../globals';

class LocationAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new window.AbortController();
    this.minimumSearch = 3;
    this.state = {
      value: '',
      items: [],
      loading: false,
      noMatches: false,
    };
    this.baContainer = React.createRef();
  }

  getLocations(query) {
    if (this.state.loading) {
      this.controller.abort();
    }
    const autocomplete = this;
    this.setState({ loading: true });
    fetch(`${GEO_LOCATION_API}?q=${query}`, {
      signal: this.controller.signal,
    })
      .then(function(response) {
        if (response.ok) {
          response.json().then(data => {
            if (autocomplete.state.value.length >= autocomplete.minimumSearch) {
              autocomplete.setState({ loading: false, items: data, noMatches: data.length === 0 });
            }
          });
        }
      })
      .catch(error => console.log(error), this.setState({ loading: false }));
  }

  setMenuPosition() {
    const container = this.baContainer.current;
    const screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (screenHeight - container.getBoundingClientRect().y < 250) {
      container.classList.add('ba-options-up');
    } else {
      container.classList.remove('ba-options-up');
    }
  }

  render() {
    const autocompleteId = _get(this.props.extraInputProps, 'id', 'ba-brengerLocationAutoComplete');
    return (
      <div className={'input-row'}>
        <div
          className={'input-element type-ba ba-container' + (this.state.loading ? ' ba-is-loading' : '')}
          ref={this.baContainer}
        >
          {this.props.textLabel && <label htmlFor={autocompleteId}>{this.props.textLabel}</label>}
          <div className={'ba-wrapper '}>
            {this.state.loading && <IconSmile spinning={true} />}
            {!this.state.loading && <IconMarker />}
            <ReactAutocomplete
              wrapperStyle={{}}
              inputProps={{
                id: autocompleteId,
                className: 'ba-input-element ' + _get(this.props.extraInputProps, 'className', ''),
                onFocus: () => {
                  this.baContainer.current.classList.add('ba-focused');
                  this.setMenuPosition();
                },
                onBlur: () => this.baContainer.current.classList.remove('ba-focused'),
              }}
              items={this.state.items}
              getItemValue={item => item.label}
              value={this.state.value}
              onChange={(event, value) => {
                this.setState({ value: value });
                if (value.length >= this.minimumSearch) {
                  this.getLocations(value);
                } else {
                  if (this.state.loading) {
                    this.controller.abort();
                  }
                  this.setState({ items: [] });
                }
              }}
              onSelect={(value, item) => {
                this.setState({ value: item.label, items: [] });
                this.props.getValue(item);
              }}
              renderMenu={items => <div className="ba-menu">{items}</div>}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`ba-item ba-item--value-${item.label.replace(/[, ]+/g, '_')} ${
                    isHighlighted ? 'ba-item--highlighted' : ''
                  }`}
                  key={item.label.replace(/[, ]+/g, '_')}
                >
                  {item.label}
                </div>
              )}
              isItemSelectable={item => item.hasOwnProperty('label')}
              {...this.props}
            />
            <div className={'ba-feedback'}>
              {this.state.value === '' && (
                <div className="ba-feedback-item ">
                  <IconInfo />{' '}
                  <span>
                    <Translate id={'location.autocomplete.info'} />
                  </span>
                </div>
              )}
              {this.state.value.length > 0 && this.state.value.length < this.minimumSearch && (
                <div className="ba-feedback-item ba-feedback-item--hint">
                  <IconInfo />{' '}
                  <span>
                    <Translate id={'location.autocomplete.minimum'} data={{ number: this.minimumSearch }} />
                  </span>
                </div>
              )}
              {this.state.noMatches && (
                <div className="ba-feedback-item ba-feedback-item--no-matches">
                  <IconInfo />{' '}
                  <span>
                    <Translate id={'location.autocomplete.no_matches'} />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationAutocomplete;
