import { call, all, put, select, takeEvery } from 'redux-saga/effects';
import { prepareOfferItem } from '../actions/planning';
import { getSelectedDelivered, getSelectedDeliveredId, types as deliveredTypes } from '../reducers/delivered';
import {
  fetchDeliveredError,
  fetchDeliveredStart,
  fetchDeliveredSuccess,
  setDeliveredFilter,
  toggleDeliveredFilters,
  updateDeliveredJob,
} from '../actions/delivered';
import moment from 'moment';
import { getDeliveredDateFrom, getDeliveredDateTo } from '../reducers/delivered';
import http from '../UtilsHttp';
import { TJAL_STATUS, TJ_STATUS } from '../globals';
import { getCountries } from '../reducers/countries';
import _get from 'lodash/get';
import { fetchBundledJobStops } from './planning';
import { logException } from '../Utils';

export function* deliveredSagas() {
  yield takeEvery(deliveredTypes.FETCH_DELIVERED_START, fetchDeliveredSaga);
  yield takeEvery(deliveredTypes.SUBMIT_DELIVERED_FILTERS, submitFiltersSaga);
  yield takeEvery(deliveredTypes.SELECT_DELIVERED, selectDeliveredJob);
}

export function getTransportJob(url) {
  return http()
    .get(url, { withCredentials: true })
    .then(resp => resp);
}

export function setCountryCode(countries, job) {
  // set the country codes to addresses
  const pickup_country = countries.filter(country => country['@id'] === job.pickups[0].address.country);
  const delivery_country = countries.filter(country => country['@id'] === job.deliveries[0].address.country);
  job.pickups[0].address.country = _get(pickup_country[0], 'code', 'NL');
  job.deliveries[0].address.country = _get(delivery_country[0], 'code', 'NL');
  return job;
}

export function* selectDeliveredJob() {
  try {
    const countries = yield select(getCountries);
    const selectedId = yield select(getSelectedDeliveredId);
    const selectedJobLink = yield select(getSelectedDelivered);
    if (selectedJobLink) {
      const transportJob = yield call(getTransportJob, selectedJobLink.transport_job);
      if (_get(transportJob.data, 'pickups[0].address', false)) {
        transportJob.data = setCountryCode(countries, transportJob.data);
      }
      yield put(updateDeliveredJob(selectedId, transportJob.data));
    }
  } catch (err) {
    console.log(err);
    logException(err);
  }
}

export function fetchDeliveredJobs(from, to, page = 1) {
  const tjalStatusCodes = [TJAL_STATUS.DELIVERED, TJAL_STATUS.ACCEPTED];
  const tjStatusCodes = [
    TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER,
    TJ_STATUS.DELIVER_CONFIRMED_BY_CUSTOMER,
    TJ_STATUS.DELIVERED,
    TJ_STATUS.MIXED_DONE,
  ];
  const endpoint = 'transport_job_account_links';
  const params = {
    date_from: moment(from).format('YYYY-MM-DD HH:mm:ss'),
    date_to: moment(to).format('YYYY-MM-DD HH:mm:ss'),
    state: tjalStatusCodes.join(','),
    transport_job_state: tjStatusCodes.join(','),
    page: page,
  };
  return http()
    .get(endpoint, { params: params })
    .then(resp => resp);
}

export const scrollToSidebarItem = id => {
  const item = document.getElementById(`/transport_job_account_links/${id}`);
  if (item !== null) {
    // Behaviour smooth is not supported by all browser, we could see it as a nice to have, or polyfill it later
    item.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
  }
};

export function* fetchDeliveredSaga() {
  try {
    const countries = yield select(getCountries);
    const filterDateFrom = yield select(getDeliveredDateFrom);
    const filterDateTo = yield select(getDeliveredDateTo);
    const selectedId = yield select(getSelectedDeliveredId);

    // initial fetch within filters
    let filteredJobs = [];
    let page = 1;
    let jobLinks = yield call(fetchDeliveredJobs, filterDateFrom, filterDateTo, page);
    let items = jobLinks.data['hydra:member'];

    // fetch all pages
    page++;
    let nextPage = yield call(fetchDeliveredJobs, filterDateFrom, filterDateTo, page);

    while (nextPage.data['hydra:member'].length !== 0) {
      items = [...items, ...nextPage.data['hydra:member']];
      page++;
      nextPage = yield call(fetchDeliveredJobs, filterDateFrom, filterDateTo, page);
    }

    // let's filter delivered jobs by range dates in client-side
    for (let item of items) {
      item = prepareOfferItem(item);

      // fetch selected transport job
      if (selectedId && item['@id'].indexOf(selectedId) > -1) {
        const selectedJob = yield call(getTransportJob, item.transport_job);
        item.transport_job_details = selectedJob.data;
      }

      // fetch all stops details for bundled jobs
      if (item.transport_job_bundled) {
        const job = yield call(getTransportJob, item.transport_job);
        if (job.data.transport_route) {
          const stops = yield call(fetchBundledJobStops, job.data);
          item.transport_job_details = {
            ...item.transport_job_details,
            stops: stops,
            pickups: [stops[0].pickup],
            deliveries: [stops[stops.length - 1].delivery],
          };
        } else {
          continue;
        }
      } else {
        item.transport_job_details = {
          ...item.transport_job_details,
          pickups: [item.pickup_commitments[0].pickup],
          deliveries: [item.delivery_commitments[0].delivery],
        };
      }
      if (_get(item, 'transport_job_details.pickups[0].address', false)) {
        item.transport_job_details = setCountryCode(countries, item.transport_job_details);
      }
      filteredJobs.push(item);
    }
    yield put(fetchDeliveredSuccess(filteredJobs));
    if (selectedId) {
      yield call(scrollToSidebarItem, selectedId);
    }
  } catch (e) {
    console.log(e);
    yield put(fetchDeliveredError(e));
  }
}

function* submitFiltersSaga(params) {
  try {
    for (const key of Object.keys(params.filters)) {
      yield put(setDeliveredFilter(key, params.filters[key]));
    }
    yield all([put(fetchDeliveredStart()), put(toggleDeliveredFilters())]);
  } catch (e) {
    yield console.log(e);
  }
}
