import React from 'react';
import '../css/IconStyle.css';

const IconManyObjects = () => {
  return (
    <svg version="1.1" viewBox="0 0 100.8 84" width={100.8} height={84}>
      <polyline className="line" points="75.2,82 75.2,58.4 75.2,34.8 " />
      <g>
        <path d="M98.8,56.4H51.6c-1.1,0-2,0.9-2,2s0.9,2,2,2h47.2c1.1,0,2-0.9,2-2S99.9,56.4,98.8,56.4z" />
        <path
          d="M22.5,31.3L42.8,3.2c0.4-0.6,0.5-1.4,0.2-2.1c-0.3-0.7-1-1.1-1.8-1.1H2.2c-1.1,0-2,0.9-2,2s0.9,2,2,2h35L16.6,32.8
              c-0.5,0.7-0.5,1.6,0,2.3c0.5,0.7,1.3,1,2.1,0.8c10.3-2.4,15.6,2.8,18.2,7.5c5.2,9.4,4.2,24.8-2.1,31C31.2,78,21.5,85.1,8.5,74.3
              C3.4,70,4,59,4.1,58.8c0.1-1.1-0.8-2-1.9-2.1c-1.1-0.1-2,0.8-2.1,1.9c0,0.5-0.8,13.2,5.9,18.8c2.4,2,7.4,5.5,13.6,6.4
              c1,0.1,2,0.2,3,0.2c5.5,0,10.5-2.3,15-6.8c7.5-7.5,8.8-24.9,2.8-35.8C36.5,34.4,30.2,30.9,22.5,31.3z"
        />
      </g>
    </svg>
  );
};

export default IconManyObjects;
