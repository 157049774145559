import React from 'react';
import { Button } from '../../Button';
import { Translate } from 'react-localize-redux';
import * as globals from '../../globals';
import Modal from '../../Modal';

const CustomerModal = () => {
  return (
    <Modal open={true} header={<Translate id={'transport_request.messages.suspended_heading'} />}>
      <p>
        <Translate id={'transport_request.messages.non_driver_role'} />
      </p>
      <div className={'modal-footer'}>
        <Button href={globals.generalContactUrl} target={'_blank'}>
          <Translate id={'communication.contact_us'} />
        </Button>
      </div>
    </Modal>
  );
};

export default CustomerModal;
