import React, { Component } from 'react';
import { SectionWrapper } from '../../components/layout/SectionWrapper';
import { setDocumentTitle } from '../../Utils';
import { translate } from '../../UtilsTranslation';
import VehicleDetails from '../onboard/VehicleDetails';
import { actions, getVehicles } from '../../reducers/vehicles';
import { connect } from 'react-redux';
import { getUserDetails } from '../../reducers/user';

class AddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
    };
    this.handleNavigation = this.handleNavigation.bind(this);
    this.addVehicle = this.addVehicle.bind(this);
  }
  addVehicle(vehicle) {
    let currentVehicles = this.state.vehicles;
    currentVehicles.push({
      has_tailgate: false,
      has_pallet_jack: false,
      data_verified: false,
      color: 'white',
      license_plate: vehicle.licensePlate,
      account: this.props.user.account['@id'],
      make: vehicle.make,
      images: vehicle.image !== '' ? vehicle.image : [],
      model: vehicle.model,
      loading_weight_limit_kg: parseInt(vehicle.loading_weight_limit_kg, 10),
      loading_dimensions_width_cm: parseInt(vehicle.loading_dimensions_width_cm, 10),
      loading_dimensions_height_cm: parseInt(vehicle.loading_dimensions_height_cm, 10),
      loading_dimensions_length_cm: parseInt(vehicle.loading_dimensions_length_cm, 10),
    });
    this.setState({ vehicles: currentVehicles });
  }
  componentDidMount() {
    setDocumentTitle(translate('menu.dashboard'));
  }
  handleNavigation() {
    this.props.postVehicle(this.state.vehicles[0]);
  }
  render() {
    return (
      <div>
        <SectionWrapper centerContent={true}>
          <VehicleDetails
            renderHeader={false}
            multipleVanAllowed={false}
            vehicles={this.state.vehicles}
            country={this.props.countries.find(item => item['@id'] === this.props.address.country)}
            addVehicle={this.addVehicle}
            error={this.props.error}
            handleNavigation={this.handleNavigation}
          />
        </SectionWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: getUserDetails(state),
  address: state.user.address,
  countries: state.countries.userChoices,
  vehicles: getVehicles(state),
  error: state.vehicles.error,
});

const mapDispatchToProps = dispatch => ({
  postVehicle: vehicle => dispatch(actions.addVehicle(vehicle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVehicle);
