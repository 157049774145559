import React from 'react';

/**
 *
 * @param {Boolean} spinning
 * @param {Boolean} centered
 * @returns {*}
 * @constructor
 */
const LocationMarker = ({ direction, textLabel }) => {
  return (
    <div className={'location-label location-' + direction}>
      <div className={'location-text'}>{textLabel}</div>
      {direction === 'pickup' && (
        <svg viewBox="695.5 422.5 25 25" width="17" height="17">
          <circle
            cx="708"
            cy="435"
            r="10"
            fill="#FFF"
            strokeWidth="5"
            stroke="#0073fa"
            strokeLinejoin="miter"
            strokeLinecap="square"
            strokeMiterlimit="3"
          />
        </svg>
      )}
      {direction === 'delivery' && (
        <svg viewBox="698 425 20 20" width="17" height="17">
          <circle vectorEffect="non-scaling-stroke" cx="708" cy="435" r="10" fill="#0073fa" />
        </svg>
      )}
    </div>
  );
};

export default LocationMarker;
