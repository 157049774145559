import React from 'react';
import './css/FlashMessage.css';

/**
 *
 * @param type
 * @param message
 * @returns {*}
 * @constructor
 */
const FlashMessage = ({ type, message }) => {
  let messages = [];
  if (message.toString().indexOf('Network') > -1) {
    return <p>Network error!</p>;
  }
  // deal with react components
  if (typeof message['$$typeof'] === 'symbol') {
    return <p className={'message message--' + type}>{message}</p>;
  }

  // deal with message strings as objects
  if (typeof message === 'object') {
    for (var i in message)
      messages.push(
        <p key={i} className={'message message--' + type}>
          {message[i]}
        </p>
      );
    return <div>{messages}</div>;
  }

  // deal with default messaging
  return <p className={'message message--' + type}>{message}</p>;
};

export default FlashMessage;
