import React from 'react';
import '../css/IconStyle.css';

const IconCheckmarkWithCircle = () => {
  return (
    <svg className={'IconCheckmarkWithCircle'} width="48" height="49" viewBox="0 0 48 49">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 .353)">
          <mask id="b" fill="#fff">
            <path id="a" d="M0 .072h47.625v22.394H0z" />
          </mask>
          <path
            fill="#FFF"
            d="M46.586 22.465c-.521 0-.97-.39-1.03-.92-1.253-11.059-10.6-19.398-21.744-19.398-11.143 0-20.49 8.34-21.743 19.398a1.037 1.037 0 1 1-2.062-.234C1.379 9.203 11.613.072 23.812.072c12.2 0 22.434 9.131 23.806 21.24a1.038 1.038 0 0 1-1.032 1.153"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(0 25.84)">
          <mask id="d" fill="#fff">
            <path id="c" d="M0 .119h47.625v22.394H0z" />
          </mask>
          <path
            fill="#FFF"
            d="M23.812 22.513c-12.2 0-22.433-9.13-23.805-21.24a1.038 1.038 0 0 1 2.061-.232C3.322 12.099 12.67 20.439 23.812 20.439c11.143 0 20.491-8.34 21.744-19.398a1.038 1.038 0 0 1 2.062.233c-1.372 12.109-11.606 21.24-23.806 21.24"
            mask="url(#d)"
          />
        </g>
        <path
          fill="#FFF"
          d="M19.73 33.18a1.037 1.037 0 0 1-.734-1.77l14.05-14.05a1.037 1.037 0 1 1 1.467 1.467l-14.05 14.05a1.034 1.034 0 0 1-.733.303"
        />
        <path
          fill="#FFF"
          d="M19.73 33.18c-.266 0-.531-.101-.734-.304l-5.884-5.884a1.037 1.037 0 1 1 1.467-1.467l5.884 5.884a1.037 1.037 0 0 1-.733 1.77"
        />
      </g>
    </svg>
  );
};

export default IconCheckmarkWithCircle;
