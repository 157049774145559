import React from 'react';
import '../../css/Section-wrapper.css';

/**
 *
 * @returns {*}
 * @constructor
 */
export const SectionWrapper = props => {
  return (
    <section className={'section-wrapper section-wrapper-style-' + props.background + ' ' + props.extraClasses}>
      <div className={'section-wrapper-content' + (props.centerContent ? ' main-centered-content' : '')}>
        {props.children}
      </div>
    </section>
  );
};
