import React, { Component } from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { Button } from '../../Button';
import { translate } from '../../UtilsTranslation';
import FlashMessage from '../../FlashMessage';

class BundledOfferForm extends Component {
  render() {
    const scheduleDate = _get(this.props.stops[0], 'proposed_datetime_period.start', null);
    let stopsToRender = [];
    this.props.stops.forEach((stop, index) => {
      const stopType = _get(stop, 'pickup', false) ? 'pickup' : 'delivery';
      const locality = _get(stop, `${stopType}.address.locality`, '');
      const startTime = moment(stop.proposed_datetime_period.start).format('HH');
      const endTime = moment(stop.proposed_datetime_period.end).format('HH');
      stopsToRender.push(
        <p key={`offer-stop-${index}`}>
          Stop {index + 1}: {locality} - {`${startTime}:00`} {`${endTime}:00`}
        </p>
      );
    });
    return (
      <>
        {this.props.stops.length === 0 || !scheduleDate ? (
          <h4>
            <Translate id={'bundled.offer.invalid'} />
          </h4>
        ) : (
          <div className={'bundled-offer-form'}>
            <p>
              <Translate id={'bundled.offer.description'} />
            </p>
            <b className={'start-date'}>
              <Translate id={'bundled.offer.schedule_for'} /> {moment(scheduleDate).format('llll')}
            </b>
            <div className={'offer-form-stops-list'}>{stopsToRender}</div>
            <p>
              <Translate id={'bundled.offer.receive_data_after_claim'} />
            </p>
            <div className={'conditions'}>
              <Translate id={'transportRequest.conditions'} options={{ renderInnerHtml: true }} />
            </div>
            <div className={'errors'}>
              {this.props.errors && this.props.errors.length > 0 && (
                <FlashMessage message={this.props.errors[0]} type={'error'} />
              )}
            </div>
            <Button
              type="submit"
              disabled={false}
              loading={false}
              buttonStyle="primary"
              onClick={() => this.claimOffer()}
            >
              <Translate id={'form.send'} />
            </Button>
          </div>
        )}
      </>
    );
  }
  claimOffer() {
    this.props.showModal({
      modalType: 'prompt',
      modalProps: {
        header: <Translate id={'modal.are_you_sure'} />,
        action: () => this.props.postBundledOffer(this.props.transportJobId),
        prompts: [
          {
            name: 'general',
            text: translate('modal.ok'),
          },
        ],
        message: [
          <span>
            * <b>{translate('search.messages.bundled.message')}</b>
          </span>,
        ],
        actionText: <Translate id={'offer.claim'} />,
      },
    });
  }
}

export default BundledOfferForm;
