import { connect } from 'react-redux';
import MagicMap from '../../components/routePlanner/MagicMap';
import { getUserDetails } from '../../reducers/user';

const mapStateToProps = state => {
  return {
    user: getUserDetails(state),
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MagicMap);
