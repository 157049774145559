import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import InputText from './InputText';
import Heading from '../Heading';
import { Button } from '../Button';
import { Container, Col, Row } from '../Grid.js';
import * as MainApp from '../App';
import { Link } from 'react-router-dom';
import FlashMessage from '../FlashMessage';
import InputPassword from './InputPassword';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      email: '',
      password: '',
      errors: {
        email: '',
        password: '',
      },
    };
    this.body = document.querySelector('body');
  }
  componentDidMount() {
    this.body.classList.add('bd-hide-menu');
  }
  componentWillUnmount() {
    this.body.classList.remove('bd-hide-menu');
  }

  render() {
    return (
      <Container fluid={true}>
        <Row>
          <Col xs={12} md={6} lg={4} extraClasses={'offset-md-3 offset-lg-4'}>
            <div className={'login-wrapper'}>
              <Heading size={'h4'}>
                <Translate id={'form.login.title'} />
              </Heading>
              <p>
                <Translate
                  id={'form.login.intro'}
                  data={{
                    contact: (
                      <a href="https://www.brenger.nl/contact">
                        <Translate id={'form.login.contact'} />
                      </a>
                    ),
                  }}
                />
              </p>
              <form className={'login-form'}>
                <div className={'input-row'}>
                  <InputText
                    name={'name'}
                    label={<Translate id={'form.fields.login.email.label'} />}
                    onChange={event => this.handleEmail(event.target.value)}
                    error={this.state.errors.email}
                    value={this.state.email}
                  />
                </div>
                <div className={'input-row'}>
                  <InputPassword
                    name={'password'}
                    label={<Translate id={'form.fields.login.password.label'} />}
                    onChange={event => this.handlePassword(event.target.value)}
                    error={this.state.errors.password}
                    value={this.state.password}
                  />
                </div>
                <div className={'btn-wrapper'}>
                  <Button
                    disabled={this.props.isSubmitting || this.state.email === '' || this.state.password === ''}
                    loading={this.props.isSubmitting}
                    buttonStyle={'primary'}
                    onClick={event => {
                      event.preventDefault();
                      this.props.onSubmit({
                        email: this.state.email,
                        password: this.state.password,
                        navigateTo:
                          this.props.redirectUrl !== '' ? this.props.redirectUrl : MainApp.SECTIONS.planning.path,
                      });
                    }}
                  >
                    <Translate id={'form.login.button'} />
                  </Button>
                </div>
                <p>{this.props.errors !== '' && <FlashMessage message={this.props.errors} type={'error'} />}</p>
                <p>
                  <Translate id={'authentication.reset.lost_password'} />{' '}
                  <a href="https://www.brenger.nl/nl/customer/password_reset">
                    <Translate id={'authentication.general.click_here'} />
                  </a>
                </p>
                <p>
                  <Translate
                    id={'form.register_text'}
                    data={{
                      register: (
                        <Link to={MainApp.SECTIONS.onboarding.path}>
                          <Translate id={'form.register_here'} />
                        </Link>
                      ),
                    }}
                  />
                </p>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  handleEmail(value) {
    let errors = this.state.errors;
    if (value.length === 0) {
      errors.email = <Translate id={'form.fields.login.email.required'} />;
    } else {
      errors.email = '';
    }
    this.setState({ email: value, errors: errors });
  }

  handlePassword(value) {
    let errors = this.state.errors;
    if (value.length === 0) {
      errors.password = <Translate id={'form.fields.login.password.required'} />;
    } else {
      errors.password = '';
    }
    this.setState({ password: value, errors: errors });
  }
}

export default LoginForm;
