import React, { Component } from 'react';
import moment from 'moment';
import './css/Messages.css';
import * as utilMessages from './UtilsMessages';
import * as appUtils from './Utils';
import _get from 'lodash/get';

class Messages extends Component {
  componentDidMount() {
    this.bindViewPortChecks();
  }
  getAuthorLabel(item) {
    const type = item['@type'];
    switch (type) {
      case 'SystemMessage':
        return 'Brenger Team';
      case 'DriverMessage':
        return _get(item, 'author.first_name', false) + ' ' + _get(item, 'author.last_name', false);
      case 'CustomerMessage':
        return _get(item, 'customer.first_name', '') + ' ' + _get(item, 'customer.last_name', false);
      default:
        return 'Anonymous';
    }
  }
  render() {
    const messages = _get(this.props.messages, 'items', false) ? this.props.messages.items : this.props.messages;
    if (messages && Object.keys(messages).length > 0) {
      return messages.map((item, index) => {
        const lines = utilMessages.cleanMessage(item.body).map((item, index) => <p key={'msg' + index}>{item}</p>);
        const author = this.getAuthorLabel(item);
        const extraClassName =
          item['@type'] === 'SystemMessage' || item['@type'] === 'DriverMessage' ? ' their-message' : '';
        return [
          <div className={'card single-comment ' + extraClassName} key={index} id={item['@id']}>
            <div className={'single-comment-header'}>
              <span className={'author'}>{author}</span>
              {moment(item.created_at).isValid() && (
                <span className={'date-time'}>{utilMessages.formatTimestamp(moment(item.created_at))}</span>
              )}
            </div>
            <div className={'single-comment-body'}>
              {lines.map((line, index) => (
                <p key={index} dangerouslySetInnerHTML={utilMessages.createMarkup(line.props.children)} />
              ))}
            </div>
          </div>,
        ];
      });
    } else {
      return <div />;
    }
  }

  bindViewPortChecks() {
    if (!this.props.hasNew || this.props.messages.length === 0) {
      return;
    }
    // First check without scroll
    this.isInViewPort();
    // attach listeners
    if (window.innerWidth < 993) {
      appUtils.addEvent(document.querySelector('.single-view'), 'scroll', this.isInViewPort.bind(this));
    } else {
      appUtils.addEvent(window, 'scroll', this.isInViewPort.bind(this));
      appUtils.addEvent(window, 'resize', this.isInViewPort.bind(this));
    }
  }

  isInViewPort() {
    const messageElements = document.querySelectorAll('.single-comment');
    // check if messages are fully rendered
    if (messageElements.length === 0) {
      return;
    }
    // get last message element
    const lastMessageElement = messageElements[messageElements.length - 1];
    // when not in viewport, keep checking
    if (appUtils.isElementInViewport(lastMessageElement) === false) {
      return;
    }
    // remove listeners
    if (window.innerWidth < 993) {
      appUtils.removeEvent(document.querySelector('.single-view'), 'scroll', this.isInViewPort.bind(this));
    } else {
      appUtils.removeEvent(window, 'scroll', this.isInViewPort.bind(this));
      appUtils.removeEvent(window, 'resize', this.isInViewPort.bind(this));
    }
    // check if we marked it allready
    if (this.props.markedAsRead.includes(this.props.selectedConversationId) === true) {
      return;
    }
    // We are about to mark them as read
    // Prevent triggering twice
    if (this.props.isPatching === true) {
      return;
    }
    this.props.fetchMarkMessageAsRead(
      this.props.selectedConversationId,
      lastMessageElement.getAttribute('id'),
      this.props.updateConversations
    );
  }
}

export default Messages;
