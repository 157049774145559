import React from 'react';
import StatusLabel from '../../StatusLabel';
import Heading from '../../Heading';
import IconSmile from '../../icons/IconSmile';
import Medal from '../../icons/Medal';
import CountUp from './CountUp';
import '../../css/Card-stats.css';
import { NavLink } from 'react-router-dom';

/**
 *
 * @returns {*}
 * @constructor
 */
export const CardStats = props => {
  return (
    <CardStatusWrapper {...props}>
      {props.status && <StatusLabel status={props.status} />}
      {props.preText && <div>{props.preText}</div>}
      <Heading size={'h5'}>
        <span className={'text--' + props.textColor}>
          {props.bignumber && (
            <span className={'bignumber'}>
              {props.bignumber.hasOwnProperty('prefix') && (
                <span className={'bignumber-prefix'}>{props.bignumber.prefix}</span>
              )}
              <CountUp number={props.bignumber.value} />
              {props.bignumber.hasOwnProperty('suffix') && (
                <span className={'bignumber-suffix'}>{props.bignumber.suffix}</span>
              )}
            </span>
          )}
        </span>
      </Heading>
      {props.subText && <div>{props.subText}</div>}
      {props.medal && <Medal type={props.medal} animated={true} />}
      {(props.href || props.onClick) && <IconSmile />}
    </CardStatusWrapper>
  );
};
const CardStatusWrapper = props => {
  if (typeof props.href !== 'undefined') {
    return (
      <NavLink to={props.href} className={'card card-status clickable' + (props.medal ? ' card-with-medal' : '')}>
        {props.children}
      </NavLink>
    );
  }
  return (
    <div
      className={'card card-status' + (props.onClick ? ' clickable' : '') + (props.medal ? ' card-with-medal' : '')}
      onClick={props.onClick ? () => props.onClick() : null}
    >
      {props.children}
    </div>
  );
};
