import React, { Component } from 'react';
import '../../css/Section-header.css';
import MainNavigation from './MainNavigation';
import BrengerLogo from '../../icons/BrengerLogo';
import { Col, Row, Container } from '../../Grid.js';
import { missingImageSrc } from '../../globals';
import * as MainApp from '../../App';
import * as InfoApp from '../../components/profile/InfoApp';
import history from '../../History';
import { connect } from 'react-redux';

class MainHeader extends Component {
  render() {
    let navItems = [
      {
        title: 'Dashboard',
        url: MainApp.SECTIONS.profile.path,
        extra_classes: 'menu-index-1',
      },
      {
        title: 'Opdrachten',
        url: MainApp.SECTIONS.default.path,
        extra_classes: 'menu-index-2',
      },
      {
        title: 'Hoe het werkt',
        url: 'https://www.brenger.nl/brenger-voor-koeriers',
        extra_classes: 'menu-index-3',
        is_normal_link: true,
      },
      {
        title: 'Berichten',
        url: MainApp.SECTIONS.inbox.path,
        extra_classes: 'menu-index-4',
      },
      {
        title: 'Favoriete routes',
        url: MainApp.SECTIONS.routes.path,
        extra_classes: 'menu-index-7',
      },
      {
        title: '',
        url: MainApp.SECTIONS.profile.path,
        image: this.props.profileImage || missingImageSrc,
        extra_classes: 'menu-index-5',
        children: [
          {
            title: 'Profiel',
            url: InfoApp.SECTIONS.details.path,
            extra_classes: 'menu-index-6',
          },
          {
            title: 'Uitloggen',
            url: 'https://api.brenger.nl/logout',
            extra_classes: 'hide-mobile menu-index-7 dd-nav-item--offset color-grey',
            is_normal_link: true,
          },
        ],
      },
    ];

    return (
      <header className={'bd-main-header'}>
        <Container fluid={true}>
          <Row>
            <Col xs={4} md={2} extraClasses={'logo-col'}>
              <span
                className={'trigger'}
                onClick={() => {
                  history.push(MainApp.SECTIONS.planning.path);
                }}
              >
                <BrengerLogo />
              </span>
            </Col>
            <Col xs={8} md={10}>
              <MainNavigation items={navItems} is_main_app_loaded={this.props.is_main_app_loaded} />
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    profileImage: state.user.isLoggedIn ? state.user.details.profile_image : null,
    user: state.user,
  };
};

export default connect(mapStateToProps)(MainHeader);
