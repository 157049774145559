import { connect } from 'react-redux';
import Routes from '../../components/routes/RoutesList';
import { deletePreferredRouteStart, fetchPreferredRoutes } from '../../actions/preferredRoutes';

const getPreferredRoutes = (preferredRoutes, filter) => {
  //Filters can be implemented later if needed
  return preferredRoutes;
};

const mapStateToProps = state => ({
  preferredRoutes: getPreferredRoutes(state.preferredRoutes.routes, {}),
  isFetching: state.preferredRoutes.isFetching,
});

const mapDispatchToProps = dispatch => ({
  deletePreferredRoute: id => dispatch(deletePreferredRouteStart(id)),
  fetchPreferredRoutes: () => dispatch(fetchPreferredRoutes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
