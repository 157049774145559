import React from 'react';
import Select from './Select';

/**
 * Time Picker params.
 * @value {string} chosen time frame format(HH:mm).
 * @options {array} list of available time options [[value, label]]
 * @id {string} element id
 * @name {string} element name
 * @disabled {boolean} disability status
 * @handleChange {function} onChange event callback function
 * @return {number} The result of adding num1 and num2.
 */

class TimePicker extends React.Component {
  componentDidUpdate(prevProps) {
    document.getElementById(this.props.id).value = this.props.value;
  }

  render() {
    return <Select {...this.props} />;
  }
}

export default TimePicker;
