import React, { Component } from 'react';
import '../../css/Progress-header.css';
import { ProgressBar } from '../ProgressBar';
import { ProgressSteps } from '../ProgressSteps';
import BrengerLogo from '../../icons/BrengerLogo';
import IconSmile from '../../icons/IconSmile';

class ProgressHeader extends Component {
  constructor(props) {
    super(props);
    this.body = document.querySelector('body');
  }

  componentDidMount() {
    this.checkProcess();
    this.body.classList.add('bd-progress-header-loaded');
  }
  componentWillUnmount() {
    this.body.classList.remove('bd-progress-header-loaded', 'bd-progress-header-finished', 'bd-hide-menu');
  }
  componentDidUpdate() {
    this.checkProcess();
  }

  checkProcess() {
    if (parseInt(this.props.currentSection) > parseInt(this.props.totalSections)) {
      this.body.classList.add('bd-progress-header-finished', 'bd-hide-menu');
      this.body.classList.remove('bd-progress-header-loaded');
    } else {
      this.body.classList.remove('bd-progress-header-finished', 'bd-hide-menu');
    }
  }

  render() {
    return (
      <header className={'progress-header'}>
        {this.props.currentSection !== 1 && this.props.currentSection >= 2 && (
          <span className={'go-back trigger'} onClick={() => this.props.handleNavigation(false)}>
            <IconSmile />
          </span>
        )}
        <BrengerLogo />
        <ProgressSteps {...this.props} />
        <ProgressBar {...this.props} />
      </header>
    );
  }
}
export default ProgressHeader;
