import React from 'react';
import IconSmile from '../icons/IconSmile';
import '../css/Form-select.css';
import _get from 'lodash/get';

class Select extends React.Component {
  render() {
    const isTranslationRequired = _get(this.props, 'translate', null);
    return (
      <div className={'input-select-wrapper'}>
        <select
          disabled={this.props.disabled || false}
          name={this.props.name}
          id={this.props.id || ''}
          defaultValue={this.props.selected}
          onChange={event => this.props.handleChange(event)}
        >
          {this.props.options.map((item, index) => {
            return (
              <option key={index} value={item[0]}>
                {isTranslationRequired ? this.props.translate(item[1]) : item[1]}
              </option>
            );
          })}
        </select>
        <IconSmile />
      </div>
    );
  }
}
export default Select;
