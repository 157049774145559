import React from 'react';
import { Radio, RadioGroup } from 'react-radio-group';
import moment from 'moment';
import '../css/Card.css';
import '../css/Conversation.css';
import '../css/Form.css';
import '../css/PikaDay.css';
import { Col, Row } from '../Grid.js';
import FlashMessage from '../FlashMessage';
import DatePicker from './DatePicker';
import { Translate } from 'react-localize-redux';
import TimePicker from './TimePicker';
import _get from 'lodash/get';

class DatetimePeriodsField extends React.Component {
  constructor(props) {
    super(props);

    this.handleRadioGroupChange = this.handleRadioGroupChange.bind(this);
    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }
  handleRadioGroupChange(selectedIndex) {
    let newState = {
      selectedDatetimePeriodIndex: selectedIndex,
      selectedDatetimePeriod: {
        start: moment(this.props.availableDatetimePeriods[selectedIndex].start),
        end: moment(this.props.availableDatetimePeriods[selectedIndex].end),
      },
      chosenDatetimePeriod: {
        start: moment(this.props.availableDatetimePeriods[selectedIndex].start),
        end: moment(this.props.availableDatetimePeriods[selectedIndex].start).add(4, 'hours'),
      },
      errors: [],
    };
    this.props.handleDatetimePeriodChange(this.props.name, newState);
    setTimeout(() => {
      const startValue = newState.chosenDatetimePeriod.start.format('HH:mm');
      const endValue = newState.chosenDatetimePeriod.end.format('HH:mm');
      const startTime = document.getElementById(`${this.props.name}-start-time`);
      const endTime = document.getElementById(`${this.props.name}-end-time`);
      startTime.value = startValue;
      endTime.value = endValue;
    }, 250);
  }

  handleDatePickerChange(date) {
    let newState = {};
    const selectedDate = moment(date);
    if (date === null) {
      newState = {
        ...this.props.state,
        chosenDatetimePeriod: {},
      };
    } else {
      newState = {
        ...this.props.state,
        selectedDatetimePeriod: {
          start: selectedDate.clone().set({ hour: 8, minute: 0 }),
          end: selectedDate.clone().set({ hour: 22, minute: 0 }),
        },
        chosenDatetimePeriod: {
          start: selectedDate.clone().set({ hour: 8, minute: 0 }),
          end: selectedDate.clone().set({ hour: 12, minute: 0 }),
        },
      };
    }
    this.props.handleDatetimePeriodChange(this.props.name, newState);
  }

  handleTimeChange(event) {
    let [hour, minute] = event.target.value.split(':');
    let chosenDatetimePeriod = this.props.state.chosenDatetimePeriod;

    if (event.target.name.endsWith('start-time')) {
      chosenDatetimePeriod.start.set({
        hour: hour,
        minute: minute,
      });
    }

    if (event.target.name.endsWith('end-time')) {
      chosenDatetimePeriod.end.set({
        hour: hour,
        minute: minute,
      });
    }
    let newState = {
      ...this.props.state,
      chosenDatetimePeriod: chosenDatetimePeriod,
    };
    this.props.handleDatetimePeriodChange(this.props.name, newState);
  }

  getTimeOptions(limit, startTime = '8:00', endTime = '22:00') {
    let timeOptions = [];
    let [startHour, startMinute] = startTime.split(':');
    let [endHour, endMinute] = endTime.split(':');
    const startMoment = moment()
      .hour(parseInt(startHour))
      .minute(parseInt(startMinute));
    const endMoment = moment()
      .hour(parseInt(endHour))
      .minute(parseInt(endMinute))
      .subtract(limit ? 0 : 0, 'hour');
    for (let i = 0; startMoment.isBefore(endMoment); i++) {
      const time = startMoment.add(i > 0 ? 30 : 0, 'minute');
      timeOptions.push([time.format('HH:mm'), time.format('HH:mm')]);
    }
    timeOptions.unshift([null, '']);

    return timeOptions;
  }

  render() {
    let state = this.props.state;
    let selectDatetimePeriod;
    const selectedDatePeriod = _get(this, 'state.selectedDatetimePeriodIndex', 0);
    if (this.props.availableDatetimePeriods.length > 0) {
      selectDatetimePeriod = (
        <Col xs={12}>
          <RadioGroup
            className={'input-row'}
            name={this.props.name}
            selectedValue={state.selectedDatetimePeriodIndex}
            onChange={this.handleRadioGroupChange}
          >
            {this.props.availableDatetimePeriods.map((item, index) => (
              <div className={'input-element type-radio'} key={index}>
                <Radio value={index} id={`${this.props.name}-${index}`} />
                <label htmlFor={`${this.props.name}-${index}`}>
                  {moment(item.start).format('DD-MM-YYYY (HH:mm - ') + moment(item.end).format('HH:mm)')}
                </label>
              </div>
            ))}
          </RadioGroup>
        </Col>
      );
    } else {
      selectDatetimePeriod = (
        <Col xs={12} md={4}>
          <div className={'input-element type-text'} key={1}>
            <label htmlFor={this.props.name + '-date'}>
              <Translate id={'datetime.date'} />
            </label>
            <DatePicker
              min={this.props.min}
              // Datepicker should receive american formatted date
              value={
                state.chosenDatetimePeriod.hasOwnProperty('start')
                  ? state.chosenDatetimePeriod.start.format('MM-DD-YYYY')
                  : null
              }
              name={this.props.name + '-date'}
              handleChange={this.handleDatePickerChange.bind(this)}
            />
          </div>
        </Col>
      );
    }
    return (
      <div>
        <Row>
          {selectDatetimePeriod}
          <Col xs={6} md={4}>
            <div className={'input-row'}>
              <div className={'input-element type-text'}>
                <label htmlFor={`${this.props.name}-start-time`}>
                  <Translate id={'datetime.from'} />
                </label>
                <TimePicker
                  value={
                    state.chosenDatetimePeriod.hasOwnProperty('start')
                      ? state.chosenDatetimePeriod.start.format('HH:mm')
                      : ''
                  }
                  options={this.getTimeOptions(
                    true,
                    this.props.availableDatetimePeriods.length === 0
                      ? undefined
                      : this.props.availableDatetimePeriods[selectedDatePeriod].start.format('HH:mm'),
                    this.props.availableDatetimePeriods.length === 0
                      ? undefined
                      : this.props.availableDatetimePeriods[selectedDatePeriod].end.format('HH:mm')
                  )}
                  id={`${this.props.name}-start-time`}
                  name={`${this.props.name}-start-time`}
                  handleChange={this.handleTimeChange}
                  disabled={!state.chosenDatetimePeriod.hasOwnProperty('start')}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} md={4}>
            <div className={'input-row'}>
              <div className={'input-element type-text'}>
                <label htmlFor={`${this.props.name}-end-time`}>
                  <Translate id={'datetime.until'} />
                </label>
                <TimePicker
                  value={
                    state.chosenDatetimePeriod.hasOwnProperty('end')
                      ? state.chosenDatetimePeriod.end.format('HH:mm')
                      : ''
                  }
                  options={this.getTimeOptions(
                    false,
                    state.chosenDatetimePeriod.hasOwnProperty('start')
                      ? state.chosenDatetimePeriod.start.format('HH:mm')
                      : undefined,
                    state.chosenDatetimePeriod.hasOwnProperty('start')
                      ? state.chosenDatetimePeriod.start
                          .clone()
                          .add(4, 'hour')
                          .format('HH:mm')
                      : undefined
                  )}
                  id={`${this.props.name}-end-time`}
                  name={`${this.props.name}-end-time`}
                  handleChange={this.handleTimeChange}
                  disabled={!state.chosenDatetimePeriod.hasOwnProperty('start')}
                />
              </div>
            </div>
          </Col>
        </Row>
        {state.errors.length > 0 &&
          state.errors.map((message, index) => <FlashMessage key={index} type={'error'} message={message} />)}
      </div>
    );
  }
}

export default DatetimePeriodsField;
