/// Internal methods
const WA_BASE_LINK = 'https://api.whatsapp.com/send?phone=';
const TEL_PREFIX = 'tel:';
const REACHABLE_SUFFIX = ['06', '+316', '00316'];
const SUFFIX_LOCAL_TO_INTERNATIONAL = [
  { from: '06', to: '316' },
  { from: '04', to: '324' },
  { from: '3106', to: '316' },
  { from: '3204', to: '324' },
];
const AFTER_CLEAN_SUFFIX_SHOULD_CREATE_WA_LINK = [
  '316', // Dutch
  '324', // Belgium
];

/**
 *
 * @param {String} input
 * @returns {Boolean}
 */
const isReachableNumber = phoneNumber => {
  if (!phoneNumber) return false;
  return (
    REACHABLE_SUFFIX.includes(phoneNumber.slice(0, 2)) ||
    REACHABLE_SUFFIX.includes(phoneNumber.slice(0, 4)) ||
    REACHABLE_SUFFIX.includes(phoneNumber.slice(0, 5))
  );
};

/**
 *
 * @param {String} input
 * @returns {String}
 */
const removeSpace = input => {
  return input.replace(/\s/g, '');
};

/**
 *
 * @param {String} input
 * @returns {String}
 */
const removePrecedingPlus = input => {
  if ('+' !== input.charAt(0)) {
    return input;
  }

  return input.replace('+', ''); // Replaces only the first occurrence
};

/**
 *
 * @param {String} input
 * @returns {String}
 */
const localToInternational = input => {
  SUFFIX_LOCAL_TO_INTERNATIONAL.some(({ from, to }) => {
    if (input.startsWith(from)) {
      input = input.replace(from, to);
      return true;
    }

    return false;
  });

  return input;
};

/**
 *
 * @param {String} number
 * @returns {boolean}
 */
const shouldCreateWhatsappLink = number => {
  return AFTER_CLEAN_SUFFIX_SHOULD_CREATE_WA_LINK.includes(number.substring(0, 3));
};

/**
 *
 * @param {String} number
 * @returns {String}
 */
const createWhatsappPrefix = number => {
  return WA_BASE_LINK + number;
};

/**
 *
 * @param {String} number
 * @returns {String}
 */
const createPhonePrefix = number => {
  return TEL_PREFIX + removeSpace(number);
};

/**
 *
 * @param {String} number
 * @returns {String}
 */
const cleanPhoneNumber = number => {
  number = removeSpace(number);
  number = removePrecedingPlus(number);
  number = localToInternational(number);

  return number;
};

/// Exportable methods

/**
 *
 * @param {String} number
 * @returns {String}
 */
const whatsappOrPhone = number => {
  if (!number) {
    return '';
  }

  const cleanedNumber = cleanPhoneNumber(number);

  return shouldCreateWhatsappLink(cleanedNumber) ? createWhatsappPrefix(cleanedNumber) : createPhonePrefix(number);
};

/**
 *
 * @param {String} number
 * @returns {String}
 */
const whatsapp = number => {
  if (!number) {
    return '';
  }

  const cleanedNumber = cleanPhoneNumber(number);

  return shouldCreateWhatsappLink(cleanedNumber) ? createWhatsappPrefix(cleanedNumber) : '';
};

const phoneRegExp = /^[+]?[(]?[0-9]{3,4}[)]?[-\s]?[0-9]{3,4}[-\s]?[0-9]{4,8}$/im;

/// Exports
export { whatsapp, whatsappOrPhone, isReachableNumber, phoneRegExp };
