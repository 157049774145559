import React from 'react';
import '../css/Medal-container.css';

const Medal = props => {
  if (typeof props.type === 'undefined') {
    return null;
  }
  const colors = {
    silver: {
      accent: '#7D8893',
      fill: '#dce2e9',
    },
    gold: {
      // this is silver color
      accent: '#7D8893',
      fill: '#dce2e9',
    },
    platinum: {
      // this is gold color
      accent: '#ffec93',
      fill: '#d4b604',
    },
    diamond: {
      // this is actually diamond, wow
      accent: '#70D1F4',
      fill: '#DDEEED',
    },
  };
  return (
    <div className={'medal-container' + (props.animated ? ' medal-animated' : '') + ' medal-type-' + props.type}>
      <svg width="50" height="88" viewBox="0 0 50 88">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="50%" y2="50%">
            <stop offset="0%" stopColor="#FFF" />
            <stop offset="100%" stopColor="#BABCBF" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M44.274.13H35.62l-17.31 50.53H31.289L48.6.13z" />
          <circle
            cx="25"
            cy="63"
            r="23"
            fill={colors[props.type]['fill']}
            stroke={colors[props.type]['accent']}
            strokeWidth="4"
          />
          <path
            fill={colors[props.type]['accent']}
            d="M32.586 67.701a.38.38 0 0 1 .372.421C32.528 71.988 29.356 75 25.516 75c-3.84 0-7.012-3.012-7.44-6.878a.378.378 0 0 1 .37-.42h2.926c.174 0 .33.121.363.298.348 1.837 1.91 3.229 3.781 3.229 1.873 0 3.434-1.392 3.782-3.23a.37.37 0 0 1 .363-.298h2.925zm-6.701-10.398c5.253 0 6.967 4.163 7.114 6.862a.361.361 0 0 1-.36.382h-2.917a.372.372 0 0 1-.369-.34c-.097-.968-.654-3.662-3.836-3.662-3.22 0-3.752 2.747-3.84 3.69a.334.334 0 0 1-.326.312h-3.02a.338.338 0 0 1-.331-.344V51.804c0-.444.346-.803.773-.803l2.36-.001c.426 0 .772.36.772.802v7.001c.725-.75 2.243-1.5 3.98-1.5z"
          />
        </g>
      </svg>
      <div className={'shiners'}>&nbsp;</div>
      <div
        className={'medal-flag medal-flag--left'}
        style={{ borderColor: colors[props.type]['accent'], background: colors[props.type]['fill'] }}
      >
        <div style={{ borderColor: colors[props.type]['accent'] }} className={'flag-tip'}>
          &nbsp;
        </div>
      </div>
      <div
        className={'medal-flag medal-flag--right'}
        style={{ borderColor: colors[props.type]['accent'], background: colors[props.type]['fill'] }}
      >
        <div style={{ borderColor: colors[props.type]['accent'] }} className={'flag-tip'}>
          &nbsp;
        </div>
      </div>
      {props.type === 'react' && (
        <React.Fragment>
          <div className={'pattern'}>&nbsp;</div>
          <div className={'sparks'}>
            <div className={'sp_ sp_1'}>&nbsp;</div>
            <div className={'sp_ sp_2'}>&nbsp;</div>
            <div className={'sp_ sp_3'}>&nbsp;</div>
            <div className={'sp_ sp_4'}>&nbsp;</div>
            <div className={'sp_ sp_5'}>&nbsp;</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default Medal;
