import * as appUtils from '../Utils';

export const fetchGet = apiUrl => {
  return fetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
    },
  });
};

export const fetchUrl = (url, token = '', mode = 'cors') => {
  return new Promise((resolve, conflict) => {
    fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: mode,
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => resolve(data));
        }
      })
      .catch(error => conflict(error));
  });
};

export const fetchDelete = apiUrl => {
  return fetch(apiUrl, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  });
};

export const fetchPost = (token, apiUrl, message) => {
  return fetch(apiUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json; charset=utf-8',
      'x-csrf-token': token,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: message,
  });
};

export const fetchPatch = (token, apiUrl) => {
  return fetch(apiUrl, {
    method: 'PATCH',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'x-csrf-token': token,
    },
  });
};

export const uploadHttp = (token, apiUrl, file, headers) => {
  return fetch(appUtils.createApiUrl(apiUrl, 2), {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'multipart/form-data',
      ...headers,
    },
    body: file,
  });
};

export const fetchPut = (token, apiUrl, body, headers) => {
  return fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json',
    },
    body: JSON.stringify(body),
  });
};

export const fetchToken = () => {
  let tokenq = appUtils.createApiUrl(`session/token`);
  return new Promise((resolve, conflict) => {
    fetchGet(tokenq)
      .then(response => response.text().then(token => resolve(token)))
      .catch(error => conflict(error));
  });
};

export const fetchUser = () => {
  const apiUrl = appUtils.createApiUrl(`users/me`, 2);
  return fetchGet(apiUrl).then(response =>
    response.json().then(userData => {
      return userData;
    })
  );
};

export const fetchUserOldApi = () => {
  const apiUrl = appUtils.createApiUrl(`users/me`, 1);
  return fetchGet(apiUrl).then(response =>
    response.json().then(userData => {
      return userData;
    })
  );
};

export const fetchLoggedInState = () => {
  const apiUrl = appUtils.createApiUrl('/am_i_logged_in', 2);
  return fetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'text/plain',
    },
  })
    .then(status)
    .then(text)
    .then(text => text === 'yes')
    .catch(error => {
      console.log(error);
    });
};

export const fetchPostUser = data => {
  return fetch(appUtils.createApiUrl('users', 2), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
    .then(status)
    .then(json)
    .catch(error => {
      console.log(error);
    });
};

function status(response) {
  if ((response.status >= 200 && response.status < 300) || response.status === 400) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(new Error(response.statusText));
  }
}

function json(response) {
  return response.json();
}

function text(response) {
  return response.text();
}
