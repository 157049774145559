import React, { Component } from 'react';
import '../../css/Card-route.css';
import { Translate } from 'react-localize-redux';
import IconArrow from '../../icons/IconArrow';
import { Button } from '../../Button';
import List from '../../components/routes/RoutesList';
import Form from '../../forms/PreferredRouteForm';
import FlashMessage from '../../FlashMessage';
import { Col, Row } from '../../Grid';
import Heading from '../../Heading';
import { SectionWrapper } from '../layout/SectionWrapper';

class PreferedRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: [],
    };
  }
  render() {
    let validations = this.props.validations.entry;
    let messages = [];
    let disabled = this.props.disabled;
    let loading = this.props.loading;

    for (let field in validations) {
      messages[field] = React.createElement(FlashMessage, { message: validations[field], type: 'error' }, null);
    }

    return (
      <>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg routes-info-wrapper'}
          centerContent={true}
        >
          <Row>
            <Col xs={12}>
              <Heading size={'h5'}>
                <Translate id={'profile_page.preferred_routes.title'} />
              </Heading>
              <p>
                <Translate id={'profile_page.preferred_routes.explain'} />
              </p>
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'main-centered-content pt-1'}>
          <div className={'favorite-routes-in-register'}>
            <Form onSubmit={this.props.onRouteSubmit} isSubmitting={this.props.isSubmitting} />
            <List
              deletePreferredRoute={this.props.onRouteDelete}
              preferredRoutes={this.props.routes}
              fetchPreferredRoutes={() => {}}
            />
          </div>
          {messages['general']}
          <div className={'form-bottom-actions'}>
            <div className={'main-centered-content'}>
              <Button
                buttonStyle={'secondary block text-left'}
                loading={loading}
                disabled={disabled}
                iconPos={'right'}
                onClick={event => this.handleSubmit(event)}
              >
                <Translate id={'onboarding.step.next'} />
                <IconArrow />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.handleNavigation();
  }
}

export default PreferedRoutes;
