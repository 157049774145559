import React, { PureComponent } from 'react';
import { Col } from '../../Grid.js';
import IconSmile from '../../icons/IconSmile';
import moment from 'moment';
import '../../css/OneItem.css';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import BundledItemReward from '../bundled/BundledItemReward';
import BundledItemSpecs from '../bundled/BundledItemSpecs';
import BundledItemAddress from '../bundled/BundledItemAddress';
import { getBundledTimePeriod } from '../../UtilsDatetime';
import { getBundledMaxLoad, getUniqueStopAddressesCount } from '../../Utils';

class DeliveryBundledItem extends PureComponent {
  render() {
    const transport = this.props.item;
    const uuid = transport['@id'].split('transport_job_account_links/')[1];
    return (
      <div
        className={'card one-item-container bundle-job ' + (this.props.isSelected ? ' is-selected' : '')}
        onClick={() => this.props.onItemClick(uuid, 'delivered')}
      >
        <div className={'one-item-inner type-delivered'} id={this.props.item['@id']}>
          <div className={'dropdown-toggle'}>
            <IconSmile />
          </div>
          <div className="row address-details address-pickup">
            <BundledItemAddress transport={transport.transport_job_details} type={'pickup'} />
            <Col xs="5" extraClasses={'bundled-date-time-col'}>
              <div className="date" style={{ textTransform: 'capitalize' }}>
                {this.getBundledStartDate(transport)}
              </div>
              <div className={'time-window'}>± {getBundledTimePeriod(transport.transport_job_details)}</div>
              <div className={'max-load'}>
                <Translate id="bundled.details.max_load_abbr" />: {getBundledMaxLoad(transport.transport_job_details)} m
                <sup>3</sup>
              </div>
            </Col>
          </div>
          <p>{getUniqueStopAddressesCount(transport.transport_job_details) - 2} tussenstops</p>
          <div className="row address-details address-delivery">
            <BundledItemAddress transport={transport.transport_job_details} type={'delivery'} />
          </div>
          <>
            <BundledItemSpecs
              transport={transport}
              isPrepaid={transport.transport_job_prepaid}
              isBusiness={transport.transport_job_is_business}
            />
            <BundledItemReward transport={transport} isSelected={this.props.isSelected} renderType={'delivered'} />
          </>
        </div>
      </div>
    );
  }
  getBundledStartDate(transport) {
    transport = transport.transport_job_details;
    const date = _get(transport, `stops[0].proposed_datetime_period.start`, null);
    return date ? moment(date).format('dd D MMM') : '';
  }
}

export default DeliveryBundledItem;
