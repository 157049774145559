export const limitDayNum = 5;
export const missingImageSrc =
  '//d1kgnukroyc1ww.cloudfront.net/sites/default/files/styles/avatar/public/default_images/default_0.png?itok=O6Q5J3bZ';
export const anonymousTranslateKey = 'default.anonymous';
export const driverAccountManager = 'default.us';
export const driverHelpDeskNumber = '085-8881934';
export const driverHelpDeskEmail = 'info@brenger.nl';
export const driverAccountVerifyName = driverAccountManager;
export const driverAccountVerifyNumber = driverHelpDeskNumber;
export const generalContactUrl = 'https://www.brenger.nl/nl/contact-met-team-brenger';

//duration between user update cycle. 60000 === 1 minute
export const userRefreshTime = 60000;

// DRIVER_PROGRESS_LEVELS

export const PROGRESS_LEVELS = {
  bronze: 'LEVEL_BRONZE',
  gold: 'LEVEL_GOLD',
  platinum: 'LEVEL_PLATINUM',
  diamond: 'LEVEL_DIAMOND',
};

// transport job statuses

export const TJ_STATUS = {
  PUBLISHED: 'published',
  SUBMITTED: 'submitted',
  MIXED_DONE: 'mixed_done',
  MIXED_IN_PROGRESS: 'mixed_in_progress',
  EXPIRED: 'expired',
  DRIVER_FOUND: 'driver_found',
  MAX_DRIVERS_FOUND: 'max_drivers_found',
  DRIVER_CONFIRMED: 'driver_confirmed',
  DELIVER_CONFIRMED_BY_CUSTOMER: 'delivery_confirmed_by_customer',
  DELIVER_CONFIRMED_BY_DRIVER: 'delivery_confirmed_by_driver',
  DELIVERED: 'delivered',
  DISPUTED: 'disputed',
  CANCELLED: 'cancelled',
};

export const TJAL_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  REVOKED_BY_DRIVER: 'revoked_by_driver',
  REVOKED_BY_CUSTOMER_CARE: 'revoked_by_customer_care',
  DELIVERED: 'delivered',
  TRANSPORT_JOB_CANCELLED: 'transport_job_cancelled',
};

export const BUNDLED_STATUS = {
  READY_FOR_PICKUP: 'ready_for_pickup',
  PICKUP_CONFIRMED_BY_CUSTOMER: 'pickup_confirmed_by_customer',
  PICKUP_CONFIRMED_BY_DRIVER: 'pickup_confirmed_by_driver',
  PICKED_UP: 'picked_up',
  DELIVERY_CONFIRMED_BY_DRIVER: 'delivery_confirmed_by_driver',
  DELIVERY_CONFIRMED_BY_CUSTOMER: 'delivery_confirmed_by_customer',
  DELIVERED: 'delivered',
  DISPUTED: 'disputed',
  CANCELLED: 'cancelled',
};

// transport job situations

export const TJ_DETAILS_TYPES = {
  help_carrying_yes_equipment: 'equipment',
  help_carrying_yes_extra: 'extra_driver',
  help_carrying_no: 'not_needed',
  help_carrying_no_two: 'needed',
  equipment_tailgate: 'equipment_tailgate',
  equipment_tailgate_pallet_jack: 'equipment_tailgate_pallet_jack',
  date_custom: 'date_custom',
  time_custom: 'time_custom',
  floor_custom: 'floor_custom',
};

export const SITUATION_TYPES = {
  home: 'home',
  shop: 'shop',
  store: 'store',
  auction: 'auction',
  other: 'other',
};

export const PriceLabels = {
  rush: 'rush',
  budget: 'budget',
  sustainable: 'sustainable',
  standard: 'budget',
};

// endpoints
export const GEO_LOCATION_API = 'https://i3vfmxtba0.execute-api.eu-west-1.amazonaws.com/dev/geo/autocomplete';
export const _CALCULATE_POLYLINES_URL = `https://i3vfmxtba0.execute-api.eu-west-1.amazonaws.com/dev/geo/calculate_route`;
export const _SEARCH_GEO_POINT_URL = `https://i3vfmxtba0.execute-api.eu-west-1.amazonaws.com/dev/geo/autocomplete`;

// supported provinces

export const provincesList = [
  {
    name: 'Groningen',
    abbreviation: 'GR',
  },
  {
    name: 'Friesland',
    abbreviation: 'FR',
  },
  {
    name: 'Drenthe',
    abbreviation: 'DR',
  },
  {
    name: 'Overijssel',
    abbreviation: 'OV',
  },
  {
    name: 'Flevoland',
    abbreviation: 'FL',
  },
  {
    name: 'Gelderland',
    abbreviation: 'GD',
  },
  {
    name: 'Utrecht',
    abbreviation: 'UT',
  },
  {
    name: 'Noord-Holland',
    abbreviation: 'NH',
  },
  {
    name: 'Zuid-Holland',
    abbreviation: 'ZH',
  },
  {
    name: 'Zeeland',
    abbreviation: 'ZL',
  },
  {
    name: 'Noord-Brabant',
    abbreviation: 'NB',
  },
  {
    name: 'Limburg',
    abbreviation: 'LB',
  },
  {
    name: 'Antwerpen',
    abbreviation: 'ANT',
  },
  {
    name: 'Henegouwen',
    abbreviation: 'HAI',
  },
  {
    name: 'Luik',
    abbreviation: 'LIE',
  },
  {
    name: 'Limburg',
    abbreviation: 'LB',
  },
  {
    name: 'Luxemburg',
    abbreviation: 'LUX',
  },
  {
    name: 'Namen',
    abbreviation: 'NAM',
  },
  {
    name: 'Oost-Vlaanderen',
    abbreviation: 'OVL',
  },
  {
    name: 'Vlaams-Brabant',
    abbreviation: 'VBR',
  },
  {
    name: 'Waals-Brabant',
    abbreviation: 'WBR',
  },
  {
    name: 'West-Vlaanderen',
    abbreviation: 'WVL',
  },
  {
    name: 'Hainaut',
    abbreviation: 'HAI',
  },
];
