import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as appUtils from './Utils';
import { withLocalize } from 'react-localize-redux';
import './css/Profile-Pages.css';
import InfoApp from './components/profile/InfoApp';
import CriteriaApp from './components/profile/CriteriaApp';
import ProfileDashboard from './components/profile/ProfileDashboard';

const SITEPREFIX = appUtils.getPathForApp();
const SECTIONPREFIX = '/profile';

class ProfileApp extends Component {
  render() {
    return (
      <div className={'container-fluid'}>
        <Switch>
          <Route exact path={SITEPREFIX + SECTIONPREFIX} component={ProfileDashboard} />
          <Route path={SITEPREFIX + SECTIONPREFIX + '/info'} component={InfoApp} />
          <Route path={SITEPREFIX + SECTIONPREFIX + '/criteria'} component={CriteriaApp} />
          <Redirect to={SITEPREFIX + SECTIONPREFIX} />
        </Switch>
      </div>
    );
  }
}

export default withLocalize(ProfileApp);
