import moment from 'moment';
import _get from 'lodash/get';
import { PROGRESS_LEVELS, TJ_STATUS, TJAL_STATUS } from '../globals';

export const types = {
  SELECT_DELIVERED: 'SELECT_DELIVERED',
  DESELECT_DELIVERED: 'DESELECT_DELIVERED',
  OPEN_DELIVERED: 'OPEN_DELIVERED',
  CLOSE_DELIVERED: 'CLOSE_DELIVERED',
  REMOVE_DELIVERED: 'REMOVE_DELIVERED',
  FETCH_DELIVERED_START: 'FETCH_DELIVERED_START',
  FETCH_DELIVERED_SUCCESS: 'FETCH_DELIVERED_SUCCESS',
  SET_FETCHING_STATUS: 'SET_FETCHING_STATUS',
  FETCH_DELIVERED_ERROR: 'FETCH_DELIVERED_ERROR',
  FETCH_SINGLE_DELIVERED_START: 'FETCH_SINGLE_DELIVERED_START',
  SET_DELIVERED_FILTER: 'SET_DELIVERED_FILTER',
  TOGGLE_DELIVERED_FILTERS: 'TOGGLE_DELIVERED_FILTERS',
  SUBMIT_DELIVERED_FILTERS: 'SUBMIT_DELIVERED_FILTERS',
  FETCH_INVOICE_START: 'FETCH_INVOICE START',
  FETCH_INVOICE_SUCCESS: 'FETCH_INVOICE_SUCCESS',
  SET_ROUTING_DATA: 'SET_ROUTING_DATA',
  SET_SELECTED_JOB_DETAILS: 'SET_SELECTED_JOB_DETAILS',
  UPDATE_DELIVERED_JOB: 'UPDATE_DELIVERED_JOB',
  FETCH_INVOICE_ERROR: 'FETCH_INVOICE_ERROR',
  CLEAR_DELIVERED_ERRORS: 'CLEAR_DELIVERED_ERRORS',
};

const defaultState = {
  items: [],
  openItems: [],
  selectedId: '',
  isFetching: false,
  isFetchingInvoice: false,
  errors: [],
  filters: {
    dateFrom: moment(new Date()).add(-30, 'days'),
    dateTo: moment(new Date()).add(7, 'days'),
  },
};

//Selectors
export const getDeliveredById = (state, id) => {
  return state.delivered.items.find(offer => offer['@id'].split('transport_job_account_links/')[1] === id);
};
export const getSelectedDeliveredId = state => {
  return state.delivered.selectedId;
};
export const getSelectedDelivered = state => {
  return getDeliveredById(state, getSelectedDeliveredId(state));
};
export const getDeliveredDateFrom = state => {
  return state.delivered.filters.dateFrom;
};
export const getDeliveredDateTo = state => {
  return state.delivered.filters.dateTo;
};

export const isFetchingDelivered = state => {
  return state.delivered.isFetching;
};
export const getDeliveredFilters = state => {
  return state.delivered.filters;
};
export const isFetchingInvoice = state => {
  return state.delivered.isFetchingInvoice;
};

export const isBundledDelivered = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const stops = _get(job, 'transport_job_details.stops', false);
  let delivered = true;
  if (stops) {
    stops.forEach(stop => {
      const type = _get(stop, 'pickup', false) ? 'pickup' : 'delivery';
      if (_get(stop, `${type}.item_sets`, false)) {
        if (stop[type].item_sets[0].state !== 'delivery_confirmed_by_driver') {
          delivered = false;
        }
      }
    });
  } else {
    delivered = false;
  }
  return delivered;
};

export const getBundledStops = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'stops', []);
};

// return selected transport job or select by job id
export const getTransportJob = (state, jobId = null) => {
  return jobId ? getDeliveredById(state, jobId) : getSelectedDelivered(state);
};

export const getRouteDistance = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const isBundled = _get(job, 'transport_job_details.bundled', false);
  const stops = _get(job, 'transport_job_details.stops', []);
  return _get(
    job,
    isBundled ? `transport_job_details.stops[${stops.length - 1}].distance_km` : 'transport_job_details.route.distance',
    0
  );
};

export const getCurrentStatus = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const status = _get(job, 'state', TJ_STATUS.SUBMITTED);
  return _get(job, 'transport_job_details.own_transport_job_account_link.state', status);
};

export const isValidTransport = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const hasItemSet = _get(job, 'transport_job_details.item_sets[0]', false);
  return hasItemSet && job.transport_job_details.item_sets[0].items.length > 0;
};

export const getItemImages = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const images = [];
  _get(job, 'item_sets', []).map(itemSet => {
    itemSet.items.map(item => item.job_image && images.push(item.job_image));
    return images;
  });
  return images;
};
export const isProgressLevelRequired = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'transport_job_details.minimum_progress_level_required', PROGRESS_LEVELS.bronze);
};

export const isBundled = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'transport_job_details.bundled', false);
};

export const getItemCardElement = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return hasOwnOffer(state, jobId)
    ? job.transport_job_details.own_transport_job_account_link['@id']
    : _get(job, '@id', '');
};

export const isPaid = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'prepaid', false);
};

// check is there any offer from drivers for selected job
export const hasOwnOffer = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const offer = _get(job, 'transport_job_details.own_transport_job_account_link', false);
  const status = getCurrentStatus(state, jobId);
  if (
    !job ||
    status === TJAL_STATUS.REVOKED_BY_DRIVER ||
    status === TJAL_STATUS.REVOKED_BY_CUSTOMER_CARE ||
    status === TJAL_STATUS.TRANSPORT_JOB_CANCELLED
  ) {
    return false;
  }
  return !!offer;
};

const delivered = (state = defaultState, action) => {
  switch (action.type) {
    case types.SELECT_DELIVERED:
      if (state.selectedId !== action.id) {
        return {
          ...state,
          isFetching: true,
          selectedId: action.id,
        };
      } else {
        return state;
      }
    case types.DESELECT_DELIVERED:
      return {
        ...state,
        selectedId: '',
        isFetching: false,
      };
    case types.SET_SELECTED_JOB_DETAILS:
      let _items = state.items;
      const duplicate = _items.find(offer => offer['@id'] === action.payload['@id']);
      if (duplicate) {
        _items.find(offer => offer['@id'] === action.payload['@id']).transport_job_details =
          action.payload.transport_job_details;
      } else {
        _items.concat(action.payload);
      }
      return {
        ...state,
        items: _items,
        isFetching: false,
      };
    case types.OPEN_DELIVERED:
      return {
        ...state,
        openItems: state.openItems.concat(action.id),
      };
    case types.CLOSE_DELIVERED:
      return {
        ...state,
        openItems: state.openItems.filter(offerId => offerId !== action.id),
      };
    case types.SET_FETCHING_STATUS:
      return {
        ...state,
        isFetching: action.payload,
      };
    case types.REMOVE_DELIVERED:
      return {
        ...state,
        items: state.items.filter(offer => offer.uuid !== action.id),
      };
    case types.FETCH_DELIVERED_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_DELIVERED_SUCCESS:
      let deliveredItems = action.payload;
      return {
        ...state,
        items: deliveredItems,
        isFetching: false,
      };
    case types.FETCH_DELIVERED_ERROR:
      //@todo: handle error
      return {
        ...state,
      };
    case types.TOGGLE_DELIVERED_FILTERS:
      return {
        ...state,
        filtersOpen: !state.filtersOpen,
      };
    case types.CLEAR_DELIVERED_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case types.UPDATE_DELIVERED_JOB:
      const items = state.items.map(offer => {
        if (offer['@id'] === `/transport_job_account_links/${action.id}`) {
          let details = action.data;
          details['pickups'] = offer.transport_job_details.pickups;
          details['deliveries'] = offer.transport_job_details.deliveries;
          if (_get(offer, 'transport_job_details.stops', false)) {
            details['stops'] = offer.transport_job_details.stops;
          }
          offer.transport_job_details = details;
        }
        return offer;
      });
      return {
        ...state,
        errors: [],
        isFetching: false,
        items: items,
      };
    case types.SET_DELIVERED_FILTER:
      let filters = {
        ...state.filters,
      };
      filters[action.name] = action.value;
      return {
        ...state,
        filters: filters,
      };
    case types.FETCH_INVOICE_START:
      return {
        ...state,
        isFetchingInvoice: true,
      };
    case types.FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        isFetchingInvoice: false,
      };
    case types.FETCH_INVOICE_ERROR:
      return {
        ...state,
        isFetchingInvoice: false,
      };
    case types.SET_ROUTING_DATA:
      let tmp = state.items;
      Object.keys(state.items).reduce((job, d) => {
        if (tmp[d]['@id'] === '/transport_job_account_links/' + state.selectedId) {
          tmp[d]['transport_job_details']['route'] = action.payload;
        }
        return tmp[d];
      }, []);
      return {
        ...state,
        items: tmp,
      };
    default:
      return state;
  }
};

export default delivered;
