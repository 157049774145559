import React from 'react';
import UserRestrictionMessage from './UserRestrictionMessage';
import { Translate } from 'react-localize-redux';
import * as globals from '../../globals';
import { translate } from '../../UtilsTranslation';
import { SECTIONS } from '../../App';

const UserRestrictionMessageList = ({ driverLevel, progressLevelRequired, restrictions, isClaimedByOtherDriver }) => {
  return (
    <div className="pb-1 hide-print restriction-messages">
      {/* TODO: separate component for displaying applying error messages*/}
      {restrictions.includes('insurance') && (
        <UserRestrictionMessage
          id={1}
          translationId={'transport_request.messages.insurance_insufficient'}
          translationData={{
            editInsurance: (
              <a target={'_blank'} href="https://www.brenger.nl/dashboard/settings/public-profile">
                <Translate id={'transport_request.messages.insurance_insufficient_link'} />
              </a>
            ),
          }}
        />
      )}
      {restrictions.includes('suspended') && (
        <UserRestrictionMessage
          id={2}
          translationId={'transport_request.messages.suspended'}
          translationData={{
            planningPath: SECTIONS.default.path,
            accountmanager: translate(globals.driverAccountManager),
            phoneNumber: '<a href="tel:' + globals.driverHelpDeskNumber + '">' + globals.driverHelpDeskNumber + '</a>',
          }}
        />
      )}
      {isClaimedByOtherDriver && (
        <UserRestrictionMessage id={8} translationId={'search.messages.confirm.claim_failed'} translationData={{}} />
      )}
      {restrictions.includes('limited') && (
        <UserRestrictionMessage
          id={3}
          translationId={'transport_request.messages.limited_level'}
          translationData={{
            current_level: String(translate(`level.${driverLevel}`)),
            required_level: String(translate(`level.${progressLevelRequired}`)),
          }}
        />
      )}
      {restrictions.includes('business') && (
        <UserRestrictionMessage
          id={8}
          translationId={'transport_request.messages.not_business'}
          translationData={{
            editBusiness: (
              <a target={'_blank'} href="https://www.brenger.nl/dashboard/settings/business">
                <Translate id={'transport_request.messages.not_business_link'} />
              </a>
            ),
          }}
        />
      )}
    </div>
  );
};

export default UserRestrictionMessageList;
