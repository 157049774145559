import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Col, Row } from '../../../Grid';
import { SectionWrapper } from '../../layout/SectionWrapper';
import Heading from '../../../Heading';
import List from '../../../containers/PreferredRoutes/VisiblePreferredRoutesList';
import Form from '../../../containers/forms/VisiblePreferredRouteForm';

class Routes extends Component {
  render() {
    return (
      <div>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg routes-info-wrapper'}
          centerContent={true}
        >
          <Row>
            <Col xs={12}>
              <Heading size={'h5'}>
                <Translate id={'profile_page.preferred_routes.title'} />
              </Heading>
              <p>
                <Translate id={'profile_page.preferred_routes.explain'} />
              </p>
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'main-centered-content'}>
          <Form />
          <List />
        </div>
      </div>
    );
  }
}

export default Routes;
