import { types as routesTypes } from '../../reducers/preferredRoutes';

export const submitPreferredRouteFormStart = data => ({
  type: routesTypes.SUBMIT_PREFERRED_ROUTE_FORM_START,
  route: data,
});

export const submitPreferredRouteFormSuccess = data => ({
  type: routesTypes.SUBMIT_PREFERRED_ROUTE_FORM_SUCCESS,
  id: data['id'],
  from: data['from_locality'],
  to: data['to_locality'],
  transport_type: data['transport_size_class'],
});

export const submitPreferredRouteFormError = { type: routesTypes.SUBMIT_PREFERRED_ROUTE_FORM_ERROR };
export const deletePreferredRouteStart = id => ({ type: routesTypes.DELETE_PREFERRED_ROUTE_START, id });
export const deletePreferredRouteSuccess = id => ({ type: routesTypes.DELETE_PREFERRED_ROUTE_SUCCESS, id });
export const fetchPreferredRoutes = () => ({ type: routesTypes.FETCH_PREFERRED_ROUTES });
export const fetchPreferredRoutesSuccess = payload => ({ type: routesTypes.FETCH_PREFERRED_ROUTES_SUCCESS, payload });
export const fetchPreferredRoutesError = () => ({ type: routesTypes.FETCH_PREFERRED_ROUTES_ERROR });
