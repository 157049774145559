import React, { Component } from 'react';

class CountUp extends Component {
  constructor(props) {
    super(props);
    this.counter = React.createRef();
  }

  componentDidMount() {
    let i = 0;
    const numberDecimal = String(this.props.number).split('.')[1] || '';
    this.interval = setInterval(
      function() {
        if (i === Math.floor(this.props.number)) clearInterval(this.interval);
        this.counter.current.innerHTML = i + (numberDecimal ? '.' + numberDecimal : '');
        i++;
      }.bind(this),
      15
    );
  }

  render() {
    return <span className={'countUp'} ref={this.counter} />;
  }
}

export default CountUp;
