import React from 'react';
import IconAuction from './IconAuction';
import IconExtraPerson from './IconExtraPerson';
import IconManyObjects from './IconManyObjects';
import IconFrontDoor from './IconFrontdoor';
import IconOther from './IconOther';
import IconHelpCarying from './IconHelpCarying';
import IconShop from './IconShop';
import IconVeryHeavy from './IconVeryHeavy';
import IconVideo from './IconVideo';
import IconPalletJack from './IconPalletJack';
import IconTailgate from './IconTailgate';
import IconElevator from './IconElevator';
import IconHome from './IconHome';

/**
 *
 * @param {String} icondata
 * @returns {*}
 * @constructor
 */
const RenderRequestSpecIcon = ({ icondata }) => {
  switch (icondata) {
    case 'auction':
      return <IconAuction />;
    case 'two_people':
      return <IconExtraPerson />;
    case 'help_with_carrying':
      return <IconHelpCarying />;
    case 'extra_carry_help': // this is the one with extra person
      return (
        <React.Fragment>
          <IconExtraPerson />
          <IconHelpCarying />
        </React.Fragment>
      );

    case 'home':
      return <IconHome />;
    case 'front_door':
      return <IconFrontDoor />;
    case 'elevator':
      return <IconElevator />;
    case 'large_objects':
      return <IconManyObjects />;
    case 'other':
      return <IconOther />;
    case 'pallet_jack':
    case 'help_with_pallet_jack':
      return <IconPalletJack />;
    case 'tail_gate':
    case 'help_with_tailgate':
      return <IconTailgate />;
    case 'store':
      return <IconShop />;
    case 'ikea':
      return <IconShop />;
    case 'very_heavy':
      return <IconVeryHeavy />;
    case 'live_video':
      return <IconVideo />;
    default:
      return null;
  }
};

export default RenderRequestSpecIcon;
