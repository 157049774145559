import { connect } from 'react-redux';
import DeliveryDetail from '../../components/delivered/DeliveryDetail';
import { closeSingleView } from '../../actions/delivered';
import { getSelectedDelivered } from '../../reducers/delivered';
import { getTranslate } from 'react-localize-redux';
import * as appUtils from '../../Utils';
import {
  getItemCardElement,
  getItemImages,
  hasOwnOffer,
  isBundled,
  isProgressLevelRequired,
  isValidTransport,
  isPaid,
  getRouteDistance,
  getBundledStops,
  isBundledDelivered,
  getCurrentStatus,
} from '../../reducers/delivered';
import { getCountries } from '../../reducers/countries';
import { clearErrors } from '../../actions/delivered';

const mapStateToProps = state => ({
  offer: getSelectedDelivered(state),
  translate: getTranslate(state.localize),
  isFetching: state.delivered.isFetching,
  domain: 'delivered',
  user: state.user.details,
  itemImages: getItemImages(state),
  bundled: isBundled(state),
  isAvailable: isValidTransport(state),
  progressLevelRequired: isProgressLevelRequired(state),
  itemCardElement: getItemCardElement(state),
  hasOffer: hasOwnOffer(state),
  countries: getCountries(state),
  status: getCurrentStatus(state),
  claimedByOtherDriver: true,
  distance: getRouteDistance(state),
  isPaid: isPaid(state),
  stops: getBundledStops(state),
  isBundledDelivered: isBundledDelivered(state),
});

const mapDispatchToProps = dispatch => ({
  clearErrors: () => dispatch(clearErrors()),
  closeDetails: () => {
    dispatch(closeSingleView('delivered'));
    appUtils.deActivateSingleView();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetail);
