import * as appUtils from '../Utils';
import {
  deselectOffer,
  fetchOffersError,
  fetchOffersSuccess,
  patchOfferError,
  patchOfferSuccess,
  prepareOfferItem,
  scrollToSidebarItem,
  removeOffer,
  selectOffer,
  toggleFetchOffers,
  updateSelectedBundledStops,
  confirmDeliverySucceed,
  confirmDeliveryError,
  updateOffers,
} from '../actions/planning';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { getSelectedOffer, getSelectedOfferId } from '../reducers/offers';
import { types as offerTypes } from '../reducers/offers';
import { hideModal, showModal } from '../actions/modals';
import { fetchOffersStart } from '../actions/planning';
import { Translate } from 'react-localize-redux';
import { removeFromSidebar } from '../actions/planning/SingleViewActions';
import React from 'react';
import FlashMessage from '../FlashMessage';
import http from '../UtilsHttp';
import { getCountries } from '../reducers/countries';
import _get from 'lodash/get';
import moment from 'moment';
import { TJ_STATUS, TJAL_STATUS } from '../globals';
import history from '../History';
import { getTranslateFromStore } from '../reducers';
import { logException } from '../Utils';

export function* planningSagas() {
  yield takeEvery(offerTypes.FETCH_OFFERS_START, fetchInitialOffersSaga);
  yield takeEvery(offerTypes.PATCH_OFFER_START, patchOfferSaga);
  yield takeEvery(offerTypes.PATCH_OFFER_SUCCESS, patchOfferSuccessSaga);
  yield takeEvery(offerTypes.POST_GEOLOCATION_FOR_ETA, postGeoLocationForEta);
  yield takeEvery(offerTypes.CONFIRM_DELIVERY_PER_STOP, confirmDeliveryPerStop);
  yield takeEvery(offerTypes.SELECT_OFFER, selectPlannedTransportJob);
}

export function* fetchInitialOffersSaga() {
  try {
    const selectedItemId = yield select(getSelectedOfferId);
    const countries = yield select(getCountries);
    const date_from = moment(new Date()).add(-364, 'days');
    const data_to = moment(new Date()).add(366, 'days');
    yield* fetchOffersSaga(date_from, data_to, countries);
    if (selectedItemId) {
      const selectedOffer = yield select(getSelectedOffer);
      const transportJob = yield call(getTransportJob, selectedOffer.transport_job);
      yield put(updateOffers(selectedItemId, transportJob.data));
      scrollToSidebarItem(selectedItemId);
    }
  } catch (e) {
    yield put(toggleFetchOffers());
    yield put(fetchOffersError(e));
  }
}

export function* selectPlannedTransportJob() {
  try {
    const selectedId = yield select(getSelectedOfferId);
    const selectedOffer = yield select(getSelectedOffer);
    if (selectedOffer) {
      const transportJob = yield call(getTransportJob, selectedOffer.transport_job);
      yield put(updateOffers(selectedId, transportJob.data));
    } else {
      yield put(fetchOffersStart());
    }
  } catch (err) {
    logException(err);
  }
}

export function getTransportJob(url) {
  return http()
    .get(url, { withCredentials: true })
    .then(resp => resp);
}

export function fetchPlannedJobs(from, to, page) {
  const endpoint = 'transport_job_account_links';
  const tjalStatusCodes = [TJAL_STATUS.PENDING, TJAL_STATUS.ACCEPTED];
  const tjStatusCodes = [
    TJ_STATUS.DRIVER_FOUND,
    TJ_STATUS.MAX_DRIVERS_FOUND,
    TJ_STATUS.DRIVER_CONFIRMED,
    TJ_STATUS.DELIVER_CONFIRMED_BY_CUSTOMER,
    TJ_STATUS.MIXED_IN_PROGRESS,
  ];
  const params = {
    date_from: from,
    date_to: to,
    state: tjalStatusCodes.join(','),
    transport_job_state: tjStatusCodes.join(','),
    page: page,
  };
  return http()
    .get(endpoint, { params: params })
    .then(resp => resp);
}

export function* fetchOffersSaga(from, to, countries) {
  try {
    const dateTo = to ? moment(to).format('YYYY-MM-DD HH:mm:ss') : null;
    const dateFrom = from ? moment(from).format('YYYY-MM-DD HH:mm:ss') : null;
    const selectedId = yield select(getSelectedOfferId);

    // fetch all transport job account links
    let dataItems = [];
    let page = 1;
    let jobLinks = yield call(fetchPlannedJobs, dateFrom, dateTo, page);
    let items = jobLinks.data['hydra:member'];

    // fetch all pages
    page++;
    let nextPage = yield call(fetchPlannedJobs, dateFrom, dateTo, page);

    while (nextPage.data['hydra:member'].length !== 0) {
      items = [...items, ...nextPage.data['hydra:member']];
      page++;
      nextPage = yield call(fetchPlannedJobs, dateFrom, dateTo, page);
    }

    // let's filter planned jobs by range dates in client-side
    for (let item of items) {
      if (
        _get(item, 'pickup_commitments[0].pickup', null) !== null &&
        _get(item, 'delivery_commitments[0].delivery', null) !== null
      ) {
        item = prepareOfferItem(item);

        if (selectedId && item['@id'].indexOf(selectedId) > -1) {
          const selectedJob = yield call(getTransportJob, item.transport_job);
          item.transport_job_details = selectedJob.data;
        }
        // fetch all stops details for bundled jobs
        if (item.transport_job_bundled) {
          const job = yield call(getTransportJob, item.transport_job);
          if (job.data.transport_route) {
            const stops = yield call(fetchBundledJobStops, job.data);
            item.transport_job_details = {
              ...item.transport_job_details,
              stops: stops,
              pickups: [stops[0].pickup],
              deliveries: [stops[stops.length - 1].delivery],
            };
          } else {
            continue;
          }
        } else {
          item.transport_job_details = {
            ...item.transport_job_details,
            pickups: [item.pickup_commitments[0].pickup],
            deliveries: [item.delivery_commitments[0].delivery],
          };
        }

        // set the country codes to addresses
        if (_get(item, 'transport_job_details.pickups[0].address', false)) {
          const pickup_country = countries.filter(
            country => country['@id'] === item.transport_job_details.pickups[0].address.country
          );
          const delivery_country = countries.filter(
            country => country['@id'] === item.transport_job_details.deliveries[0].address.country
          );
          item.transport_job_details.pickups[0].address.country = _get(pickup_country[0], 'code', 'NL');
          item.transport_job_details.deliveries[0].address.country = _get(delivery_country[0], 'code', 'NL');
        }
        dataItems.push(item);
      }
    }
    yield put(fetchOffersSuccess(dataItems));
  } catch (e) {
    console.log(e);
    yield put(fetchOffersError(e));
  }
}

// update the selected bundled job stops
export function* updateBundledJobStops() {
  try {
    const offer = yield select(getSelectedOffer);
    const job = yield call(getTransportJob, offer.transport_job);
    const stops = yield call(fetchBundledJobStops, job.data);
    yield put(updateSelectedBundledStops(stops));
  } catch (err) {
    appUtils.logException('Failed to update the stops after confirmation');
  }
}

export function* fetchBundledJobStops(transportJob) {
  try {
    const req = IRI =>
      http()
        .get(IRI)
        .then(resp => resp);
    // fetch all stop details and available date-times
    const route = yield call(req, transportJob.transport_route);
    const stops = _get(route, 'data.stops', []).sort((a, b) => {
      return a.index > b.index ? 1 : -1;
    });
    stops.forEach(stop => {
      if (stop.pickup) {
        stop.pickup = transportJob.pickups.filter(pickup => pickup['@id'] === stop.pickup)[0];
        // replace pickup item set details for the stop target
        stop.pickup.item_sets.map(
          (itemSet, i) =>
            (stop.pickup.item_sets[i] = transportJob.item_sets.filter(itemSetIdx => itemSetIdx['@id'] === itemSet)[0])
        );
      }
      if (stop.delivery) {
        stop.delivery = transportJob.deliveries.filter(delivery => delivery['@id'] === stop.delivery)[0];
        // replace delivery item set details for the stop target
        stop.delivery.item_sets.map(
          (itemSet, i) =>
            (stop.delivery.item_sets[i] = transportJob.item_sets.filter(itemSetIdx => itemSetIdx['@id'] === itemSet)[0])
        );
      }
    });
    return stops;
  } catch (err) {
    return [];
  }
}

export function* confirmDeliveryPerStop(action) {
  const type = action.payload.type;
  const url = `${action.payload.stop['@id']}/confirm_${type}_by_driver`;
  const selectedOfferId = yield select(getSelectedOfferId);
  try {
    const req = url =>
      http()
        .post(url, {})
        .then(resp => resp);
    yield call(req, url);
    yield put(confirmDeliverySucceed());
    yield put(deselectOffer());
    yield put(selectOffer(selectedOfferId));
    yield call(updateBundledJobStops);
    document.querySelector('.close-modal').click();
  } catch (err) {
    const msg = 'problem with confirm the delivery per stop with driver';
    yield put(confirmDeliveryError(msg));
    appUtils.logException(msg, err);
  }
}

export function confirmDeliveries(deliveries) {
  return deliveries.map(delivery => {
    return http()
      .post(`${delivery['@id']}/confirm_delivery_by_driver`, {}, { withCredentials: true })
      .then(resp => resp);
  });
}

export function revokeDelivery(id) {
  return http()
    .post(`transport_job_account_links/${id}/revoke`, {}, { withCredentials: true })
    .then(resp => resp);
}

export function* confirm(deliveries, selectedOfferId, action) {
  try {
    yield all([
      call(confirmDeliveries, deliveries),
      put(removeOffer('/transport_job_account_links/' + selectedOfferId)),
      put(patchOfferSuccess(selectedOfferId, action)),
    ]);
  } catch (error) {
    appUtils.logException('CONFIRM_DELIVERY_FAILED', { error, selectedOfferId, action });
    yield put(patchOfferError({ offerId: selectedOfferId, action, error }));
  }
}

export function* revoke(selectedOfferId, action) {
  try {
    yield call(revokeDelivery, selectedOfferId);
    yield put(removeOffer('/transport_job_account_links/' + selectedOfferId));
    yield put(
      showModal({
        modalType: 'notification',
        modalProps: {
          message: <FlashMessage type={'success'} message={<Translate id={'messages.success'} />} />,
        },
      })
    );
  } catch (error) {
    yield put(patchOfferError({ offerId: selectedOfferId, action, error }));
  }
}

export function* patchOfferSaga({ action }) {
  const selectedOffer = yield select(getSelectedOffer);
  const selectedOfferId = selectedOffer['@id'].split('transport_job_account_links/')[1];
  if (action === 'cancel') {
    yield call(revoke, selectedOfferId, action);
  }
  if (action === 'confirm-delivery') {
    const deliveries = _get(selectedOffer, 'transport_job_details.deliveries', []);
    if (deliveries.length === 0) {
      return;
    }
    yield call(confirm, deliveries, selectedOfferId, action);
  }
}

export function* patchOfferSuccessSaga({ offerId, action }) {
  const translate = yield select(getTranslateFromStore);

  if (action === 'cancel') {
    yield put(removeFromSidebar(offerId, 'planning'));
    yield put(
      showModal({
        modalType: 'notification',
        modalProps: {
          message: (
            <div>
              <FlashMessage type={'success'} message={<Translate id={'messages.success'} />} />
            </div>
          ),
        },
      })
    );
    yield put(fetchOffersStart());
    yield delay(2000);
    yield put(hideModal());
  }

  if (action === 'confirm-delivery') {
    yield put(
      showModal({
        modalType: 'notification',
        action: () => history.push('/delivered'),
        actionText: translate('search.messages.confirm.action'),
        modalProps: {
          message: (
            <div>
              <FlashMessage type={'success'} message={<Translate id={'messages.success'} />} />
            </div>
          ),
        },
      })
    );
    yield put(fetchOffersStart());
    yield put(deselectOffer());
    yield delay(2000);
    yield put(hideModal());
    history.push(appUtils.getPathForApp() + '/delivered');
  }
}

export function* postGeoLocationForEta(action) {
  let coordsStorage = localStorage.getItem('userCoords');

  if (!coordsStorage) {
    return;
  }

  coordsStorage = JSON.parse(coordsStorage);
  const body = JSON.stringify({ lat: coordsStorage.coords.lat, lng: coordsStorage.coords.lon });
  try {
    yield call(fetch, appUtils.createApiUrl(`/stops/${action.stop_id}/eta`, 2), {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: body,
    });
  } catch (e) {
    //needs error handling
    console.log(e);
  }
}
