import React, { Component } from 'react';

import './css/Badge.css';

class Badge extends Component {
  render() {
    return (
      <span className={'badge-inline'} onClick={this.props.onClick}>
        {this.props.children}
      </span>
    );
  }
}

export default Badge;
