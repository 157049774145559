const defaultState = {
  isFetching: false,
  conversations: [],
  errors: [],
  selectedConversationId: '',
};

export const types = {
  SELECT_CONVERSATION: 'SELECT_CONVERSATION',
  DESELECT_CONVERSATION: 'DESELECT_CONVERSATION',
  FETCH_CONVERSATIONS_START: 'FETCH_CONVERSATIONS_START',
  FETCH_CONVERSATIONS_SUCCESS: 'FETCH_CONVERSATIONS_SUCCESS',
  FETCH_CONVERSATIONS_ERROR: 'FETCH_CONVERSATIONS_ERROR',
  FETCH_INBOX_START: 'FETCH_INBOX_START',
};

const inbox = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_CONVERSATIONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        conversations: action.payload,
      };
    case types.FETCH_CONVERSATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: action.error,
      };
    default:
      return state;
  }
};

export default inbox;
