import React from 'react';
const CirclePerson = () => {
  return (
    <svg viewBox="0 0 165 165" width="165" height="165">
      <g>
        <path
          d=" M 163.903 82.415 C 163.903 37.115 127.203 0.415 81.903 0.415 C 36.603 0.415 -0.097 37.115 -0.097 82.415 C -0.097 110.815 14.303 135.815 36.203 150.515 C 43.203 140.515 53.503 133.015 65.503 129.615 L 65.503 140.915 C 65.503 148.115 71.303 154.015 78.603 154.015 C 85.803 154.015 91.703 148.215 91.703 140.915 C 91.703 140.715 91.703 140.515 91.703 140.215 L 91.703 137.015 C 91.703 134.215 92.403 131.515 93.703 129.115 C 106.903 132.515 118.203 140.815 125.403 151.915 C 148.503 137.515 163.903 111.715 163.903 82.415 Z "
          fill="rgb(0,235,157)"
        />
        <path
          d=" M 93.603 129.215 C 92.303 131.615 91.603 134.215 91.603 137.115 L 91.603 140.315 C 91.603 140.515 91.603 140.715 91.603 141.015 C 91.603 148.215 85.803 154.115 78.503 154.115 C 71.303 154.115 65.403 148.315 65.403 141.015 L 65.403 129.715 C 53.403 133.115 43.103 140.615 36.103 150.615 C 49.203 159.415 64.903 164.515 81.803 164.515 C 97.703 164.515 112.603 159.915 125.203 152.115 C 118.103 140.815 106.803 132.615 93.603 129.215 Z "
          fill="rgb(0,56,59)"
        />
        <g>
          <g>
            <path
              d=" M 100.103 53.515 C 97.203 52.515 94.003 52.015 90.803 52.115 C 80.003 52.415 71.103 59.615 67.403 69.215 C 70.803 71.915 75.203 73.515 79.903 73.515 C 90.903 73.515 99.903 64.615 100.103 53.515 Z "
              fill="rgb(0,236,157)"
            />
          </g>
          <path
            d=" M 78.603 154.015 C 85.803 154.015 91.703 148.215 91.703 140.915 C 91.703 140.715 91.703 140.515 91.703 140.215 L 91.703 137.015 C 91.703 134.215 92.403 131.515 93.703 129.115 C 89.403 128.015 84.903 127.415 80.303 127.415 C 75.203 127.415 70.203 128.115 65.603 129.515 L 65.603 140.815 C 65.503 148.115 71.303 154.015 78.603 154.015 Z "
            fill="rgb(0,114,255)"
          />
        </g>
        <path
          d=" M 77.703 45.915 L 94.203 45.915 C 107.003 45.915 117.503 56.415 117.503 69.215 L 117.503 72.415 C 117.503 85.215 107.003 95.715 94.203 95.715 L 77.703 95.715 C 64.903 95.715 54.403 85.215 54.403 72.415 L 54.403 69.215 C 54.403 56.415 64.903 45.915 77.703 45.915 Z "
          fill="rgb(0,235,156)"
        />
        <path
          d=" M 77.703 48.515 L 94.203 48.515 C 107.003 48.515 117.503 59.015 117.503 71.815 L 117.503 75.015 C 117.503 87.815 107.003 98.315 94.203 98.315 L 77.703 98.315 C 64.903 98.315 54.403 87.815 54.403 75.015 L 54.403 71.815 C 54.403 59.015 64.903 48.515 77.703 48.515 Z "
          fill="rgb(0,113,255)"
        />
        <path
          d=" M 66.603 77.315 C 66.603 76.215 65.503 75.315 64.403 75.615 C 60.103 76.615 56.903 80.415 56.903 85.015 C 56.903 89.615 60.103 93.515 64.403 94.415 C 65.503 94.715 66.603 93.815 66.603 92.715 L 66.603 77.315 Z "
          fill="rgb(0,169,226)"
        />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="87.10284999999999"
          cy="84.11515"
          r="3.799999999999997"
          fill="rgb(0,169,226)"
        />
        <path
          d=" M 117.603 95.915 C 117.603 91.415 117.603 84.015 117.603 79.515 C 118.203 67.515 110.703 57.215 100.003 53.515 C 99.803 64.615 90.803 73.515 79.703 73.515 C 75.003 73.515 70.703 71.915 67.203 69.215 C 66.003 72.215 65.403 75.515 65.403 78.915 L 65.403 92.615 C 65.403 94.915 65.403 96.815 65.403 97.715 L 65.403 124.215 L 65.403 129.615 C 70.103 128.315 75.003 127.515 80.103 127.515 C 84.703 127.515 89.203 128.115 93.503 129.215 C 95.303 125.915 98.103 123.215 101.703 121.715 C 111.703 117.515 118.403 107.415 117.603 95.915 Z "
          fill="rgb(255,255,255)"
        />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="86.20285000000001"
          cy="84.11515"
          r="3.799999999999997"
          fill="rgb(0,169,226)"
        />
        <circle vectorEffect="non-scaling-stroke" cx="108.40285" cy="84.11515" r="3.799999999999997" fill="#00eba0" />
        <circle
          vectorEffect="non-scaling-stroke"
          cx="108.20285000000001"
          cy="84.11515"
          r="3.799999999999997"
          fill="rgb(0,169,226)"
        />
        <path
          d=" M 106.903 104.115 L 102.603 104.115 C 102.303 104.115 102.103 104.315 102.103 104.515 C 101.603 107.115 99.303 109.115 96.503 109.115 C 93.703 109.115 91.403 107.115 90.903 104.515 C 90.903 104.215 90.603 104.115 90.403 104.115 L 86.103 104.115 C 85.803 104.115 85.503 104.415 85.503 104.715 C 86.103 110.215 90.803 114.515 96.503 114.515 C 102.203 114.515 106.903 110.215 107.503 104.715 C 107.403 104.415 107.203 104.115 106.903 104.115 Z "
          fill="rgb(0,169,226)"
        />
        <path
          d=" M 67.303 69.115 C 67.303 69.115 72.603 52.515 90.803 52.515 C 90.803 52.515 94.103 52.115 100.103 53.415 C 100.103 53.415 99.803 66.715 87.903 71.715 C 87.903 71.815 77.903 77.115 67.303 69.115 Z "
          fill="rgb(0,113,255)"
        />
      </g>
    </svg>
  );
};

export default CirclePerson;
