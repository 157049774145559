const defaultState = {
  details: [],
  userChoices: [],
};

export const types = {
  SET_COUNTRIES: 'SET_COUNTRIES',
  GET_COUNTRIES: 'GET_COUNTRIES',
};

export const actions = {
  setCountries: countries => ({ type: types.SET_COUNTRIES, countries }),
  getCountries: () => ({ type: types.GET_COUNTRIES }),
};

export const getCountries = state => {
  return state.countries.details;
};

export const getCountry = (state, id) => {
  return state.countries;
};

const countries = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_COUNTRIES:
      return {
        ...state,
        details: action.countries['hydra:member'].sort((a, b) => {
          if (a['code'] < b['code']) {
            return -1;
          }
          if (a['code'] > b['code']) {
            return 1;
          }
          return 0;
        }),
        userChoices: action.countries['hydra:member']
          .filter(item => item.code === 'NL' || item.code === 'BE')
          .reverse(),
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default countries;
