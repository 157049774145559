import React, { Component } from 'react';
import '../../css/Main-navigation.css';
import { NavLink, Router } from 'react-router-dom';
import history from '../../History';
import Badge from '../../Badge';

class MainNavigation extends Component {
  constructor(props) {
    super(props);
    this.mainNavigation = React.createRef();
    this.state = {
      open: false,
    };
  }

  toggleMobileNav(open) {
    const body = document.querySelector('body');
    if (open === false) {
      this.setState({ open: false }, function() {
        body.classList.remove('bd-nav-main--is-open');
      });
    } else {
      this.setState({ open: true }, function() {
        body.classList.add('bd-nav-main--is-open');
      });
    }
  }

  componentDidMount() {
    history.listen(() => {
      this.toggleMobileNav(false);
      const subMenuNavItem = document.querySelector('.bd-nav-item.sub-is-open');
      if (subMenuNavItem !== null) {
        subMenuNavItem.classList.remove('sub-is-open');
      }
      if (this.props.is_main_app_loaded) {
        window.scrollTo(0, 0);
      }
    });
  }

  render() {
    return (
      <div>
        <div className={'bd-nav-toggle'} onClick={() => this.toggleMobileNav(!this.state.open)}>
          <div className="bd-nav-bar" />
          <div className="bd-nav-bar" />
          <div className="bd-nav-bar" />
        </div>
        <Router history={history}>
          <nav className={'bd-nav-main'} ref={this.mainNavigation}>
            <ul>
              {this.props.items.map((item, index) => {
                item.is_main_app_loaded = this.props.is_main_app_loaded;
                return <NavItem key={index} {...item} />;
              })}
            </ul>
            <a className={'bottom-position mobile-only menu-index-8'} href={'https://api.brenger.nl/logout'}>
              Uitloggen
            </a>
            <div className={'bd-nav-filler'} />
          </nav>
        </Router>
      </div>
    );
  }
}

export default MainNavigation;

function toggleSubMenu(el) {
  const parent = el.closest('.bd-nav-item');
  if (parent.classList.contains('sub-is-open')) {
    parent.classList.remove('sub-is-open');
  } else {
    parent.classList.add('sub-is-open');
  }
}

const NavItem = props => {
  return (
    <li
      className={
        'bd-nav-item bd-nav-item-name-' +
        props.title +
        (props.children ? ' bd-nav-item--has-sub' : '') +
        (props.image ? ' bd-nav-item--has-image' : '') +
        (props.extra_classes ? ' ' + props.extra_classes : '')
      }
    >
      {!props.children && (
        <LinkElement {...props}>
          <span className={'bd-nav-item-label'}>
            {props.title}
            {parseInt(props.badge) > 0 && <Badge>{props.badge}</Badge>}
            {props.image && <span className={'bd-nav-image'} style={{ backgroundImage: `url(${props.image})` }} />}
          </span>
        </LinkElement>
      )}
      {props.children && [
        <span key={1} className={'bd-nav-item-label'} onClick={event => toggleSubMenu(event.target)}>
          {props.title}
          {props.image && <span className={'bd-nav-image'} style={{ backgroundImage: `url(${props.image})` }} />}
        </span>,
        <ul key={2} className={'bd-sub-nav'}>
          {props.children.map((item, index) => {
            item.is_main_app_loaded = props.is_main_app_loaded;
            return <NavItem key={index} {...item} />;
          })}
        </ul>,
      ]}
    </li>
  );
};

const LinkElement = props => {
  let url = props.url;
  if (Array.isArray(url)) {
    url = url[0];
  }
  if (!props.is_normal_link && props.is_main_app_loaded) {
    return (
      <NavLink activeClassName="is-active" to={url}>
        {props.children}
      </NavLink>
    );
  }
  return <a href={(!props.is_normal_link ? 'https://driver.brenger.nl/' : '') + url}>{props.children}</a>;
};
