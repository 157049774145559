import React, { Component } from 'react';
import IconCross from './icons/IconCross';

import './css/Modal.css';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      toggle:
        this.props.toggleFunction && !this.props.onlyToggleByButton
          ? this.props.toggleFunction
          : this.closeModal.bind(this),
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      open: props.open,
      toggle:
        this.props.toggleFunction && !this.props.onlyToggleByButton
          ? this.props.toggleFunction
          : this.closeModal.bind(this),
    });
  }

  render() {
    return (
      <div id={this.props.id} className={'modal' + (this.state.open ? ' modal--isOpen' : ' modal--isClosed')}>
        <div className={'modal-backdrop'} onClick={this.state.toggle}>
          <div className={'modal-inner'} onClick={event => this.blockClick(event)}>
            <div className={'modal-header'}>
              {this.props.header}
              {!this.props.onlyToggleByButton && (
                <div className={'modal-close'} onClick={this.state.toggle}>
                  <IconCross />
                </div>
              )}
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  closeModal() {
    if (this.props.onlyToggleByButton) {
      return false;
    }
    this.setState({
      open: false,
    });
  }

  blockClick = event => {
    event.stopPropagation();
  };
}

export default Modal;
