import Reports from './Reports';
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import MagicMap from '../../containers/routePlanner/MagicMap';
import SortableStopList from './SortableStopList';
import { arrayMove } from 'react-sortable-hoc';
import { getAvailableDateimePeriodForStop } from '../../UtilsDatetime';

class RouteOptimizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSorting: 'kickoff-sort',
      directionsReady: false,
      routeRequest: null,
      avgStopTime: 0,
      originRelative: null,
      endTime: '',
      currentStartTime: null,
      waypoints: [],
      loading: false,
      isValid: true,
      routePolylines: [],
      pickupCoords: [],
      deliveryCoords: [],
      readyToDraw: false,
      message: '',
      optimizedRoute: [],
      legs: null, // google direction response route legs
      summary: [], // summary of best route suggestions result
      sortedSummary: [], //summary for times in sortable list component
      sortedStops: [],
      stops: [],
      roots: [], // start position of scanned data
      serviceTime: 15,
      startLocation: 'Amsterdam',
      startTime: '09:00',

      report: {
        // reports params object
        reward: 0,
        drivingTime: 0,
        drivingDistance: 0,
        serviceTime: 0,
        isFeasible: null,
      },
    };

    this.findBestRoute = this.findBestRoute.bind(this);
    this.setStartTime = this.setStartTime.bind(this);
    this.setStartLocation = this.setStartLocation.bind(this);
    this.setServiceTime = this.setServiceTime.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let report = state.report;
    if (props.transportRequests.length > 0) {
      report.reward = props.transportRequests.reduce(
        (acc, current) => acc + parseInt(current.price.reward.amount) + parseInt(current.price.compensation.amount),
        0
      );
    }

    if (props.transportRequests.length * 2 > state.sortedStops.length) {
      //the last transportRequest is not in the stops yet
      let transportRequest = props.transportRequests[props.transportRequests.length - 1];
      let sortedStops = state.sortedStops;
      let pickup = transportRequest.pickups[0];
      pickup['type'] = 'pickup';
      pickup['transport_request'] = transportRequest.uuid;

      let delivery = transportRequest.deliveries[0];
      delivery['type'] = 'delivery';
      delivery['transport_request'] = transportRequest.uuid;

      sortedStops.push(pickup);
      sortedStops.push(delivery);
      return { sortedStops: sortedStops, report: report };
    }

    if (props.transportRequests.length * 2 < state.sortedStops.length) {
      //some transportRequest is now removed
      let sortedStops = state.sortedStops;
      let uuids = props.transportRequests.map(item => item.uuid);
      sortedStops = sortedStops.filter(item => uuids.includes(item.transport_request));
      return { sortedStops: sortedStops, report: report };
    }

    return null;
  }

  findBestRoute() {
    this.setState({ loading: true, directionsReady: false });
    const startDatetime = moment(this.props.date)
      .hour(parseInt(this.state.startTime.split(':')[0]))
      .minute(parseInt(this.state.startTime.split(':')[1]));
    let waypoints = [];
    let report = {
      reward: this.state.reward,
      drivingTime: 0,
      drivingDistance: 0,
      serviceTime: 0,
    };

    waypoints.push({
      address: this.state.startLocation.city,
      lat: this.state.startLocation.lat,
      lng: this.state.startLocation.lon,
    });

    let i = 1; //used for restrictions, starts at 1, because starting point will be added
    for (let transportRequest of this.props.transportRequests) {
      const pickup = this.props.routePolylines[transportRequest.uuid][0];
      const delivery = this.props.routePolylines[transportRequest.uuid].slice(-1)[0];
      let period = getAvailableDateimePeriodForStop(transportRequest.pickups[0], this.props.date);

      waypoints.push({
        address: `pickup::${transportRequest.uuid}`,
        lat: pickup['lat'],
        lng: pickup['lon'],
        servicetime: this.state.serviceTime,
        restrictions: {
          before: i + 1,
          ready: Math.max(0, moment(period.start).diff(startDatetime, 'minutes')),
          due: Math.max(0, moment(period.end).diff(startDatetime, 'minutes')),
        },
      });

      period = getAvailableDateimePeriodForStop(transportRequest.deliveries[0], this.props.date);

      waypoints.push({
        address: `delivery::${transportRequest.uuid}`,
        lat: delivery['lat'],
        lng: delivery['lon'],
        servicetime: this.state.serviceTime,
        restrictions: {
          after: i,
          ready: Math.max(0, moment(period.start).diff(startDatetime, 'minutes')),
          due: Math.max(0, moment(period.end).diff(startDatetime, 'minutes')),
        },
      });
      i += 2;
    }

    waypoints.push({
      address: this.state.startLocation.city,
      lat: this.state.startLocation.lat,
      lng: this.state.startLocation.lon,
    });

    let body = 'locations=' + JSON.stringify(waypoints);

    fetch('https://i3vfmxtba0.execute-api.eu-west-1.amazonaws.com/dev/geo/optimize_route', {
      body: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    })
      .then(resp => resp.json())
      .then(data => {
        let newSortedStops = [];
        for (let stopIndex in data['route']) {
          let stop = data['route'][stopIndex];
          report['drivingTime'] += stop['traveltime'] || 0;
          report['serviceTime'] += stop['servicetime'] || 0;
          let [type, uuid] = stop.name.split('::');
          if (typeof uuid !== 'undefined') {
            let newStop = this.state.sortedStops.find(sortedStop => {
              return sortedStop.transport_request === uuid && sortedStop.type === type;
            });
            newStop['details'] = stop;
            newSortedStops.push(newStop);
          }
        }
        report['drivingDistance'] = data['route'].slice(-1)[0]['distance'];
        report['isFeasible'] = data['feasible'];

        this.setState({
          sortedStops: newSortedStops,
          loading: false,
          directionsReady: true,
          optimizedRoute: data['route'],
          report: report,
        });
      });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ sortedStops }) => ({
      sortedStops: arrayMove(sortedStops, oldIndex, newIndex),
    }));
  };

  setStartTime(time) {
    this.setState({ startTime: time });
  }

  setStartLocation(location) {
    this.setState({ startLocation: location });
  }

  setServiceTime(minutes) {
    this.setState({ serviceTime: minutes });
  }

  render() {
    let activeSorting = this.state.activeSorting;
    return (
      <>
        <section className={`sorted-cities ${activeSorting}`}>
          <Reports
            findRoute={this.findBestRoute}
            loading={this.state.loading}
            report={this.state.report}
            sortedStops={this.state.sortedStops}
            date={this.props.date}
            setStartTime={this.setStartTime}
            setStartLocation={this.setStartLocation}
            setServiceTime={this.setServiceTime}
          />
          {!this.state.isValid && (
            <div className="validations-reports">
              <p>{this.state.message}</p>
            </div>
          )}
          <SortableStopList
            helperClass={'sortable-items'}
            stops={this.state.sortedStops}
            startDatetime={moment(this.props.date)
              .hour(parseInt(this.state.startTime.split(':')[0]))
              .minute(parseInt(this.state.startTime.split(':')[1]))}
            startLocation={this.state.startLocation.city}
            optimizedRoute={this.state.optimizedRoute}
            onSortEnd={this.onSortEnd}
          />

          {this.state.summary.length > 0 &&
            this.state.summary.map((note, i) => {
              return (
                <div key={i} className="summary">
                  <small>#{note}</small>
                </div>
              );
            })}
        </section>
        <MagicMap
          updateReport={this.updateReport}
          loading={this.state.loading}
          stops={this.state.sortedStops}
          startLocation={this.state.startLocation.city}
          directionsReady={this.state.directionsReady}
        />
      </>
    );
  }
}

export default withLocalize(RouteOptimizer);
