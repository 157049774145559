import React, { Component } from 'react';
import './css/Pagination.css';
import IconSmile from './icons/IconSmile';

/**
 * @param {Number} page: current page
 * @param {Number} total: total items
 * @param {Number} limit: page limits
 * @param {Callback} onChange: paginate event callback
 */

class Pagination extends Component {
  render() {
    let pagination = [];
    let total_pages = Math.floor(this.props.total / this.props.limit) + 1;
    // create pagination template
    for (let i = 1; i <= total_pages; i++) {
      pagination.push(
        <button
          key={i}
          page={i}
          className={i === this.props.page ? 'btn btn--primary' : 'btn btn--outline'}
          onClick={i !== this.props.page ? this.props.onChange : null}
        >
          {i}
        </button>
      );
    }
    return (
      <div className={'pagination'}>
        {total_pages > 3 && this.props.page !== 1 && (
          <button
            page={this.props.page - 1}
            className={'btn btn--tertiary pagination--go-to-prev'}
            onClick={this.props.onChange}
          >
            <IconSmile />
          </button>
        )}
        {total_pages > 1 && pagination}
        {total_pages > 3 && this.props.page !== total_pages && (
          <button
            page={this.props.page + 1}
            className={'btn btn--tertiary pagination--go-to-next'}
            onClick={this.props.onChange}
          >
            <IconSmile />
          </button>
        )}
      </div>
    );
  }
}

export default Pagination;
