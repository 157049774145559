import React, { Component } from 'react';
import BrengerLogo from '../../icons/BrengerLogo';
import SingleViewInner from '../../SingleViewInner';
import { setDocumentTitle } from '../../Utils';
import { Translate, withLocalize } from 'react-localize-redux';
import PreviewModal from '../modals/PreviewModal';

class PreviewDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true,
    };
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  render() {
    setDocumentTitle(this.props.translate('search.title'));
    return (
      <main className={'single-view' + (this.props.singleTransportJob !== undefined ? ' is-active' : '')}>
        {this.props.singleTransportJob && (
          <>
            <SingleViewInner
              single={this.props.singleTransportJob}
              closeDetails={this.props.closeTransportJob}
              user={this.props.user}
              userIsRestricted={['preview_mode']}
              openModal={this.openModal.bind(this)}
            />
            <PreviewModal toggleFunction={this.props.closeTransportJob} open={this.state.modalOpen} />
          </>
        )}
        <div className="not-active-view">
          <BrengerLogo />
          <p>
            <Translate id={'search.detail.welcome'} />
          </p>
        </div>
      </main>
    );
  }
}

export default withLocalize(PreviewDetail);
