import { actions as userActions, fetchAddress, fetchBankAccount, types as userTypes } from '../reducers/user';
import { fetchGet, fetchLoggedInState, fetchPostUser, fetchDelete, fetchUser, uploadHttp } from '../api/general';
import { fetchCountries } from '../actions/country';
import { actions as countryActions } from '../reducers/countries';
import { call, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import { types as searchTypes } from '../reducers/search';
import { userRestrictions } from '../actions/search';
import * as globals from '../globals';
import { SECTIONS as onBoardingSections } from '../OnboardBrengerApp';
import { SECTIONS as appSections } from '../App';
import * as appUtils from '../Utils';
import History from '../History';
import { isUserLoggedIn } from '../reducers/user';
import { submitPreferredRouteFormStart } from '../actions/preferredRoutes';
import { trackEvent } from '../Utils';
import { getNetworkStatus } from '../actions/general/network';
import http from '../UtilsHttp';
import { getTranslateFromStore } from '../reducers';
import { actions as vehicleActions, types as vehicleTypes } from '../reducers/vehicles';
import _get from 'lodash/get';

export function* userSaga() {
  yield fork(updateUserCycle);
  yield takeEvery('@@localize/INITIALIZE', initialSaga);
  yield takeEvery(userTypes.START_USER_LOCATION_PREFERENCE, setLocationPreferences);
  yield takeEvery(searchTypes.POST_NEW_OFFER_SUCCESS, loadUser);
  yield takeEvery(vehicleTypes.FETCH_VEHICLES_START, fetchVehicles);
  yield takeEvery(vehicleTypes.REMOVE_VEHICLE_START, removeVehicle);
  yield takeEvery(vehicleTypes.ADD_VEHICLE_START, postVehicle);
  yield takeEvery(userTypes.LOGIN_USER_START, loginUser);
  yield takeEvery(userTypes.LOAD_NEW_USER, loadNewUserAttempts);
  yield takeEvery(userTypes.SUBMIT_NEW_USER_FORM_START, submitNewUser);
  yield takeEvery(userTypes.UPLOAD_PROFILE_IMAGE_START, uploadProfileImage);
}

function* removeVehicle(action) {
  const url = appUtils.createApiUrl(action.payload, 2);
  const req = () => fetchDelete(url).then(resp => resp);
  try {
    yield call(req);
  } catch (err) {
    appUtils.logException('Problem with remove vehicle', err);
  }
}

function* fetchVehicles() {
  const url = appUtils.createApiUrl('vehicles', 2);
  const request = url =>
    fetchGet(url)
      .then(response => response.json())
      .then(vehicles => vehicles);
  try {
    const vehicles = yield call(request, url);
    yield put(vehicleActions.fetchVehiclesSuccess(vehicles));
  } catch (err) {
    yield put(vehicleActions.fetchVehiclesFailure());
  }
}

function* initialSaga() {
  let countries = yield call(fetchCountries);
  yield put(countryActions.setCountries(countries));
}

function* updateUserCycle() {
  // set logged in only on first round
  yield call(loadUser, true);
  yield delay(globals.userRefreshTime);

  while (true) {
    yield call(loadUser, false);
    yield delay(globals.userRefreshTime);
  }
}

function* uploadProfileImage(action, update = true) {
  if (action.payload) {
    const formData = new FormData();
    formData.append('file', action.payload);

    const configs = {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: {
        Accept: 'application/ld+json',
      },
    };

    const req = url => fetch(url, configs).then(resp => resp.json());
    const resp = yield call(req, appUtils.createApiUrl('users/me/profile_image', 2));

    if (_get(resp, '@type', '') !== 'hydra:Error') {
      if (update) {
        let user = yield call(fetchUser);
        user.image = user.profile_image;
        yield delay(1000);
        yield put(userActions.setUser(user));
      }
    } else {
      appUtils.logException('Oops, upload profile image!', resp);
    }
  }
}

function navigateToVehiclesList() {
  History.push(appSections.vehicles.path);
}

function* postVehicle(action) {
  try {
    yield call(addVehicle, action.payload);
    yield put(vehicleActions.addVehicleSuccess());
    yield call(navigateToVehiclesList);
  } catch (err) {
    const translate = yield select(getTranslateFromStore);
    yield put(vehicleActions.addVehicleFailure(translate('profile_page.vehicles.add_vehicle_failure')));
    appUtils.logException('Problem with post vehicle, ', action.payload);
  }
}

function addVehicle(vehicle) {
  return http()
    .post('/vehicles', vehicle, { withCredentials: true })
    .then(resp => resp);
}

function* submitNewUser(action) {
  try {
    const data = action.user;
    data.account.bank_accounts[0].country = data.address.country;
    let resp = yield call(fetchPostUser, data);
    let validations = {};
    if (typeof resp.violations !== 'undefined') {
      validations = [];
      for (let violation of resp.violations) {
        const _msg = `${violation.propertyPath}: ${violation.message}`;
        validations.push(_msg);
      }
      yield put(userActions.submitNewUserFormError(validations));
    } else {
      if (_get(resp, '@type', '') === 'hydra:Error') {
        yield put(userActions.submitNewUserFormError([_get(resp, 'hydra:description', 'Internal server error!')]));
        return;
      }

      yield loginUser({
        credentials: {
          email: action.user.email,
          password: action.user.new_password,
        },
      });

      yield loadNewUserAttempts();

      // submit the vehicle details
      for (let vehicle of action.user.vehicles) {
        let data = {
          account: resp.account,
          images: vehicle.images,
          make: vehicle.make,
          model: vehicle.model,
          license_plate: vehicle.licensePlate,
          data_verified: false,
          color: 'white',
          loading_dimensions_width_cm: parseInt(vehicle.loading_dimensions_width_cm, 10),
          loading_dimensions_height_cm: parseInt(vehicle.loading_dimensions_height_cm, 10),
          loading_dimensions_length_cm: parseInt(vehicle.loading_dimensions_length_cm, 10),
          loading_weight_limit_kg: parseInt(vehicle.loading_weight_limit_kg, 10),
          has_tailgate: false,
          has_pallet_jack: false,
        };
        yield call(addVehicle, data);
      }

      for (let route of action.user.routes) {
        yield put(submitPreferredRouteFormStart(route));
      }

      if (action.user.profile_image) {
        yield call(uploadProfileImage, { payload: action.user.profile_image }, false);
      }
      if (action.user.id_card) {
        yield call(uploadHttp, null, 'users/me/id_document', action.user.id_card);
      }

      // Fire register event
      trackEvent('Driver', 'New driver registration');

      yield put({ type: userTypes.SUBMIT_NEW_USER_FORM_SUCCESS });
    }
  } catch (err) {
    console.log(err);
  }
}

// This generator tries to load the user every half a second
function* loadNewUserAttempts() {
  let attempts = 0;
  let logged_in = yield select(isUserLoggedIn);
  while (!logged_in && attempts <= 20) {
    yield call(loadUser);
    logged_in = yield select(isUserLoggedIn);
    attempts++;
    if (attempts === 20 && !logged_in) {
      // Somehow we failed to automatically login the user, so log it to sentry
      appUtils.logException('Failed to login new user, after ' + attempts + ' attempts');
      // So send the user to the login form, with redirect path set to thank you for registering.
      yield put(userActions.setLoginRedirect(onBoardingSections.thank_you.path));
      History.push(appSections.login.path);
    }
    yield delay(500);
  }
}

function* loadUser(setLoggedInState = true) {
  try {
    const network_status = yield select(getNetworkStatus);
    if (network_status !== 'OFFLINE') {
      let logged_in = yield call(fetchLoggedInState);
      if (!logged_in && setLoggedInState) {
        yield put(userActions.setUserLoggedIn({ isLoggedIn: false }));
        return;
      }

      let user = yield call(fetchUser);

      user.image = user.profile_image;
      user.restrictions = userRestrictions(user);

      // update user
      yield put(userActions.setUser(user));
      if (setLoggedInState) {
        yield put(userActions.setUserLoggedIn({ isLoggedIn: logged_in }));
      }

      let address = yield fetchAddress(user);
      let bankAccount = yield fetchBankAccount(user.account.bank_accounts[0]);
      yield put(userActions.setAddress(address));
      yield put(userActions.setBankAccount(bankAccount));
    }
  } catch (error) {
    console.log('Error fetching user details', error);
  }
}

function* setLocationPreferences(params) {
  // Cut out permission API, do a simple check on local storage
  const coordsStorage = localStorage.getItem('userCoords');
  if (coordsStorage === null) {
    yield params.onChange('prompt');
  } else if (coordsStorage === 'denied') {
    yield params.onChange('denied');
  } else {
    yield params.onChange('granted');
  }
}

function doLogin(data) {
  return http()
    .post('/login', data, { withCredentials: true })
    .then(resp => resp);
}

function* loginUser(action) {
  const translate = yield select(getTranslateFromStore);
  try {
    const request = yield call(doLogin, { email: action.credentials.email, password: action.credentials.password });
    if (request) {
      yield put({ type: userTypes.LOGIN_USER_SUCCESS });
      setTimeout(() => {
        History.push(appUtils.getPathForApp() + '/planning');
      }, 250);
    }
  } catch (error) {
    console.log(error);
    yield put({ type: userTypes.LOGIN_USER_ERROR, payload: translate('user.bad_credentials') });
  }

  yield loadUser();
}
