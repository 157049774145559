import React, { Component } from 'react';

import './css/Heading.css';

class Heading extends Component {
  render() {
    return <this.props.size onClick={this.props.onClick}>{this.props.children}</this.props.size>;
  }
}

export default Heading;
