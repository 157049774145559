import React, { Component } from 'react';
import { translate } from '../../UtilsTranslation';
import { Button } from '../../Button';
import moment from 'moment';
import LocationAutocomplete from '../../forms/LocationAutocomplete';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startLocation: {},
      startTime: '09:00',
      averageStop: 15,
      drivingTime: '00:00',
      reward: '...',
    };
  }

  componentDidMount() {
    this.props.setStartTime(this.state.startTime);
  }

  render() {
    let disabled = this.props.loading;
    let report = this.props.report;

    const _t = {
      startLocation: translate('route_planning.params.start_origin'),
      startTime: translate('route_planning.params.start_time'),
      avgStopTime: translate('route_planning.params.avg_stop_time'),
      submit: translate('route_planning.params.submit'),
      driveTime: translate('route_planning.reports.drive_time'),
      driveDistance: translate('route_planning.reports.drive_distance'),
      rewards: translate('route_planning.reports.rewards'),
    };

    return (
      <div>
        {
          <div>
            <LocationAutocomplete getValue={this.props.setStartLocation} />
            <strong>{_t.startTime}:</strong>
            <input
              type="time"
              value={this.state.startTime}
              onChange={e => {
                this.props.setStartTime(e.target.value);
                this.setState({ startTime: e.target.value });
              }}
              min="8:00"
              max="18:00"
            />
            <strong>{_t.avgStopTime}:</strong>
            <input
              placeholder={'Average stop time'}
              type="number"
              value={this.state.averageStop}
              onChange={e => {
                this.props.setServiceTime(e.target.value);
                this.setState({ averageStop: e.target.value });
              }}
              min="00:00"
              max="02:00"
            />

            <Button disabled={disabled} buttonStyle={'secondary'} onClick={() => this.props.findRoute()}>
              {_t.submit}
            </Button>
            {typeof report.reward !== 'undefined' && (
              <div>
                <hr />
                {report.isFeasible === false && (
                  <h6 style={{ color: 'red' }}>Some restrictions could not be satisfied!</h6>
                )}
                <p>
                  {_t.avgStopTime}{' '}
                  {moment.duration(report.serviceTime, 'minutes').get('hours') +
                    ':' +
                    moment.duration(report.serviceTime, 'minutes').get('minutes')}
                </p>
                <p>
                  {_t.driveTime}{' '}
                  {moment.duration(report.drivingTime, 'minutes').get('hour') +
                    ':' +
                    moment.duration(report.drivingTime, 'minutes').get('minutes')}
                </p>
                <p>
                  {_t.driveDistance} {parseInt(report.drivingDistance)} KM
                </p>
                <p>
                  {_t.rewards} &euro; {report.reward / 100}
                </p>
                <hr />
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

export default Reports;
