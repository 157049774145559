import React, { Component } from 'react';
import Heading from '../Heading';
import DatetimePeriodsField from './DatetimePeriodsField';
import { Button } from '../Button';
import moment from 'moment';
import * as datetimePeriodUtils from '../UtilsDatetime.js';
import FlashMessage from '../FlashMessage';
import '../css/OfferForm.css';
import { Translate, withLocalize } from 'react-localize-redux';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { translate } from '../UtilsTranslation';
import _get from 'lodash/get';
import { SITUATION_TYPES, TJ_DETAILS_TYPES } from '../globals';

const initialState = () => {
  return {
    offerMessage: '',
    pickupDatetimePeriodsFieldState: initialDatetimePeriodsFieldState(),
    deliveryDatetimePeriodsFieldState: initialDatetimePeriodsFieldState(),
    isTouched: false,
    errors: [],
  };
};

const initialDatetimePeriodsFieldState = () => {
  return {
    selectedDatetimePeriodIndex: null,
    selectedDatetimePeriod: {},
    chosenDatetimePeriod: {},
    errors: [],
  };
};

class OfferForm extends Component {
  constructor(props) {
    super(props);

    this.handleDatetimePeriodChange = this.handleDatetimePeriodChange.bind(this);
    this.submitOffer = this.submitOffer.bind(this);

    yup.addMethod(yup.date, 'formatDateType', this.formatDateType);
    yup.addMethod(yup.mixed, 'OutOfRangeValid', this.OutOfRangeValid);
    yup.addMethod(yup.mixed, 'tooNearTimeValid', this.tooNearTimeValid);
    yup.addMethod(yup.mixed, 'tooLongOrShortValid', this.tooLongOrShortValid);
    yup.addMethod(yup.mixed, 'endBeforeStart', this.endBeforeStart);
    yup.addMethod(yup.mixed, 'pickupBeforeDelivery', this.pickupBeforeDelivery);

    this.state = {
      forms: setFormStateAtKey({}, this.props.uuid, this.initialState),
      isSubmitting: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors, isSubmitting: false });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.forms[props.uuid]) {
      return {
        ...props,
        forms: setFormStateAtKey(state.forms, props.uuid, initialState()),
      };
    } else {
      return state;
    }
  }

  tooNearTimeValid(msg) {
    return yup.mixed().test('tooNearTimeValid', `${msg}`, function(value) {
      if (
        (value.pickup.end && value.pickup.end.diff(moment(), 'minutes') < 120) ||
        (value.delivery.end && value.delivery.end.diff(moment(), 'minutes') < 120)
      )
        return false;
      return true;
    });
  }

  tooLongOrShortValid(msg) {
    return yup.mixed().test('tooLongOrShortValid', `${msg}`, function(value) {
      if (
        value.pickup.end &&
        (moment(value.pickup.end).diff(moment(value.pickup.start), 'minutes') < 1 * 60 ||
          moment(value.pickup.end).diff(moment(value.pickup.start), 'minutes') > 4 * 60)
      )
        return false;
      if (
        value.delivery.end &&
        (moment(value.delivery.end).diff(moment(value.delivery.start), 'minutes') < 1 * 60 ||
          moment(value.delivery.end).diff(moment(value.delivery.start), 'minutes') > 4 * 60)
      )
        return false;
      return true;
    });
  }

  OutOfRangeValid(msg, dates) {
    return yup.mixed().test('OutOfRangeValid', `${msg}`, function(value) {
      if (
        dates.selectedDatetimePeriod.hasOwnProperty('start') &&
        (dates.chosenDatetimePeriod.start.isBefore(dates.selectedDatetimePeriod.start, 'minute') ||
          dates.chosenDatetimePeriod.end.isAfter(dates.selectedDatetimePeriod.end, 'minute'))
      ) {
        return false;
      }
      return true;
    });
  }

  formatDateType(msg) {
    return yup.object().test('formatDateType', msg, function(value) {
      if (!value) {
        return false;
      }
      return moment.isMoment(value);
    });
  }

  endBeforeStart(msg) {
    return yup.mixed().test('endBeforeStart', `${msg}`, function(value) {
      if (value.pickup.start && moment(value.pickup.start).format('x') > moment(value.pickup.end).format('x'))
        return false;
      if (value.delivery.start && moment(value.delivery.start).format('x') > moment(value.delivery.end).format('x'))
        return false;
      return true;
    });
  }

  pickupBeforeDelivery(msg) {
    return yup.mixed().test('pickupBeforeDelivery', `${msg}`, function(value) {
      if (value.pickup.chosenDatetimePeriod.start.isAfter(value.delivery.chosenDatetimePeriod.start, 'minute')) {
        return false;
      }
      return true;
    });
  }

  validate(callback) {
    let pickup = this.state.forms[this.props.uuid].pickupDatetimePeriodsFieldState;
    let delivery = this.state.forms[this.props.uuid].deliveryDatetimePeriodsFieldState;

    let locale = {
        pickupOutOfRangeMsg: () => {
          if (pickup.selectedDatetimePeriod.start) {
            return this.props.translate('datetime.messages.out_of_range', {
              start: pickup.selectedDatetimePeriod.start.format('HH:mm'),
              end: pickup.selectedDatetimePeriod.end.format('HH:mm'),
            });
          }
        },
        deliveryOutOfRangeMsg: () => {
          if (delivery.selectedDatetimePeriod.start) {
            return this.props.translate('datetime.messages.out_of_range', {
              start: delivery.selectedDatetimePeriod.start.format('HH:mm'),
              end: delivery.selectedDatetimePeriod.end.format('HH:mm'),
            });
          }
        },
        pickupBeforeDelivery: this.props.translate('datetime.messages.delivery_is_before_pickup'),
        tooLongOrShortValid: this.props.translate('datetime.messages.too_short_too_long'),
        tooNearTimeMsg: this.props.translate('datetime.messages.too_near'),
        endBeforeStart: this.props.translate('datetime.messages.end_before_start'),
      },
      errors = [];

    let inputValidation = {
      pickup: pickup.chosenDatetimePeriod,
      delivery: delivery.chosenDatetimePeriod,
    };

    const inputSchema = yup.object().shape({
      pickup: yup.object().shape({
        start: yup
          .date()
          .required()
          .formatDateType(
            this.props.translate('form.fields.default.required', {
              field: this.props.translate('datetime.pickup_period'),
            })
          ),
        end: yup
          .date()
          .required()
          .formatDateType(
            this.props.translate('form.fields.default.required', {
              field: this.props.translate('datetime.pickup_period'),
            })
          ),
      }),
      delivery: yup.object().shape({
        start: yup
          .date()
          .required()
          .formatDateType(
            this.props.translate('form.fields.default.required', {
              field: this.props.translate('datetime.delivery_period'),
            })
          ),
        end: yup
          .date()
          .required()
          .formatDateType(
            this.props.translate('form.fields.default.required', {
              field: this.props.translate('datetime.delivery_period'),
            })
          ),
      }),
    });

    const customSchema = {
      endBeforeStart: yup.mixed().endBeforeStart(locale.endBeforeStart),
      pickupBeforeDelivery: yup.mixed().pickupBeforeDelivery(locale.pickupBeforeDelivery, { pickup, delivery }),
      OutOfPickupRange: yup.mixed().OutOfRangeValid(locale.pickupOutOfRangeMsg(), pickup),
      OutOfDeliveryRange: yup.mixed().OutOfRangeValid(locale.deliveryOutOfRangeMsg(), delivery),
      tooNearTime: yup.mixed().tooNearTimeValid(locale.tooNearTimeMsg),
      tooLongOrShort: yup.mixed().tooLongOrShortValid(locale.tooLongOrShortValid),
    };

    try {
      const isValid =
        inputSchema.validateSync(inputValidation) &&
        customSchema.endBeforeStart.validateSync(inputValidation) &&
        customSchema.pickupBeforeDelivery.validateSync({ pickup, delivery }) &&
        customSchema.OutOfPickupRange.validateSync(inputValidation) &&
        customSchema.OutOfDeliveryRange.validateSync(inputValidation) &&
        customSchema.tooNearTime.validateSync(inputValidation) &&
        customSchema.tooLongOrShort.validateSync(inputValidation);
      if (isValid && callback) callback();
    } catch (e) {
      if (e.errors) errors = e.errors;
      else errors = [];
    }

    this.setState(
      {
        forms: setFormStateAtKey(this.state.forms, this.props.uuid, {
          ...this.state.forms[this.props.uuid],
          errors,
          isTouched: true,
        }),
      },
      () => {
        this.forceUpdate();
      }
    );
  }

  render() {
    let formState = this.state.forms[this.props.uuid];
    let isValid = formState.errors.length === 0 && formState.isTouched;
    let offerMessage = this.composeOfferMessage(formState, isValid);
    const pickupAvailableDateTime = this.props.transport_job.pickups[0].available_datetime_periods;
    const deliveryAvailableDateTime = this.props.transport_job.deliveries[0].available_datetime_periods;
    const isPickupAvailable = _get(pickupAvailableDateTime, '[0].start', false);
    const isDeliveryAvailable = _get(deliveryAvailableDateTime, '[0].start', false);
    const isPickupSameDayAsToday = isPickupAvailable
      ? moment().isSame(moment(pickupAvailableDateTime[0].start), 'date')
      : false;
    const isDeliverySameDayAsToday = isDeliveryAvailable
      ? moment().isSame(moment(deliveryAvailableDateTime[0].start), 'date')
      : false;
    // make pickup available date time to claim
    let pickupDatetimePeriods = datetimePeriodUtils.transformToFutureOnlyDatetimePeriods(
      pickupAvailableDateTime,
      moment.duration(isPickupSameDayAsToday ? 0 : 2, 'hours')
    );
    pickupDatetimePeriods = datetimePeriodUtils.filterShortDatetimePeriods(pickupDatetimePeriods);
    // make delivery available date time to claim
    let deliveryDatetimePeriods = datetimePeriodUtils.transformToFutureOnlyDatetimePeriods(
      deliveryAvailableDateTime,
      moment.duration(isDeliverySameDayAsToday ? 0.5 : 2, 'hours')
    );
    deliveryDatetimePeriods = datetimePeriodUtils.filterShortDatetimePeriods(deliveryDatetimePeriods);
    return (
      <Formik onSubmit={() => this.validate(this.submitOffer)}>
        {props => (
          <Form>
            <Field
              name="pickup"
              render={() => (
                <div className={'input-row'}>
                  <p>
                    <Translate id={'datetime.desc'} />
                  </p>
                  <p>{this.props.transport_job.pickups[0].locality}</p>
                  <legend>
                    <Translate id={'datetime.pickup_period'} />
                  </legend>
                  <DatetimePeriodsField
                    name="pickup"
                    min={moment().toDate()}
                    availableDatetimePeriods={pickupDatetimePeriods}
                    handleDatetimePeriodChange={this.handleDatetimePeriodChange}
                    state={formState.pickupDatetimePeriodsFieldState}
                  />
                </div>
              )}
            />
            <Field
              name="delivery"
              render={() => (
                <div className={'input-row'}>
                  <p>
                    <Translate id={'datetime.desc'} />
                  </p>
                  <p>{this.props.transport_job.deliveries[0].locality}</p>
                  <legend>
                    <Translate id={'datetime.delivery_period'} />
                  </legend>
                  <DatetimePeriodsField
                    name="delivery"
                    availableDatetimePeriods={deliveryDatetimePeriods}
                    state={formState.deliveryDatetimePeriodsFieldState}
                    handleDatetimePeriodChange={this.handleDatetimePeriodChange}
                    min={
                      formState.pickupDatetimePeriodsFieldState.chosenDatetimePeriod.hasOwnProperty('start')
                        ? moment(formState.pickupDatetimePeriodsFieldState.chosenDatetimePeriod.end).toDate()
                        : moment().toDate()
                    }
                  />
                </div>
              )}
            />
            {offerMessage}
            <div className={'conditions'}>
              <Translate id={'transportRequest.conditions'} options={{ renderInnerHtml: true }} />
            </div>
            {formState.errors.map((message, index) => {
              return <FlashMessage key={index} type={'error'} message={message} />;
            })}
            {/* general errors */}
            {this.props.errors &&
              this.props.errors.map((message, index) => {
                return <FlashMessage key={index} type={'error'} message={message} />;
              })}
            <Button
              type="submit"
              disabled={!isValid || this.state.isSubmitting}
              loading={this.state.isSubmitting}
              buttonStyle="primary"
              onClick={() => this.validate(this.submitOffer)}
            >
              <Translate id={'form.send'} />
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  handleDatetimePeriodChange(situation, newState) {
    let newForms = this.state.forms;
    if (situation === 'pickup') {
      newForms[this.props.uuid].pickupDatetimePeriodsFieldState = newState;
    }
    if (situation === 'delivery') {
      newForms[this.props.uuid].deliveryDatetimePeriodsFieldState = newState;
    }
    this.setState(
      {
        forms: newForms,
      },
      () => {
        this.validate();
      }
    );
  }

  handleOfferMessageChange(event) {
    let newForms = this.state.forms;
    newForms[this.props.uuid].offerMessage = event.target.value;
    this.setState({ forms: newForms });
  }

  composeOfferMessage(formState, isValid) {
    return (
      <div className={'input-row'}>
        <Heading size="h4">
          <Translate id={'communication.message'} />
        </Heading>
        {isValid && (
          <div className={'composed-message'}>
            <p>
              <Translate id={'datetime.compose_message.salutation'} data={{ name: this.props.clientname }} />
            </p>
            <p>
              <Translate id={'datetime.compose_message.intro'} />
            </p>
            {formState.pickupDatetimePeriodsFieldState.chosenDatetimePeriod.hasOwnProperty('start') && (
              <p>
                <Translate
                  id={'datetime.compose_message.pickup_datetime_period'}
                  data={{
                    date: this.state.forms[
                      this.props.uuid
                    ].pickupDatetimePeriodsFieldState.chosenDatetimePeriod.start.format('DD-MM-YYYY'),
                    start: this.state.forms[
                      this.props.uuid
                    ].pickupDatetimePeriodsFieldState.chosenDatetimePeriod.start.format('HH:mm'),
                    end: this.state.forms[
                      this.props.uuid
                    ].pickupDatetimePeriodsFieldState.chosenDatetimePeriod.end.format('HH:mm'),
                  }}
                />
              </p>
            )}
            {formState.deliveryDatetimePeriodsFieldState.chosenDatetimePeriod.hasOwnProperty('start') && (
              <p>
                <Translate
                  id={'datetime.compose_message.delivery_datetime_period'}
                  data={{
                    date: this.state.forms[
                      this.props.uuid
                    ].deliveryDatetimePeriodsFieldState.chosenDatetimePeriod.start.format('DD-MM-YYYY'),
                    start: this.state.forms[
                      this.props.uuid
                    ].deliveryDatetimePeriodsFieldState.chosenDatetimePeriod.start.format('HH:mm'),
                    end: this.state.forms[
                      this.props.uuid
                    ].deliveryDatetimePeriodsFieldState.chosenDatetimePeriod.end.format('HH:mm'),
                  }}
                />
              </p>
            )}
          </div>
        )}
        <div className={'input-element type-textarea'}>
          <textarea
            id={'message_offer'}
            value={formState.offerMessage}
            onChange={event => this.handleOfferMessageChange(event)}
            placeholder={this.props.translate('placeholders.type_message')}
          />
        </div>
        {isValid && (
          <div className={'composed-message'}>
            <p>
              <Translate id={'datetime.compose_message.greeting'} />
            </p>
            <p>{this.props.username}</p>
          </div>
        )}
      </div>
    );
  }

  submitOffer() {
    // TODO: determine live video from external services
    const isPrePaid = _get(this.props.transport_job, 'prepaid', false);
    const isBundled = _get(this.props.transport_job, 'bundled', false);
    const requirements = this.getTransportJobRequirements(this.props.transport_job);
    const message = [];
    let prompts = [];

    this.setState({
      isSubmitting: true,
    });

    const messageFinal = {
      pickup: {
        start: this.state.forms[this.props.uuid].pickupDatetimePeriodsFieldState.chosenDatetimePeriod.start.format(
          'YYYY-MM-DDTHH:mm:ssZ'
        ),
        end: this.state.forms[this.props.uuid].pickupDatetimePeriodsFieldState.chosenDatetimePeriod.end.format(
          'YYYY-MM-DDTHH:mm:ssZ'
        ),
      },
      delivery: {
        start: this.state.forms[this.props.uuid].deliveryDatetimePeriodsFieldState.chosenDatetimePeriod.start.format(
          'YYYY-MM-DDTHH:mm:ssZ'
        ),
        end: this.state.forms[this.props.uuid].deliveryDatetimePeriodsFieldState.chosenDatetimePeriod.end.format(
          'YYYY-MM-DDTHH:mm:ssZ'
        ),
      },
      message: this.state.forms[this.props.uuid].offerMessage,
    };

    const postOffer = () => {
      return new Promise(() => {
        return this.props.postOffer(messageFinal, this.props.uuid, this.props.user, this.props.transport_job);
      })
        .then(response => {
          this.setState({
            isSubmitting: false,
          });
          if (!_get(response, '@id', false)) {
            this.handleErrors(<Translate id={'messages.error'} />);
            return;
          }
          this.setState({
            forms: setFormStateAtKey(this.state.forms, this.props.uuid, initialState()),
          });
          this.props.showSuccessModal();
          this.props.onFormSuccess(response['@id'].split('transport_job_account_links/')[1]);
        })
        .catch(error => {
          this.handleErrors(<Translate id={'messages.error'} />);
          this.setState({
            isSubmitting: false,
          });
        });
    };

    this.setState({
      isSubmitting: false,
    });

    for (let req of requirements) {
      message.push(
        <span key={'m2'} className={'prepaid-msg'}>
          * <b className={`key-trans-${req}`}>{translate(`search.messages.requirements.${req}`)}</b>
        </span>
      );
    }
    message.push(
      <span key={'m3'} className={'prepaid-msg'}>
        * <b>{translate(`search.messages.legal`)}</b>
      </span>
    );

    if (isBundled) {
      message.push(
        <span key={'m2'} style={{ background: 'greenyellow' }}>
          * <b className={`key-trans-bundled`}>{translate('search.messages.bundled.message')}</b>
        </span>
      );
    }

    prompts.push({
      name: 'general',
      text: translate('modal.ok'),
    });

    this.props.showModal({
      modalType: 'prompt',
      modalProps: {
        header: <Translate id={'modal.are_you_sure'} />,
        action: () => postOffer(),
        prompts: prompts,
        message: message,
        actionText: isPrePaid ? <Translate id={'offer.claim'} /> : <Translate id={'offer.create'} />,
      },
    });
  }
  extraRequirementsByStop(stopType) {
    let requirements = [];
    if (stopType.details.situation === SITUATION_TYPES.auction) {
      requirements.push(SITUATION_TYPES.auction);
    }
    if (stopType.details.carrying_help === TJ_DETAILS_TYPES.help_carrying_yes_extra) {
      requirements.push(TJ_DETAILS_TYPES.help_carrying_yes_extra);
    }
    if (stopType.details.floor_level > 0) {
      requirements.push('floor_custom');
    }
    if (stopType.details.help_equipment === TJ_DETAILS_TYPES.equipment_tailgate) {
      requirements.push(TJ_DETAILS_TYPES.equipment_tailgate);
    }
    if (stopType.details.help_equipment === TJ_DETAILS_TYPES.equipment_tailgate_pallet_jack) {
      requirements.push(TJ_DETAILS_TYPES.equipment_tailgate_pallet_jack);
    }
    return requirements;
  }
  getTransportJobRequirements(job) {
    let requirements = [];
    let pickup_keys = [];
    let delivery_keys = [];
    job.pickups.map(pickup => {
      pickup_keys = pickup_keys.concat(this.extraRequirementsByStop(pickup));
      return pickup_keys;
    });
    job.deliveries.map(delivery => {
      delivery_keys = delivery_keys.concat(this.extraRequirementsByStop(delivery));
      return delivery_keys;
    });
    if (job.prepaid) {
      requirements.push('prepaid');
    }
    requirements = requirements.concat(pickup_keys);
    requirements = requirements.concat(delivery_keys);
    return [...new Set(requirements)];
  }
  handleErrors(message) {
    this.setState({
      forms: setFormStateAtKey(this.state.forms, this.props.uuid, {
        ...this.state.forms[this.props.uuid],
        errors: this.state.forms[this.props.uuid].errors.concat(message),
        isTouched: true,
      }),
      isSubmitting: false,
    });
  }
}

const setFormStateAtKey = (array, key, state) => {
  array[key] = state;
  return array;
};

export default withLocalize(OfferForm);
