import React, { Component } from 'react';
import './css/App.css';
import VisibleInvoiceList from './containers/delivered/VisibleDeliveredList';
import VisibleDeliveredDetail from './containers/delivered/VisibleDeliveredDetail';

class DeliveredApp extends Component {
  render() {
    return (
      <div className={'main-with-sidebar'}>
        <VisibleInvoiceList />
        <VisibleDeliveredDetail />
      </div>
    );
  }
}

export default DeliveredApp;
