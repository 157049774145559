import React from 'react';
import Pikaday from 'pikaday';
import moment from 'moment';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.needsDatePicker = React.createRef();
  }
  componentDidMount() {
    this.picker = new Pikaday({
      field: this.needsDatePicker.current,
      format: 'DD-MM-YYYY',
      defaultDate: null,
      setDefaultDate: true,
      minDate: this.props.min,
      onSelect: this.props.handleChange ? date => this.props.handleChange(date) : null,
    });
  }

  componentWillUpdate() {
    if (this.props.min) {
      this.picker.setMinDate(this.props.min);
      if (moment(this.picker.getDate()).isBefore(this.props.min)) {
        this.picker.setDate(null, false);
        this.props.handleChange(null);
      }
    }
  }

  render() {
    return (
      <input
        value={
          moment(this.props.value).format('DD-MM-YYYY') === 'Invalid date'
            ? ''
            : moment(this.props.value).format('DD-MM-YYYY')
        }
        id={this.props.name}
        name={this.props.name}
        onChange={this.props.handleChange}
        ref={this.needsDatePicker}
      />
    );
  }
}

export default DatePicker;
