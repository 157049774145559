import React from 'react';
import { connect } from 'react-redux';

import { default as modalTypes } from '../../components/modals';
import Modal from '../../Modal';
import { hideModal } from '../../actions/modals';

export const MODAL_TYPES = {
  confirm: modalTypes.confirmModal,
  prompt: modalTypes.promptModal,
  notification: modalTypes.notificationModal,
};

const mapStateToProps = state => ({
  ...state.modalReducer,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideModal()),
});

class ModalContainer extends React.Component {
  render() {
    if (!this.props.modalType) {
      return null;
    }
    const SpecifiedModal = MODAL_TYPES[this.props.modalType];
    return (
      <div>
        <Modal
          open={this.props.modalIsOpen}
          toggleFunction={this.props.closeModal}
          header={this.props.modalProps.header}
        >
          <SpecifiedModal closeModal={this.props.closeModal} {...this.props.modalProps} />
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
