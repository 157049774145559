import { connect } from 'react-redux';
import { setPlanningFilter, togglePlanningFilters } from '../../actions/planning';
import PlanningFilters from '../../components/planning/PlanningFilters';
import { getPlanningFilters } from '../../reducers/offers';

const mapStateToProps = (state, ownProps) => ({
  filters: getPlanningFilters(state),
  open: state.planning.offers.filtersOpen,
  resultCount: ownProps.resultCount,
});

const mapDispatchToProps = dispatch => ({
  handleToggleFilters: () => dispatch(togglePlanningFilters()),
  handleSetFilter: filter => dispatch(setPlanningFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanningFilters);
