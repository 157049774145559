import * as appUtils from '../../Utils';
import moment from 'moment';
import history from '../../History';
import { types as offerTypes } from '../../reducers/offers';
import _get from 'lodash/get';
import http from '../../UtilsHttp';

const SITEPREFIX = appUtils.getPathForApp();

export const closeSingleView = () => {
  appUtils.deActivateSingleView();
  return dispatch => {
    dispatch(deselectOffer());
    const link = SITEPREFIX + '/planning/';
    history.push(link);
  };
};

export const selectOffer = id => ({ type: offerTypes.SELECT_OFFER, id });
export const deselectOffer = () => ({ type: offerTypes.DESELECT_OFFER });
export const removeOffer = id => ({ type: offerTypes.REMOVE_OFFER, id });
export const updateOffers = (id, data) => ({ type: offerTypes.UPDATE_OFFERS, id, data });
export const updateSelectedBundledStops = stops => ({ type: offerTypes.UPDATE_BUNDLED_STOPS, stops });
export const fetchOffersStart = () => ({ type: offerTypes.FETCH_OFFERS_START });
export const fetchOffersSuccess = payload => ({ type: offerTypes.FETCH_OFFERS_SUCCESS, payload });
export const fetchOffersError = () => ({ type: offerTypes.FETCH_OFFERS_ERROR });
export const fetchSingleOfferStart = id => ({ type: offerTypes.FETCH_SINGLE_OFFER_START, id });
export const toggleFetchOffers = () => ({ type: offerTypes.TOGGLE_FETCH_OFFERS });
export const togglePlanningFilters = () => ({ type: offerTypes.TOGGLE_PLANNING_FILTERS });
export const confirmDeliverySucceed = err => ({ type: offerTypes.CONFIRM_DELIVERY_PER_STOP_SUCCESS, err });
export const confirmDeliveryError = err => ({ type: offerTypes.CONFIRM_DELIVERY_PER_STOP_ERROR, err });
export const clearErrors = () => ({ type: offerTypes.CLEAR_OFFERS_ERRORS });

export const setPlanningFilter = event => {
  return {
    type: offerTypes.SET_PLANNING_FILTER,
    filter: event.target.name,
  };
};
export const confirmDeliveryPerStop = (stop, type) => ({
  type: offerTypes.CONFIRM_DELIVERY_PER_STOP,
  payload: { stop, type },
});
export const patchOfferStart = action => ({ type: offerTypes.PATCH_OFFER_START, action });
export const patchOfferSuccess = (offerId, action) => ({ type: offerTypes.PATCH_OFFER_SUCCESS, offerId, action });
export const patchOfferError = (action, error) => ({ type: offerTypes.PATCH_OFFER_ERROR, action, error });

export const onOfferClick = (uuid, sectionName) => {
  appUtils.activateSingleView();
  return dispatch => {
    dispatch(selectOffer(uuid));
    let link = SITEPREFIX + `/${sectionName}/` + uuid;
    history.push(link);
  };
};

export const createSortedDayListFromOffers = (offers = [], includePickup = true) => {
  const dayList = new Set();
  dayList.add(moment().format('YYYYMMDD'));
  for (const item of offers) {
    if (includePickup && _get(item, 'pickup_commitments[0]', false)) {
      dayList.add(item.pickup_commitments[0].committed_datetime_period.start.format('YYYYMMDD'));
    }
    if (_get(item, 'delivery_commitments[0]', false)) {
      dayList.add(item.delivery_commitments[0].committed_datetime_period.start.format('YYYYMMDD'));
    }
  }
  return new Set([...dayList].sort());
};

export const scrollToSidebarItem = id => {
  const item = document.getElementById(`/transport_job_account_links/${id}`);
  if (item !== null) {
    // Behaviour smooth is not supported by all browser, we could see it as a nice to have, or polyfill it later
    item.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
  }
};

export const patchExistingOfferForTransportJob = (newData, offerId, user, job) => {
  const data = {
    transport_job: job['@id'],
    driver_account: user.account['@id'],
    driver_user: user['@id'],
    pickup_commitments: [
      {
        pickup: job.pickups[0]['@id'], // IRI
        committed_datetime_period: newData.pickup,
      },
    ],
    delivery_commitments: [
      {
        delivery: job.deliveries[0]['@id'], //IRI
        committed_datetime_period: newData.delivery,
      },
    ],
  };
  return new Promise((resolve, conflict) =>
    http()
      .put(`/transport_job_account_links/${offerId}`, data, { withCredentials: true })
      .then(resp => {
        resolve(resp.data);
        return;
      })
      .catch(error => {
        conflict(error);
        return;
      })
  );
};

export const prepareOfferItem = apiOfferItem => {
  if (!apiOfferItem.hasOwnProperty('@id')) {
    return;
  }
  // #MIGRATION
  apiOfferItem.created_at = moment(apiOfferItem.created_at);
  apiOfferItem.pickup_commitments.map(pick => {
    pick.committed_datetime_period.start = moment(pick.committed_datetime_period.start);
    pick.committed_datetime_period.end = moment(pick.committed_datetime_period.end);
    return pick;
  });
  apiOfferItem.delivery_commitments.map(delivery => {
    delivery.committed_datetime_period.start = moment(delivery.committed_datetime_period.start);
    delivery.committed_datetime_period.end = moment(delivery.committed_datetime_period.end);
    return delivery;
  });
  return apiOfferItem;
};
