import React, { PureComponent } from 'react';
import { Col } from '../../Grid.js';
import IconSmile from '../../icons/IconSmile';
import moment from 'moment';
import '../../css/OneItem.css';
import StatusLabel from './../../StatusLabel';

import RenderRequestSpecIcon from '../../icons/RenderRequestSpecIcon';
import { Translate } from 'react-localize-redux';
import * as appUtils from './../../Utils';
import _get from 'lodash/get';
import ItemVolumes from '../../ItemVolumes';
import CityName from '../../CityName.js';

const provinces = {
  Drenthe: 'DR',
  Flevoland: 'FL',
  Friesland: 'FR',
  Gelderland: 'GE',
  Groningen: 'GR',
  Limburg: 'LI',
  'Noord-Brabant': 'NB',
  'Noord-Holland': 'NH',
  Overijssel: 'OV',
  Utrecht: 'UT',
  Zeeland: 'ZE',
  'Zuid-Holland': 'ZH',
};

class OneItem extends PureComponent {
  render() {
    const transport =
      this.props.renderType === 'planning' || this.props.renderType === 'inbox' || this.props.renderType === 'delivered'
        ? this.props.item.transport_job
        : this.props.item;
    const requestReward = transport.hasOwnProperty('price') ? appUtils.priceLayout(transport.price) : '0.00';
    const requestIcons = this.renderSpecialIcons(transport);
    return (
      <div
        className={'card one-item-container' + (this.props.isSelected ? ' is-selected' : '')}
        onClick={() => this.props.onItemClick(this.props.item.uuid, this.props.renderType)}
      >
        <div className={'one-item-inner type-' + this.props.renderType} id={this.props.item.uuid}>
          <div className={'dropdown-toggle'}>
            <IconSmile />
          </div>
          <div className="row address-details address-pickup">
            <Col xs="8">
              <div className="city-name">
                <span className="short">{transport.pickups[0].address.locality}</span>
              </div>
              {(this.props.renderType === 'planning' || this.props.renderType === 'delivered') && (
                <div className="city-details">
                  {this.getAddressLines(transport.pickups[0]).map((item, index) => {
                    return <span key={index}>{item}</span>;
                  })}
                  <RenderRequestSpecIcon icondata={_get(transport, 'pickups[0].situation.system_name', '')} />
                </div>
              )}
              {this.props.renderType === 'search' && (
                <div className="city-details">
                  <span>
                    <Translate id="transport_request.pickup_situation" />:
                  </span>{' '}
                  <RenderRequestSpecIcon icondata={_get(transport, 'pickups[0].situation.system_name', '')} />
                </div>
              )}
            </Col>
            <Col xs="4" extraClasses={'date-time-col'}>
              {this.props.renderType === 'inbox' && <div className="date">{this.inboxTimeDisplay('pickup')}</div>}
              {this.props.renderType !== 'inbox' && <div className="date">{this.determineTimeDisplay('pickup')}</div>}
            </Col>
          </div>
          <div className="row address-details address-delivery">
            <Col xs="8">
              <CityName>{transport.hasOwnProperty('deliveries') && this.getCityname(transport.deliveries[0])}</CityName>
              <div className="city-details">
                <span>
                  <Translate id="transport_request.delivery_situation" />
                </span>{' '}
                <RenderRequestSpecIcon icondata={_get(transport, 'deliveries[0].situation.system_name', '')} />
              </div>
              <div className="city-name">
                <span className="short">{transport.deliveries[0].address.locality}</span>
              </div>
              {this.props.renderType === 'planning' && (
                <div className="city-details">
                  {this.getAddressLines(transport.deliveries[0]).map((item, index) => {
                    return <span key={index}>{item}</span>;
                  })}
                  <RenderRequestSpecIcon icondata={_get(transport, 'deliveries[0].situation.system_name', '')} />
                </div>
              )}
              {this.props.renderType === 'search' && (
                <div className="city-details">
                  <span>
                    <Translate id="transport_request.delivery_situation" />
                  </span>{' '}
                  <RenderRequestSpecIcon icondata={_get(transport, 'deliveries[0].situation.system_name', '')} />
                </div>
              )}
            </Col>
            <Col xs="4" extraClasses={'date-time-col'}>
              {this.props.renderType === 'inbox' && <div className="date">{this.inboxTimeDisplay('delivery')}</div>}
              {this.props.renderType !== 'inbox' && <div className="date">{this.determineTimeDisplay('delivery')}</div>}
            </Col>
          </div>
          <div className="row">
            <Col xs="12">
              {transport.title} <ItemVolumes items={_get(transport, 'items', [])} />
            </Col>
          </div>
          {(this.props.renderType === 'search' || this.props.renderType === 'planning') && (
            <div className={'request-details'}>
              <div className="column-1">
                <div className={'labels'}>
                  <StatusLabel
                    status={
                      this.props.renderType === 'search'
                        ? this.props.item.offers.length > 0
                          ? this.props.item.offers[0].status
                          : 'open'
                        : this.props.item.status
                    }
                  />
                  {transport.is_business && <StatusLabel status={'business'} />}
                </div>
                <div className={'transport_icons'}>{requestIcons}</div>
              </div>
              <div className="column-2">
                {this.props.renderType === 'search' && (
                  <RenderRequestSpecIcon icondata={_get(transport, 'size_type.system_name', '')} />
                )}
                <div className="request-details-price">
                  &euro; {requestReward}
                  {transport.is_paid_in_advance && <StatusLabel status={'paid_in_advance'} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  getCityname(direction) {
    if (direction.country_code === 'NL') {
      return direction.locality + (direction.region ? ', ' + _get(provinces, direction.region, '') : '');
    }
    return direction.locality + ', ' + direction.country_code;
  }

  getAddressLines(direction) {
    if (typeof direction.address_line1 === 'undefined') {
      return [<Translate id="transport_request.addressHidden" />];
    }
    const address1 = direction.address_line1 || '';
    const address2 = direction.address_line2 || '';
    const postalCode = direction.postal_code || '';
    const locality = direction.locality || '';
    return [address1 + ' ' + address2, postalCode + ' ' + locality];
  }

  renderSpecialIcons(transport) {
    return [
      _get(transport, 'special_requirements', []).map((requirement, index) => {
        return <RenderRequestSpecIcon key={index} icondata={requirement.system_name} />;
      }),
    ];
  }

  inboxTimeDisplay(direction) {
    if (this.props.item.status === 'waiting_for_datetime_periods') {
      return '';
    }
    return (
      this.props.item[direction].start.format('DD-MM-YY') +
      '\n' +
      this.props.item[direction].start.format('HH:mm') +
      '-' +
      this.props.item[direction].end.format('HH:mm')
    );
  }

  determineTimeDisplay(direction) {
    if (typeof this.props.date === 'undefined') {
      return '';
    }
    if (this.props.item.hasOwnProperty(direction)) {
      if (this.props.date.isSame(this.props.item[direction].start, 'day')) {
        return this.props.item[direction].start.format('HH:mm') + '-' + this.props.item[direction].end.format('HH:mm');
      } else {
        return <Translate id={'datetime.otherDay'} />;
      }
    } else {
      let datetimePeriods = _get(
        this.props.item,
        direction === 'pickup' ? 'pickups[0].available_datetime_periods' : 'deliveries[0].available_datetime_periods',
        []
      );
      if (datetimePeriods.length === 0) {
        return <Translate id={'transport_request.propose'} />;
      }
      let dateTimeperiodsArr = datetimePeriods.map((datetimePeriod, index) => {
        return moment(datetimePeriod.start).format('DD-MM HH:mm');
      });
      return dateTimeperiodsArr[0] + (dateTimeperiodsArr.length > 1 ? '+' : '');
    }
  }

  formatDate(date) {
    if (typeof this.props.date === 'undefined') {
      return '';
    }
    // return dateformat without dot at the end
    return appUtils.stripLastCharacter(moment(date).format('dd DD MMM')).toUpperCase();
  }

  formatTime(start, end) {
    return start.format('HH:mm') + '-' + end.format('HH:mm');
  }
}

export default OneItem;
