import React from 'react';
import '../css/IconStyle.css';

const IconHelpCarying = () => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-153.000000, -853.000000)" fill="#000000" fillRule="nonzero">
          <g transform="translate(0.000000, 612.000000)">
            <polygon points="163.909091 241 163.909091 244.636364 160.272727 244.636364 160.272727 248.272727 156.636364 248.272727 156.636364 251.909091 153 251.909091 153 257 154.454545 257 154.454545 253.363636 158.090909 253.363636 158.090909 249.727273 161.727273 249.727273 161.727273 246.090909 165.363636 246.090909 165.363636 242.454545 169 242.454545 169 241" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconHelpCarying;
