import React, { Component } from 'react';
import '../../css/Sidebar.css';
import '../../css/RoutePlanner.css';
import { Col } from '../../Grid';
import { Button } from '../../Button';
import VisibleFilters from '../../containers/routePlanner/VisibleFilters';
import OneItem from './OneItem';
import IconSmile from '../../icons/IconSmile';
import RouteMap from '../../containers/routePlanner/RouteMap';
import { Translate, withLocalize } from 'react-localize-redux';
import IconFilters from '../../icons/IconFilters';
import * as appUtils from '../../Utils';
import { fetchGet } from '../../api/general';
import RouteOptimizer from '../routeOptimizer/RouteOptimizer';
import _ from 'lodash';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSorting: 'kickoff-sort',
      selectedTransportRequests: [],
      distanceService: null, //Google map distance service object
      directionsService: null, //Google map direction service object
      geoCoder: null, //Google map geo coder service object
      loading: false,
      isValid: true,
      routePolylines: [],
      pickupCoords: [],
      deliveryCoords: [],
      readyToDraw: false,
      message: '',
    };
    this.handleTransportRequestClick = this.handleTransportRequestClick.bind(this);
  }

  componentWillMount() {
    this.props.fetchTransportJobs();
  }

  componentDidUpdate(prevProps) {
    const cities = this.props.citySortedItems;
    const filters = this.props.filters;

    if (cities !== prevProps.citySortedItems || filters !== prevProps.filters) {
      this.props.fetchTransportJobs();
    }
  }

  getMapData() {
    let result = {};
    for (let transportRequest of this.state.selectedTransportRequests) {
      result[transportRequest.uuid] = this.state.routePolylines[transportRequest.uuid];
    }
    this.setState({
      readyToDraw: true,
    });
    this.forceUpdate();
  }

  handleTransportRequestClick(uuid, renderType) {
    const jobData = this.props.transportRequests.find(item => item.uuid === uuid);
    let selectedTransportRequests = this.state.selectedTransportRequests;
    this.setState({
      readyToDraw: false,
    });

    if (typeof selectedTransportRequests.find(item => item.uuid === uuid) !== 'undefined') {
      selectedTransportRequests = _.remove(selectedTransportRequests, item => item.uuid !== uuid);
      this.setState({ readyToDraw: true });
    } else {
      selectedTransportRequests.push(jobData);
      if (!this.state.routePolylines.includes(uuid)) {
        this.fetchRoutePolygons(uuid)
          .then(resp => {
            let routePolylines = this.state.routePolylines;
            routePolylines[uuid] = resp;
            this.setState({ routePolylines: routePolylines, readyToDraw: true });
          })
          .catch(err => {
            //console.log(err)
          });
      } else {
        this.setState({ readyToDraw: true });
      }
    }
    this.setState({ selectedTransportRequests: selectedTransportRequests });
  }

  fetchRoutePolygons(uuid) {
    return new Promise((resolve, conflict) => {
      const apiUrl = appUtils.createApiUrl(`/transport_jobs/${uuid}/route`);
      fetchGet(apiUrl).then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data.polygons);
          });
        } else {
          conflict(null);
        }
      });
    });
  }

  getZoomCenter() {
    return { center: { lat: 52.55498, lng: 5.91721 }, zoom: 11 };
  }

  render() {
    return (
      <div className={'route-planner-wrapper'}>
        <aside
          className={
            'sidebar sidebar-planner view-search' + (this.props.selectedTransportJobId === '' ? '' : ' hide-print')
          }
        >
          <div className={'list'}>
            <div>
              {
                <Col xs="12" extraClasses={'filter-toggle-wrap hide-print'}>
                  <Button buttonStyle={'primary'} onClick={() => this.props.handleToggleFilters()}>
                    <Translate id={'search.list.open_filters'} /> <IconFilters />
                  </Button>
                </Col>
              }
              {this.props.isFetching && (
                <Col xs="12">
                  <IconSmile spinning={true} /> <Translate id={'search.list.loading'} />
                </Col>
              )}
              <section className={'list-section'}>
                {!this.props.filtersOpen &&
                  this.props.transportJobs.map((value, index) => {
                    return _.get(value, 'pickups[0].address.locality', false) &&
                      _.get(value, 'deliveries[0].address.locality', false) ? (
                      <OneItem
                        key={index}
                        item={value}
                        onItemClick={this.handleTransportRequestClick}
                        isSelected={this.state.selectedTransportRequests.find(selected => selected.uuid === value.uuid)}
                        renderType={this.props.renderType}
                        date={value.created_at}
                      />
                    ) : null;
                  })}
              </section>
            </div>
            <VisibleFilters resultCount={this.props.transportJobs.length} />
          </div>
        </aside>
        {this.props.transportRequests.length > 0 && (
          <RouteMap routePolylines={this.getMapData()} readyToDraw={this.state.readyToDraw} />
        )}
        <RouteOptimizer
          transportRequests={this.state.selectedTransportRequests}
          routePolylines={this.state.routePolylines}
          date={this.props.filters.date}
        />
      </div>
    );
  }
}

export default withLocalize(Layout);
