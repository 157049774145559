import React, { PureComponent } from 'react';
import { Col } from '../../Grid.js';
import IconSmile from '../../icons/IconSmile';
import moment from 'moment';
import '../../css/OneItem.css';
import _get from 'lodash/get';
import { provincesList } from '../../globals';

class PreviewItemCard extends PureComponent {
  render() {
    const transport = this.props.item;
    const uuid = transport['@id'].split('transport_jobs/')[1];
    return (
      <div
        className={'card one-item-container one-item-inbox' + (this.props.isSelected ? ' is-selected' : '')}
        onClick={() => this.props.onItemClick(uuid)}
      >
        <div className={'one-item-inner type-search'} id={this.props.item['@id']}>
          <div className={'dropdown-toggle'}>
            <IconSmile />
          </div>
          <div className="row address-details address-pickup">
            <Col xs="6">
              <div className="city-name">
                <span className="short">
                  {`${_get(transport.pickups[0], 'address.locality', '')}, `}
                  {this.getProvinceAbbr(_get(transport.pickups[0], 'address.administrative_area', ''))}
                </span>
              </div>
            </Col>
            <Col xs="6" extraClasses={'date-time-col'}>
              <div className="date">{this.inboxTimeDisplay('pickups')}</div>
            </Col>
          </div>
          <div className="row address-details address-delivery">
            <Col xs="6">
              <div className="city-name">
                <span className="short">
                  {`${_get(transport.deliveries[0], 'address.locality', '')}, `}
                  {this.getProvinceAbbr(_get(transport.deliveries[0], 'address.administrative_area', ''))}
                </span>
              </div>
            </Col>
            <Col xs="6" extraClasses={'date-time-col'}>
              <div className="date">{this.inboxTimeDisplay('deliveries')}</div>
            </Col>
          </div>
        </div>
      </div>
    );
  }
  getProvinceAbbr(administrativeArea) {
    const target = provincesList.filter(province => province.name === administrativeArea);
    return target.length > 0 ? target[0].abbreviation : administrativeArea;
  }
  inboxTimeDisplay(stopType) {
    if (
      !_get(this.props.item[stopType][0], 'available_datetime_periods[0].start', false) ||
      !_get(this.props.item[stopType][0], 'available_datetime_periods[0].end', false)
    ) {
      return '';
    }
    return (
      moment(this.props.item[stopType][0].available_datetime_periods[0].start).format('DD-MM-YY') +
      '\n' +
      moment(this.props.item[stopType][0].available_datetime_periods[0].start).format('HH:mm') +
      '-' +
      moment(this.props.item[stopType][0].available_datetime_periods[0].end).format('HH:mm')
    );
  }
}

export default PreviewItemCard;
