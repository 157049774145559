import * as appUtils from '../Utils';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { fetchUrl } from '../api/general';
import { types as preferredRoutes } from '../reducers/preferredRoutes';
import {
  submitPreferredRouteFormSuccess,
  deletePreferredRouteStart,
  deletePreferredRouteSuccess,
  fetchPreferredRoutesError,
  fetchPreferredRoutesSuccess,
  submitPreferredRouteFormError,
} from '../actions/preferredRoutes';

import { getUserDetails } from '../reducers/user';

export function* preferredRoutesSagas() {
  yield takeEvery(preferredRoutes.FETCH_PREFERRED_ROUTES, fetchPreferredRoutes);
  yield takeEvery(preferredRoutes.DELETE_PREFERRED_ROUTE_START, deletePreferredRouteSaga);
  yield takeEvery(preferredRoutes.SUBMIT_PREFERRED_ROUTE_FORM_START, submitPreferredRouteFormStart);
}

export function* fetchPreferredRoutes() {
  try {
    let url = appUtils.createApiUrl(`users/me/preferred_routes`, 2);
    let response = yield call(fetchUrl, url);
    let mappedRoutes = response['hydra:member'].map(route => ({
      id: route.id,
      from: route.from_locality,
      to: route.to_locality,
      transport_type: route.transport_size_class,
    }));
    yield put(fetchPreferredRoutesSuccess(mappedRoutes));
  } catch (e) {
    yield put(fetchPreferredRoutesError(e));
  }
}

export function* deletePreferredRouteSaga({ id }) {
  // handle api
  let url = appUtils.createApiUrl('preferred_routes/' + id, 2);
  try {
    yield call(fetch, url, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/ld+json',
      },
    });
    yield put(deletePreferredRouteSuccess(id));
  } catch (e) {
    //retry
    yield put(deletePreferredRouteStart(id));
  }
}

export function* submitPreferredRouteFormStart(action) {
  let route = action.route;
  let user = yield select(getUserDetails);
  route.user = user['@id'];
  const url = appUtils.createApiUrl('preferred_routes', 2);
  try {
    const data = yield call(fetch, url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/ld+json',
      },
      body: JSON.stringify(route),
    });

    route['@id'] = data['@id'];
    yield put(submitPreferredRouteFormSuccess(route));
  } catch (e) {
    yield put(submitPreferredRouteFormError);
  }
}
