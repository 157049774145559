import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { SectionWrapper } from '../../layout/SectionWrapper';
import StatusLabel from '../../../StatusLabel';
import Heading from '../../../Heading';
import Medal from '../../../icons/Medal';
import CriteriaAngularLists from './CriteriaAngularLists';

class CriteriaAngular extends Component {
  render() {
    const brengerLevel = 'angular';
    return (
      <div>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg profile-status-wrapper'}
          centerContent={true}
        >
          <StatusLabel status={'title'} />
          <Heading size={'h3'}>
            <Translate id={'status.' + brengerLevel} />
          </Heading>
          <div>
            <Translate id={'profile_page.intro.' + brengerLevel} />
          </div>
          <Medal type={'silver'} animated={true} />
        </SectionWrapper>
        <div className={'main-centered-content'}>
          <CriteriaAngularLists />
        </div>
      </div>
    );
  }
}

export default CriteriaAngular;
