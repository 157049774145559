const initialState = {
  modalType: null,
  modalProps: {},
  modalIsOpen: false,
};

export const types = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        modalProps: action.modalProps,
        modalType: action.modalType,
        type: action.type,
        modalIsOpen: true,
      };
    case types.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
