import React from 'react';
import '../../css/Line-list.css';
import { Translate } from 'react-localize-redux';
import { renderScoreAsStars, renderCheckmark } from '../../UtilsReviews';

/**
 *
 * @returns {*}
 * @constructor
 */
export const StatsList = props => {
  return (
    <ul className={'line-list'}>
      {props.items.map((item, index) => {
        return (
          <li className={'line-list-item delay-index-' + index} key={index}>
            <div className={'line-list-item-label'}>
              <Translate id={item.label} />
            </div>
            {item.hasOwnProperty('value') && (
              <div className={'line-list-item-value'}>
                {typeof item.value === 'number' && renderScoreAsStars(item.value, item.label)}
                {typeof item.value === 'boolean' && renderCheckmark(item.value)}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};
