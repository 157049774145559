import React, { Component } from 'react';
import { TJ_STATUS, TJAL_STATUS } from '../../globals';
import _get from 'lodash/get';
import { Translate } from 'react-localize-redux';
import { Col } from '../../Grid';

class ExternalInvoices extends Component {
  render() {
    return (
      <>
        <Col xs="12" extraClasses="instructions-part">
          {this.props.status === TJAL_STATUS.DELIVERED ||
          this.props.status === TJAL_STATUS.ACCEPTED ||
          this.props.status === TJ_STATUS.DRIVER_CONFIRMED ||
          this.props.status === TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER ||
          this.props.status === TJ_STATUS.DELIVER_CONFIRMED_BY_CUSTOMER ? (
            this.props.itemSets[0].external_invoices.map(invoice => (
              <>
                <h4>
                  <Translate id={'bundled.essentials.factuur'} />:
                </h4>
                <p>
                  <a key={'download_invoice'} href={_get(invoice, 'download_link', '#')} target={'_blank'}>
                    <Translate id={'actions.download_invoices'} />
                  </a>
                </p>
              </>
            ))
          ) : (
            <p>
              <Translate id={'bundled.offer.info_available_after_claim'} />
            </p>
          )}
        </Col>
      </>
    );
  }
}

export default ExternalInvoices;
