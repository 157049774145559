import React, { Component } from 'react';
import VisibleSidebar from './containers/routePlanner/VisibleSidebar';

class RoutePlanner extends Component {
  render() {
    return [<VisibleSidebar key={1} />];
  }
}

export default RoutePlanner;
