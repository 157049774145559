import React, { Component } from 'react';
import ConversationItem from './ConversationItem';
import * as utilMessages from '../../UtilsMessages';
import * as appUtils from '../../Utils';
import history from '../../History';
import { Col } from '../../Grid';
import IconSmile from '../../icons/IconSmile';
import { scrollToSidebarItem } from '../../actions/planning';
import { Translate } from 'react-localize-redux';
import { getIdFromUrl } from '../../Utils';
import { matchPath } from 'react-router-dom';
import _get from 'lodash/get';

const SITEPREFIX = appUtils.getPathForApp();

class ConversationList extends Component {
  componentWillMount() {
    this.props.deselectConversation();
    this.props.fetchConversations();
  }
  componentDidMount() {
    //Update the active  conversation every 30seconds
    window.conversationInterval = setInterval(() => this.props.fetchConversations(), 30000);

    //Upon navigating through forward/back, select/deselect according to new url
    window.onpopstate = () => {
      const selectedUUID = getIdFromUrl(history, SITEPREFIX, 'inbox');
      if (selectedUUID !== '') {
        this.props.selectConversation(selectedUUID);
        scrollToSidebarItem(selectedUUID);
      } else {
        this.props.deselectConversation();
      }
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.conversations !== this.props.conversations &&
      Object.keys(this.props.conversations).length > 0 &&
      this.props.isFetching === true
    ) {
      const path = history.location.pathname;
      const match = matchPath(path, { path: SITEPREFIX + `/messages/:id` });
      if (match) {
        // first deselect
        this.props.deselectConversation();
        this.props.selectConversation('/transport_job_account_links/' + match.params.id);
      }
    }
  }
  componentWillUnmount() {
    clearInterval(window.conversationInterval);
  }
  render() {
    const conversations = this.props.conversations;
    return (
      <aside className={'sidebar'}>
        <div className={'list'}>
          <div className={'dd-tabs sticky'}>
            <Col xs="12">
              <h3>
                <Translate id={'inbox.title'} />
              </h3>
            </Col>
          </div>
          {this.props.isFetching && (
            <Col xs="12">
              <IconSmile spinning={true} />
              <Translate id="inbox.list.loading" />
            </Col>
          )}
          {Object.keys(conversations).length > 0 &&
            Object.keys(conversations).map((item, index) => {
              return (
                <ConversationItem
                  key={item}
                  isBundled={_get(conversations[item], 'transport.bundled', false)}
                  conversation={conversations[item]}
                  isSelected={
                    this.props.selectedConversationId ===
                    _get(conversations[item], `job.transport_job_account_link['@id']`, null)
                  }
                  isNew={utilMessages.conversationHasNewMessages(item)}
                  onClick={this.props.onConversationClick}
                />
              );
            })}
          {conversations.length === 0 && this.props.isFetching === false && (
            <div className={'conversation-item'}>
              <Translate id="inbox.list.zero_results" />
            </div>
          )}
        </div>
      </aside>
    );
  }
}

export default ConversationList;
