import React, { Component } from 'react';
import { Button } from '../../Button.js';
import { Col } from '../../Grid.js';
import '../../css/Sidebar.css';
import '../../css/Form.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as appUtils from '../../Utils';
import IconSmile from '../../icons/IconSmile';
import VisiblePlanningFilters from '../../containers/planning/VisiblePlanningFilters';
import IconFilters from '../../icons/IconFilters';
import VisibleDeliveredFilters from '../../containers/delivered/VisibleDeliveredFilters';
import { Translate } from 'react-localize-redux';
import { scrollToSidebarItem } from '../../actions/planning';
import history from '../../History';
import { getIdFromUrl } from '../../Utils';
import RenderRequestSpecIcon from '../../icons/RenderRequestSpecIcon';
import { getSpecialIconsArray } from '../../Utils';
import _get from 'lodash/get';
import DeliveryItem from '../delivered/DeliveryItem';
import DeliveryBundledItem from '../delivered/DeliveryBundledItem';

const SITEPREFIX = appUtils.getPathForApp();

class DeliveredList extends Component {
  constructor(props) {
    super(props);
    // I am crying inside because of this
    let visible = window.location.pathname.indexOf(SITEPREFIX + '/delivered') > -1 ? 'delivered' : 'planning';
    this.state = {
      visible: visible,
    };
  }
  componentWillMount() {
    this.props.fetchOffers();
  }
  componentDidMount() {
    let selectedUUID = getIdFromUrl(history, SITEPREFIX, this.props.renderType);
    if (selectedUUID !== '') {
      //id given as parameter, attempt to fetch corresponding offer from api
      this.props.selectOffer(selectedUUID);
    }
    //Upon navigating through forward/back, select/deselect according to new url
    window.onpopstate = () => {
      let selectedUUID = getIdFromUrl(history, SITEPREFIX, this.props.renderType);
      if (selectedUUID !== '') {
        this.props.selectOffer(selectedUUID);
        scrollToSidebarItem(selectedUUID);
      } else {
        this.props.deselectOffer();
      }
    };
  }
  render() {
    let dayViewItems = this.createDayViewItems(this.props.dataSegments);
    return (
      <aside
        className={'sidebar view-' + this.props.renderType + (this.props.selectedUUID === '' ? '' : ' hide-print')}
      >
        <div className={'list'}>
          <div className="dd-tabs sticky hide-print">
            <div className={'tabs-navigation'}>
              <Link className={'tab'} to={SITEPREFIX + '/search'}>
                <Translate id="status.new" />
              </Link>
              <Link
                className={'tab' + (this.props.renderType === 'planning' ? ' is-active' : '')}
                to={SITEPREFIX + '/planning'}
              >
                <Translate id="planning.title" />
              </Link>
              <Link
                className={'tab' + (this.props.renderType === 'delivered' ? ' is-active' : '')}
                to={SITEPREFIX + '/delivered'}
              >
                <Translate id="delivered.title" />
              </Link>
            </div>
          </div>
          <div>
            <Col xs="12" extraClasses="filter-toggle-wrap hide-print">
              <Button buttonStyle={'primary'} onClick={() => this.props.handleToggleFilters()}>
                <Translate id={'search.list.open_filters'} /> <IconFilters />
              </Button>
            </Col>
          </div>
          <div>
            {dayViewItems}
            <Col xs="12" extraClasses={'text-center'}>
              {this.props.isFetching && (
                <div>
                  <IconSmile spinning={true} /> <Translate id="planning.list.loading" />
                </div>
              )}
              {this.props.offers && this.props.offers.length > 0 && !this.props.isFetching && (
                <Translate id="planning.list.done_loading" />
              )}
              {this.props.offers && this.props.offers.length === 0 && !this.props.isFetching && (
                <Translate id="planning.list.zero_results" />
              )}
            </Col>
          </div>
          {this.props.renderType === 'planning' && <VisiblePlanningFilters resultCount={this.props.offers.length} />}
          {this.props.renderType === 'delivered' && <VisibleDeliveredFilters />}
        </div>
      </aside>
    );
  }
  createDayViewItems(dataSegments) {
    const dayViewItems = [];
    const dayList = Object.keys(dataSegments).sort();
    dayList.forEach(day => {
      // check if we have days to process
      if (dataSegments[day].length === 0) {
        return null;
      }
      // start process
      const date = moment(day);
      let dayName = appUtils.capsFirstLetter(moment(date).format('dddd DD MMMM'));
      if (date.isSame(moment(), 'days')) {
        dayName = <Translate id={'datetime.today'} />;
      }
      const data = dataSegments[day] || [];
      dayViewItems.push(
        <section key={date} className={'list-section'}>
          <div className={'list-section-head'}>
            {dayName} {this.createDayIcons(data)}
          </div>
          {data.map((item, index) => {
            if (_get(item, 'transport_job_details', false)) {
              const props = {
                key: index,
                item: item,
                onItemClick: this.props.onOfferClick,
                isSelected: this.props.selectedUUID === item['@id'].split('transport_job_account_links/')[1],
                date: date,
                renderType: 'delivered',
              };
              return !item.transport_job_bundled ? <DeliveryItem {...props} /> : <DeliveryBundledItem {...props} />;
            } else {
              return <div key={index} />;
            }
          })}
        </section>
      );
    });
    return dayViewItems;
  }
  createDayIcons(data) {
    const iconsPerDay = [];
    // loop trough all jobs of that day
    data.map(transportJob => {
      const iconsPerJob = getSpecialIconsArray(transportJob);
      return (
        // loop trough icons of the job
        iconsPerJob.map(icon => {
          if (iconsPerDay.includes(icon)) {
            return false;
          }
          return iconsPerDay.push(icon);
        })
      );
    });
    return iconsPerDay.map((icon, index) => {
      return <RenderRequestSpecIcon key={index} icondata={icon} />;
    });
  }
}

export default DeliveredList;
