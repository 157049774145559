import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import moment from 'moment';

class DateTime extends Component {
  render() {
    return (
      <>
        {this.props.dateTimePeriods.length === 0 && <Translate id={'transport_request.propose'} />}
        {this.props.dateTimePeriods.map((item, index) => {
          return (
            <div key={index}>
              <p>{moment(item.start).format('dddd DD-MM-YY HH:mm') + '-' + moment(item.end).format('HH:mm')}</p>
            </div>
          );
        })}
      </>
    );
  }
}

export default DateTime;
