import * as moment from 'moment';
import _get from 'lodash/get';
import { PROGRESS_LEVELS, TJ_STATUS, TJAL_STATUS } from '../globals';
import { userIsRestricted } from '../actions/search';

const defaultState = {
  filters: {
    size: { bag: false, car: false, van: false },
    countryPickup: { nl: false, be: false, other: false },
    regionPickup: {
      nl: {
        Drenthe: false,
        Flevoland: false,
        Friesland: false,
        Gelderland: false,
        Groningen: false,
        Limburg: false,
        'Noord-Brabant': false,
        'Noord-Holland': false,
        Overijssel: false,
        Utrecht: false,
        Zeeland: false,
        'Zuid-Holland': false,
      },
    },
    countryDelivery: { nl: false, be: false, other: false },
    regionDelivery: {
      nl: {
        Drenthe: false,
        Flevoland: false,
        Friesland: false,
        Gelderland: false,
        Groningen: false,
        Limburg: false,
        'Noord-Brabant': false,
        'Noord-Holland': false,
        Overijssel: false,
        Utrecht: false,
        Zeeland: false,
        'Zuid-Holland': false,
      },
    },
    special: { twoPeople: false, veryHeavy: false, largeObjects: false, elevator: false },
    pickupSituation: { home: false, auction: false, store: false, other: false },
    date: '',
    responded: false,
    bundled: false,
    prepaid: false,
  },
  citySortedItems: {
    routes: [],
  },
  sort: 'date',
  commonRoutes: [
    ['Amsterdam', 'Rotterdam'],
    ['Amersfoort', 'Almere'],
    ['Eindhoven', 'Nijmegen'],
    ['Groningen', 'Assen'],
    ['Amstelveen', 'Weesp'],
    ['Zwolle', 'Delft'],
    ['Leiden', 'The Hague'],
    ['Utrecht', 'Woerden'],
  ],
  filtersOpen: false,
  isFetching: false,
  transportJobs: [],
  errors: [],
  selectedTransportJobId: '',
};

export const types = {
  TOGGLE_SEARCH_FILTERS: 'TOGGLE_SEARCH_FILTERS',
  SET_ROUTING_DATA: 'SET_ROUTING_DATA',
  SET_CITY_FILTER: 'SET_CITY_FILTER',
  CLEAR_SEARCH_ERRORS: 'CLEAR_SEARCH_ERRORS',
  UPDATE_TRANSPORT_JOB: 'UPDATE_TRANSPORT_JOB',
  POST_NEW_BUNDLED_OFFER_SUCCESS: 'POST_NEW_BUNDLED_OFFER_SUCCESS',
  POST_NEW_BUNDLED_OFFER_START: 'POST_NEW_BUNDLED_OFFER_START',
  SET_BUNDLED_STOP_DETAILS: 'SET_BUNDLED_STOP_DETAILS',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER',
  SET_SEARCH_SORTING: 'SET_SEARCH_SORTING',
  SELECT_TRANSPORT_JOB: 'SELECT_TRANSPORT_JOB',
  DESELECT_TRANSPORT_JOB: 'DESELECT_TRANSPORT_JOB',
  FETCH_TRANSPORT_JOBS_START: 'FETCH_TRANSPORT_JOBS_START',
  FETCH_TRANSPORT_JOBS_SUCCESS: 'FETCH_TRANSPORT_JOBS_SUCCESS',
  FETCH_TRANSPORT_JOBS_ERROR: 'FETCH_TRANSPORT_JOBS_ERROR',
  POST_NEW_OFFER_START: 'POST_NEW_OFFER_START',
  POST_NEW_OFFER_SUCCESS: 'POST_NEW_OFFER_SUCCESS',
  POST_NEW_OFFER_ERROR: 'POST_NEW_OFFER_ERROR',
  TOGGLE_FETCHING: 'TOGGLE_FETCHING',
};

//Selectors
export const getSelectedTransportJobId = state => state.search.selectedTransportJobId;
export const getFilters = state => state.search.filters;
export const getSelectedTransportJob = state => getTransportJobById(state, getSelectedTransportJobId(state));

// return selected transport job or select by job id
export const getTransportJob = (state, jobId = null) => {
  return jobId ? getTransportJobById(state, jobId) : getSelectedTransportJob(state);
};

export const getCurrentTransportJobs = state => state.search.transportJobs;

export const getTransportJobById = (state, id) => {
  return state.search.transportJobs.find(item => item['@id'].split('transport_jobs/')[1] === id);
};

export const isAllowedToMakeOffer = (state, jobId) => {
  const job = getTransportJob(state, jobId);
  const userRestrictions = userIsRestricted(state, job);
  const status = getCurrentStatus(state, jobId);
  const allowedStatuses = [TJAL_STATUS.REVOKED_BY_DRIVER, TJAL_STATUS.REVOKED_BY_CUSTOMER_CARE, TJ_STATUS.CANCELLED];
  return (
    (!isClaimedByOtherDriver(state, jobId) && !hasOwnOffer(state, jobId) && userRestrictions.length === 0) ||
    allowedStatuses.indexOf(status) > -1
  );
};

export const getRouteDistance = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const isBundled = _get(job, 'bundled', false);
  const stops = _get(job, 'stops', []);
  return _get(job, isBundled ? `stops[${stops.length - 1}].distance_km` : 'route.distance', 0);
};

export const getCurrentStatus = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const status = _get(job, 'state', TJ_STATUS.SUBMITTED);
  return _get(job, 'own_transport_job_account_link.state', status);
};

export const isValidTransport = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  if (!job) {
    return false;
  }
  if (job.bundled) {
    return _get(job, 'stops[0]', false) && job.stops.length > 0;
  } else {
    return _get(job, 'item_sets[0]', false) && job.item_sets[0].items.length > 0;
  }
};

export const getItemImages = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const images = [];
  _get(job, 'item_sets', []).map(itemSet => {
    itemSet.items.map(item => item.job_image && images.push(item.job_image));
    return images;
  });
  return images;
};

export const isProgressLevelRequired = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'minimum_progress_level_required', PROGRESS_LEVELS.bronze);
};

export const isBundled = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'bundled', false);
};

export const isPaid = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'eligible_for_delivery', false);
};

export const isClaimedByOtherDriver = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const status = _get(job, 'state', '');
  return (
    window.location.href.indexOf('search') > -1 && status !== TJ_STATUS.PUBLISHED && status !== TJ_STATUS.DRIVER_FOUND
  );
};

export const getItemCardElement = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return hasOwnOffer(state, jobId) ? job.own_transport_job_account_link['@id'] : _get(job, '@id', '');
};

// check is there any offer from drivers for selected job
export const hasOwnOffer = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const status = getCurrentStatus(state, jobId);
  if (
    !job ||
    status === TJAL_STATUS.REVOKED_BY_DRIVER ||
    status === TJAL_STATUS.REVOKED_BY_CUSTOMER_CARE ||
    status === TJAL_STATUS.TRANSPORT_JOB_CANCELLED
  ) {
    return false;
  }
  return job.hasOwnProperty('own_transport_job_account_link');
};

export const getBundledStops = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'stops', []);
};

const search = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_SEARCH_FILTERS:
      return {
        ...state,
        filtersOpen: !state.filtersOpen,
      };
    case types.POST_NEW_OFFER_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.TOGGLE_FETCHING:
      return {
        ...state,
        isFetching: action.flag,
      };
    case types.POST_NEW_OFFER_SUCCESS:
    case types.POST_NEW_OFFER_ERROR:
    case types.POST_NEW_BUNDLED_OFFER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case types.SET_SEARCH_FILTER:
      let filters = {
        ...state.filters,
      };
      filters = setSearchFilter(action, filters);
      return {
        ...state,
        filters,
      };
    case types.SET_CITY_FILTER:
      let citySortedItems = {
        ...state.citySortedItems,
      };
      citySortedItems = setCityFilter(action, citySortedItems);
      return {
        ...state,
        citySortedItems,
      };
    case types.UPDATE_TRANSPORT_JOB:
      const currentJobs = state.transportJobs;
      const selectedId = currentJobs.findIndex(job => job['@id'] === `/transport_jobs/${action.id}`);
      if (selectedId > -1) {
        currentJobs[selectedId] = action.data;
      } else {
        currentJobs.unshift(action.data);
      }
      return {
        ...state,
        transportJobs: currentJobs,
        isFetching: action.data.bundled,
      };
    case types.SET_BUNDLED_STOP_DETAILS:
      const jobs = state.transportJobs;
      const uuid = `/transport_jobs/${action.payload.transportJobId}`;
      const selectedIdx = state.transportJobs.findIndex(job => job['@id'] === uuid);
      jobs[selectedIdx]['stops'] = jobs[selectedIdx]['stops'] || [];
      jobs[selectedIdx]['stops'].push(action.payload.stop);
      return {
        ...state,
        isFetching: false,
        transportJobs: jobs,
      };
    case types.SET_SEARCH_SORTING:
      let transportJobs = state.transportJobs;
      transportJobs = transportJobs.sort(sortAsSelected(action.sortType));
      return {
        ...state,
        sort: action.sortType,
        transportJobs: transportJobs,
      };
    case types.SELECT_TRANSPORT_JOB:
      if (state.selectedTransportJobId !== action.id) {
        return {
          ...state,
          errors: [],
          isFetching: true,
          selectedTransportJobId: action.id,
        };
      } else {
        return state;
      }
    case types.DESELECT_TRANSPORT_JOB:
      return {
        ...state,
        errors: [],
        selectedTransportJobId: '',
      };
    case types.FETCH_TRANSPORT_JOBS_START:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case types.CLEAR_SEARCH_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case types.FETCH_TRANSPORT_JOBS_SUCCESS:
      return {
        ...state,
        transportJobs: action.payload.sort(sortAsSelected(state.sort)),
        isFetching: state.selectedTransportJobId !== '',
      };
    case types.FETCH_TRANSPORT_JOBS_ERROR:
      return {
        ...state,
        errors: [action.payload],
      };
    default:
      return state;
  }
};

const setCityFilter = (action, filters) => {
  filters.routes = filters.routes || [];
  const index = [filters.routes.indexOf(action.data.filter[0]), filters.routes.indexOf(action.data.filter[1])];

  if (index[0] > -1 && index[1] > -1) {
    filters.routes = filters.routes.filter(
      route => route !== `${action.data.filter[0]}` && route !== `${action.data.filter[1]}`
    );
    return filters;
  } else {
    filters.routes.push(`${action.data.filter[0]}`);
    filters.routes.push(`${action.data.filter[1]}`);
    return filters;
  }
};

const setSearchFilter = (action, filters) => {
  if (!action.filter.hasOwnProperty('target')) {
    filters.date = action.filter;
    return filters;
  }
  const setFilter = action.filter.target.name.split('_');
  switch (setFilter[0]) {
    case 'responded':
      filters.responded = !filters.responded;
      break;
    case 'bundled':
      filters.bundled = !filters.bundled;
      break;
    case 'prepaid':
      filters.prepaid = !filters.prepaid;
      break;
    case 'date':
      filters.date = setFilter[1];
      break;
    case 'region':
      if (setFilter[1] === 'pickup') {
        filters['regionPickup'][setFilter[2]][setFilter[3]] = !filters['regionPickup'][setFilter[2]][setFilter[3]];
      } else {
        filters['regionDelivery'][setFilter[2]][setFilter[3]] = !filters['regionDelivery'][setFilter[2]][setFilter[3]];
      }
      break;
    case 'country':
      if (setFilter[1] === 'pickup') {
        filters['countryPickup'][setFilter[2]] = !filters['countryPickup'][setFilter[2]];
      } else {
        filters['countryDelivery'][setFilter[2]] = !filters['countryDelivery'][setFilter[2]];
      }
      break;
    default:
      filters[setFilter[0]][setFilter[1]] = !filters[setFilter[0]][setFilter[1]];
  }
  return filters;
};

const sortAsSelected = sort => {
  switch (sort) {
    case 'price':
      return sortTransportJobsByPrice;
    case 'newest':
      return sortTransportJobsByCreationDate;
    case 'date':
    default:
      return sortTransportJobsByDate;
  }
};

const determineDateSortValue = item => {
  let sortValue;

  if (!item.first_pickup || !item.first_delivery) {
    return 'open options';
  }

  if (item.first_pickup === 'open options') {
    if (item.first_delivery === 'open options') {
      sortValue = 'open options';
    } else {
      sortValue = item.first_delivery.start;
    }
  } else {
    if (item.first_delivery === 'open options') {
      sortValue = item.first_pickup.start;
    } else {
      sortValue = moment.min([item.first_pickup.start, item.first_delivery.start]);
    }
  }
  return sortValue;
};

const sortTransportJobsByDate = (left, right) => {
  let sortLeftValue = determineDateSortValue(left);
  let sortRightValue = determineDateSortValue(right);
  if (sortLeftValue === 'open options') {
    return sortRightValue === 'open options' ? 0 : 1;
  } else {
    return sortRightValue === 'open options' ? -1 : sortLeftValue.diff(sortRightValue);
  }
};

const sortTransportJobsByCreationDate = (left, right) => {
  return right.created_at.diff(left.created_at);
};

const sortTransportJobsByPrice = (left, right) => {
  return left.reward.amount - right.reward.amount;
};

export default search;
