import React from 'react';
import '../css/IconStyle.css';

const IconVideo = () => {
  return (
    <svg className="IconVideo" width="57" height="40.45" viewBox="0 0 57 40.45">
      <path d="M56,24.45a1,1,0,0,0-1,1V36.53a2.13,2.13,0,0,1-.94,1.7,1.72,1.72,0,0,1-1.57,0L44,34.36V13.64l8.49-3.92A1.77,1.77,0,0,1,55,11.33V22.11a1,1,0,0,0,2,0V11.45a3.89,3.89,0,0,0-1-2.73,3.77,3.77,0,0,0-4.31-.82L44,11.44v-.13A3.31,3.31,0,0,0,40.69,8H36V3a3,3,0,0,0-3-3H1A1,1,0,0,0,1,2H33a1,1,0,0,1,1,1V8H3.31A3.31,3.31,0,0,0,0,11.31V21.78a1,1,0,0,0,2,0V11.31A1.31,1.31,0,0,1,3.31,10H40.69A1.31,1.31,0,0,1,42,11.31V36.69A1.31,1.31,0,0,1,40.69,38H3.31A1.31,1.31,0,0,1,2,36.69V25.2a1,1,0,0,0-2,0v11.5A3.31,3.31,0,0,0,3.31,40H40.69A3.31,3.31,0,0,0,44,36.69v-.13l7.66,3.53a3.76,3.76,0,0,0,4.24-.75A3.93,3.93,0,0,0,57,36.53V25.45A1,1,0,0,0,56,24.45Z" />
    </svg>
  );
};

export default IconVideo;
