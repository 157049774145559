import { fetchSingleOfferStart } from '../actions/planning';
import { put, all, takeEvery } from 'redux-saga/effects';
import { types as inboxTypes } from '../reducers/inbox';
import { fetchMessagesStart } from '../actions/conversations';

export function* inboxSagas() {
  yield takeEvery(inboxTypes.FETCH_INBOX_START, fetchSingleInboxSaga);
}

export function* fetchSingleInboxSaga({ offerId }) {
  yield all([put(fetchMessagesStart(offerId)), put(fetchSingleOfferStart(offerId))]);
}
