import React, { Component } from 'react';
import Heading from '../Heading';
import { Button } from '../Button';
import { connect } from 'react-redux';
import { fetchListMessagesStart, toggleCommentSubmit, selectConversation } from '../actions/conversations';
import FlashMessage from '../FlashMessage';
import { getTranslate, Translate } from 'react-localize-redux';
import http from '../UtilsHttp';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageForms: [],
      errors: [],
    };
  }

  render() {
    const uuid = this.props.uuid;
    const message = this.state.messageForms[uuid] ? this.state.messageForms[uuid] : '';
    return (
      <form className="comment-form">
        <div className={'input-row'}>
          <div className={'input-element type-textarea'}>
            <label htmlFor="simple-message">
              <Heading size="h4">
                <span className={'required'}>
                  <Translate id={'communication.message'} />
                </span>
              </Heading>
            </label>
            <textarea
              id={'simple-message'}
              value={message}
              placeholder={this.props.translate('placeholders.type_message')}
              onChange={this.handleChange.bind(this)}
              disabled={this.props.isSubmitting}
            />
          </div>
        </div>
        {this.state.errors.length > 0 &&
          this.state.errors.map((errorMessage, index) => {
            return <FlashMessage key={index} type={'error'} message={errorMessage} />;
          })}
        <div className={'form-actions'}>
          <Button
            type="button"
            buttonStyle="primary"
            onClick={() =>
              this.props.dispatch(
                this.handleSubmit(
                  message,
                  uuid,
                  this.props.updateInbox,
                  this.props.itemSets,
                  this.props.account,
                  this.props.job
                ).bind(this)
              )
            }
            loading={this.props.isSubmitting}
            disabled={message.length < 1 || this.props.isSubmitting}
          >
            <Translate id={'form.send'} />
          </Button>
        </div>
      </form>
    );
  }

  handleSubmit(message, uuid, updateInbox = false, itemSets, account, job) {
    return function(dispatch) {
      dispatch(toggleCommentSubmit());
      return postMessage(message, uuid, itemSets, account).then(
        response => {
          const messageForms = this.state.messageForms;
          messageForms[uuid] = '';
          this.setState({
            messageForms: messageForms,
            errors: '',
          });
          dispatch(selectConversation(uuid));
          dispatch(fetchListMessagesStart());
        },
        error => {
          let errors = this.state.errors;
          errors[uuid] = error;
          this.setState({
            errors: errors,
          });
        }
      );
    };
  }

  handleChange(event) {
    let messageForms = this.state.messageForms;
    messageForms[this.props.uuid] = event.target.value;
    this.setState({
      messageForms: messageForms,
    });
  }
}

function postMessage(message, uuid, itemSets, account) {
  return new Promise((resolve, conflict) => {
    http()
      .post(
        '/driver_messages',
        {
          author: account,
          transport_job_account_link: uuid,
          item_set: itemSets,
          body: message,
          client_ip_address: '1.1.1.1',
        },
        { withCredentials: true }
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => conflict(error));
  });
}

const mapStateToProps = (state, ownprops) => ({
  uuid: ownprops.uuid,
  itemSets: ownprops.itemSets,
  account: ownprops.account,
  isSubmitting: state.conversations.isSubmitting,
  translate: getTranslate(state.localize),
  job: ownprops.job,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
