import React, { Component } from 'react';
import IconSmile from '../../icons/IconSmile';
import { Translate } from 'react-localize-redux';

class OnLoad extends Component {
  render() {
    return (
      <>
        <div style={{ textAlign: 'center' }} className={'col-12'}>
          {this.props.isFetching ? (
            <div>
              <IconSmile spinning={true} />
            </div>
          ) : (
            <div>
              <h3>
                <Translate id={'planning.job_not_available_title'} />
              </h3>
              <p>
                <Translate id={'planning.job_not_available_caption'} />
              </p>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default OnLoad;
