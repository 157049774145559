import { types as modalActionTypes } from '../reducers/modalReducer';

/**
 *
 * @param modalProps
 * @param modalType
 * @returns {{type: string, modalProps: *, modalType: *}}
 */
export const showModal = ({ modalProps, modalType }) => ({
  type: modalActionTypes.SHOW_MODAL,
  modalProps,
  modalType,
});

export const hideModal = () => ({
  type: modalActionTypes.HIDE_MODAL,
});
