import { connect } from 'react-redux';
import Filters from '../../components/search/Filters';
import { setSearchFilter, toggleSearchFilters } from '../../actions/search';

const mapStateToProps = (state, ownProps) => ({
  search: state.search,
  open: state.search.filtersOpen,
  resultCount: ownProps.resultCount,
});

const mapDispatchToProps = dispatch => ({
  handleToggleFilters: () => dispatch(toggleSearchFilters()),
  handleSearchFilters: filterEvent => dispatch(setSearchFilter(filterEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
