// general actions

export const types = {
  SET_NETWORK_STATUS: 'SET_NETWORK_STATUS',
};

export const getNetworkStatus = state => {
  return state.user.network;
};

export const networkStatus = status => ({
  type: types.SET_NETWORK_STATUS,
  payload: status,
});
