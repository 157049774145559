import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SectionWrapper } from '../../components/layout/SectionWrapper';
import { getUserDetails } from '../../reducers/user';
import { actions, getVehicles } from '../../reducers/vehicles';
import { setDocumentTitle } from '../../Utils';
import { translate } from '../../UtilsTranslation';
import VehicleCard from '../../VehicleCard';
import CircleBus from '../../icons/CircleBus';
import { Translate } from 'react-localize-redux';
import History from '../../History';
import { Button } from '../../Button';
import { ADD_VEHICLE_ROUTE } from '../profile/sections/Vehicles';

class VehiclesList extends Component {
  componentWillMount() {
    this.props.fetchVehicles();
  }
  componentDidMount() {
    setDocumentTitle(translate('menu.dashboard'));
  }
  render() {
    return (
      <div>
        <SectionWrapper centerContent={true}>
          {!this.props.isFetching &&
            this.props.total > 0 &&
            this.props.vehicles.map((vehicle, i) => {
              const vehicleProps = {
                ...vehicle,
                remove: this.props.removeVehicle,
              };
              return <VehicleCard key={i} {...vehicleProps} />;
            })}
          {!this.props.isFetching && this.props.total === 0 && (
            <div className={'text--center'}>
              <CircleBus />
              <figure>
                <Translate id={'profile_page.vehicles.no_vehicle'} />
              </figure>
            </div>
          )}
          <div className={'text--center'}>
            <Button
              onClick={() => History.push(ADD_VEHICLE_ROUTE)}
              extraClasses={'btn-add-vehicle'}
              buttonStyle={'secondary'}
            >
              <Translate id={'profile_page.vehicles.add_vehicle'} />
            </Button>
          </div>
        </SectionWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: getUserDetails(state),
  vehicles: getVehicles(state),
  isFetching: state.vehicles.isFetching,
  total: state.vehicles.total,
});

const mapDispatchToProps = dispatch => ({
  fetchVehicles: () => dispatch(actions.fetchVehicles()),
  removeVehicle: id => dispatch(actions.removeVehicle(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesList);
