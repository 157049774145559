import { provincesList } from './globals';
import { Translate } from 'react-localize-redux';
import React from 'react';

const getProvinceAbbr = administrativeArea => {
  const target = provincesList.filter(province => province.name === administrativeArea);
  return target.length > 0 ? target[0].abbreviation : administrativeArea;
};

const getAddressLines = stopType => {
  if (typeof stopType.address_line1 === 'undefined') {
    return [<Translate id="transport_request.address_hidden" />];
  }
  const address1 = stopType.address_line1 || '';
  const address2 = stopType.address_line2 || '';
  const postalCode = stopType.postal_code || '';
  const locality = stopType.locality || '';
  return [address1 + ' ' + address2, postalCode + ' ' + locality];
};

export { getProvinceAbbr, getAddressLines };
