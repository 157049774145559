import 'react-app-polyfill/ie11'; // For IE 11 support
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { logger } from './Utils';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './reducers';
import { rootSaga } from './sagas';
import 'moment/locale/nl';
import App from './App';
import { LocalizeProvider } from 'react-localize-redux';

const sagaMiddleware = createSagaMiddleware();

//only composes with DevTools if extension is enabled
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, sagaMiddleware)));

sagaMiddleware.run(rootSaga);

let appContainer = document.querySelector('#root');
const rootContainerIsPresent = appContainer !== null;

const container = document.createElement('DIV');
container.id = 'react-menu-container';
document.querySelector('body').appendChild(container);

window.logger = logger;

// IIFE to load Sentry if possible
(function() {
  if (!rootContainerIsPresent) {
    return;
  }
  if (window.location.hostname === 'localhost') {
    return;
  }
  const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
  if (!sentryDsn) return;

  let script = document.createElement('script');
  script.setAttribute('src', 'https://browser.sentry-cdn.com/5.11.2/bundle.min.js');
  script.addEventListener('load', function() {
    // @ts-ignore
    window.Sentry.init({
      dsn: sentryDsn,
      ignoreErrors: [
        'hj.hq is not a function',
        'Could not load "onion".',
        'jQuery is not defined',
        'parentRef.postMessage is not a function',
        /hj.hq/,
        /fbq/,
        'QuotaExceededError: QuotaExceededError',
        "Argument 1 ('element') to Window.getComputedStyle must be an instance of Element",
        'QuotaExceededError: The quota has been exceeded.',
        /initFreshChat/,
        'geannuleerd',
        'Abgebrochen',
        'cancelled',
      ],
      blacklistUrls: [
        /https:\/\/maps.googleapis.com\/maps\/api\/js(\?{1}.*)/,
        /https:\/\/wchat.freshchat.com\/js\/widget.js/,
        /https:\/\/cdn.jsdelivr.net\/npm\/jquery-validation@1.17.0\/dist\/additional-methods.min.js/,
        /https:\/\/www.brenger.nl\/nl\/hoe-het-werkt/,
        /www.brenger.nl\/sites\/default\//,
        /script.hotjar.com/,
        /maps.googleapis.com\/maps-api-v3\//,
        /\/themes\/brenger_rebranding_theme\//,
      ],
      environment: process.env.REACT_APP_ENV,
      maxValueLength: 1000,
    });
  });
  document.body.appendChild(script);
})();

if (rootContainerIsPresent) {
  ReactDOM.render(
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <App />
      </LocalizeProvider>
    </Provider>,
    appContainer
  );
}

registerServiceWorker();
