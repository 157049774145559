import React from 'react';
import '../css/IconStyle.css';

const IconMarker = () => {
  return (
    <svg className={'IconMarker'} width="16px" height="20px" viewBox="0 0 16 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-472.000000, -698.000000)" fill="#0073FA">
          <g transform="translate(140.000000, 625.000000)">
            <g transform="translate(30.000000, 20.000000)">
              <g transform="translate(282.000000, 38.000000)">
                <path
                  d="M24.335181,22.9288603 C24.335181,20.8148118 25.9834419,19.1011383 28.0164378,19.1011383 C30.0494337,19.1011383 31.6976946,20.8148118 31.6976946,22.9288603 C31.6976946,25.0429088 30.0494337,26.7565823 28.0164378,26.7565823 C25.9834419,26.7565823 24.335181,25.0429088 24.335181,22.9288603 Z M28.0132437,15 C23.806093,15 20.3089609,18.4996652 20.0197399,22.9835007 C19.9408146,24.1319138 20.0985622,25.2528994 20.4929825,26.3738851 C20.8612319,27.4401232 21.492222,28.4517209 22.3073368,29.381358 L27.7239196,34.8631847 C27.8028449,34.9452524 27.9079412,35 28.0132437,35 C28.11834,35 28.2498135,34.9452524 28.3023616,34.8631847 C28.3549098,34.7811169 33.6926703,29.381358 33.6926703,29.381358 C34.5341623,28.4517209 35.1388783,27.4401232 35.5070246,26.3738851 C35.901342,25.2528994 36.0591925,24.1319138 35.9802672,22.9835007 C35.7173203,18.4996652 32.2202912,15 28.0132437,15 Z"
                  id="icon-naar"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconMarker;
