import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { missingImageSrc, TJ_STATUS, TJAL_STATUS } from './globals';
import './css/SingleViewInner.css';
import { Col, Row } from './Grid.js';
import IconCross from './icons/IconCross';
import _get from 'lodash/get';
import RouteMap from './components/transport/RouteMap';
import JobActions from './components/transport/JobActions';
import StopCard from './components/transport/StopCard';
import JobOverview from './components/transport/JobOverview';
import StopOverview from './components/bundled/StopOverview';
import Signature from './components/transport/Signature';
import Driver from './components/transport/Driver';
import Offer from './components/transport/Offer';
import OnLoad from './components/transport/OnLoad';
import UserRestrictionMessageList from './components/search/UserRestrictionMessageList';
import StopDetails from './components/transport/StopDetails';
import { Translate } from 'react-localize-redux';
import IconMail from './icons/IconMail';
import history from './History';
import * as appUtils from './Utils';
import { Button } from './Button';

class SingleViewInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stopDetails: {
        visible: false, // selected stop visibility
        type: null, // selected stop type
        stop: null, // selected stop details
      },
    };
    this.toggleStopDetails = this.toggleStopDetails.bind(this);
    this.getDateTimePeriods = this.getDateTimePeriods.bind(this);
  }
  componentDidUpdate(prevProps) {
    // reset all open modals after select another job
    if (this.props.single['@id'] !== prevProps.single['@id']) {
      this.reset();
    }
  }
  render() {
    const hasDetails = this.props.single.hasOwnProperty('transport_job_details');
    const transport = hasDetails ? this.props.single.transport_job_details : this.props.single;
    const status = this.props.status;
    const shortId = _get(transport, 'short_id', '');
    const stops = _get(transport, 'stops', []);
    const reward = this.getReward(transport);
    let renderOfferForm = !this.props.isOffer;
    if (transport.bundled && stops.length === 0 && !this.props.isOffer) {
      renderOfferForm = false;
    }
    const isPlanning = window.location.href.indexOf('/planning') > -1;
    const invoice = _get(this.props.single, 'payout_invoice_url', null);
    return (
      <>
        <div
          className={
            'single-view-inner status-' +
            status +
            (this.props.hasOffer ? ' has-offers' : '') +
            (this.props.bundled ? ' is-bundled' : '') +
            (this.state.stopDetails.visible ? ' details-active' : '')
          }
        >
          {this.props.isAvailable ? (
            <>
              {/* mobile view header */}
              <div className="col-12 single-view-header-mobile hide-print">
                <h1 className={'reward'}>&euro; {reward}</h1>
                <h1 className={'short-id'}>#{shortId}</h1>
                <Link
                  className="go-to-dashboard"
                  to="#"
                  onClick={() => this.closeSingleView(this.props.itemCardElement)}
                >
                  <IconCross />
                </Link>
              </div>
              <div className={'col-12 card'}>
                {/* Make an offer form */}
                {renderOfferForm && (
                  <Offer
                    stops={transport.stops}
                    isAllowedToMakeOffer={this.props.isAllowedToMakeOffer}
                    status={this.props.status}
                    isBundled={this.props.bundled}
                    isPaid={this.props.isPaid}
                    closeModal={this.closeSingleView.bind(this)}
                  />
                )}
                {/* stop details modal */}
                {this.state.stopDetails.visible && (
                  <div className={'stop-details-wrapper'}>
                    <StopDetails
                      errors={this.props.errors}
                      isSubmitting={this.props.isSubmitting || false}
                      showModal={this.props.showModal}
                      getDateTimePeriods={this.getDateTimePeriods}
                      confirmDeliveryPerStop={this.props.confirmDeliveryPerStop}
                      transport={transport}
                      toggleStopDetails={this.toggleStopDetails}
                      type={this.state.stopDetails.type}
                      stop={this.state.stopDetails.stop}
                      {...this.props}
                    />
                  </div>
                )}

                {/* transport job header */}
                <RouteMap transport={transport} distance={this.props.distance} />

                {/* transport job body */}
                <div className="card-body">
                  <Row>
                    <Col xs="12" sm="12" xl="12" extraClasses={'request-main-info'}>
                      {/* regular transport job body content ===================== */}
                      {!this.props.bundled && (
                        <>
                          {/* job brief information (items) */}
                          <JobOverview
                            isOffer={this.props.isOffer}
                            status={status}
                            transport={transport}
                            reward={reward}
                            shortId={shortId}
                          />
                          {/* job stops brief information */}
                          <StopCard
                            type={'pickup'}
                            getDateTimePeriods={this.getDateTimePeriods}
                            transport={transport}
                            status={status}
                            isOffer={this.props.isOffer}
                            single={this.props.single}
                            toggleStopDetails={this.toggleStopDetails}
                          />
                          <StopCard
                            type={'delivery'}
                            getDateTimePeriods={this.getDateTimePeriods}
                            transport={transport}
                            status={status}
                            isOffer={this.props.isOffer}
                            single={this.props.single}
                            toggleStopDetails={this.toggleStopDetails}
                          />
                        </>
                      )}

                      {/* bundled transport job body content ===================== */}
                      {this.props.bundled && this.props.stops.length > 0 && (
                        <StopOverview
                          isBundledDelivered={this.props.isBundledDelivered}
                          toggleStopDetails={this.toggleStopDetails}
                          isOffer={this.props.isOffer}
                          shortId={shortId}
                          transport={transport}
                          countries={this.props.countries}
                          stops={this.props.stops}
                        />
                      )}

                      {/* render all restriction messages for driver */}
                      <UserRestrictionMessageList
                        isClaimedByOtherDriver={this.props.isClaimedByOtherDriver}
                        driverLevel={this.props.user.progress.level}
                        progressLevelRequired={this.props.progressLevelRequired}
                        restrictions={this.props.userIsRestricted}
                      />
                      <div className={'mt-1'}>
                        {/* print action */}
                        {status === TJAL_STATUS.ACCEPTED && (
                          <div className={'print-button-wrap'}>
                            <a className={'btn btn--primary'} href={'#top'} onClick={() => window.print()}>
                              <Translate id={'actions.print_page'} />
                            </a>
                          </div>
                        )}

                        <div className={'messages-link'}>
                          {/* link to job conversation on planning mode */}
                          {isPlanning && this.props.itemCardElement && !this.props.bundled && (
                            <div
                              className={'btn btn--icon-left btn--primary'}
                              onClick={() => this.showMessages(this.props.itemCardElement)}
                            >
                              <IconMail /> <Translate id={'bundled.essentials.message'} />
                            </div>
                          )}

                          {/* ALREADY CONFIRMED BY DRIVER MESSAGE */}
                          {!this.props.bundled &&
                            this.props.status === TJAL_STATUS.ACCEPTED &&
                            this.props.status === TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER && (
                              <fieldset className={'request-label--yellow'}>
                                <Translate id={'transport_request.confirmed_delivery_by_driver_message'} />
                              </fieldset>
                            )}
                        </div>
                        {/* Download invoice ============================= */}
                        {(this.props.status === TJAL_STATUS.DELIVERED ||
                          this.props.status === TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER ||
                          this.props.status === TJ_STATUS.DELIVER_CONFIRMED_BY_CUSTOMER) &&
                          invoice && (
                            <Button href={invoice} target={'_blank'} extraClasses="mt-1">
                              <Translate id={'transport_request.download_invoice'} />
                            </Button>
                          )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* customer signature in print view */}
              {!this.props.bundled && <Signature transport={transport} status={status} />}

              {/* Driver information and messages */}
              <Driver
                status={status}
                isOffer={this.props.isOffer}
                isClaimedByOtherDriver={this.props.isClaimedByOtherDriver}
                customerName={_get(transport, 'pickups[0].contact.first_name', '')}
                customerImage={_get(transport, 'pickups[0].contact.profile_image', missingImageSrc)}
              />
            </>
          ) : (
            <OnLoad isFetching={this.props.isFetching} />
          )}
        </div>
        {/* render all possible actions for transport job */}
        {this.props.isAvailable && (
          <JobActions userIsRestricted={this.props.userIsRestricted} transport={transport} status={status} />
        )}
      </>
    );
  }
  getReward(transport) {
    return transport.hasOwnProperty('reward')
      ? appUtils.priceLayout(transport.total_payout ? transport.total_payout : transport.reward)
      : '0.00';
  }
  reset() {
    this.setState({
      stopDetails: {
        visible: false,
        type: null,
        stop: null,
      },
    });
    this.closeOfferModal();
    this.toggleStopDetails(false, null, null);
    const stickyBtn = document.querySelector('.request-action-wrapper--sticky');
    if (stickyBtn) {
      stickyBtn.classList.remove('hide');
    }
  }

  showMessages(iri) {
    history.push(appUtils.getPathForApp() + `/messages/${iri.split('transport_job_account_links/')[1]}`);
  }

  getDateTimePeriods(transport, type, stop = null) {
    if (stop) {
      // get bundled stop available date time
      return [stop.proposed_datetime_period];
    } else if (this.props.isOffer) {
      // get pickup and delivery commitment after claim
      return [this.props.single[`${type}_commitments`][0].committed_datetime_period];
    } else {
      // get available date time for customers
      return type === 'pickup'
        ? transport.pickups[0].available_datetime_periods
        : transport.deliveries[0].available_datetime_periods;
    }
  }
  toggleStopDetails(visible, stopType, stop = null) {
    const closeBtn = document.querySelector('.go-to-dashboard');
    const stickyBtn = document.querySelector('.request-action-wrapper--sticky');
    this.props.clearErrors();
    this.setState({
      stopDetails: {
        visible: visible,
        type: stopType,
        stop: stop,
      },
    });
    if (visible) {
      if (stickyBtn) {
        stickyBtn.classList.add('hide');
      }
      if (closeBtn) {
        closeBtn.style.display = 'none';
      }
      setTimeout(() => {
        const el = document.querySelector('.stop-details-view h3');
        if (el) {
          el.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
        }
      }, 250);
    } else {
      if (stickyBtn) {
        stickyBtn.classList.remove('hide');
      }
      if (closeBtn) {
        closeBtn.style.display = 'block';
      }
    }
  }
  closeOfferModal() {
    const offerSection = document.querySelector('.make-offer');
    if (offerSection) {
      offerSection.classList.add('hide');
    }
  }
  closeSingleView(uuid) {
    const offerSection = document.querySelector('.make-offer');
    const stopDetailsModal = document.querySelectorAll('.stop-details-wrapper');
    const stickyBtn = document.querySelector('.request-action-wrapper--sticky');

    if (stickyBtn) {
      stickyBtn.classList.remove('hide');
    }

    // close claim offer modal
    if (offerSection && !offerSection.classList.contains('hide')) {
      offerSection.classList.add('hide');
      return;
    }

    // close stop details modal
    for (const stop of stopDetailsModal) {
      if (stop && !stop.classList.contains('hide')) {
        stop.classList.add('hide');
        return;
      }
    }
    // close transport single view modal
    this.props.closeDetails();
    setTimeout(() => {
      const el = document.getElementById(uuid);
      if ('scrollBehavior' in document.documentElement.style) {
        if (el) {
          window.scroll(0, el.offsetTop);
          return;
        }
      } else {
        if (el) {
          document.body.scrollTop = el.offsetTop;
          document.documentElement.scrollTop = el.offsetTop;
          return;
        }
      }
    }, 100);
  }
}

export default SingleViewInner;
