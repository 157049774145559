import React, { Component } from 'react';
import './css/App.css';
import VisibleSidebar from './containers/planning/VisibleSidebar';
import VisibleDetails from './containers/planning/VisibleDetails';

class PlanningApp extends Component {
  render() {
    return (
      <div className={'main-with-sidebar'}>
        <VisibleSidebar />
        <VisibleDetails />
      </div>
    );
  }
}

export default PlanningApp;
