import React from 'react';
import '../css/IconStyle.css';

const IconExtraPerson = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 108.6 84">
      <g>
        <g>
          <path
            d="M106.6,83.8c-1.1,0-2-0.9-2-2c0-20.5-13.7-37.2-30.5-37.2c-3.9,0-7.8,0.9-11.4,2.7c-1,0.5-2.2,0.1-2.7-0.9
			c-0.5-1-0.1-2.2,0.9-2.7c4.2-2.1,8.6-3.1,13.2-3.1c19,0,34.5,18.5,34.5,41.2C108.6,82.9,107.7,83.8,106.6,83.8z"
          />
        </g>
        <g>
          <path
            d="M74.1,37.4c-6.2,0-12-3.1-15.5-8.2c-0.6-0.9-0.4-2.2,0.5-2.8c0.9-0.6,2.2-0.4,2.8,0.5c2.7,4.1,7.3,6.5,12.2,6.5
			c8.1,0,14.7-6.6,14.7-14.7S82.2,4,74.1,4c-5,0-9.6,2.5-12.3,6.7c-0.6,0.9-1.8,1.2-2.8,0.6c-0.9-0.6-1.2-1.8-0.6-2.8
			C62,3.2,67.8,0,74.1,0c10.3,0,18.7,8.4,18.7,18.7S84.4,37.4,74.1,37.4z"
          />
        </g>
        <g>
          <path
            d="M66.9,83.8c-1.1,0-2-0.9-2-2c0-20.5-13.7-37.2-30.5-37.2S4,61.3,4,81.8c0,1.1-0.9,2-2,2s-2-0.9-2-2
			c0-22.7,15.5-41.2,34.5-41.2s34.5,18.5,34.5,41.2C68.9,82.9,68,83.8,66.9,83.8z"
          />
        </g>
        <g>
          <path
            d="M34.5,37.4c-10.3,0-18.7-8.4-18.7-18.7S24.2,0,34.5,0s18.7,8.4,18.7,18.7S44.8,37.4,34.5,37.4z M34.5,4
			c-8.1,0-14.7,6.6-14.7,14.7s6.6,14.7,14.7,14.7s14.7-6.6,14.7-14.7S42.6,4,34.5,4z"
          />
        </g>
        <g>
          <path
            d="M2.1,84c-1.1,0-2-0.9-2-2C0.1,71.1,3.5,60.7,9.9,52.9c6.5-7.9,15.1-12.3,24.4-12.4c0.1,0,0.1,0,0.2,0
			c18.9,0,34.4,18.4,34.5,41c0,1.1-0.9,2-2,2c0,0,0,0,0,0c-1.1,0-2-0.9-2-2c-0.1-20.5-13.8-37-30.5-37c-0.1,0-0.1,0-0.2,0
			c-8.1,0-15.6,3.9-21.3,10.9C7.2,62.6,4.1,72,4.1,82C4.1,83.1,3.2,84,2.1,84C2.1,84,2.1,84,2.1,84z"
          />
        </g>
        <g>
          <path
            d="M34.5,37.4c-10.3,0-18.7-8.4-18.7-18.7S24.2,0,34.5,0s18.7,8.4,18.7,18.7S44.8,37.4,34.5,37.4z M34.5,4
			c-8.1,0-14.7,6.6-14.7,14.7s6.6,14.7,14.7,14.7s14.7-6.6,14.7-14.7S42.6,4,34.5,4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconExtraPerson;
