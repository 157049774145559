import React from 'react';
import '../css/IconStyle.css';

const IconPhone = () => {
  return (
    <svg className="IconPhone" viewBox="112 503 26.056 26.027" width="26.056" height="26.027">
      <clipPath id="_clipPath_iENMVgdLrYuSxVqyWp5UBI7fGTRqc9Ne">
        <path
          d=" M 117.68 503 C 117.16 503 116.65 503.19 116.22 503.53 L 116.15 503.56 L 116.12 503.59 L 113 506.81 L 113.03 506.84 C 112.06 507.73 111.77 509.07 112.18 510.22 C 112.19 510.23 112.18 510.24 112.18 510.25 C 113.03 512.68 115.2 517.36 119.43 521.59 C 123.68 525.84 128.43 527.93 130.78 528.84 L 130.81 528.84 C 132.02 529.25 133.34 528.96 134.28 528.16 L 137.43 525 C 138.26 524.17 138.26 522.73 137.43 521.91 L 133.37 517.84 L 133.34 517.78 C 132.51 516.95 131.04 516.95 130.22 517.78 L 128.22 519.78 C 127.49 519.43 125.77 518.54 124.12 516.97 C 122.49 515.41 121.65 513.61 121.34 512.91 L 123.34 510.91 C 124.18 510.07 124.2 508.67 123.31 507.84 L 123.34 507.81 L 123.25 507.72 L 119.25 503.59 L 119.22 503.56 L 119.15 503.53 C 118.72 503.19 118.21 503 117.68 503 Z  M 117.68 505 C 117.76 505 117.83 505.04 117.9 505.09 L 121.9 509.19 L 122 509.28 C 121.99 509.27 122.06 509.38 121.93 509.5 L 119.43 512 L 118.97 512.44 L 119.18 513.06 C 119.18 513.06 120.33 516.14 122.75 518.44 L 122.97 518.63 C 125.29 520.75 128.03 521.91 128.03 521.91 L 128.65 522.19 L 131.62 519.22 C 131.79 519.05 131.76 519.05 131.93 519.22 L 136.03 523.31 C 136.2 523.48 136.2 523.42 136.03 523.59 L 132.97 526.66 C 132.5 527.05 132.02 527.13 131.43 526.94 C 129.17 526.05 124.77 524.11 120.84 520.19 C 116.88 516.23 114.82 511.74 114.06 509.56 C 113.91 509.16 114.02 508.55 114.37 508.25 L 114.43 508.19 L 117.47 505.09 C 117.54 505.04 117.61 505 117.68 505 Z "
          className={'PhoneColor'}
        />
      </clipPath>
      <g clipPath="url(#_clipPath_iENMVgdLrYuSxVqyWp5UBI7fGTRqc9Ne)">
        <clipPath id="_clipPath_x0BmHRIt7NDHdGMHj01R6XhmiXYkXFWA">
          <path d=" M 0 1454 L 320 1454 L 320 0 L 0 0 L 0 1454 Z " className={'PhoneColor'} />
        </clipPath>
        <g clipPath="url(#_clipPath_x0BmHRIt7NDHdGMHj01R6XhmiXYkXFWA)">
          <path d=" M 107 498 L 143.06 498 L 143.06 534.03 L 107 534.03 L 107 498 Z " className={'PhoneColor'} />
        </g>
      </g>
    </svg>
  );
};

export default IconPhone;
