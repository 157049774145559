import React from 'react';
import '../css/IconStyle.css';

const IconTrash = () => {
  return (
    <div className={'IconTrash'}>
      <svg className={'IconTrash--lid'} width="12" height="5" viewBox="0 0 12 5">
        <path
          className="trashColor"
          d="M12,5H0V3.87A2,2,0,0,1,.2,2.81,1.46,1.46,0,0,1,.81,2.2,2,2,0,0,1,1.87,2H3V1.87A2,2,0,0,1,3.2.81,1.46,1.46,0,0,1,3.81.2,2,2,0,0,1,4.87,0H7.13A2,2,0,0,1,8.19.2,1.47,1.47,0,0,1,8.8.81,2,2,0,0,1,9,1.87V2h1.13a2,2,0,0,1,1.05.2,1.47,1.47,0,0,1,.61.61A2,2,0,0,1,12,3.87ZM1,4H11V3.87c0-.43,0-.51-.08-.59a.47.47,0,0,0-.2-.19c-.08,0-.16-.08-.59-.08H1.87c-.43,0-.51,0-.59.08a.46.46,0,0,0-.19.19c0,.08-.08.16-.08.59ZM4,2H8V1.87c0-.43,0-.51-.08-.59a.47.47,0,0,0-.2-.19C7.65,1,7.56,1,7.13,1H4.87c-.43,0-.51,0-.59.08a.46.46,0,0,0-.19.19c0,.08-.08.16-.08.59Z"
        />
      </svg>
      <svg className={'IconTrash--can'} width="10" height="12" viewBox="0 0 10 12">
        <path
          className="trashColor"
          d="M8.13,12H1.87a2,2,0,0,1-1.05-.2,1.46,1.46,0,0,1-.61-.61A2,2,0,0,1,0,10.13V0H10V10.13a2,2,0,0,1-.2,1.05,1.47,1.47,0,0,1-.61.61A2,2,0,0,1,8.13,12ZM1,1v9.13c0,.43,0,.51.08.59a.46.46,0,0,0,.19.19c.08,0,.16.08.59.08H8.13c.43,0,.51,0,.59-.08a.48.48,0,0,0,.2-.2c0-.08.08-.16.08-.59V1Z"
        />
        <path className="trashColor" d="M7.5,10h-1V2h1Zm-2,0h-1V2h1Zm-2,0h-1V2h1Z" />
      </svg>
    </div>
  );
};

export default IconTrash;
