import React, { Component } from 'react';
import { connect } from 'react-redux';
import VisibleDetails from '../../containers/profile/VisibleDetails';
import Routes from './sections/Routes';
import Vehicles from './sections/Vehicles';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import Reviews from './sections/Reviews';
import * as appUtils from '../../Utils';
import VisibleTabNavigation from '../../containers/layout/VisibleTabNavigation';
import { Translate } from 'react-localize-redux';
import * as MainApp from '../../App';
import { actions as reviewActions } from '../../actions/reviews';

const SITEPREFIX = appUtils.getPathForApp();
const SECTIONPREFIX = '/profile/info/';
const SECTIONTRANS = 'profile_page.sections.info.';

export const SECTIONS = {
  details: {
    name: SECTIONTRANS + 'details',
    path: SITEPREFIX + SECTIONPREFIX + 'details',
  },
  reviews: {
    name: SECTIONTRANS + 'reviews',
    path: SITEPREFIX + SECTIONPREFIX + 'reviews',
  },
  routes: {
    name: SECTIONTRANS + 'routes',
    path: SITEPREFIX + SECTIONPREFIX + 'routes',
  },
  vehicles: {
    name: SECTIONTRANS + 'vehicles',
    path: SITEPREFIX + SECTIONPREFIX + 'vehicles',
  },
  default: {
    name: SECTIONTRANS + 'title',
    path: SITEPREFIX + SECTIONPREFIX + 'details',
  },
};

class InfoApp extends Component {
  render() {
    return (
      <div>
        <div className={'main-centered-content'}>
          <VisibleTabNavigation
            navItems={[SECTIONS.details, SECTIONS.reviews, SECTIONS.routes, SECTIONS.vehicles]}
            header={SECTIONTRANS + 'title'}
            withDoctitle={true}
          />
        </div>
        {this.props.address && (
          <Switch>
            <Route
              path={SECTIONS.details.path}
              component={() => (
                <VisibleDetails
                  address={this.props.address}
                  bankAccount={this.props.bankAccount}
                  user={this.props.user}
                  countries={this.props.countries}
                />
              )}
            />
            <Route path={SECTIONS.routes.path} component={Routes} />
            <Route path={SECTIONS.vehicles.path} component={Vehicles} />
            <Route
              path={SECTIONS.reviews.path}
              render={props => <Reviews {...props} getReviews={this.props.getReviews} />}
            />
            <Redirect to={SECTIONS.default.path} />
          </Switch>
        )}
        <p className={'text-center pb-0-5 pt-0-5'}>
          <NavLink to={MainApp.SECTIONS.profile.path} className={'btn btn--outline'}>
            <Translate id={'nav.go_back_to'} /> <Translate id={'nav.dashboard'} />
          </NavLink>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.details,
    address: state.user.address,
    bankAccount: state.user.bankAccount,
    countries: state.countries.details,
  };
};

const mapDispatchToProps = dispatch => ({
  getReviews: page => dispatch(reviewActions.getReviews(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoApp);
