import React from 'react';
import IconSmile from '../../icons/IconSmile';
import '../../css/Card-action.css';
import { NavLink } from 'react-router-dom';

/**
 *
 * @returns {*}
 * @constructor
 */
export const CardImage = props => {
  return (
    <CardActionWrapper {...props}>
      <div className={'image-wrap'}>
        <div className={'image-position'}>{props.image}</div>
      </div>
      <div className={'content-wrap'}>
        <b>{props.title}</b>
        <div>{props.subText}</div>
      </div>
      <IconSmile />
    </CardActionWrapper>
  );
};
const CardActionWrapper = props => {
  if (typeof props.href !== 'undefined') {
    return (
      <NavLink to={props.href} className={'card card-action clickable card-style--' + props.cardStyle}>
        {props.children}
      </NavLink>
    );
  }
  return (
    <div
      className={'card card-action' + (props.onClick ? ' clickable' : '') + ' card-style--' + props.cardStyle}
      onClick={props.onClick ? () => props.onClick() : null}
    >
      {props.children}
    </div>
  );
};
