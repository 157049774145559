import { connect } from 'react-redux';
import PreviewDetail from '../../components/preview/PreviewDetail';
import { closeSingleView } from '../../actions/preview';
import { getSelectedTransportJob } from '../../reducers/search';
import { getUserDetails } from '../../reducers/user';

const mapStateToProps = state => {
  return {
    singleTransportJob: getSelectedTransportJob(state),
    user: getUserDetails(state),
  };
};

const mapDispatchToProps = dispatch => ({
  closeTransportJob: () => {
    dispatch(closeSingleView('search'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDetail);
