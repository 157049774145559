import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { translate } from '../../UtilsTranslation';
import { Button } from '../../Button';
import Select from '../../forms/Select';
import IconArrow from '../../icons/IconArrow';
import InputText from '../../forms/InputText';
import { Col, Row } from '../../Grid';
import Heading from '../../Heading';
import { SectionWrapper } from '../layout/SectionWrapper';

class AccountDetails extends Component {
  render() {
    // filtered the countries for now, not all options are needed for registering + set
    const countries = this.props.countries;
    let validations = this.props.validations.entry;
    let messages = [];
    let disabled = this.props.disabled;
    let loading = this.props.loading;
    let extra_services = this.props.extra_services;

    for (let field in validations) {
      const field_input = document.querySelector(`input[name=${field}]`);
      if (field_input) {
        messages[field] = validations[field];
      }
    }
    return (
      <>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg routes-info-wrapper'}
          centerContent={true}
        >
          <Row>
            <Col xs={12}>
              <Heading size={'h5'}>
                <Translate id={'onboarding.account_details.title'} />
              </Heading>
              <p>
                <Translate id={'onboarding.account_details.intro'} />
              </p>
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'main-centered-content'}>
          <form className={'onboarding-form onboarding-form-account'}>
            <div className={'input-row'}>
              <div className={'input-element type-text'}>
                <Select
                  name={'country'}
                  translate={translate}
                  handleChange={this.props.onChange.bind(this, 'address')}
                  options={[
                    ...countries.map(item => {
                      return [item['@id'], 'onboarding.account_details.countries.' + item['code']];
                    }),
                  ]}
                />
              </div>
              {messages['country']}
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.account.name}
                onChange={this.props.onChange.bind(this, 'account')}
                onBlur={this.props.onBlur.bind(this, 'account')}
                name={'name'}
                errors={messages['name']}
                label={translate('form.fields.account.account_name.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.address.line1}
                onChange={this.props.onChange.bind(this, 'address')}
                onBlur={this.props.onBlur.bind(this, 'address')}
                name={'line1'}
                errors={messages['line1']}
                label={translate('form.fields.address.line1.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.address.locality}
                onChange={this.props.onChange.bind(this, 'address')}
                onBlur={this.props.onBlur.bind(this, 'address')}
                name={'locality'}
                errors={messages['locality']}
                label={translate('form.fields.address.locality.placeholder')}
              />
            </div>
            <div className={'input-row inlined'}>
              <div className={'input-element type-text'}>
                <InputText
                  type={'text'}
                  value={this.props.address.postal_code}
                  onChange={this.props.onChange.bind(this, 'address')}
                  onBlur={this.props.onBlur.bind(this, 'address')}
                  name={'postal_code'}
                  errors={messages['postal_code']}
                  label={translate('form.fields.address.postal_code.placeholder')}
                />
              </div>
              <div className={'input-element type-text'}>
                <InputText
                  type={'text'}
                  value={this.props.address.house_number}
                  onChange={this.props.onChange.bind(this, 'address')}
                  onBlur={this.props.onBlur.bind(this, 'address')}
                  name={'house_number'}
                  errors={messages['house_number']}
                  label={translate('form.fields.address.house_number.placeholder')}
                />
              </div>
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.account.phone}
                onChange={this.props.onChange.bind(this, 'account')}
                onBlur={this.props.onBlur.bind(this, 'account')}
                name={'phone'}
                errors={messages['phone']}
                label={translate('form.fields.account.phone.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.account.coc_number}
                onChange={this.props.onChange.bind(this, 'account')}
                onBlur={this.props.onBlur.bind(this, 'account')}
                name={'coc_number'}
                errors={messages['coc_number']}
                label={translate('form.fields.account.coc_number.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.account.vat_number}
                onChange={this.props.onChange.bind(this, 'account')}
                onBlur={this.props.onBlur.bind(this, 'account')}
                name={'vat_number'}
                errors={messages['vat_number']}
                label={translate('form.fields.account.vat_number.placeholder')}
              />
            </div>
            <div className={'text-center pt-1 pb-0-5'}>
              <b>
                <Translate id={'onboarding.bank_details.title'} />
              </b>
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.bankAccount.account_holder_name}
                onChange={this.props.onChange.bind(this, 'bankAccount')}
                onBlur={this.props.onBlur.bind(this, 'bankAccount')}
                name={'account_holder_name'}
                errors={messages['account_holder_name']}
                label={translate('form.fields.bank_account.account_holder_name.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.bankAccount.account_number}
                onChange={this.props.onChange.bind(this, 'bankAccount')}
                onBlur={this.props.onBlur.bind(this, 'bankAccount')}
                name={'account_number'}
                errors={messages['account_number']}
                label={translate('form.fields.bank_account.account_number.placeholder')}
              />
            </div>
            <div className={'text-center pt-1 pb-0-5'}>
              <b>
                <Translate id={'profile_page.extra_services.title'} />
              </b>
            </div>
            <br />
            <div className={'extra_services_checks pb-1'}>
              {extra_services &&
                Object.keys(extra_services).map((service, index) => {
                  return (
                    <div key={index} className={'input-row'}>
                      <div key={index} className={'input-element type-check'}>
                        <input
                          checked={extra_services[service]}
                          name={service}
                          type={'checkbox'}
                          onChange={this.props.onChange.bind(this, 'extra_services')}
                        />
                        <label
                          onClick={this.props.onChange.bind(this, 'extra_services')}
                          name={service}
                          htmlFor={'extra_services_' + service}
                        >
                          <Translate id={'form.fields.account.extra_services.' + service} />
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={'form-bottom-actions'}>
              <div className={'main-centered-content'}>
                <Button
                  buttonStyle={'secondary block text-left'}
                  loading={loading}
                  disabled={disabled}
                  iconPos={'right'}
                  onClick={event => this.handleSubmit(event)}
                >
                  <Translate id={'onboarding.step.next'} />
                  <IconArrow />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.handleNavigation();
  }
}

export default AccountDetails;
