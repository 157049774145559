import React, { Component } from 'react';
import '../../css/Sidebar.css';
import { Col } from '../../Grid';
import VisibleFilters from '../../containers/search/VisibleFilters';
import IconSmile from '../../icons/IconSmile';
import * as appUtils from '../../Utils';
import history from '../../History';
import { Translate, withLocalize } from 'react-localize-redux';

import { getIdFromUrl } from '../../Utils';
import SearchItem from '../search/SearchItem';
const SITEPREFIX = appUtils.getPathForApp();

class PreviewList extends Component {
  componentDidMount() {
    this.props.fetchTransportJobs();
    // check if there is an id given as parameter
    let selectedUUID = getIdFromUrl(history, SITEPREFIX, this.props.renderType);
    if (selectedUUID !== '') {
      //id given as parameter, attempt to fetch corresponding offer from api
      this.props.selectTransportJob(selectedUUID);
    }

    //Upon navigating through forward/back, select/deselect according to new url
    window.onpopstate = () => {
      let selectedUUID = getIdFromUrl(history, SITEPREFIX, this.props.renderType);
      if (selectedUUID !== '') {
        this.props.selectTransportJob(selectedUUID);
      } else {
        this.props.deselectTransportJob();
      }
    };
  }

  render() {
    return (
      <aside className={'sidebar view-search' + (this.props.selectedTransportJobId === '' ? '' : ' hide-print')}>
        <div className={'list'}>
          <div>
            {this.props.isFetching && (
              <Col xs="12">
                <IconSmile spinning={true} /> <Translate id={'search.list.loading'} />
              </Col>
            )}
            <section className={'list-section'}>
              {!this.props.filtersOpen &&
                this.props.transportJobs.map((item, index) => {
                  return (
                    <SearchItem
                      key={index}
                      item={item}
                      onItemClick={this.props.onTransportJobClick}
                      isSelected={this.props.selectedTransportJobId === item.uuid}
                      renderType={this.props.renderType}
                      date={item.created_at}
                    />
                  );
                })}
            </section>
          </div>
          <VisibleFilters resultCount={this.props.transportJobs.length} />
        </div>
      </aside>
    );
  }
}

export default withLocalize(PreviewList);
