import React, { Component } from 'react';
import Card from './Card';
import Heading from './Heading';

class CardAuthor extends Component {
  render() {
    return (
      <Card sizeclass={'card-author hide-print'}>
        <img alt={this.props.name} className={'author-image'} src={this.props.profilepic} />
        <div className={'author-desc'}>
          <Heading size={'h4'}>{this.props.name}</Heading>
          {this.props.desc && <div className={'author-extra-info'}>{this.props.desc}</div>}
        </div>
      </Card>
    );
  }
}

export default CardAuthor;
