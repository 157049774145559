import React, { Component } from 'react';
import BundledOfferForm from '../bundled/BundledOfferForm';
import Heading from '../../Heading';
import { Translate } from 'react-localize-redux';
import VisibleTransportJobOfferForm from '../../containers/search/VisibleTransportJobOfferForm';
import IconCross from '../../icons/IconCross';
import { Link } from 'react-router-dom';
import { postNewBundledOfferStart, postNewOfferStart, postNewOfferSuccess } from '../../actions/search';
import { showModal } from '../../actions/modals';
import { connect } from 'react-redux';

class Offer extends Component {
  render() {
    return (
      <>
        {this.props.isAllowedToMakeOffer && (
          <div className={'make-offer hide-print hide'}>
            <Heading size="h3">
              {this.props.isPaid ? <Translate id={'offer.claim'} /> : <Translate id={'offer.create'} />}
            </Heading>
            <Link className="close-modal" to="#" onClick={() => this.props.closeModal()}>
              <IconCross />
            </Link>
            {!this.props.isBundled ? (
              <VisibleTransportJobOfferForm />
            ) : (
              <BundledOfferForm
                transportJobId={this.props.transportJobId}
                errors={this.props.errors}
                postBundledOffer={this.props.postBundledOffer}
                showModal={this.props.showModal}
                stops={this.props.stops}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  stops: ownProps.stops,
  closeModal: ownProps.closeModal,
  isAllowedToMakeOffer: ownProps.isAllowedToMakeOffer,
  isPaid: ownProps.isPaid,
  isBundled: ownProps.isBundled,
  errors: state.search.errors,
  transportJobId: state.search.selectedTransportJobId,
});

const mapDispatchToProps = dispatch => ({
  postBundledOffer: transportJobId => dispatch(postNewBundledOfferStart(transportJobId)),
  postOffer: (message, transportJobId) => dispatch(postNewOfferStart(message, transportJobId)),
  showModal: props => dispatch(showModal(props)),
  onFormSuccess: offerId => dispatch(postNewOfferSuccess(offerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
