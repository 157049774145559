import React, { Component } from 'react';
import FlashMessage from '../../FlashMessage';
import '../../css/Line-list.css';
import { Translate } from 'react-localize-redux';
import { translate } from '../../UtilsTranslation';
import { Button } from '../../Button';
import IconSmile from '../../icons/IconSmile';

export const EditableFieldList = props => {
  return (
    <ul className={'line-list'}>
      {props.items.map((item, index) => {
        return (
          <li className={'line-list-item delay-index-' + index} key={index}>
            <EditableFieldListItem
              onSubmit={props.onSubmit}
              type={props.type}
              key={index}
              validations={props.validations}
              onChange={props.onChange}
              item={item}
            />
          </li>
        );
      })}
    </ul>
  );
};

class EditableFieldListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isSubmitting: false,
      value: this.props.item.value,
      originalState: this.props.item.value,
      onChange: this.props.onChange,
      validations: this.props.validations,
      updateRespMsg: [],
    };
  }
  isValid(field) {
    //just check it's valid field in the our schema
    let validations = this.props.validations.entry;
    for (let param in validations) {
      if (field === param) return false;
    }
    return true;
  }
  getCountryCode(countries, id) {
    if (countries && id) {
      const country = countries.filter(country => country.id === id);
      if (country && country.length > 0) return country[0].code;
    }
    return '';
  }
  renderEditFieldByType(type, label, field, value, data = []) {
    const simpleInputs = ['text', 'tel', 'number', 'url', 'email'];
    if (simpleInputs.includes(type)) {
      return (
        <div className={'input-element type-' + type}>
          <input
            type={type}
            placeholder={translate(label)}
            value={this.state.value || ''}
            onChange={event => this.activeInputChange(event, field)}
          />
        </div>
      );
    }
    if (type === 'password' || type === 'bank_account') {
      return (
        <div className={'input-element type-text'}>
          <input
            type={'text'}
            placeholder={translate(label)}
            value={this.state.value || ''}
            onChange={event => this.activeInputChange(event, field)}
          />
        </div>
      );
    }
    if (type === 'textarea') {
      return (
        <div className={'input-element type-' + type}>
          <textarea
            placeholder={translate(label)}
            value={this.state.value || ''}
            onChange={event => this.activeInputChange(event, field)}
          />
        </div>
      );
    }
    if (type === 'select') {
      return (
        <div className={'input-select-wrapper'}>
          <select onChange={event => this.activeInputChange(event, field)} defaultValue={this.props.item.value || null}>
            <option key={0} value={null}>
              {translate('form.make_choice')}
            </option>
            {this.props.item.data &&
              this.props.item.data.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.code}
                  </option>
                );
              })}
          </select>
          <IconSmile />
        </div>
      );
    }
  }
  activeInputChange(event, field) {
    this.setState({ value: event.target.value });
    this.state.onChange(field, event.currentTarget.value);
  }
  startEditing() {
    this.setState({ isEditing: true });
  }
  cancelEditing() {
    this.setState({ isEditing: false });
  }
  hideMessages() {
    setTimeout(() => {
      this.setState({ updateRespMsg: [] });
    }, 3000);
  }
  saveField() {
    let resp_messages = this.state.updateRespMsg;
    this.setState({ isSubmitting: true });
    if (this.isValid(this.props.item.field)) {
      this.props.onSubmit(this.props.type, (type, resp, status) => {
        if (status === 'ok') {
          resp_messages[this.props.item.field] = {
            msg: translate('messages.success_update'),
            type: 'success',
          };
          this.setState({
            isEditing: false,
            updateRespMsg: [],
            isSubmitting: false,
          });
        } else {
          resp_messages[this.props.item.field] = {
            msg: translate('messages.error'), //resp.toString()
            type: 'error',
          };
        }
        this.setState({ updateRespMsg: resp_messages, isSubmitting: false });
        this.hideMessages();
      });
    }
  }
  render() {
    let validations = this.props.validations.entry;
    let validations_msg = [];
    let field = this.props.item.field;
    let updateRespMsg = this.state.updateRespMsg;
    let after_request_messages = [];

    for (let param in validations) {
      if (field === param) {
        validations_msg.push(<FlashMessage key={field} type={'error'} message={validations[param]} />);
      }
    }

    for (let field in updateRespMsg)
      after_request_messages.push(
        <FlashMessage key={field} type={updateRespMsg[field].type} message={updateRespMsg[field].msg} />
      );

    return (
      <div
        className={
          'line-list-item-editable state-' +
          (this.state.isEditing ? 'edit' : 'view') +
          (!this.props.item.withLabel && !this.props.item.value ? ' with-placeholder' : '')
        }
      >
        {this.props.item.withLabel && (
          <div className={'line-list-item-label'}>
            <Translate id={this.props.item.label} />
          </div>
        )}
        {!this.props.item.withLabel && !this.props.item.value && (
          <div className={'line-list-item-label line-list-item-placeholder'}>
            <Translate id={this.props.item.label} />
          </div>
        )}
        <div className={'line-list-item-value'}>
          <div className={'state-view-display'}>
            {(this.props.item.type === 'text' ||
              this.props.item.type === 'email' ||
              this.props.item.type === 'tel' ||
              this.props.item.type === 'textarea' ||
              this.props.item.type === 'number') &&
              this.props.item.value}
            {this.props.item.type === 'bank_account' && (
              <span className={'bank-account-number'}>
                {this.props.item.value.substr(0, 4)}
                {' **** **** '}
                {this.props.item.value.substr(16, 6)}
              </span>
            )}

            {this.props.item.type === 'select' && (
              <span>{this.getCountryCode(this.props.item.data, this.props.item.value)}</span>
            )}
          </div>
          <div className={'state-edit-display'}>
            {typeof this.props.item.type === 'string' && (
              <div className={'input-row inlined'}>
                {this.renderEditFieldByType(this.props.item.type, this.props.item.label, this.props.item.field)}
              </div>
            )}
            {typeof this.props.item.fields === 'object' &&
              this.props.item.fields.map((item, index) => {
                return (
                  <div className={'input-row inlined'} key={index}>
                    {this.renderEditFieldByType(item.type, item.value, item.field)}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={'line-list-items-actions'}>
          <div className={'state-view-display'}>
            <Button buttonStyle={'tertiary'} onClick={this.startEditing.bind(this)}>
              <Translate id={'form.edit'} />
            </Button>
          </div>
          {typeof this.props.item.type === 'string' && this.props.item.field !== 'current_password' && (
            <div className={'state-edit-display'}>
              <Button buttonStyle={'tertiary'} onClick={this.cancelEditing.bind(this)}>
                <Translate id={'form.cancel'} />
              </Button>
              <Button
                disabled={!this.isValid(this.props.item.field) || this.state.isSubmitting}
                loading={this.state.isSubmitting}
                buttonStyle={'secondary'}
                onClick={this.saveField.bind(this)}
              >
                <Translate id={'form.save'} />
              </Button>
            </div>
          )}
        </div>
        {validations_msg}
        {after_request_messages}
      </div>
    );
  }
}
