import React, { Component } from 'react';
import * as appUtils from '../../Utils';

class BundledItemReward extends Component {
  render() {
    const reward = this.getReward(this.props.renderType, this.props.transport);
    return (
      <>
        {reward && (
          <span className={`bundled-request-details-price ${this.props.isSelected ? 'selected' : ''}`}>
            &euro; {reward}
          </span>
        )}
      </>
    );
  }
  getReward(renderType, transport) {
    // we don't show the reward in planning mode to drivers
    if (renderType === 'planning') {
      return null;
    }
    const prefix = renderType === 'search' ? '' : 'transport_job_';
    return appUtils.priceLayout(
      transport.hasOwnProperty(`${prefix}total_payout`)
        ? transport[`${prefix}total_payout`]
        : transport[`${prefix}reward`]
    );
  }
}

export default BundledItemReward;
