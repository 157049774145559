import React, { Component } from 'react';
import { TJAL_STATUS, TJ_STATUS } from '../../globals';
import { Button } from '../../Button';
import { Translate } from 'react-localize-redux';
import * as appUtils from '../../Utils';
import history from '../../History';
import '../../css/SingleViewInner.css';
import { showModal } from '../../actions/modals';
import { patchOfferStart } from '../../actions/planning';
import { setSelectedTab } from '../../actions/planning/SingleViewActions';
import { connect } from 'react-redux';
import { fetchInvoiceStart } from '../../actions/delivered';
import UserRestrictionMessage from '../search/UserRestrictionMessage';
import {
  getBundledStops,
  getSelectedTransportJob,
  hasOwnOffer,
  isClaimedByOtherDriver,
  isPaid,
  isProgressLevelRequired,
} from '../../reducers/search';

class JobActions extends Component {
  render() {
    if (this.props.transport.bundled && this.props.stops.length === 0) {
      return <div />;
    }
    return (
      <div className={'request-action-wrapper hide-print request-action-wrapper--sticky'}>
        {/* SEARCH ACTIONS ============================= */}
        {window.location.href.indexOf('search/') > -1 &&
          !this.props.isOffer &&
          this.props.userIsRestricted.length === 0 &&
          (this.props.isClaimedByOtherDriver ? (
            <UserRestrictionMessage
              id={8}
              translationId={'search.messages.confirm.claim_failed'}
              translationData={{}}
            />
          ) : (
            <Button buttonStyle={'secondary'} onClick={() => this.showOffer()}>
              {this.getButtonText(this.props.status)}
              &nbsp; &euro;
              {this.getReward(this.props.transport)}
            </Button>
          ))}

        {/* PLANNING ACTIONS ============================= */}
        {window.location.href.indexOf('planning/') > -1 && (
          <>
            {/* CONFIRM DELIVERY ACTION */}
            {!this.props.transport.bundled &&
              this.props.status === TJAL_STATUS.ACCEPTED &&
              this.props.status !== TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER && (
                <Button
                  onClick={() =>
                    this.props.showModal({
                      action: () => this.props.patchOffer('confirm-delivery'),
                      message: <Translate id={'planning.messages.' + this.props.status} />,
                      header: <Translate id={'modal.are_you_sure'} />,
                    })
                  }
                  disabled={this.props.isSubmitting}
                  color="secondary"
                >
                  <Translate id={'transport_request.confirm_delivery'} />
                </Button>
              )}
            {/* REVOKE AN OFFER ACTION */}
            {this.props.status === TJAL_STATUS.PENDING && (
              <Button
                color="delete"
                disabled={this.props.isSubmitting}
                onClick={() =>
                  this.props.showModal({
                    action: () => this.props.patchOffer('cancel'),
                    message: <Translate id={'planning.messages.' + this.props.status} />,
                    header: <Translate id={'modal.are_you_sure'} />,
                  })
                }
              >
                <Translate id={'offer.revoke'} />
              </Button>
            )}
            {/* DELETE AN OFFER ACTION */}
            {this.props.status === TJAL_STATUS.REJECTED && (
              <Button
                onClick={() =>
                  this.props.showModal({
                    action: () => this.props.patchOffer('cancel'),
                    message: <Translate id={'planning.messages.' + this.props.status} />,
                    header: <Translate id={'modal.are_you_sure'} />,
                  })
                }
                disabled={this.props.isSubmitting}
                color="delete"
              >
                <Translate id={'transport_request.delete'} />
              </Button>
            )}
          </>
        )}
      </div>
    );
  }
  getReward(transport) {
    return transport.hasOwnProperty('reward')
      ? appUtils.priceLayout(transport.total_payout ? transport.total_payout : transport.reward)
      : '0.00';
  }
  showOffer() {
    const stickyBtn = document.querySelector('.request-action-wrapper--sticky');
    const makeOfferHead = document.querySelector('.make-offer h3');
    if (stickyBtn) {
      stickyBtn.classList.add('hide');
    }
    if (this.props.hasOffer) {
      this.handleViewOffer();
    } else {
      const stopDetails = document.querySelector('.stop-details-wrapper');
      const offerSection = document.querySelector('.make-offer');
      if (offerSection) {
        offerSection.classList.remove('hide');
      }
      if (stopDetails) {
        stopDetails.classList.add('hide');
      }
    }
    if (makeOfferHead) {
      makeOfferHead.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
    }
  }
  getButtonText(status) {
    if (this.props.hasOffer) {
      if (status === TJAL_STATUS.REVOKED_BY_DRIVER || status === TJAL_STATUS.REVOKED_BY_CUSTOMER_CARE) {
        return <Translate id={'offer.create'} />;
      }
      return <Translate id={'offer.view'} />;
    } else if (this.props.isPrepaid) {
      return <Translate id={'offer.claim'} />;
    }
    return <Translate id={'offer.create'} />;
  }
  handleViewOffer() {
    let link =
      appUtils.getPathForApp() +
      '/planning/' +
      this.props.single.own_transport_job_account_link['@id'].split('transport_job_account_links/')[1];
    history.push(link);
  }
}

const mapStateToProps = (state, ownProps) => ({
  single: getSelectedTransportJob(state),
  isFetching: state.search.isFetching,
  progressLevelRequired: isProgressLevelRequired(state),
  hasOffer: hasOwnOffer(state),
  isOffer: false,
  isClaimedByOtherDriver: isClaimedByOtherDriver(state),
  isPrepaid: isPaid(state),
  isSubmitting: state.search.isSubmitting,
  stops: getBundledStops(state),
});

const mapDispatchToProps = dispatch => ({
  showModal: modalProps => dispatch(showModal({ modalType: 'confirm', modalProps })),
  patchOffer: action => dispatch(patchOfferStart(action)),
  setSelectedTab: tab => dispatch(setSelectedTab(tab)),
  fetchInvoice: uuid => dispatch(fetchInvoiceStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobActions);
