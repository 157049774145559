import { connect } from 'react-redux';
import Select from '../../forms/Select';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize),
  ownProps,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
