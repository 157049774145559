import React from 'react';
import { Button } from '../../Button';
import { Translate, withLocalize } from 'react-localize-redux';

const ConfirmModal = ({ closeModal, closeText, action, actionText, message }) => {
  return (
    <div className="modal-content">
      <div>
        <p>{message}</p>
      </div>
      <div className={'btn-wrapper'}>
        <Button onClick={() => closeModal()} buttonStyle="tertiary">
          {closeText ? closeText : <Translate id="modals.confirm_modal.cancel" />}
        </Button>
        <Button
          onClick={() => {
            action();
            closeModal();
          }}
          buttonStyle={'primary'}
        >
          {actionText ? actionText : <Translate id="modals.confirm_modal.ok" />}
        </Button>
      </div>
    </div>
  );
};

export default withLocalize(ConfirmModal);
