import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Row, Col } from '../../../Grid';
import { SectionWrapper } from '../../layout/SectionWrapper';
import Heading from '../../../Heading';
import Pagination from '../../../Pagination';
import { StatsList } from '../StatsList';
import { CardReview } from '../CardReview';
import { connect } from 'react-redux';
import IconSmile from '../../../icons/IconSmile';
import Collapse from '../../../Collapse';
import '../../../css/Profile-Pages.css';

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: {
        total: 0,
        punctuality: 0,
        communication: 0,
        friendliness: 0,
      },
      pagination: {
        limit: 30,
        page: 1,
        total: null,
      },
      tipsVisible: false,
    };
    this.paginate = this.paginate.bind(this);
  }
  componentWillMount() {
    // Load current page data
    if (this.props.location.search.indexOf('page=') > -1) {
      this.initPagination();
    } else {
      this.props.getReviews(1);
    }
  }
  initPagination() {
    const redirect_page = parseInt(this.props.location.search.split('page=')[1]);
    this.setPage(redirect_page);
    this.props.getReviews(redirect_page);
  }
  paginate(event) {
    const page = parseInt(event.currentTarget.getAttribute('page'));
    this.setPage(page);
    this.props.history.push(`?page=${page}`);
    this.props.getReviews(page);
  }
  setPage(target) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: target,
      },
    });
  }
  toggleHintMessage() {
    this.setState({ tipsVisible: !this.state.tipsVisible });
  }
  render() {
    let review_summary = this.props.user.progress.review_summary.all;
    let avg_total_rate = review_summary.average;
    let avg_punctuality_rate = review_summary.averages_by_category.punctuality;
    let avg_communication_rate = review_summary.averages_by_category.communication;
    let avg_friendliness_rate = review_summary.averages_by_category.friendliness;
    let isFetched = typeof this.props.reviews !== 'undefined' && this.props.reviews;

    return (
      <div>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg profile-reviews-wrapper'}
          centerContent={true}
        >
          <Row>
            <Col xs={9}>
              <Heading size={'h5'}>
                <Translate id={'profile_page.rating'} />
              </Heading>
              <p>
                <Translate id={'profile_page.rating_explain'} />
              </p>
            </Col>
            <Col xs={3} extraClasses={'text-right'}>
              <Heading size={'h5'}>{avg_total_rate ? parseFloat(avg_total_rate).toFixed(1) : '-'}</Heading>
              <p>/5.0</p>
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'main-centered-content'}>
          {this.props.isFetching && (
            <div className={'pt-1 pb-1'}>
              <IconSmile spinning={true} /> <Translate id={'default.loading'} />
            </div>
          )}
          {isFetched && (
            <div className={'review-tips'}>
              <p onClick={() => this.toggleHintMessage()}>
                <IconSmile />
                <b>
                  {' '}
                  <Translate id={'profile_page.review.tips.title'} />
                </b>
              </p>
              <Collapse isOpen={this.state.tipsVisible}>
                <h5>
                  <Translate id={'profile_page.review.punctuality'} />:
                </h5>
                <ul>
                  <li>
                    <Translate id={'profile_page.review.tips.punctuality.first'} />
                  </li>
                  <li>
                    <Translate id={'profile_page.review.tips.punctuality.second'} />
                  </li>
                  <li>
                    <Translate id={'profile_page.review.tips.punctuality.third'} />
                  </li>
                  <li>
                    <Translate id={'profile_page.review.tips.punctuality.forth'} />
                  </li>
                </ul>

                <h5>
                  <Translate id={'profile_page.review.communication'} />:
                </h5>
                <ul>
                  <li>
                    <Translate id={'profile_page.review.tips.communication.first'} />
                  </li>
                  <li>
                    <Translate id={'profile_page.review.tips.communication.second'} />
                  </li>
                  <li>
                    <Translate id={'profile_page.review.tips.communication.third'} />
                  </li>
                </ul>

                <h5>
                  <Translate id={'profile_page.review.friendliness'} />:
                </h5>
                <ul>
                  <li>
                    <Translate id={'profile_page.review.tips.friendliness.first'} />
                  </li>
                  <li>
                    <Translate id={'profile_page.review.tips.friendliness.second'} />
                  </li>
                  <li>
                    <Translate id={'profile_page.review.tips.friendliness.third'} />
                  </li>
                </ul>
              </Collapse>
            </div>
          )}
          {isFetched && this.props.reviews.length > 0 && (
            <>
              <StatsList
                items={[
                  { label: 'profile_page.review.punctuality', value: Math.round(avg_punctuality_rate) },
                  { label: 'profile_page.review.communication', value: Math.round(avg_communication_rate) },
                  { label: 'profile_page.review.friendliness', value: Math.round(avg_friendliness_rate) },
                ]}
              />
              <p>
                <b>
                  <Translate id={'profile_page.recent_reviews'} />
                </b>
              </p>
              {this.props.reviews.map((item, index) => {
                return <CardReview key={index} item={item} />;
              })}
              <Pagination
                onChange={this.paginate.bind(this)}
                total={this.props.total}
                page={this.state.pagination.page}
                limit={this.state.pagination.limit}
              />
            </>
          )}
          {isFetched && this.props.reviews.length === 0 && (
            <p className={'pt-1'}>
              <Translate id={'profile_page.none_rating'} />
            </p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.details,
    reviews: state.reviews.details,
    total: state.reviews.total,
    isFetching: state.reviews.isFetching,
  };
};

export default connect(mapStateToProps)(Reviews);
