import React from 'react';
import '../css/IconStyle.css';

const IconWhatsapp = () => {
  return (
    <svg className="IconWhatsapp" viewBox="183 503 24 24.109" width="24" height="24.109">
      <clipPath id="_clipPath_xt6CpneWgpdwWxARcmaUzT47cPypoARt">
        <path
          d=" M 203.5 506.5 C 201.25 504.25 198.25 503 195.05 503 C 188.46 503 183.1 508.36 183.1 514.95 C 183.1 517.05 183.65 519.11 184.7 520.92 L 183 527.11 L 189.34 525.45 C 191.08 526.4 193.05 526.9 195.05 526.9 L 195.05 526.9 C 201.64 526.9 207 521.54 207 514.95 C 207 511.76 205.76 508.76 203.5 506.5 Z  M 195.05 524.88 L 195.05 524.88 C 193.27 524.88 191.52 524.4 189.99 523.5 L 189.63 523.29 L 185.87 524.27 L 186.87 520.61 L 186.64 520.23 C 185.64 518.65 185.12 516.82 185.12 514.95 C 185.12 509.47 189.57 505.02 195.05 505.02 C 197.71 505.02 200.2 506.05 202.07 507.93 C 203.95 509.81 204.98 512.3 204.98 514.95 C 204.98 520.43 200.52 524.88 195.05 524.88 Z  M 200.5 517.45 C 200.2 517.3 198.73 516.57 198.46 516.48 C 198.18 516.38 197.98 516.33 197.79 516.63 C 197.59 516.93 197.02 517.6 196.84 517.8 C 196.67 517.99 196.49 518.02 196.2 517.87 C 195.89 517.72 194.93 517.41 193.79 516.39 C 192.91 515.6 192.3 514.62 192.13 514.32 C 191.96 514.02 192.11 513.86 192.26 513.71 C 192.4 513.58 192.56 513.36 192.71 513.19 C 192.86 513.02 192.91 512.89 193.01 512.69 C 193.11 512.49 193.06 512.32 192.98 512.17 C 192.91 512.02 192.31 510.55 192.06 509.95 C 191.82 509.37 191.57 509.45 191.39 509.44 C 191.22 509.43 191.02 509.43 190.82 509.43 C 190.62 509.43 190.3 509.5 190.02 509.8 C 189.75 510.1 188.98 510.82 188.98 512.29 C 188.98 513.76 190.05 515.18 190.2 515.38 C 190.35 515.58 192.3 518.59 195.3 519.89 C 196.01 520.2 196.57 520.38 197 520.52 C 197.71 520.74 198.37 520.71 198.88 520.64 C 199.46 520.55 200.65 519.91 200.9 519.21 C 201.14 518.52 201.14 517.92 201.07 517.8 C 201 517.67 200.8 517.6 200.5 517.45 Z "
          className={'WhatsappColor'}
        />
      </clipPath>
      <g clipPath="url(#_clipPath_xt6CpneWgpdwWxARcmaUzT47cPypoARt)">
        <clipPath id="_clipPath_QIJ5AUTKocl6KO4jexR7X2PPe7NhZAhE">
          <path d=" M 0 1454 L 320 1454 L 320 0 L 0 0 L 0 1454 Z " className={'WhatsappColor'} />
        </clipPath>
        <g clipPath="url(#_clipPath_QIJ5AUTKocl6KO4jexR7X2PPe7NhZAhE)">
          <path d=" M 178 498 L 212 498 L 212 532.11 L 178 532.11 L 178 498 Z " className={'WhatsappColor'} />
        </g>
      </g>
    </svg>
  );
};

export default IconWhatsapp;
