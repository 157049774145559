import _get from 'lodash/get';
import { PROGRESS_LEVELS, TJ_STATUS, TJAL_STATUS } from '../globals';

export const types = {
  TOGGLE_SUBMIT: 'TOGGLE_SUBMIT',
  CONFIRM_DELIVERY_PER_STOP_SUCCESS: 'CONFIRM_DELIVERY_PER_STOP_SUCCESS',
  CONFIRM_DELIVERY_PER_STOP_ERROR: 'CONFIRM_DELIVERY_PER_STOP_ERROR',
  CONFIRM_DELIVERY_PER_STOP: 'CONFIRM_DELIVERY_PER_STOP',
  SELECT_OFFER: 'SELECT_OFFER',
  SET_TRANSPORT_JOBS: 'SET_TRANSPORT_JOBS',
  DESELECT_OFFER: 'DESELECT_OFFER',
  REMOVE_OFFER: 'REMOVE_OFFER',
  UPDATE_BUNDLED_STOPS: 'UPDATE_BUNDLED_STOPS',
  FETCH_OFFERS_START: 'FETCH_OFFERS_START',
  FETCH_OFFERS_SUCCESS: 'FETCH_OFFERS_SUCCESS',
  FETCH_OFFERS_ERROR: 'FETCH_OFFERS_ERROR',
  FETCH_TRANSPORT_JOBS_START: 'FETCH_TRANSPORT_JOBS_START',
  FETCH_SINGLE_OFFER_START: 'FETCH_SINGLE_OFFER_START',
  TOGGLE_FETCH_OFFERS: 'TOGGLE_FETCH_OFFERS',
  TOGGLE_PLANNING_FILTERS: 'TOGGLE_PLANNING_FILTERS',
  SET_PLANNING_FILTER: 'SET_PLANNING_FILTER',
  PATCH_OFFER_START: 'PATCH_OFFER_START',
  PATCH_OFFER_SUCCESS: 'PATCH_OFFER_SUCCESS',
  PATCH_OFFER_ERROR: 'PATCH_OFFER_ERROR',
  UPDATE_OFFERS: 'UPDATE_OFFERS',
  POST_GEOLOCATION_FOR_ETA: 'POST_GEOLOCATION_FOR_ETA',
  CLEAR_OFFERS_ERRORS: 'CLEAR_OFFERS_ERRORS',
};

const defaultState = {
  items: [],
  transport_jobs: [],
  selectedOfferId: '',
  isSubmitting: false,
  isFetching: false,
  errors: [],
  filtersOpen: false,
  filters: {
    state: { pending: true, accepted: true, rejected: false },
  },
};

//Selectors
export const getOfferById = (state, id) => {
  return state.planning.offers.items.find(offer => offer['@id'].split('transport_job_account_links/')[1] === id);
};
export const getSelectedOfferId = state => {
  return state.planning.offers.selectedOfferId;
};
export const getSelectedOffer = state => {
  return getOfferById(state, getSelectedOfferId(state));
};
export const getCursorDate = state => {
  return state.planning.offers.cursorDate;
};
export const isFetchingOffers = state => {
  return state.planning.offers.isFetching;
};
export const getPlanningFilters = state => {
  return state.planning.offers.filters;
};

export const isClaimedByOtherDriver = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const status = _get(job, 'state', '');
  return (
    window.location.href.indexOf('search') > -1 &&
    (status === TJ_STATUS.DELIVERED ||
      status === TJ_STATUS.MAX_DRIVERS_FOUND ||
      status === TJ_STATUS.DRIVER_CONFIRMED ||
      status === TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER ||
      status === TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER)
  );
};

export const isBundledDelivered = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const stops = _get(job, 'transport_job_details.stops', false);
  let delivered = true;
  if (stops) {
    stops.forEach(stop => {
      const type = _get(stop, 'pickup', false) ? 'pickup' : 'delivery';
      if (_get(stop, `${type}.item_sets`, false)) {
        if (stop[type].item_sets[0].state !== 'delivery_confirmed_by_driver') {
          delivered = false;
        }
      }
    });
  } else {
    delivered = false;
  }
  return delivered;
};

// return selected transport job or select by job id
export const getTransportJob = (state, jobId = null) => {
  return jobId ? getOfferById(state, jobId) : getSelectedOffer(state);
};

export const getRouteDistance = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const isBundled = _get(job, 'transport_job_details.bundled', false);
  const stops = _get(job, 'transport_job_details.stops', []);
  return _get(
    job,
    isBundled ? `transport_job_details.stops[${stops.length - 1}].distance_km` : 'transport_job_details.route.distance',
    0
  );
};

export const getCurrentStatus = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const status = _get(job, 'state', TJ_STATUS.SUBMITTED);
  return _get(job, 'transport_job_details.own_transport_job_account_link.state', status);
};

export const isValidTransport = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const hasItemSet = _get(job, 'transport_job_details.item_sets[0]', false);
  return hasItemSet && job.transport_job_details.item_sets[0].items.length > 0;
};

export const getBundledStops = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'transport_job_details.stops', []);
};

export const getItemImages = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const images = [];
  _get(job, 'item_sets', []).map(itemSet => {
    itemSet.items.map(item => item.job_image && images.push(item.job_image));
    return images;
  });
  return images;
};

export const isProgressLevelRequired = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'transport_job_details.minimum_progress_level_required', PROGRESS_LEVELS.bronze);
};

export const isBundled = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'transport_job_details.bundled', false);
};

export const getItemCardElement = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return hasOwnOffer(state, jobId)
    ? job.transport_job_details.own_transport_job_account_link['@id']
    : _get(job, '@id', '');
};

export const isPaid = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  return _get(job, 'prepaid', false);
};

// check is there any offer from drivers for selected job
export const hasOwnOffer = (state, jobId = null) => {
  const job = getTransportJob(state, jobId);
  const offer = _get(job, 'transport_job_details.own_transport_job_account_link', false);
  const status = getCurrentStatus(state, jobId);
  if (
    !job ||
    status === TJAL_STATUS.REVOKED_BY_DRIVER ||
    status === TJAL_STATUS.REVOKED_BY_CUSTOMER_CARE ||
    status === TJAL_STATUS.TRANSPORT_JOB_CANCELLED
  ) {
    return false;
  }
  return !!offer;
};

export const offers = (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_SUBMIT:
      return {
        ...state,
        isSubmitting: !state.isSubmitting,
      };
    case types.PATCH_OFFER_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.PATCH_OFFER_SUCCESS:
    case types.PATCH_OFFER_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };
    case types.SELECT_OFFER:
      if (state.selectedOfferId !== action.id) {
        return {
          ...state,
          isFetching: true,
          errors: [],
          selectedOfferId: action.id,
        };
      } else {
        return state;
      }
    case types.DESELECT_OFFER:
      return {
        ...state,
        selectedOfferId: '',
        errors: [],
      };
    case types.REMOVE_OFFER:
      return {
        ...state,
        items: state.items.filter(offer => offer['@id'] !== '/transport_job_account_links/' + action.id),
      };
    case types.FETCH_OFFERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_OFFERS_SUCCESS:
      //filter out any duplicates
      let filteredFetch = action.payload.filter(offer => state.items.map(o => o['@id']).indexOf(offer['@id']) === -1);
      let _items = [],
        flag = 0;
      filteredFetch = filteredFetch.filter(
        offer => offer.pickup_commitments.length !== 0 || offer.delivery_commitments.length !== 0
      );

      if (action.payload.length === 1) {
        _items = state.items;
        Object.keys(_items).map(item => {
          if (_items[item]['@id'] === action.payload[0]['@id']) {
            _items[item] = action.payload[0];
            flag = 1;
          }
          return _items;
        });
      }
      if (flag === 0) {
        _items = state.items.concat(filteredFetch);
      }
      let _isFetching = action.payload.length === 0 ? false : state.isFetching;
      if (state.selectedOfferId === '') {
        _isFetching = false;
      }
      return {
        ...state,
        items: _items,
        isFetching: _isFetching,
      };
    case types.FETCH_OFFERS_ERROR:
      //@todo: handle error
      return {
        ...state,
        isFetching: false,
      };
    case types.CONFIRM_DELIVERY_PER_STOP:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.CONFIRM_DELIVERY_PER_STOP_ERROR:
      return {
        ...state,
        errors: [action.err],
        isSubmitting: false,
      };
    case types.UPDATE_BUNDLED_STOPS:
      const updatedItems = state.items;
      const index = state.items.findIndex(
        item => item['@id'].split('transport_job_account_links/')[1] === state.selectedOfferId
      );
      const targetItem = state.items[index];
      targetItem['transport_job_details']['stops'] = action.stops;
      updatedItems[index] = targetItem;
      return {
        ...state,
        items: updatedItems,
      };
    case types.CONFIRM_DELIVERY_PER_STOP_SUCCESS:
      return {
        ...state,
        errors: [],
        isSubmitting: false,
      };
    case types.UPDATE_OFFERS:
      const items = state.items.map(offer => {
        if (offer['@id'] === `/transport_job_account_links/${action.id}`) {
          let details = action.data;
          details['pickups'] = offer.transport_job_details.pickups;
          details['deliveries'] = offer.transport_job_details.deliveries;
          if (_get(offer, 'transport_job_details.stops', false)) {
            details['stops'] = offer.transport_job_details.stops;
          }
          offer.transport_job_details = details;
        }
        return offer;
      });
      return {
        ...state,
        items: items,
        isFetching: false,
        errors: [],
      };
    case types.TOGGLE_FETCH_OFFERS:
      return {
        ...state,
        isFetching: !state.isFetching,
      };
    case types.TOGGLE_PLANNING_FILTERS:
      return {
        ...state,
        filtersOpen: !state.filtersOpen,
      };
    case types.CLEAR_OFFERS_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case types.SET_TRANSPORT_JOBS:
      return {
        ...state,
        transport_jobs: action.payload,
      };
    case types.SET_PLANNING_FILTER:
      let filters = {
        ...state.filters,
      };
      const setFilter = action.filter.split('_');
      const filterType = setFilter[0];
      const filterSubType = setFilter[1];
      filters[filterType][filterSubType] = !filters[filterType][filterSubType];
      return {
        ...state,
        filters: filters,
      };
    default:
      return state;
  }
};
