import React, { Component } from 'react';
import IconMaps from '../../icons/IconMaps';
import Modal from '../../Modal';
import * as appUtils from '../../Utils';
import { isReachableNumber } from '../../UtilsPhone';
import { Button } from '../../Button';
import FlashMessage from '../../FlashMessage';
import '../../css/OfferForm.css';
import Tooltip from '../../Tooltip';
import { Translate } from 'react-localize-redux';

class RouteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeModalOpen: false,
      isWatching: false,
      phoneCanReceiveMessage: isReachableNumber(this.props.phone),
      coordsUpToDate: false,
      sendMessage: {
        pickup: false,
        delivery: false,
      },
    };
  }

  componentDidMount() {
    this.props.startUserLocationPreference();
    this.validateLocation();
    this.validateLocationInterval = setInterval(this.validateLocation.bind(this), 5 * 60 * 1000);
  }

  componentDidUpdate() {
    if (this.props.locationPreference === 'granted' && this.state.isWatching === false) {
      this.setLocationWatcher();
      this.validateLocation();
      this.setState({
        isWatching: true,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.validateLocationInterval);
  }

  render() {
    return (
      <div className={'route-button-container'}>
        <span className={'address-action action-route'} onClick={() => this.toggleRouteModal()}>
          <IconMaps />
          <span className={'action-label'}>
            <Translate id="location.address" />
          </span>
        </span>
        {this.props.locationPreference === 'prompt' && (
          <Modal
            header={<Translate id="planning.detail.route.header.prompt" />}
            open={this.state.routeModalOpen}
            toggleFunction={this.toggleRouteModal.bind(this)}
          >
            <p>
              <Translate id="planning.detail.route.permissions.intro" />
            </p>
            <div className={'modal-footer'}>
              <Button buttonStyle={'tertiary'} onClick={() => this.toggleRouteModal()}>
                <Translate id="nav.go_back" />
              </Button>
              <Button buttonStyle={'secondary'} onClick={() => this.setLocationWatcher()}>
                <Translate id="planning.detail.route.permissions.go_to_settings" />
              </Button>
            </div>
          </Modal>
        )}
        {(this.props.locationPreference === 'granted' || this.props.locationPreference === 'denied') && (
          <Modal
            header={<Translate id={'planning.detail.route.header.' + this.props.direction} />}
            open={this.state.routeModalOpen}
            toggleFunction={this.toggleRouteModal.bind(this)}
          >
            {this.state.routeModalOpen && (
              <img
                src={
                  'https://maps.googleapis.com/maps/api/staticmap?center=' +
                  encodeURI(this.props.googleFormat) +
                  '&size=600x300&markers=color:0x0073fa%7Clabel:A%7C' +
                  encodeURI(this.props.googleFormat) +
                  '&zoom=9&key=AIzaSyCYRuVsNx0FOApLKQj5mvUMm8tbusecKHo'
                }
                alt={'map'}
              />
            )}
            <p>
              <b>
                <Translate id="location.address" />:
              </b>{' '}
              {this.props.address}
            </p>
            <p>
              <b>
                <Translate id="transport_request.contact" />:
              </b>{' '}
              {this.props.person}
            </p>
            <div>
              {this.props.locationPreference === 'granted' && (
                <div className={'route-modal-actions'}>
                  {!this.state.phoneCanReceiveMessage && (
                    <FlashMessage
                      type={'error'}
                      message={<Translate id={'planning.detail.route.notify_invalid_num'} />}
                    />
                  )}
                  {this.state.coordsUpToDate && this.state.phoneCanReceiveMessage && (
                    <div className="input-row">
                      <div className={'input-element type-check'}>
                        <input
                          id={'sendMessage_' + this.props.direction}
                          name={'sendMessage_' + this.props.direction}
                          type={'checkbox'}
                          onChange={() => this.handleSendMessage()}
                          checked={this.state.sendMessage[this.props.direction]}
                        />
                        <label htmlFor={'sendMessage_' + this.props.direction}>
                          <Translate
                            id="planning.detail.route.notify_checkbox"
                            data={{ person: <b>{this.props.person}</b> }}
                          />
                        </label>
                        <Tooltip color={'blue'}>
                          <Translate id="planning.detail.route.tooltip" />
                          <a target={'_blank'} href={'https://www.brenger.nl/nl/hoe-werkt-locatie-sms'}>
                            <Translate id={'nav.read_more'} />
                          </a>
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  {!this.state.coordsUpToDate && (
                    <FlashMessage
                      type={'info'}
                      message={
                        <Translate
                          id={'planning.detail.route.location_not_up_to_date'}
                          data={{
                            reload: (
                              <span className={'trigger'} onClick={() => window.location.reload()}>
                                <Translate id={'planning.detail.route.location_not_up_to_date_link'} />
                              </span>
                            ),
                          }}
                        />
                      }
                    />
                  )}

                  <div className={'modal-footer-sub'}>
                    <div>
                      <Button buttonStyle={'secondary'} onClick={this.handleNavigationButton.bind(this)}>
                        <Translate id={'location.start_nav'} />{' '}
                        {this.state.sendMessage[this.props.direction] && (
                          <Translate id={'planning.detail.route.and_send_message'} />
                        )}
                      </Button>
                    </div>
                    {this.state.coordsUpToDate && (
                      <div>
                        <Translate
                          id={'planning.detail.route.notify_only'}
                          data={{
                            click: (
                              <span
                                className={'trigger'}
                                onClick={() => {
                                  this.props.postGeoLocationForEta(this.props.stopId);
                                  appUtils.trackEvent('Send location text', 'Message only');
                                }}
                              >
                                <Translate id={'nav.click_here'} />
                              </span>
                            ),
                          }}
                        />
                        <Tooltip color={'blue'}>
                          <Translate id={'planning.detail.route.tooltip'} />
                          <a target={'_blank'} href={'https://www.brenger.nl/nl/hoe-werkt-locatie-sms'}>
                            <Translate id={'nav.read_more'} />
                          </a>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.props.locationPreference === 'denied' && (
                <div>
                  <FlashMessage
                    type={'info'}
                    message={
                      <Translate
                        id={'planning.detail.route.switch_on_geo'}
                        data={{
                          needHelp: (
                            <a target={'_blank'} href={'https://www.brenger.nl/nl/hoe-werkt-locatie-sms'}>
                              <Translate id={'planning.detail.route.switch_on_geo_link'} />
                            </a>
                          ),
                        }}
                      />
                    }
                  />
                  <div key={2} className={'modal-footer'}>
                    <Button
                      href={'https://www.google.nl/maps/dir//' + this.props.googleFormat}
                      target="_blank"
                      buttonStyle={'secondary'}
                    >
                      <Translate id={'location.start_nav'} />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    );
  }

  setLocationWatcher() {
    const self = this;
    const options = {
      enableHighAccuracy: false, // default, don't use gps
      maximumAge: 0, // default, don't use cached position
    };

    function success(pos) {
      const coords = pos.coords;
      const time = new Date();
      let coordsObject = {};
      coordsObject['coords'] = {
        lat: coords.latitude,
        lon: coords.longitude,
      };
      coordsObject['time'] = time.getTime();
      localStorage.setItem('userCoords', JSON.stringify(coordsObject), self.validateLocation());
      self.props.startUserLocationPreference();
    }

    function error(err) {
      localStorage.setItem('userCoords', 'denied');
      self.props.startUserLocationPreference();
    }

    navigator.geolocation.watchPosition(success, error, options);
  }

  handleSendMessage() {
    let obj = this.state.sendMessage;
    obj[this.props.direction] = !obj[this.props.direction];
    this.setState({
      sendMessage: obj,
    });
  }

  toggleRouteModal() {
    const coordsStorage = localStorage.getItem('userCoords');
    // if user switched from denied to ask we like to serve him a popup.
    // if the user did not switch (so location is still blocked) this fails silently.
    if (coordsStorage && coordsStorage === 'denied') {
      this.setLocationWatcher();
    }
    this.setState({
      routeModalOpen: !this.state.routeModalOpen,
    });
  }

  validateLocation() {
    const coordsStorage = localStorage.getItem('userCoords');
    let coordsUpToDate = false;
    if (coordsStorage && coordsStorage !== 'denied') {
      const time = new Date();
      const coordsObject = JSON.parse(coordsStorage);
      coordsUpToDate = time.getTime() - coordsObject.time < 5 * 60 * 1000; // 5 minutes old check
    }
    if (coordsUpToDate) {
      this.setState({
        coordsUpToDate: coordsUpToDate,
      });
      return;
    }
    // coords are not up to date, so also make sure that we won't send a message.
    this.setState({
      coordsUpToDate: coordsUpToDate,
      sendMessage: {
        pickup: false,
        delivery: false,
      },
    });
  }

  handleNavigationButton() {
    if (this.state.sendMessage[this.props.direction]) {
      this.props.postGeoLocationForEta(this.props.stopId);
      appUtils.trackEvent('Send location text', 'On navigation start');
    }

    window.open('https://www.google.nl/maps/dir//' + this.props.googleFormat, '_blank');

    this.setState({
      routeModalOpen: false,
      sendMessage: {
        pickup: false,
        delivery: false,
      },
    });
  }
}

export default RouteButton;
