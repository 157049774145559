import React from 'react';
import { Translate } from 'react-localize-redux';

/**
 *
 * @param props should contain: header, countryFilter, regionFilter, handleSearchFilters
 * @returns {*}
 * @constructor
 */
export const LocalityFilter = props => {
  return (
    <div>
      <b>
        <Translate id={'search.filters.delivery_location_header'} />:
      </b>
      {Object.keys(props.countryFilter).map((countryCode, index) => {
        return (
          <div key={index} className={'input-element type-check'}>
            <input
              id={'country_delivery_' + countryCode}
              name={'country_delivery_' + countryCode}
              type={'checkbox'}
              onChange={event => props.handleSearchFilters(event)}
              checked={props.countryFilter[countryCode]}
            />
            <label htmlFor={'country_delivery_' + countryCode}>
              <Translate id={'search.filters.country.' + countryCode} />
            </label>
            {props.countryFilter[countryCode] && props.regionFilter[countryCode] && (
              <div
                className={
                  'col ' +
                  (Object.keys(props.regionFilter[countryCode]).some(k => props.regionFilter[countryCode][k])
                    ? 'region-filter-active'
                    : '')
                }
              >
                {Object.keys(props.regionFilter[countryCode]).map((regionCode, index) => {
                  return (
                    <div key={index} className={'input-element type-check'}>
                      <input
                        id={'region_delivery_' + regionCode}
                        name={'region_delivery_' + countryCode + '_' + regionCode}
                        type={'checkbox'}
                        onChange={event => props.handleSearchFilters(event)}
                        checked={props.regionFilter[countryCode][regionCode]}
                      />
                      <label htmlFor={'region_delivery_' + regionCode}>
                        <Translate id={'search.filters.region.' + countryCode + '.' + regionCode} />
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
