import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';

class Specs extends Component {
  render() {
    const floor_level = _get(this.props.stop, 'details.floor_level', 1);
    const elevator = _get(this.props.stop, 'details.elevator', false);
    const carryingHelp = _get(this.props.stop, 'details.carrying_help', false);
    return (
      <div className={'stop-card-specs'}>
        <div className={'address-situation'}>
          <div className={'situation-content'}>
            <span>
              <Translate id={`stops.details.situation.${_get(this.props.stop, 'details.situation')}`} />
            </span>
            {elevator ? (
              <span>
                <Translate id={'stops.details.elevator_available'} />
              </span>
            ) : floor_level === 0 ? (
              <span>
                <Translate id={'stops.details.floor.ground_floor'} />
              </span>
            ) : (
              <span>
                <Translate
                  id={'stops.details.floor.level_x'}
                  data={{ level: _get(this.props.stop, 'details.floor_level', 1) }}
                />
              </span>
            )}
            <span>
              <Translate id={`stops.details.carrying_help.${carryingHelp}`} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Specs;
