import React, { Component } from 'react';

import './css/Collapse.css';

class Collapse extends Component {
  render() {
    let collapseClass = 'collapse';
    if (this.props.isOpen) {
      collapseClass += ' visible';
    }
    return <div className={collapseClass}>{this.props.children}</div>;
  }
}

export default Collapse;
