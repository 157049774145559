import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { translate } from '../../UtilsTranslation';
import { Button } from '../../Button';
import Tooltip from '../../Tooltip';
import { Row, Col } from '../../Grid';
import IconArrow from '../../icons/IconArrow';
import InputText from '../../forms/InputText';
import InputFile from '../../forms/InputFile';
import InputPassword from '../../forms/InputPassword';
import Heading from '../../Heading';
import { SectionWrapper } from '../layout/SectionWrapper';

class UserDetails extends Component {
  render() {
    let validations = this.props.validations.entry;
    let messages = [];
    let disabled = this.props.disabled;
    let loading = this.props.loading;
    for (let field in validations) {
      const field_input = document.querySelector(`input[name=${field}]`);
      if (field_input) {
        messages[field] = validations[field];
      }
    }
    return (
      <>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg routes-info-wrapper'}
          centerContent={true}
        >
          <Row>
            <Col xs={12}>
              <Heading size={'h5'}>
                <Translate id={'onboarding.contact_details.title'} />
              </Heading>
              <p>
                <Translate id={'onboarding.contact_details.intro'} />
              </p>
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'main-centered-content'}>
          <form className={'onboarding-form onboarding-form-contact'}>
            <div className={'text-center pt-1 pb-0-5'}>
              <b>
                <Translate id={'onboarding.contact_details.title'} />
              </b>
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.user.first_name}
                onChange={this.props.onChange.bind(this, 'user')}
                onBlur={this.props.onBlur.bind(this, 'user')}
                name={'first_name'}
                errors={messages['first_name']}
                label={translate('form.fields.user.first_name.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'text'}
                value={this.props.user.last_name}
                onChange={this.props.onChange.bind(this, 'user')}
                onBlur={this.props.onBlur.bind(this, 'user')}
                name={'last_name'}
                errors={messages['last_name']}
                label={translate('form.fields.user.last_name.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'email'}
                value={this.props.user.email}
                onChange={this.props.onChange.bind(this, 'user')}
                onBlur={this.props.onBlur.bind(this, 'user')}
                name={'email'}
                errors={messages['email']}
                success={validations['email-available']}
                label={translate('form.fields.user.email.placeholder')}
              />
            </div>
            <div className={'input-row'}>
              <InputText
                type={'tel'}
                value={this.props.user.phone}
                onChange={this.props.onChange.bind(this, 'user')}
                onBlur={this.props.onBlur.bind(this, 'user')}
                name={'phone'}
                errors={messages['phone']}
                label={translate('form.fields.user.phone.placeholder')}
              />
            </div>
            <Row>
              <Col xs={12} lg={6}>
                <div className={'text-center pt-1 pb-0-5'}>
                  <b>
                    <Translate id={'onboarding.contact_details.add_document'} />
                    <Tooltip>
                      <Translate id={'onboarding.contact_details.add_document_hint'} />
                    </Tooltip>
                  </b>
                </div>
                <div className={'input-row'}>
                  <InputFile
                    name={'id_card'}
                    onChange={event => this.props.setInputFileData(event)}
                    onBlur={event => this.props.setInputFileData(event)}
                    label={translate('onboarding.contact_details.upload')}
                    errors={messages['id_card']}
                    value={this.props.user.id_card.name}
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className={'text-center pt-1 pb-0-5'}>
                  <b>
                    <Translate id={'onboarding.contact_details.add_profile'} />
                  </b>
                </div>
                <div className={'input-row'}>
                  <InputFile
                    name={'profile_image'}
                    onChange={event => this.props.setInputFileData(event)}
                    onBlur={event => this.props.setInputFileData(event)}
                    label={translate('onboarding.contact_details.upload')}
                    errors={messages['profile_image']}
                    value={this.props.user.profile_image.name}
                  />
                </div>
              </Col>
            </Row>
            <div className={'text-center pt-1 pb-0-5'}>
              <b>
                <Translate id={'onboarding.login_details.title'} />
              </b>
            </div>
            <div className={'input-row pb-1'}>
              <InputPassword
                value={this.props.user.new_password}
                onChange={this.props.onChange.bind(this, 'user')}
                onBlur={this.props.onBlur.bind(this, 'user')}
                name={'new_password'}
                errors={messages['new_password']}
                label={translate('form.fields.user.password.placeholder')}
              />
            </div>
            <div className={'form-bottom-actions'}>
              <div className={'main-centered-content'}>
                <Button
                  buttonStyle={'secondary block text-left'}
                  iconPos={'right'}
                  loading={loading}
                  disabled={disabled}
                  onClick={event => this.handleSubmit(event)}
                >
                  <Translate id={'onboarding.step.next'} />
                  <IconArrow />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.handleNavigation();
  }
}

export default UserDetails;
