import React, { Component } from 'react';
import _get from 'lodash/get';
import StatusLabel from '../../StatusLabel';
import ItemSet from './ItemSet';
import { Translate } from 'react-localize-redux';

class JobOverview extends Component {
  render() {
    const title = _get(this.props.transport, 'item_sets[0].title', '');
    const distance = _get(this.props.transport, 'route.distance', 0);
    return (
      <div className={'transport-job-overview'}>
        {/* render item dimensions */}
        <h3>{title}</h3>
        <h4>#{this.props.shortId}</h4>
        <p>
          <Translate id={`bundled.details.reward`} />: &euro; {this.props.reward}
        </p>
        <p>
          {distance > 0 && (
            <>
              <Translate id={'bundled.details.distance'} /> {parseInt(distance, 10)} km
            </>
          )}
        </p>
        <p>{_get(this.props.transport)}</p>
        <ItemSet
          itemSets={this.props.transport.item_sets}
          hideTotalSize={false}
          hideDescription={true}
          hideImages={true}
        />
        {/* render specs label */}
        <div className="request-recap-overview">
          <div className="request-spec">
            <div className="request-spec-main">
              <StatusLabel status={this.props.status} />
            </div>
          </div>
          {this.props.transport.business && (
            <div className="request-spec">
              <div className="request-spec-main">
                <StatusLabel status={'business'} />
              </div>
            </div>
          )}
          {this.props.transport.prepaid && (
            <div className="request-spec">
              <div className="request-spec-main">
                <StatusLabel status={'paid_in_advance'} />
              </div>
            </div>
          )}
          {this.props.transport.bundled && (
            <div className="request-spec">
              <div className="request-spec-main">
                <StatusLabel status={'bundle'} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default JobOverview;
