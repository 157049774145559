import React, { Component } from 'react';
import BrengerLogo from '../../icons/BrengerLogo';
import SingleViewInner from '../../SingleViewInner';
import { setDocumentTitle } from '../../Utils';
import { Translate, withLocalize } from 'react-localize-redux';
import { userIsRestricted } from '../../actions/search';

class SearchDetail extends Component {
  render() {
    setDocumentTitle(
      this.props.singleTransportJob
        ? this.props.singleTransportJob.title + ' | ' + this.props.translate('search.title')
        : this.props.translate('search.title')
    );
    return (
      <main className={'single-view' + (this.props.singleTransportJob !== undefined ? ' is-active' : '')}>
        {this.props.singleTransportJob && (
          <SingleViewInner
            {...this.props}
            single={this.props.singleTransportJob}
            isClaimedByOtherDriver={this.props.isClaimedByOtherDriver}
            isOffer={false}
            closeDetails={this.props.closeTransportJob}
            user={this.props.user}
            userIsRestricted={userIsRestricted(this.props.user, this.props.singleTransportJob)}
          />
        )}
        <div className="not-active-view">
          <BrengerLogo />
          <p>
            <Translate id={'search.detail.welcome'} />
          </p>
        </div>
      </main>
    );
  }
}

export default withLocalize(SearchDetail);
