import React, { Component } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import * as appUtils from '../../Utils';
import VisibleTabNavigation from '../../containers/layout/VisibleTabNavigation';
import CriteriaAngular from './sections/CriteriaAngular';
import CriteriaVue from './sections/CriteriaVue';
import CriteriaReact from './sections/CriteriaReact';
import { Translate } from 'react-localize-redux';
import * as MainApp from '../../App';

const SITEPREFIX = appUtils.getPathForApp();
const SECTIONPREFIX = '/profile/criteria/';
const SECTIONTRANS = 'profile_page.sections.criteria.';

export const SECTIONS = {
  angular: {
    name: SECTIONTRANS + 'angular',
    path: SITEPREFIX + SECTIONPREFIX + 'silver',
  },
  vue: {
    name: SECTIONTRANS + 'vue',
    path: SITEPREFIX + SECTIONPREFIX + 'gold',
  },
  react: {
    name: SECTIONTRANS + 'react',
    path: SITEPREFIX + SECTIONPREFIX + 'diamond',
  },
  default: {
    name: SECTIONTRANS + 'title',
    path: SITEPREFIX + SECTIONPREFIX + 'silver',
  },
};

class CriteriaApp extends Component {
  render() {
    return (
      <div>
        <div className={'main-centered-content'}>
          <VisibleTabNavigation
            navItems={[SECTIONS.angular, SECTIONS.vue, SECTIONS.react]}
            header={SECTIONTRANS + 'title'}
            subheader={SECTIONTRANS + 'subtitle'}
            withDoctitle={true}
          />
        </div>
        <Switch>
          <Route path={SECTIONS.angular.path} component={CriteriaAngular} />
          <Route path={SECTIONS.vue.path} component={CriteriaVue} />
          <Route path={SECTIONS.react.path} component={CriteriaReact} />
          <Redirect to={SECTIONS.default.path} />
        </Switch>
        <p className={'text-center pb-0-5 pt-0-5'}>
          <NavLink to={MainApp.SECTIONS.profile.path} className={'btn btn--outline'}>
            <Translate id={'nav.go_back_to'} /> <Translate id={'nav.dashboard'} />
          </NavLink>
        </p>
        <p className={'text-center pb-0-5 pt-0-5'}>
          <a href={'https://www.brenger.nl/nl/tipsvoorkoeriers'} target={'_blank'} className={'btn btn--secondary'}>
            <Translate id={SECTIONTRANS + 'tips_for_profile'} />
          </a>
          <div>
            <small>
              <Translate id={SECTIONTRANS + 'tips_for_profile_explain'} />
            </small>
          </div>
        </p>
      </div>
    );
  }
}

export default CriteriaApp;
