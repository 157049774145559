import * as appUtils from '../Utils';
import { fetchGet, fetchPut, fetchPost } from '../api/general';
import { types as networkTypes } from '../actions/general/network';

const defaultState = {
  isLoggedIn: false,
  isLoaded: false,
  isSubmitting: false,
  formErrors: [],
  isUserCreated: false,
  details: {},
  address: {},
  bankAccount: {},
  preferences: {
    location: '',
  },
  login: {
    isSubmitting: false,
    redirectUrl: '',
    errors: '',
  },
  network: 'ONLINE',
};

export const types = {
  LOAD_NEW_USER: 'LOAD_NEW_USER',
  SUBMIT_NEW_USER_FORM_START: 'SUBMIT_NEW_USER_FORM_START',
  SUBMIT_NEW_USER_FORM_SUCCESS: 'SUBMIT_NEW_USER_FORM_SUCCESS',
  SUBMIT_NEW_USER_FORM_ERROR: 'SUBMIT_NEW_USER_FORM_ERROR',
  LOGIN_USER_START: 'LOGIN_USER_START',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',
  SET_LOGIN_REDIRECT: 'SET_LOGIN_REDIRECT',
  SET_USER: 'SET_USER',
  SET_USER_LOGGED_IN: 'SET_USER_LOGGED_IN',
  UPDATE_USER: 'UPDATE_USER',
  SET_ADDRESS: 'SET_ADDRESS',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  SET_BANK_ACCOUNT: 'SET_BANK_ACCOUNT',
  UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT',
  START_USER_LOCATION_PREFERENCE: 'START_USER_LOCATION_PREFERENCE',
  SET_USER_LOCATION_PREFERENCE: 'SET_USER_LOCATION_PREFERENCE',
  UPLOAD_PROFILE_IMAGE_START: 'UPLOAD_PROFILE_IMAGE_START',
};

export const actions = {
  uploadProfileImage: file => ({ type: types.UPLOAD_PROFILE_IMAGE_START, payload: file }),
  loadNewUser: () => ({ type: types.LOAD_NEW_USER }),
  submitNewUserFormStart: user => ({ type: types.SUBMIT_NEW_USER_FORM_START, user: user }),
  submitNewUserFormError: errors => ({ type: types.SUBMIT_NEW_USER_FORM_ERROR, errors: errors }),
  setLoginRedirect: redirectUrl => ({ type: types.SET_LOGIN_REDIRECT, redirectUrl: redirectUrl }),
  loginUser: credentials => ({ type: types.LOGIN_USER_START, credentials }),
  setUser: userObject => ({ type: types.SET_USER, userObject }),
  setUserLoggedIn: details => ({ type: types.SET_USER_LOGGED_IN, details }),
  updateUser: userObject => ({ type: types.UPDATE_USER, userObject }),
  setAddress: addressObject => ({ type: types.SET_ADDRESS, addressObject }),
  updateAddress: addressObject => ({ type: types.UPDATE_ADDRESS, addressObject }),
  setBankAccount: bankAccountObject => ({ type: types.SET_BANK_ACCOUNT, bankAccountObject }),
  updateBankAccount: bankAccountObject => ({ type: types.UPDATE_BANK_ACCOUNT, bankAccountObject }),
  getBillingAddress: userObject => ({ type: types.GET_USER_BILL_ADDRESS, userObject }),
  getBankAccount: userObject => ({ type: types.GET_USER_BANK_ACCOUNT, userObject }),
  startLocationPreferences: onChange => ({ type: types.START_USER_LOCATION_PREFERENCE, onChange }),
  setLocationPreferences: locationPreferenceState => ({
    type: types.SET_USER_LOCATION_PREFERENCE,
    locationPreferenceState,
  }),
};

export const getUserDetails = state => {
  return state.user.details;
};

export const isUserLoggedIn = state => {
  return state.user.isLoggedIn;
};

export const getUserId = state => {
  return state.user.details['@id'];
};

export const getAccountId = state => {
  return state.user.details.account['@id'];
};

export const getAddress = state => {
  return state.user.address;
};

export const getBankAccount = state => {
  return state.user.bankAccount;
};

export const getUnreadMessages = state => {
  return state.user.details.unread_messages;
};

export const isUserAvailable = email => {
  return new Promise((resolve, conflict) => {
    const url = appUtils.createApiUrl(`users/exists/${email}`, 2);
    fetchGet(url)
      .then(resp => {
        resolve(resp.ok);
      })
      .catch(err => {
        conflict(true);
      });
  });
};

export const updateUser = (userObject, id) => {
  return new Promise((resolve, conflict) => {
    const url = appUtils.createApiUrl(id, 2);
    fetchPut(null, url, userObject)
      .then(response => response.json())
      .then(user => resolve(user))
      .catch(err => conflict(err));
  });
};

export const createAddress = address => {
  return new Promise((resolve, conflict) => {
    const url = appUtils.createApiUrl('addresses', 2);
    fetchPost(null, url, address)
      .then(response => response.json())
      .then(address => {
        actions.updateAddress(address);
        resolve(address);
      })
      .catch(err => conflict(err));
  });
};

export const updateAddress = (address, id) => {
  return new Promise((resolve, conflict) => {
    const url = appUtils.createApiUrl(id, 2);
    fetchPut(null, url, address)
      .then(response => response.json())
      .then(address => {
        actions.updateAddress(address);
        resolve(address);
      })
      .catch(err => conflict(err));
  });
};

export const createBankAccount = account => {
  return new Promise((resolve, conflict) => {
    const url = appUtils.createApiUrl('bank_accounts', 2);

    fetchPost(null, url, account)
      .then(response => response.json())
      .then(account => {
        actions.updateBankAccount(account);
        resolve(account);
      })
      .catch(err => conflict(err));
  });
};

export const updateBankAccount = (bankAccount, id) => {
  return new Promise((resolve, conflict) => {
    const url = appUtils.createApiUrl(id, 2);
    fetchPut(null, url, bankAccount)
      .then(response => response.json())
      .then(bankAccount => {
        actions.updateBankAccount(bankAccount);
        resolve(bankAccount);
      })
      .catch(err => conflict(err));
  });
};

export const fetchAddress = userObject => {
  if (!userObject.account.address) {
    return;
  }
  const url = appUtils.createApiUrl(userObject.account.address, 2);
  return fetchGet(url)
    .then(response => response.json())
    .then(address => address);
};

export const fetchBankAccount = uuid => {
  if (!uuid) {
    return;
  }
  const url = appUtils.createApiUrl(uuid, 2);
  return fetchGet(url)
    .then(response => response.json())
    .then(bankAccounts => bankAccounts);
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOAD_NEW_USER: {
      return {
        ...state,
      };
    }
    case types.LOGIN_USER_START: {
      return {
        ...state,
        login: {
          ...state.login,
          isSubmitting: true,
          errors: '',
        },
      };
    }
    case types.LOGIN_USER_ERROR: {
      return {
        ...state,
        login: {
          ...state.login,
          isSubmitting: false,
          errors: action.payload,
        },
      };
    }
    case types.UPLOAD_PROFILE_IMAGE_START: {
      return {
        ...state,
        isUploading: true,
      };
    }
    case types.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        login: {
          ...state.login,
          redirectUrl: '',
          isSubmitting: false,
          errors: '',
        },
      };
    }
    case types.SET_LOGIN_REDIRECT: {
      return {
        ...state,
        login: {
          ...state.login,
          redirectUrl: action.redirectUrl,
        },
      };
    }
    case types.SET_USER:
      return {
        ...state,
        details: action.userObject,
      };
    case types.SET_USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.details.isLoggedIn || false,
        isLoaded: true,
      };
    case types.SET_ADDRESS:
      return {
        ...state,
        address: action.addressObject,
      };
    case types.SET_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: action.bankAccountObject,
      };
    case types.SET_USER_LOCATION_PREFERENCE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          location: action.locationPreferenceState,
        },
      };
    case types.SUBMIT_NEW_USER_FORM_START:
      return {
        ...state,
        formErrors: [],
        isSubmitting: true,
      };
    case types.SUBMIT_NEW_USER_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUserCreated: true,
      };
    case types.SUBMIT_NEW_USER_FORM_ERROR:
      return {
        ...state,
        formErrors: action.errors,
        isSubmitting: false,
      };
    case networkTypes.SET_NETWORK_STATUS:
      return {
        ...state,
        network: action.payload,
      };
    default:
      return state;
  }
};

export default user;
