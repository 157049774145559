import { connect } from 'react-redux';
import RouteMap from '../../components/routePlanner/RouteMap';
import { getFilters } from '../../reducers/search';
import { getUserDetails } from '../../reducers/user';

const mapStateToProps = state => {
  return {
    sortedCities: getFilters(state),
    user: getUserDetails(state),
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RouteMap);
