import React, { Component } from 'react';
import _get from 'lodash/get';
import { apiBaseUri } from '../../Utils';
import { Translate } from 'react-localize-redux';

class ItemSet extends Component {
  render() {
    let dimensions = [];
    this.props.itemSets.forEach(itemSet => {
      itemSet.items.forEach((item, i) => {
        dimensions.push(
          <div className={'item-set-row'} key={i}>
            <p>
              <span className={'volume-string'}>
                {`(${item.count}X) ${item.length}x${item.width}x${item.height}cm`}
                {item.weight > 0 ? `,  ${item.weight} kg` : ''}
              </span>
            </p>
          </div>
        );
      });
    });
    return (
      <>
        {dimensions}
        {/* render total size in cubic meter */}
        {!this.props.hideTotalSize && (
          <p>
            <Translate id={'actions.total_size'} />: {this.getCubicSqrMeter(this.props.itemSets)} m <sup>3</sup>
          </p>
        )}

        {/* render description */}
        <p>{!this.props.hideDescription && _get(this.props.itemSets[0], 'description', '')}</p>

        {/* item images rendering */}
        <div className={'item-set-images hide-print'}>
          {!this.props.hideImages &&
            _get(this.props, 'itemImages', []).map((image, i) => (
              <img key={i} src={apiBaseUri(2) + '/user_data' + image} alt={`item${i}`} />
            ))}
        </div>
      </>
    );
  }
  getCubicSqrMeter(itemSets) {
    let sum = 0;
    itemSets.forEach(itemSet => {
      itemSet.items.map(item => (sum += item.count * (item.width / 100) * (item.height / 100) * (item.length / 100)));
    });
    return parseFloat(sum.toFixed(3));
  }
}

export default ItemSet;
