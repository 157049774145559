import React from 'react';
import '../css/Form.css';
import IconEye from '../icons/IconEye';

class InputPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      focus: false,
    };
    this.inputWrapper = React.createRef();
  }

  render() {
    return (
      <div
        className={
          'input-element input-comp type-password input-float' +
          (this.props.value ? ' input--has-value' : '') +
          (this.state.focus ? ' input--has-focus' : '') +
          (this.state.showPassword ? ' input--show-password' : '')
        }
        ref={this.inputWrapper}
      >
        <input
          {...this.props}
          id={this.props.name}
          type={this.state.showPassword ? 'text' : 'password'}
          onFocus={(e, arg) => {
            if (this.props.onFocus) {
              this.props.onFocus(e, arg);
            }
            this.setState({ focus: true });
          }}
          onBlur={(e, arg) => {
            if (this.props.onBlur) {
              this.props.onBlur(e, arg);
            }
            this.setState({ focus: false });
          }}
        />
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <div className={'toggle-password trigger'} onClick={() => this.togglePassword()}>
          <IconEye />
        </div>
        {this.props.errors && (
          <div className={'error-message'}>
            <p>{this.props.errors}</p>
          </div>
        )}
      </div>
    );
  }

  togglePassword() {
    const wrapper = this.inputWrapper.current;
    if (!this.state.showPassword) {
      this.setState(
        {
          showPassword: true,
        },
        wrapper.classList.add('input--show-password')
      );
      return;
    }
    this.setState(
      {
        showPassword: false,
      },
      wrapper.classList.remove('input--show-password')
    );
  }
}
export default InputPassword;
