import { combineReducers } from 'redux';
import conversations from './conversations';
import preferredRoutes from './preferredRoutes';
import inbox from './inbox';
import planning from './planning';
import search from './search';
import countries from './countries';
import reviews from './reviews';
import vehicles from './vehicles';
import user from './user';
import { getTranslate, localizeReducer } from 'react-localize-redux';
import modalReducer from './modalReducer';
import delivered from './delivered';
import base, { getLocalizedState } from './base';
import _get from 'lodash/get';

export default combineReducers({
  base,
  user,
  countries,
  reviews,
  preferredRoutes,
  conversations,
  inbox,
  planning,
  search,
  vehicles,
  localize: localizeReducer,
  delivered,
  modalReducer,
});

export const getLocalizeState = state => {
  return state.localize;
};
export const getTranslateFromStore = state => {
  return getTranslate(state.localize);
};

export const selectRenderPart = state => {
  // Check if state is valid
  if (state.user.isLoaded === false && state.user.isLoggedIn === true) {
    throw new Error('User contains wrong data');
  }
  if (typeof state.user.isLoaded === 'undefined') {
    throw new Error('User object is not set right');
  }
  if (state.user.isLoaded && state.user.isLoggedIn && typeof state.user.details.account === 'undefined') {
    throw new Error('User does not have details');
  }
  // Is all data loaded?
  const isLangLoaded = getLocalizedState(state);
  const isUserLoaded = _get(state.user, 'isLoaded', false);
  if (!isLangLoaded || isUserLoaded === false) {
    return 'loading';
  }
  // Is the user logged in?
  const isUserLoggedIn = _get(state.user, 'isLoggedIn', false);
  if (isUserLoggedIn === false) {
    return 'notLoggedIn';
  }

  // Is the user a customer?
  const isCustomer = _get(state.user, 'details.roles', []).includes('ROLE_CUSTOMER');
  if (isCustomer) {
    return 'isCustomer';
  }

  if (process.env.REACT_APP_ENV !== 'dev') {
    // Is the user a business user?
    const userType = _get(state.user.details.account, 'account_type', '');
    const isUserBusiness = userType === 'business';
    if (isUserBusiness === false) {
      return 'notBusiness';
    }

    // Is the user suspended?
    const isUserSuspended = _get(state.user.details, 'is_suspended', false);
    if (isUserSuspended) {
      return 'isSuspended';
    }
  }
  return 'driverApp';
};
