import React from 'react';
import '../css/IconStyle.css';

const IconMail = () => {
  return (
    <svg className="IconMail" viewBox="252 507 26 18" width="26" height="18">
      <clipPath id="_clipPath_9hYvH7BG7pR9VeAu2RVACTuCaS06FgSs">
        <path
          d=" M 252 507 L 252 525 L 278 525 L 278 507 L 252 507 Z  M 256.31 509 L 273.69 509 L 265 514.78 L 256.31 509 Z  M 254 509.88 L 264.44 516.84 L 265 517.19 L 265.56 516.84 L 276 509.88 L 276 523 L 254 523 L 254 509.88 Z "
          className={'MailColor'}
        />
      </clipPath>
      <g clipPath="url(#_clipPath_9hYvH7BG7pR9VeAu2RVACTuCaS06FgSs)">
        <clipPath id="_clipPath_54OsII4FeeYUfIOzreusz4ZcNRjFJNmx">
          <path d=" M 0 1454 L 320 1454 L 320 0 L 0 0 L 0 1454 Z " className={'MailColor'} />
        </clipPath>
        <g clipPath="url(#_clipPath_54OsII4FeeYUfIOzreusz4ZcNRjFJNmx)">
          <path d=" M 247 502 L 283 502 L 283 530 L 247 530 L 247 502 Z " className={'MailColor'} />
        </g>
      </g>
    </svg>
  );
};

export default IconMail;
