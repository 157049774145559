import React, { Component } from 'react';
import '../../css/Maps.css';
import GoogleMap from 'google-map-react';

function createMapOptions(maps) {
  return {
    disableDefaultUI: true,
    styles: styledMapType,
  };
}

export class StaticMap extends Component {
  render() {
    const { center, zoom } = this.getZoomCenter();
    return (
      <div className={'dynamic-maps-wrap hide-print'}>
        <div id={'map'} className={'render-maps'}>
          {
            <GoogleMap
              bootstrapURLKeys={{ key: 'AIzaSyBwVhBOJxllD3rGUO0lLB6epMwKA6rXrDQ', language: '' }}
              center={center}
              zoom={zoom}
              options={createMapOptions}
              margin={[30, 30, 30, 30]}
              yesIWantToUseGoogleMapApiInternals={true}
            />
          }
        </div>
      </div>
    );
  }
  getZoomCenter() {
    return { center: { lat: 52.181859, lng: 5.311728 }, zoom: 7 }; // default return, should be locale based
  }
}

export default StaticMap;

const styledMapType = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#aad2e3',
      },
      {
        visibility: 'on',
      },
    ],
  },
];
