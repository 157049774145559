import React, { Component } from 'react';
import '../../css/Card-route.css';
import { Translate } from 'react-localize-redux';
import { Row } from '../../Grid';
import RouteCard from '../routes/RouteCard';
import IconSmile from '../../icons/IconSmile';

class RoutesList extends Component {
  componentDidMount() {
    this.props.fetchPreferredRoutes();
  }

  render() {
    return (
      <div>
        <p>
          <b>
            <Translate id={'profile_page.preferred_routes.your_routes'} />
          </b>
        </p>
        {this.props.isFetching && (
          <p>
            <Translate id={'default.loading'} /> <IconSmile spinning={'true'} />
          </p>
        )}
        <div className={'preferred-routes-overview'}>
          <Row>
            {this.props.preferredRoutes.map((preferredRoute, index) => (
              <RouteCard
                key={preferredRoute.id || index}
                from={preferredRoute.from}
                to={preferredRoute.to}
                id={preferredRoute.id}
                index={index}
                onDelete={this.props.deletePreferredRoute}
              />
            ))}
          </Row>
          {!this.props.isFetching && this.props.preferredRoutes.length === 0 && (
            <p>
              <Translate id={'profile_page.preferred_routes.no_routes_yet'} />
            </p>
          )}
        </div>
      </div>
    );
  }
}
export default RoutesList;
