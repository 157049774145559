import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
// @ts-ignore - @types does not exist for this lib. Just ignore errors.
import { Offline } from 'react-detect-offline';

import history from './History';
import PlanningApp from './PlanningApp';
import DeliveredApp from './DeliveredApp';
import RoutePlanner from './RoutePlanner';
import SearchApp from './SearchApp';
import * as appUtils from './Utils';
import { getTranslationData, onMissingTranslation } from './UtilsTranslation';
import ModalContainer from './containers/modals/ModalContainer';
import SuspendedModal from './components/modals/SuspendedModal';
import CustomerModal from './components/modals/CustomerModal';
import NonBusinessModal from './components/modals/NonBusinessModal';
import { networkStatus } from './actions/general/network';
import InboxApp from './InboxApp';
import ProfileApp from './ProfileApp';
import IconSmile from './icons/IconSmile';
import OnboardBrengerApp from './OnboardBrengerApp';
import PreviewApp from './PreviewApp';
import VisibleLoginForm from './containers/forms/VisibleLoginForm';
import { selectRenderPart } from './reducers';
import NetworkDetector from './components/NetworkDetector';
import MainHeader from './components/layout/MainHeader';
import { RootState } from './typings';

import 'bootstrap/dist/css/bootstrap-grid.css';
import './css/index.css';

const SITEPREFIX = appUtils.getPathForApp();
const COOKIES = appUtils.parseCookies();
const LANG = _get(COOKIES, 'driver_dashboard_lang', 'nl'); //active language

export const SECTIONS = {
  inbox: {
    path: SITEPREFIX + '/messages',
  },
  messages: {
    path: SITEPREFIX + '/messages/:id',
  },
  planning: {
    path: [SITEPREFIX + '/planning', SITEPREFIX + '/planning/:uuid '],
  },
  planner: {
    path: SITEPREFIX + '/planner',
  },
  delivered: {
    path: [SITEPREFIX + '/delivered', SITEPREFIX + '/delivered/:uuid'],
  },
  routes: {
    path: SITEPREFIX + '/profile/info/routes',
  },
  vehicles: {
    path: SITEPREFIX + '/profile/info/vehicles',
  },
  search: {
    path: [SITEPREFIX + '/search', SITEPREFIX + '/search/:uuid'],
  },
  onboarding: {
    path: SITEPREFIX + '/onboarding',
  },
  preview: {
    path: [SITEPREFIX + '/preview', SITEPREFIX + '/preview/:uuid'],
  },
  login: {
    path: SITEPREFIX + '/login',
  },
  profile: {
    path: SITEPREFIX + '/profile',
  },
  default: {
    path: SITEPREFIX + '/planning',
  },
};

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type AllProps = ReduxProps & LocalizeContextProps;

class App extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);

    // init translation
    props.initialize({
      languages: [
        { name: 'Dutch', code: 'nl' },
        { name: 'English', code: 'en' },
      ],
      options: {
        renderToStaticMarkup,
        onMissingTranslation,
      },
    });
  }

  componentDidMount() {
    getTranslationData(LANG, this.props.addTranslationForLanguage);
    this.props.setActiveLanguage(LANG);
  }

  render() {
    return (
      <div className={'app-main-wrapper'}>
        <MainHeader is_main_app_loaded={true} />
        <Offline polling={{ url: '/favicon.ico' }}>
          <NetworkDetector setNetworkStatus={this.props.setNetworkStatus} />
        </Offline>
        {/* Loading */}
        {this.props.renderPart === 'loading' && <LoadApplication />}
        {/* Not a registered user: */}
        {this.props.renderPart === 'notLoggedIn' && <NotLoggedInApp />}
        {/* Registered driver, but only gets partial version of app: */}
        {this.props.renderPart === 'isSuspended' && <SuspendedDriverApp />}
        {this.props.renderPart === 'notBusiness' && <NonBusinessModal />}
        {this.props.renderPart === 'isCustomer' && <CustomerModal />}
        {/* Registered driver: */}
        {this.props.renderPart === 'driverApp' && <DriverApp />}
        <ModalContainer />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  renderPart: selectRenderPart(state),
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNetworkStatus: (status: 'OFFLINE' | 'ONLINE') => dispatch(networkStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(App));

const NotLoggedInApp: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={SECTIONS.onboarding.path} component={OnboardBrengerApp} />
        <Route path={SECTIONS.preview.path} component={PreviewApp} />
        <Route path={SECTIONS.login.path} component={VisibleLoginForm} />
        <Redirect to={SECTIONS.login.path} />
      </Switch>
    </Router>
  );
};

const DriverApp: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={SECTIONS.onboarding.path} component={OnboardBrengerApp} />
        <Route path={SECTIONS.inbox.path} component={InboxApp} />
        <Route path={SECTIONS.messages.path} component={InboxApp} />
        <Route path={SECTIONS.planner.path} component={RoutePlanner} />
        <Route path={SECTIONS.planning.path} component={PlanningApp} />
        <Route path={SECTIONS.delivered.path} component={DeliveredApp} />
        <Route path={SECTIONS.search.path} component={SearchApp} />
        <Route path={SECTIONS.profile.path} component={ProfileApp} />
        <Redirect to={SECTIONS.default.path} />
      </Switch>
    </Router>
  );
};

const SuspendedDriverApp: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={SECTIONS.onboarding.path} component={SuspendedModal} />
        <Route path={SECTIONS.inbox.path} component={SuspendedModal} />
        <Route path={SECTIONS.messages.path} component={SuspendedModal} />
        <Route path={SECTIONS.planner.path} component={SuspendedModal} />
        <Route path={SECTIONS.planning.path} component={PlanningApp} />
        <Route path={SECTIONS.delivered.path} component={DeliveredApp} />
        <Route path={SECTIONS.search.path} component={SuspendedModal} />
        <Route path={SECTIONS.profile.path} component={SuspendedModal} />
        <Redirect to={SECTIONS.default.path} />
      </Switch>
    </Router>
  );
};

const LoadApplication: React.FC = () => {
  return (
    <section className={'loading-app-placeholder'}>
      <div>
        <IconSmile spinning={true} /> {LANG === 'nl' ? 'Bezig met laden...' : 'Busy loading...'}
      </div>
    </section>
  );
};
