import { debounce, put } from 'redux-saga/effects';
import { actions as baseActions } from '../reducers/base';

export function* setTranslationLoaded() {
  yield put(baseActions.setTranslationsLoaded(true));
}

export function* LocalizationSaga() {
  yield debounce(1500, '@@localize/ADD_TRANSLATION_FOR_LANGUAGE', setTranslationLoaded);
}
