import { matchPath } from 'react-router-dom';
import _get from 'lodash/get';
import { SITUATION_TYPES, TJ_DETAILS_TYPES } from './globals';
import RenderRequestSpecIcon from './icons/RenderRequestSpecIcon';
import React from 'react';

const API_BACKUP_URL = 'staging.brenger.nl';
const API_BACKUP_URL_V2 = 'staging.api.brenger.nl';
const LOGIN_PATH = '/nl/user/login';
const COOKIE = document.cookie;
const BECOME_A_DRIVER_INFO_URL = 'https://www.brenger.nl/nl/wordbrenger';

/** Internal functions **/
const apiBaseUri = (apiVersion = 1) => {
  let envUri = process.env.REACT_APP_API_URL;
  if (apiVersion === 2) {
    envUri = process.env.REACT_APP_API_URL_V2;
  }
  const currentHost = window.location.hostname;
  const whiteListedDomains = ['localhost', 'www.brenger.nl'];
  let baseUri = '';
  if (currentHost === 'staging.driver.brenger.nl') {
    baseUri = 'staging.api.brenger.nl';
  } else if (typeof envUri !== 'undefined') {
    baseUri = envUri;
  } else if (whiteListedDomains.indexOf(currentHost) > -1) {
    baseUri = currentHost + (currentHost === 'localhost' ? ':8000' : '');
    if (apiVersion === 2) {
      baseUri = currentHost + (currentHost === 'localhost' ? ':8001' : '');
    }
  } else {
    baseUri = API_BACKUP_URL;
    if (apiVersion === 2) {
      baseUri = API_BACKUP_URL_V2;
    }
  }

  if (!/^(f|ht)tps?:\/\//i.test(baseUri)) {
    baseUri = '//' + baseUri;
  }
  return baseUri;
};

/** To be exported functions **/
// @todo get rid of this crap
const momentToSortString = momentDate => {
  if (!momentDate.isValid()) {
    return '-';
  }

  return momentDate.format('YYYYMMDDHHmmss');
};

const getPathForApp = () => {
  return '/nl/driver';
};

/**
 *
 * @param history
 * @param SITEPREFIX
 * @param domain
 * @returns {string}
 */
const getIdFromUrl = (history, SITEPREFIX, domain) => {
  const match = matchPath(history.location.pathname, {
    path: SITEPREFIX + `/${domain}/:id`,
  });
  if (match !== null) {
    activateSingleView();
    return match.params.id;
  }
  deActivateSingleView();
  return '';
};

const redirectToLogin = () => {
  window.location.href = apiBaseUri() + LOGIN_PATH;
};

const createApiUrl = (path, apiVersion = 1) => {
  if (/^(f|ht)tps?:\/\//i.test(path)) {
    return path;
  }
  if ('/' !== path.charAt(path)) {
    path = '/' + path;
  }
  return apiBaseUri(apiVersion) + path;
};

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// Lodash in training
const valueOrElseWithSize = (key, dictionary, defaultVal) => {
  return key in dictionary && dictionary[key].length > 0 ? dictionary[key] : defaultVal;
};

const valueOrElse = (key, dictionary, defaultVal) => {
  return key in dictionary ? dictionary[key] : defaultVal;
};
/**
 *
 * @param {string} title
 * @param {string} suffix
 * @param {string} separator
 * @returns {null}
 */
const setDocumentTitle = (title, suffix = 'Brenger', separator = '|') => {
  try {
    document.title = `${title} ${separator} ${suffix}`;
  } catch (err) {
    document.title = `${separator} ${suffix}`;
  }
};

const isElementInViewport = el => {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const hyphensToCamelCase = string => {
  return string.replace(/_([a-z])/g, function(g) {
    return g[1].toUpperCase();
  });
};
// Crossbrowser events
const addEvent = (el, type, handler) => {
  if (el.attachEvent) el.attachEvent('on' + type, handler);
  else el.addEventListener(type, handler);
};
const removeEvent = (el, type, handler) => {
  if (el.detachEvent) el.detachEvent('on' + type, handler);
  else el.removeEventListener(type, handler);
};

const capsFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const stripLastCharacter = string => {
  return string.slice(0, -1);
};

const priceLayout = priceObject => {
  // const compensation = priceObject.compensation ? priceObject.compensation.amount : 0;
  // const totalCents = priceObject.reward.amount + compensation;
  const totalCents = priceObject.amount;
  const price = (totalCents / 100).toFixed(2);
  return String(price).replace('.', ',');
};

const getHouseAddress = request => {
  if (!('line1' in request)) return '';
  let house_number = _get(request, 'address.line2', null);
  if (!house_number || house_number === 'null') {
    house_number = '';
  }
  return `${request.line1}, ${house_number} ${request.postal_code}, ${request.locality}`;
};

/**
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @returns {null}
 */
const trackEvent = (category, action, label = '') => {
  if (typeof window.ga === 'undefined') {
    return;
  }

  window.ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  });
};
const activateSingleView = () => {
  document.querySelector('body').classList.add('single-view-active');
};

const deActivateSingleView = () => {
  document.querySelector('body').classList.remove('single-view-active');
};

const parseCookies = () => {
  return COOKIE.split(';').reduce((params, hash) => {
    let [key, val] = hash.trim().split('=');
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};

const getSituationsIcon = stop => {
  const icons = [];
  if (_get(stop, 'details', false)) {
    if (stop.details.carrying_help === TJ_DETAILS_TYPES.help_carrying_yes_extra) {
      icons.push('two_people');
    }
    if (
      stop.details.carrying_help === TJ_DETAILS_TYPES.equipment_tailgate ||
      stop.details.carrying_help === TJ_DETAILS_TYPES.equipment_tailgate_pallet_jack
    ) {
      icons.push('pallet_jack');
    }
    if (stop.details.situation === SITUATION_TYPES.shop || stop.details.situation === SITUATION_TYPES.store) {
      icons.push(SITUATION_TYPES.store);
    }
    if (stop.details.situation === SITUATION_TYPES.auction) {
      icons.push(SITUATION_TYPES.auction);
    }
  }
  return icons;
};

const getSpecialIcons = transport => {
  const renderIcons = [];
  const icons = getSpecialIconsArray(transport);
  for (let i in icons) {
    renderIcons.push(<RenderRequestSpecIcon key={i} icondata={icons[i]} />);
  }
  return renderIcons;
};

const getSpecialIconsArray = transport_job => {
  let iconsArr = [];
  if (_get(transport_job, 'pickups', false)) {
    transport_job.pickups.map(pickup => {
      if (pickup) {
        iconsArr = iconsArr.concat(getSituationsIcon(pickup));
      }
      return iconsArr;
    });
  }
  if (_get(transport_job, 'deliveries', false)) {
    transport_job.deliveries.map(delivery => {
      if (delivery) {
        iconsArr = iconsArr.concat(getSituationsIcon(delivery));
      }
      return iconsArr;
    });
  }
  return [...new Set(iconsArr)];
};

const logException = (message, data = '') => {
  if (typeof window.Raven === 'undefined') {
    return;
  }
  window.Raven.captureException(message, { extra: data });
};

const logger = message => {
  if (process.env.REACT_APP_ENV === 'dev') {
    console.log(message);
  }
};

const getBundledMaxLoad = transport => {
  let max = 0;
  _get(transport, 'stops', []).forEach(stop => {
    if (stop.total_volume_m3 > max) {
      max = stop.total_volume_m3;
    }
  });
  return Math.round(max * 100) / 100;
};

const getUniqueStopAddressesCount = transport => {
  if (_get(transport, 'stops.length', 0) === 0) {
    return;
  }
  const uniqueAddresses = new Set();
  transport.stops.forEach(stop => uniqueAddresses.add(stop));
  return uniqueAddresses.size;
};

/** Exports **/
export {
  BECOME_A_DRIVER_INFO_URL,
  getBundledMaxLoad,
  getSpecialIcons,
  getUniqueStopAddressesCount,
  momentToSortString,
  logger,
  getPathForApp,
  getIdFromUrl,
  redirectToLogin,
  createApiUrl,
  apiBaseUri,
  getHouseAddress,
  randomIntFromInterval,
  valueOrElse,
  valueOrElseWithSize,
  setDocumentTitle,
  hyphensToCamelCase,
  isElementInViewport,
  addEvent,
  removeEvent,
  capsFirstLetter,
  stripLastCharacter,
  trackEvent,
  activateSingleView,
  deActivateSingleView,
  priceLayout,
  parseCookies,
  getSpecialIconsArray,
  logException,
};
