import React from 'react';
import '../css/IconStyle.css';

const IconMaps = () => {
  return (
    <svg className="IconMaps" viewBox="46 502 18 26.75" width="18" height="26.75">
      <clipPath id="_clipPath_2rjF64TStZmxAx8LgTrczPZObA8BSR90">
        <path
          d=" M 55 502 C 50.04 502 46 506.04 46 511 C 46 512.41 46.57 514.02 47.34 515.78 C 48.12 517.54 49.11 519.41 50.13 521.16 C 52.15 524.64 54.19 527.56 54.19 527.56 L 55 528.75 L 55.81 527.56 C 55.81 527.56 57.85 524.64 59.88 521.16 C 60.89 519.41 61.88 517.54 62.66 515.78 C 63.43 514.02 64 512.41 64 511 C 64 506.04 59.96 502 55 502 Z  M 55 504 C 58.88 504 62 507.12 62 511 C 62 511.8 61.57 513.32 60.84 514.97 C 60.12 516.62 59.11 518.45 58.13 520.16 C 56.55 522.87 55.58 524.3 55 525.16 C 54.42 524.3 53.45 522.87 51.88 520.16 C 50.89 518.45 49.88 516.62 49.16 514.97 C 48.43 513.32 48 511.8 48 511 C 48 507.12 51.12 504 55 504 Z  M 55 509 C 53.89 509 53 509.89 53 511 C 53 512.11 53.89 513 55 513 C 56.11 513 57 512.11 57 511 C 57 509.89 56.11 509 55 509 Z "
          className={'MapsColor'}
        />
      </clipPath>
      <g clipPath="url(#_clipPath_2rjF64TStZmxAx8LgTrczPZObA8BSR90)">
        <clipPath id="_clipPath_l5PMjmGa8R4medj80fQ9DZb7MAnksqdv">
          <path d=" M 0 1454 L 320 1454 L 320 0 L 0 0 L 0 1454 Z " className={'MapsColor'} />
        </clipPath>
        <g clipPath="url(#_clipPath_l5PMjmGa8R4medj80fQ9DZb7MAnksqdv)">
          <path d=" M 41 497 L 69 497 L 69 533.75 L 41 533.75 L 41 497 Z " className={'MapsColor'} />
        </g>
      </g>
    </svg>
  );
};
export default IconMaps;
