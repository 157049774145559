import confirmModal from './ConfirmModal';
import promptModal from './PromptModal';
import notificationModal from './NotificationModal';

const modalTypes = {
  confirmModal,
  promptModal,
  notificationModal,
};

export default modalTypes;
