import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { StatsList } from '../StatsList';

class CriteriaAngularLists extends Component {
  render() {
    return (
      <React.Fragment>
        <p>
          <b>
            <Translate id={'loyalty.allowances.title'} />
          </b>
        </p>
        <StatsList items={[{ label: 'loyalty.allowances.access_unlimited', value: true }]} />
        <p>
          <b>
            <Translate id={'loyalty.requirements.title'} />
          </b>
        </p>
        <StatsList
          items={[
            { label: 'loyalty.requirements.verified', value: true },
            { label: 'loyalty.requirements.successful_transports', value: true },
          ]}
        />
        <p>
          <b>
            <Translate id={'loyalty.gadgets.required'} />
          </b>
        </p>
        <StatsList items={[{ label: 'loyalty.gadgets.blanket', value: true }]} />
      </React.Fragment>
    );
  }
}

export default CriteriaAngularLists;
