import React from 'react';
import { withLocalize } from 'react-localize-redux';

const NotificationModal = ({ message }) => {
  return (
    <div className="modal-content">
      <div>{message}</div>
    </div>
  );
};

export default withLocalize(NotificationModal);
