const defaultState = {
  translationsLoaded: false,
};

export const types = {
  SET_TRANSLATIONS_LOADED: 'SET_TRANSLATIONS_LOADED',
};

export const actions = {
  setTranslationsLoaded: isLocalized => ({ type: types.SET_TRANSLATIONS_LOADED, payload: isLocalized }),
};

export const getLocalizedState = state => {
  return state.base.translationsLoaded;
};

const base = (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_TRANSLATIONS_LOADED:
      return {
        ...state,
        translationsLoaded: action.payload,
      };
    default:
      return state;
  }
};

export default base;
