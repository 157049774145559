import { connect } from 'react-redux';
import PlanningDetail from '../../components/planning/PlanningDetail';
import { closeSingleView, confirmDeliveryPerStop, clearErrors } from '../../actions/planning';
import { getSelectedOffer } from '../../reducers/offers';
import { getTranslate } from 'react-localize-redux';
import { getUserDetails } from '../../reducers/user';
import {
  getItemCardElement,
  getItemImages,
  hasOwnOffer,
  isBundled,
  isProgressLevelRequired,
  isValidTransport,
  isPaid,
  getCurrentStatus,
  getRouteDistance,
  getBundledStops,
  isBundledDelivered,
  isClaimedByOtherDriver,
} from '../../reducers/offers';
import { getCountries } from '../../reducers/countries';
import { showModal } from '../../actions/modals';

const mapStateToProps = state => ({
  offer: getSelectedOffer(state),
  translate: getTranslate(state.localize),
  isFetching: state.planning.offers.isFetching,
  domain: 'planning',
  user: state.user.details,
  itemImages: getItemImages(state),
  bundled: isBundled(state),
  isAvailable: isValidTransport(state),
  countries: getCountries(state),
  progressLevelRequired: isProgressLevelRequired(state),
  itemCardElement: getItemCardElement(state),
  hasOffer: hasOwnOffer(state),
  errors: state.planning.offers.errors,
  isSubmitting: state.planning.offers.isSubmitting,
  status: getCurrentStatus(state),
  distance: getRouteDistance(state),
  claimedByOtherDriver: isClaimedByOtherDriver(state),
  isPaid: isPaid(state),
  isBundledDelivered: isBundledDelivered(state),
  stops: getBundledStops(state),
});

const mapDispatchToProps = dispatch => ({
  confirmDeliveryPerStop: (stop, type) => dispatch(confirmDeliveryPerStop(stop, type)),
  showModal: props => dispatch(showModal(props)),
  fetchUser: () => dispatch(getUserDetails()),
  closeDetails: () => dispatch(closeSingleView('planning')),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanningDetail);
