import React, { Component } from 'react';
import placeHolderImg from './images/van_placeholder.png';
import Heading from './Heading';
import Card from './Card';
import { apiBaseUri } from './Utils';
import './css/Vehicles.css';
import _get from 'lodash/get';
import IconTrash from './icons/IconTrash';

class VehicleCard extends Component {
  render() {
    const vehicle = this.props;
    const thumbnail = _get(vehicle, 'images[0]', false)
      ? apiBaseUri(2) + '/user_data' + vehicle.images[0]
      : placeHolderImg;
    return (
      <Card sizeclass={'card-van'}>
        <div className={'image-wrapper'}>
          <img alt={vehicle.make} className={'van-image'} src={thumbnail} />
        </div>
        <div className={'author-desc'}>
          <Heading size={'h4'}>
            {vehicle.make} - {vehicle.model}
          </Heading>
          <div className={'van-sizes'}>
            Max. {vehicle.loading_dimensions_length_cm}x{vehicle.loading_dimensions_width_cm}x
            {vehicle.loading_dimensions_height_cm}
            cm
          </div>
          <div className={'max-capacity'}>
            Max. {vehicle.loading_weight_limit_kg}
            kg.
          </div>
          <div className={'vehicle-actions'}>
            {_get(this.props, 'remove', false) && (
              <div className={'vehicle-actions--delete trigger'} onClick={() => this.props.remove(vehicle['@id'])}>
                <IconTrash />
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

export default VehicleCard;
