import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { Col, Row } from '../../../Grid';
import { SectionWrapper } from '../../layout/SectionWrapper';
import Heading from '../../../Heading';
import VehiclesList from '../../vehicles/VehiclesList';
import AddVehicle from '../../vehicles/AddVehicle';
import { Route } from 'react-router-dom';
import * as appUtils from '../../../Utils';

const SITEPREFIX = appUtils.getPathForApp();
const SECTIONPREFIX = '/profile/info/';
export const LIST_VEHICLES_ROUTE = SITEPREFIX + SECTIONPREFIX + 'vehicles';
export const ADD_VEHICLE_ROUTE = SITEPREFIX + SECTIONPREFIX + 'vehicles/add';

class Vehicles extends Component {
  render() {
    return (
      <div>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg routes-info-wrapper'}
          centerContent={true}
        >
          <Row>
            <Col xs={12}>
              <Heading size={'h5'}>
                <Translate id={'profile_page.vehicles.title'} />
              </Heading>
              <p>
                <Translate id={'profile_page.vehicles.explain'} />
              </p>
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'main-centered-content'}>
          <Route path={LIST_VEHICLES_ROUTE} exact component={VehiclesList} />
          <Route path={ADD_VEHICLE_ROUTE} exact component={AddVehicle} />
        </div>
      </div>
    );
  }
}

export default Vehicles;
