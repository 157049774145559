import React, { Component } from 'react';
import { Col, Row } from '../../Grid';
import { SectionWrapper } from '../layout/SectionWrapper';
import CirclePerson from '../../icons/CirclePerson2';
import IconCheckmarkWithCircle from '../../icons/IconCheckmarkWithCircle';
import Heading from '../../Heading';
import { Translate } from 'react-localize-redux';
import * as Globals from '../../globals';
import { Button } from '../../Button';
import * as InfoApp from '../../components/profile/InfoApp';
import IconArrow from '../../icons/IconArrow';
import IconSmile from '../../icons/IconSmile';

class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      agreed: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <SectionWrapper background={'primary'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
          <Row>
            <Col xs="12" extraClasses={'text-center thank-you-opening'}>
              <CirclePerson />
              <IconCheckmarkWithCircle />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={8} extraClasses={'offset-md-2'}>
              <Row>
                <Col xs="12" extraClasses={'pt-1 pb-1'}>
                  <Heading size={'h4'}>
                    <Translate id={'onboarding.thank_you.title'} />
                  </Heading>
                </Col>
                <Col xs={3} md={2}>
                  <b>
                    <Translate id={'default.step'} /> 1
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Translate id={'onboarding.thank_you.steps.1'} />
                </Col>
                <Col xs={3} md={2}>
                  <b>
                    <Translate id={'default.step'} /> 2
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Translate id={'onboarding.thank_you.steps.2'} />
                </Col>
                <Col xs={3} md={2}>
                  <b>
                    <Translate id={'default.step'} /> 3
                  </b>
                </Col>
                <Col xs={9} md={10}>
                  <Translate id={'onboarding.thank_you.steps.3'} data={{ name: Globals.driverAccountVerifyName }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </SectionWrapper>
        <SectionWrapper background={'white'} extraClasses={'profile-dashboard-wrapper'} centerContent={true}>
          <Row>
            <Col xs={12} md={8} extraClasses={'offset-md-2'}>
              <Heading size={'h5'}>
                <Translate id={'onboarding.thank_you.important'} />
              </Heading>
              <ul className={'line-list'}>
                <li className={'line-list-item hide-mobile'}>
                  <span className={'line-list-item-label'}>
                    <Translate
                      id={'onboarding.thank_you.get_in_contact'}
                      data={{ name: Globals.driverAccountVerifyName }}
                    />{' '}
                    : {Globals.driverAccountVerifyNumber}
                  </span>
                </li>
                <li className={'line-list-item show-mobile'}>
                  <a
                    className={'line-list-item-label with-icon with-icon--right'}
                    href={`tel:${Globals.driverAccountVerifyNumber}`}
                  >
                    <Translate
                      id={'onboarding.thank_you.get_in_contact'}
                      data={{ name: Globals.driverAccountVerifyName }}
                    />
                    <IconSmile />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'form-bottom-actions'}>
          <div className={'main-centered-content'}>
            <Button
              buttonStyle={'secondary block text-left'}
              iconPos={'right'}
              href={'https://www.brenger.nl' + InfoApp.SECTIONS.details.path}
            >
              <Translate id={'onboarding.thank_you.go_to_app'} />
              <IconArrow />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ThankYou;
