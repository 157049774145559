import React, { Component } from 'react';
import IconCross from '../../icons/IconCross';
import Heading from '../../Heading';
import { Col, Row } from '../../Grid';
import { Button } from '../../Button';
import '../../css/Filter-list.css';
import '../../css/Form.css';
import { Translate } from 'react-localize-redux';

class PlanningFilters extends Component {
  render() {
    return (
      <div className={'filter-list overlay' + (this.props.open ? ' is-open' : '')}>
        <div className={'filter-list-content'}>
          <div className={'filter-list-heading'}>
            <Heading size="h4">
              <Translate id={'search.filters.title'} />
            </Heading>
            <div className={'filter-list-close'} onClick={() => this.props.handleToggleFilters()}>
              <IconCross />
            </div>
          </div>
          <div className="filter-list-body">
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.size_header'} />:
                  </b>
                  {Object.keys(this.props.filters.state).map((item, index) => {
                    return (
                      <div key={index} className={'input-element type-check'}>
                        <input
                          id={'state_' + item}
                          name={'state_' + item}
                          type={'checkbox'}
                          onChange={event => this.props.handleSetFilter(event)}
                          checked={this.props.filters.state[item]}
                        />
                        <label htmlFor={'state_' + item}>
                          <Translate id={'status.' + item} />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </div>
          <div className="filter-list-footer filter-toggle-wrap">
            <Button buttonStyle={'secondary'} onClick={() => this.props.handleToggleFilters()}>
              <Translate id={'search.filters.show_results'} data={{ resultcount: this.props.resultCount }} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanningFilters;
