import React from 'react';
const CircleBus = () => {
  return (
    <svg width="155" height="97" viewBox="0 0 155 97">
      <g fill="none" fillRule="evenodd">
        <path fill="#FEFEFE" d="M132.884 21.898l14.375 29.258-63.114.938L85.362 27.8l21.73-7.048z" />
        <g transform="translate(10 18.991)">
          <mask id="b" fill="#fff">
            <path id="a" d="M.474.087h144.214v60.47H.474z" />
          </mask>
          <path
            fill="#0072FF"
            d="M137.387 31.885H96.581a2.859 2.859 0 0 1-2.852-2.849V5.605a2.86 2.86 0 0 1 2.852-2.85h26.172c-.8-1.405-2.199-2.668-5.552-2.668H3.093a2.617 2.617 0 0 0-2.62 2.617v52.384a5.473 5.473 0 0 0 5.477 5.47h133.417a4.053 4.053 0 0 0 4.056-4.05l1.265-15.633c-1.314-1.333-4.377-5.29-7.3-8.99"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#000"
          d="M125.474 74.935a4.622 4.622 0 0 0-4.62 4.614 4.623 4.623 0 0 0 4.62 4.614c2.547 0 4.62-2.07 4.62-4.614a4.622 4.622 0 0 0-4.62-4.614m0 9.363a4.757 4.757 0 0 1-4.755-4.749 4.757 4.757 0 0 1 4.755-4.749 4.757 4.757 0 0 1 4.754 4.749 4.757 4.757 0 0 1-4.754 4.749"
        />
        <path
          fill="#000"
          d="M125.474 69.254c-5.684 0-10.308 4.618-10.308 10.295s4.624 10.295 10.308 10.295 10.308-4.618 10.308-10.295-4.624-10.295-10.308-10.295m0 20.725c-5.758 0-10.443-4.679-10.443-10.43 0-5.75 4.685-10.43 10.443-10.43 5.759 0 10.443 4.68 10.443 10.43 0 5.751-4.684 10.43-10.443 10.43"
        />
        <path
          fill="#013B3D"
          d="M125.474 84.23a4.684 4.684 0 0 1-4.687-4.68H115.1c0 5.722 4.645 10.362 10.375 10.362s10.376-4.64 10.376-10.363h-5.689a4.684 4.684 0 0 1-4.687 4.681"
        />
        <path
          fill="#FEFEFE"
          d="M125.474 69.186c-5.73 0-10.375 4.64-10.375 10.363h5.688a4.684 4.684 0 0 1 4.687-4.681 4.684 4.684 0 0 1 4.687 4.68h5.689c0-5.722-4.646-10.362-10.376-10.362"
        />
        <path
          fill="#00D896"
          d="M125.474 78.702c.469 0 .848.38.848.847h3.84a4.684 4.684 0 0 0-4.688-4.681 4.684 4.684 0 0 0-4.687 4.681h3.84c0-.468.379-.847.847-.847"
        />
        <path
          fill="#0072FF"
          d="M125.474 80.396a.847.847 0 0 0 .848-.847h-1.696c0 .468.38.847.848.847M125.474 78.702a.847.847 0 0 0-.848.847h1.696a.847.847 0 0 0-.848-.847"
        />
        <path
          fill="#B9FFE6"
          d="M125.474 84.23a4.684 4.684 0 0 0 4.687-4.68h-3.839a.847.847 0 0 1-1.696 0h-3.839a4.684 4.684 0 0 0 4.687 4.68"
        />
        <path
          fill="#FEFEFE"
          d="M110.624 53.836h-.934a.117.117 0 0 0-.116.092 1.231 1.231 0 0 1-2.413 0 .117.117 0 0 0-.116-.092h-.934c-.07 0-.126.06-.118.129a2.394 2.394 0 0 0 2.375 2.114 2.393 2.393 0 0 0 2.374-2.114.118.118 0 0 0-.118-.13M149.223 64.61c-.265 2.56.06 4.69.726 4.76.667.068 1.422-1.95 1.688-4.51.265-2.56-.06-4.692-.726-4.76-.667-.07-1.422 1.95-1.688 4.51"
        />
        <path
          fill="#00C8FF"
          d="M108.677 21.747H69.289a2.859 2.859 0 0 0-2.852 2.849V73.37a2.859 2.859 0 0 0 2.852 2.849h39.388a2.859 2.859 0 0 0 2.852-2.85V21.832c0-.168-1.283-.084-2.852-.084"
        />
        <path
          fill="#FEFEFE"
          d="M106.374 53.836h-.934a.117.117 0 0 0-.116.092 1.231 1.231 0 0 1-2.413 0 .117.117 0 0 0-.116-.092h-.934c-.07 0-.126.06-.118.129a2.394 2.394 0 0 0 2.375 2.114 2.393 2.393 0 0 0 2.374-2.114.118.118 0 0 0-.118-.13M119.249 53.836h-.934a.117.117 0 0 0-.116.092 1.231 1.231 0 0 1-2.413 0 .117.117 0 0 0-.116-.092h-.934c-.07 0-.126.06-.118.129a2.394 2.394 0 0 0 2.375 2.114 2.393 2.393 0 0 0 2.374-2.114.118.118 0 0 0-.118-.13"
        />
        <path
          fill="#FEFEFE"
          d="M147.368 50.876h-65.75a2.859 2.859 0 0 1-2.853-2.848V24.596c0-1.567.103-2.85.103-2.85h53.875c2 3.496 4.833 9.655 14.625 29.13"
          opacity=".55"
        />
        <path fill="#FEFEFE" d="M10.368 59.782h144.166l-7.125-8.905-137.041.166z" opacity=".3" />
        <path
          fill="#00383B"
          d="M37.054 84.147a4.684 4.684 0 0 1-4.687-4.681h-5.689c0 5.723 4.646 10.362 10.376 10.362 5.73 0 10.375-4.64 10.375-10.362h-5.688a4.684 4.684 0 0 1-4.687 4.68"
        />
        <path
          fill="#FDFDFD"
          d="M37.054 69.103c-5.73 0-10.376 4.64-10.376 10.363h5.689a4.684 4.684 0 0 1 4.687-4.682 4.684 4.684 0 0 1 4.687 4.682h5.688c0-5.723-4.645-10.363-10.375-10.363"
        />
        <path
          fill="#00EB9D"
          d="M37.054 78.619c.468 0 .848.379.848.847h3.839a4.684 4.684 0 0 0-4.687-4.681 4.684 4.684 0 0 0-4.687 4.68h3.839c0-.467.38-.846.848-.846"
        />
        <path
          fill="#0073FF"
          d="M37.054 80.313a.847.847 0 0 0 .848-.847h-1.696c0 .468.38.847.848.847M37.054 78.619a.847.847 0 0 0-.848.847h1.696a.847.847 0 0 0-.848-.847"
        />
        <g transform="translate(-26 .015)">
          <mask id="d" fill="#fff">
            <path id="c" d="M0 .048h96.952v96.83H0z" />
          </mask>
          <path
            fill="#FFF"
            d="M96.952 48.463c0 26.74-21.703 48.415-48.476 48.415S0 75.202 0 48.463C0 21.723 21.703.048 48.476.048s48.476 21.676 48.476 48.415"
            mask="url(#d)"
          />
        </g>
        <g>
          <g transform="translate(-26 -.484)">
            <mask id="f" fill="#fff">
              <path
                id="e"
                d="M48.498 97.262c26.784.057 48.497-21.594 48.497-48.436C96.993 22.076 75.28.391 48.497.391S0 22.076 0 48.826c0 26.75 21.713 48.38 48.498 48.436z"
              />
            </mask>
            <path
              fill="#303030"
              d="M73.428 79.995H52.68c.025 5.702 4.66 10.318 10.375 10.318s10.35-4.616 10.374-10.318z"
              mask="url(#f)"
              opacity=".7"
            />
          </g>
          <g transform="translate(-26 -.484)">
            <mask id="h" fill="#fff">
              <path
                id="g"
                d="M48.498 97.262c26.784.057 48.497-21.594 48.497-48.436C96.993 22.076 75.28.391 48.497.391S0 22.076 0 48.826c0 26.75 21.713 48.38 48.498 48.436z"
              />
            </mask>
            <path
              fill="#303030"
              d="M178.716 56.795L163.104 48.9v-.075c.037-.198.059-.4.059-.608V31.358h-.06v-2.874c0-4.973-4.035-9.005-9.014-9.005H39.745a2.618 2.618 0 0 0-2.62 2.617V74.48a5.473 5.473 0 0 0 5.477 5.47h10.076l.001.046h20.75v-.046h68.321a10.354 10.354 0 0 0 .816 4.034c.087.207.184.41.285.61a10.277 10.277 0 0 0 .96 1.556 10.365 10.365 0 0 0 8.315 4.163c5.73 0 10.376-4.64 10.376-10.363h13.517a4.054 4.054 0 0 0 4.056-4.05V59.002c0-.932-.526-1.786-1.36-2.208"
              mask="url(#h)"
            />
          </g>
        </g>
        <g>
          <path
            fill="#0073FF"
            d="M14.366 69v1.818l-2.008-.355c-.085-.015-.145.08-.095.15.493.68 2.103 3.005 2.103 4.346v2.294c0 .406.33.734.735.734h1.923V69h-2.658zM34.449 70.463l-2.009.355V69h-2.657v8.987h1.923a.734.734 0 0 0 .734-.734V74.96c0-1.341 1.611-3.667 2.103-4.346.051-.07-.01-.165-.094-.15"
          />
          <path
            fill="#00D896"
            d="M17.024 77.987h12.759v-4.693h-12.76zM17.024 69a2.656 2.656 0 0 1-2.658 2.653A2.655 2.655 0 0 1 11.71 69a2.655 2.655 0 0 1 2.657-2.654A2.656 2.656 0 0 1 17.024 69"
          />
          <path fill="#0073FF" d="M14.79 69a.423.423 0 1 1-.847-.001.423.423 0 0 1 .847 0" />
          <path
            fill="#00D896"
            d="M29.783 69a2.655 2.655 0 0 0 2.658 2.653A2.655 2.655 0 0 0 35.098 69a2.655 2.655 0 0 0-2.657-2.654A2.655 2.655 0 0 0 29.783 69"
          />
          <path fill="#0073FF" d="M32.017 69a.423.423 0 1 0 .847-.001.423.423 0 0 0-.847 0" />
          <path
            fill="#FDFDFD"
            d="M28.355 74.46h-9.902a1.432 1.432 0 0 1-1.43-1.426v-2.155h12.76v2.155c0 .784-.643 1.427-1.428 1.427M18.993 62.125a.127.127 0 1 1-.255 0 .127.127 0 0 1 .255 0M22.019 62.125a.128.128 0 1 1-.255 0 .128.128 0 0 1 .255 0M25.044 62.125a.128.128 0 1 1-.255 0 .128.128 0 0 1 .255 0M28.07 62.125a.128.128 0 1 1-.127-.128c.07 0 .127.057.127.128M18.993 67.747a.127.127 0 1 1-.255 0 .127.127 0 0 1 .255 0M22.019 67.747a.128.128 0 1 1-.255 0 .128.128 0 0 1 .255 0M25.044 67.747a.128.128 0 1 1-.255 0 .128.128 0 0 1 .255 0M28.07 67.747a.128.128 0 1 1-.127-.128c.07 0 .127.058.127.128M18.993 65.02a.127.127 0 1 1-.255-.002.127.127 0 0 1 .255.001M22.019 65.02c0 .07-.057.126-.128.126a.127.127 0 1 1 0-.254c.07 0 .128.057.128.127M25.044 65.02a.127.127 0 1 1-.128-.128c.071 0 .128.057.128.127M28.07 65.02a.128.128 0 1 1-.127-.128c.07 0 .127.057.127.127"
          />
          <path fill="#00EB9D" d="M62.198 76.24l-.782 1.077-.244.01V53.946h1.026z" />
          <path fill="#00AAE3" d="M62.2 76.24l-.515.718V53.946h.514z" />
          <path fill="#0073FF" d="M59.72 79.482h-1.903v-.678h2.486z" />
          <path
            fill="#00EB9D"
            d="M52.944 34.95h-.313v-.78h-.782v-.313h1.095zM40.882 34.48h1.745v-.624h-1.745v.625zm3.49 0h1.744v-.624h-1.744v.625zm3.488 0h1.745v-.624H47.86v.625zM38.855 34.95h-.313v-1.093h1.096v.312h-.783zM38.042 38.69h.625v-1.87h-.625v1.87zm0 3.739h.625v-1.87h-.625v1.87zm0 3.738h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87zm0 3.739h.625v-1.87h-.625v1.87zm0 3.739h.625v-1.87h-.625v1.87zm0 3.739h.625v-1.87h-.625v1.87zm0 3.738h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87zm0 3.738h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87zM39.638 79.042h-1.096v-1.095h.313v.782h.783zM40.882 79.354h1.745v-.625h-1.745v.625zm3.49 0h1.744v-.625h-1.744v.625zm3.488 0h1.745v-.625H47.86v.625zM52.444 79.042H51.35v-.313h.782v-.782h.313zM52.132 38.688h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87zm0 3.738h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87zm0 3.738h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87zm0 3.738h.625v-1.87h-.625v1.87zm0 3.739h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87zm0 3.738h.625v-1.87h-.625v1.87zm0 3.74h.625v-1.87h-.625v1.87z"
          />
          <path fill="#0073FF" d="M36.008 80.413h2.082v-.901h-2.082z" />
          <path
            fill="#92FFE5"
            d="M26.915 58.78h-7.022c-4.453 0-4.622 4.803-3.758 6.547.864 1.745.879 3.672.879 3.672v3.394h12.78v-3.394s.014-1.927.878-3.672c.864-1.744.695-6.548-3.757-6.548M16.167 79.698h1.714v-1.711h-1.714zM28.927 79.698h1.713v-1.711h-1.713zM68.673 53.946H54.697l1.434-10.552h11.108zM61.426 77.302c-.225.33-.545.732-1.138 1.502h-2.471a2.051 2.051 0 0 1 1.97-1.477l1.639-.025zM37.897 79.512a.845.845 0 0 1-1.687 0h-3.84a4.682 4.682 0 0 0 4.684 4.635c2.573 0 4.66-2.071 4.684-4.635h-3.841z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CircleBus;
