import React, { PureComponent } from 'react';
import { Col } from '../../Grid.js';
import IconSmile from '../../icons/IconSmile';
import '../../css/OneItem.css';
import StatusLabel from '../../StatusLabel';
import * as appUtils from '../../Utils';
import _get from 'lodash/get';
import { determineTimeDisplay } from '../../UtilsDatetime';
import ItemAddress from '../transport/ItemAddress';

class PlanningItem extends PureComponent {
  render() {
    const transport = this.props.item;
    const rewards = this.getRewards(transport);
    const uuid = transport['@id'].split('transport_job_account_links/')[1];
    return (
      <div
        className={'card one-item-container ' + (this.props.isSelected ? ' is-selected' : '')}
        onClick={() => this.props.onItemClick(uuid, 'planning')}
      >
        <div className={'one-item-inner type-planning'} id={this.props.item['@id']}>
          <div className={'dropdown-toggle'}>
            <IconSmile />
          </div>
          <div className="row address-details address-pickup">
            <ItemAddress stop={transport.transport_job_details.pickups[0]} />
            <Col xs="6" extraClasses={'date-time-col'}>
              <div className="date">{determineTimeDisplay(this.props.item, this.props.date, 'pickup')}</div>
            </Col>
          </div>
          <div className="row address-details address-delivery">
            <ItemAddress stop={transport.transport_job_details.deliveries[0]} />
            <Col xs="6" extraClasses={'date-time-col'}>
              <div className="date">{determineTimeDisplay(this.props.item, this.props.date, 'delivery')}</div>
            </Col>
          </div>
          <div className={'request-details'}>
            <div className="column-1">
              <div className={'labels'}>
                <StatusLabel status={this.getTransportStatus(this.props.item)} />
                {transport.transport_job_is_business && <StatusLabel status={'business'} />}
              </div>
              <div className={'transport_icons'}>{appUtils.getSpecialIcons(transport.transport_job_details)}</div>
            </div>
            <div className="column-2">
              {rewards && (
                <div className="request-details-price">
                  &euro; {rewards}
                  {transport.transport_job_prepaid && <StatusLabel status={'paid_in_advance'} />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  getTransportStatus(transport) {
    return _get(transport, 'own_transport_job_account_link', false)
      ? transport.own_transport_job_account_link.state
      : transport.state;
  }
  getRewards(transport) {
    return appUtils.priceLayout(
      transport.hasOwnProperty('transport_job_total_payout')
        ? transport.transport_job_total_payout
        : transport.transport_job_reward
    );
  }
}

export default PlanningItem;
