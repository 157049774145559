import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { StatsList } from '../StatsList';

class CriteriaReactLists extends Component {
  render() {
    return (
      <React.Fragment>
        <p>
          <b>
            <Translate id={'loyalty.allowances.title'} />
          </b>
        </p>
        <StatsList
          items={[
            { label: 'loyalty.allowances.access_unlimited', value: true },
            { label: 'loyalty.allowances.auction', value: true },
            { label: 'loyalty.allowances.business_requests', value: true },
          ]}
        />
        <p>
          <b>
            <Translate id={'loyalty.requirements.title'} />
          </b>
        </p>
        <StatsList
          items={[
            { label: 'loyalty.requirements.previous_level', value: true },
            { label: 'loyalty.requirements.successful_transports_3', value: true },
            { label: 'loyalty.requirements.minimun_jobs', value: true },
          ]}
        />
        <p>
          <b>
            <Translate id={'loyalty.gadgets.title'} />
          </b>
        </p>
        <StatsList
          items={[
            { label: 'loyalty.gadgets.magnet_sticker', value: true },
            { label: 'loyalty.gadgets.new_features', value: true },
          ]}
        />
      </React.Fragment>
    );
  }
}

export default CriteriaReactLists;
