import FlashMessage from '../../FlashMessage';
import { Translate } from 'react-localize-redux';
import React from 'react';

const UserRestrictionMessage = ({ translationId, translationData }) => {
  return (
    <div className="pb-1 hide-print">
      <FlashMessage
        type={'info'}
        message={<Translate id={translationId} data={translationData} options={{ renderInnerHtml: true }} />}
      />
    </div>
  );
};

export default UserRestrictionMessage;
