import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { translate } from '../../UtilsTranslation';
import { Button } from '../../Button';
import IconArrow from '../../icons/IconArrow';
import InputText from '../../forms/InputText';
import InputFile from '../../forms/InputFile';
import { Col, Row } from '../../Grid';
import Heading from '../../Heading';
import { SectionWrapper } from '../layout/SectionWrapper';
import { VehicleSchema } from './Schema';
import VehicleCard from '../../VehicleCard';
import '../../css/Vehicles.css';
import * as appUtils from '../../Utils';
import _get from 'lodash/get';
import FlashMessage from '../../FlashMessage';
import Card from '../../Card';
import placeHolderImg from '../../images/van_placeholder.png';
import '../../css/Card-license-plate.css';

// Indicate the current vehicle details view by enum keys
const LAYOUT_KEYS = {
  PLATE_NUMBER: 'PLATE_NUMBER',
  VEHICLE_DETAILS: 'VEHICLE_DETAILS',
  VEHICLE_OVERVIEW: 'VEHICLE_OVERVIEW',
};

const defaultState = {
  licensePlate: '',
  make: '',
  model: '',
  image: '',
  loading_weight_limit_kg: '',
  loading_dimensions_width_cm: '',
  loading_dimensions_height_cm: '',
  loading_dimensions_length_cm: '',
  currentLayout: LAYOUT_KEYS.PLATE_NUMBER,
  errors: [],
};

class VehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  onChange(key, e) {
    let value = e.currentTarget.value;
    this.setState({
      [key]: value,
    });
  }

  onBlur(key, e) {
    this.validateSync(key, e.currentTarget.value);
  }

  validateSync(key, value) {
    try {
      VehicleSchema()
        .validateAt(key, this.state)
        .then(resp => resp.json())
        .catch(e => {
          let newErrors = this.state.errors;
          if (e.errors) {
            newErrors[key] = e.errors[0];
          } else {
            delete newErrors[key];
          }
          this.setState({
            errors: newErrors,
          });
        });
    } catch (e) {}
  }

  validateSyncAll(callback) {
    const model = this.getLayoutFields();
    for (let field in model) {
      this.validateSync(field, model);
    }
    // await for sync validation
    setTimeout(() => {
      callback();
    }, 500);
  }

  getLayoutFields() {
    if (this.state.currentLayout === LAYOUT_KEYS.PLATE_NUMBER) {
      return {
        licensePlate: this.state.licensePlate,
      };
    }
    if (this.state.currentLayout === LAYOUT_KEYS.VEHICLE_DETAILS) {
      return {
        make: this.state.make,
        model: this.state.model,
        loading_weight_limit_kg: this.state.loading_weight_limit_kg,
        image: this.state.image,
        loading_dimensions_width_cm: this.state.loading_dimensions_width_cm,
        loading_dimensions_height_cm: this.state.loading_dimensions_height_cm,
        loading_dimensions_length_cm: this.state.loading_dimensions_length_cm,
      };
    }
    return {};
  }

  uploadImage(e) {
    const file = e.currentTarget.files[0];
    const url = appUtils.createApiUrl('vehicle_images', 2);
    const formData = new FormData();
    formData.append('file', file);

    fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: {
        Accept: 'application/ld+json',
      },
    })
      .then(resp => resp.json())
      .then(resp => {
        this.setState({ image: [resp['@id']] });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    let messages = [];
    for (let field in this.state.errors) {
      const field_input = document.querySelector(`input[name=${field}]`);
      if (field_input) {
        messages[field] = this.state.errors[field];
      }
    }
    const countryCode = _get(this.props.country, 'code', 'NL');
    return (
      <>
        {_get(this.props, 'renderHeader', true) && (
          <SectionWrapper
            background={'primary-light'}
            extraClasses={'mt-gtr-neg routes-info-wrapper'}
            centerContent={true}
          >
            <Row>
              <Col xs={12}>
                <Heading size={'h5'}>
                  <Translate id={'onboarding.vehicle_details.title'} />
                </Heading>
                <p>
                  <Translate id={'onboarding.vehicle_details.intro'} />
                </p>
              </Col>
            </Row>
          </SectionWrapper>
        )}
        <div className={'main-centered-content'}>
          <form className={'onboarding-form onboarding-form-vehicles'}>
            {this.state.currentLayout === LAYOUT_KEYS.PLATE_NUMBER && (
              <>
                <div className={'input-row'}>
                  <Card extraClasses={'card--license-plate card--license-plate-' + countryCode}>
                    <div className={'card--license-plate--placeholder'}>
                      <img alt={'placeholder-van'} src={placeHolderImg} />
                    </div>
                    <p>
                      <b>
                        <Translate id={'form.fields.vehicles.plate_number.placeholder'} />
                      </b>
                    </p>
                    <div className={'license-plate--input'}>
                      <InputText
                        type={'text'}
                        value={this.state.licensePlate}
                        onChange={this.onChange.bind(this, 'licensePlate')}
                        onBlur={this.onBlur.bind(this, 'licensePlate')}
                        name={'licensePlate'}
                        errors={messages['licensePlate']}
                        label={''}
                      />
                    </div>
                  </Card>
                </div>
              </>
            )}
            {this.state.currentLayout === LAYOUT_KEYS.VEHICLE_DETAILS && (
              <>
                <div>
                  <b>
                    <Translate id={'onboarding.vehicle_details.your_van_title'} />
                  </b>
                </div>
                <p>
                  <Translate id={'onboarding.vehicle_details.your_van_sub_title'} />
                </p>
                <div className={'input-row'}>
                  <InputText
                    type={'text'}
                    value={this.state.make}
                    onChange={this.onChange.bind(this, 'make')}
                    onBlur={this.onBlur.bind(this, 'make')}
                    name={'make'}
                    errors={messages['make']}
                    label={translate('form.fields.vehicles.brand.placeholder')}
                  />
                </div>
                <div className={'input-row'}>
                  <InputText
                    type={'text'}
                    value={this.state.model}
                    onChange={this.onChange.bind(this, 'model')}
                    onBlur={this.onBlur.bind(this, 'model')}
                    name={'model'}
                    errors={messages['model']}
                    label={translate('form.fields.vehicles.model_number.placeholder')}
                  />
                </div>
                <div>
                  <b>
                    <Translate id={'onboarding.vehicle_details.max_load_capacity'} />
                  </b>
                </div>
                <div className={'input-row dimensions'}>
                  <InputText
                    type={'number'}
                    value={this.state.loading_dimensions_length_cm}
                    onChange={this.onChange.bind(this, 'loading_dimensions_length_cm')}
                    onBlur={this.onBlur.bind(this, 'loading_dimensions_length_cm')}
                    name={'loading_dimensions_length_cm'}
                    errors={messages['loading_dimensions_length_cm']}
                    label={translate('form.fields.vehicles.size_length.placeholder')}
                  />
                  <InputText
                    type={'number'}
                    value={this.state.loading_dimensions_width_cm}
                    onChange={this.onChange.bind(this, 'loading_dimensions_width_cm')}
                    onBlur={this.onBlur.bind(this, 'loading_dimensions_width_cm')}
                    name={'loading_dimensions_width_cm'}
                    errors={messages['loading_dimensions_width_cm']}
                    label={translate('form.fields.vehicles.size_width.placeholder')}
                  />
                  <InputText
                    type={'number'}
                    value={this.state.loading_dimensions_height_cm}
                    onChange={this.onChange.bind(this, 'loading_dimensions_height_cm')}
                    onBlur={this.onBlur.bind(this, 'loading_dimensions_height_cm')}
                    name={'loading_dimensions_height_cm'}
                    errors={messages['loading_dimensions_height_cm']}
                    label={translate('form.fields.vehicles.size_height.placeholder')}
                  />
                </div>
                <div>
                  <b>
                    <Translate id={'onboarding.vehicle_details.max_load_weight'} />
                  </b>
                </div>
                <div className={'input-row'}>
                  <InputText
                    type={'number'}
                    value={this.state.loading_weight_limit_kg}
                    onChange={this.onChange.bind(this, 'loading_weight_limit_kg')}
                    onBlur={this.onBlur.bind(this, 'loading_weight_limit_kg')}
                    name={'loading_weight_limit_kg'}
                    errors={messages['loading_weight_limit_kg']}
                    label={translate('form.fields.vehicles.max_load_capacity.placeholder')}
                  />
                </div>
                <div className={'input-row'}>
                  <InputFile
                    name={'van_image'}
                    onChange={e => this.uploadImage(e)}
                    label={translate('form.fields.vehicles.image.placeholder')}
                    errors={messages['image']}
                    value={this.state.image}
                  />
                </div>
              </>
            )}
            {this.state.currentLayout === LAYOUT_KEYS.VEHICLE_OVERVIEW && (
              <>
                {this.props.vehicles.map((vehicle, i) => {
                  return <VehicleCard key={i} {...vehicle} />;
                })}
                {_get(this.props, 'multipleVanAllowed', true) && (
                  <>
                    <Button
                      extraClasses={'btn-add-vehicle'}
                      buttonStyle={'outline'}
                      onClick={() => this.setState(defaultState)}
                    >
                      <Translate id={'onboarding.vehicle_details.add_vehicle'} />
                    </Button>
                    <span className={'hint'}>
                      <Translate id={'onboarding.vehicle_details.hints'} />
                    </span>
                  </>
                )}
              </>
            )}
            <div className={'form-bottom-actions'}>
              <div className={'main-centered-content'}>
                {_get(this.props, 'error', '') && this.props.error !== '' && (
                  <FlashMessage type={'error'} message={_get(this.props, 'error', '') !== '' && this.props.error} />
                )}
                <Button
                  loading={_get(this.props, 'loading', false)}
                  disabled={_get(this.props, 'disabled', false)}
                  buttonStyle={'secondary block text-left'}
                  iconPos={'right'}
                  onClick={event => this.handleSubmit(event)}
                >
                  {this.state.currentLayout === LAYOUT_KEYS.VEHICLE_OVERVIEW ? (
                    <Translate id={'form.save'} />
                  ) : (
                    <Translate id={'onboarding.step.next'} />
                  )}
                  <IconArrow />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
  handleSubmit(event) {
    event.preventDefault();
    this.validateSyncAll(() => {
      if (Object.keys(this.state.errors).length === 0) {
        switch (this.state.currentLayout) {
          case LAYOUT_KEYS.PLATE_NUMBER:
            this.setState({ currentLayout: LAYOUT_KEYS.VEHICLE_DETAILS });
            break;
          case LAYOUT_KEYS.VEHICLE_DETAILS:
            this.props.addVehicle(this.state);
            this.setState({ currentLayout: LAYOUT_KEYS.VEHICLE_OVERVIEW });
            break;
          case LAYOUT_KEYS.VEHICLE_OVERVIEW:
            this.props.handleNavigation();
            break;
          default:
            this.props.handleNavigation();
        }
      } else {
        // render general error or scroll to the field in mobile
      }
    });
  }
}

export default VehicleDetails;
