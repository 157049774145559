import React, { Component } from 'react';

import './css/CityName.css';

class CityName extends Component {
  render() {
    return (
      <div className="city-name">
        <h5>{this.props.children}</h5>
      </div>
    );
  }
}

export default CityName;
