import React from 'react';
import IconStar from './icons/IconStar';
import IconCheckmark from './icons/IconCheckmark';

/*
 * Renderstars for reviewscore (1-5)
 */
export const renderScoreAsStars = (reviewScore, label) => {
  if (reviewScore) {
    const fullStars = Array(reviewScore)
      .fill('')
      .map((el, i) => <IconStar key={label + '_' + i} full={true} />);

    const emptyStars = Array(5 - reviewScore)
      .fill('')
      .map((el, i) => <IconStar key={label + '_empty_' + i} full={false} />);

    return fullStars.concat(emptyStars);
  }
};

/*
 * Render checkmark based on boolean
 */
export const renderCheckmark = status => {
  if (status) {
    return (
      <div className={'stat-done'}>
        <IconCheckmark />
      </div>
    );
  }
  // A filler / empty div for now
  if (!status) {
    return <div className={'stat-not-done'}>&nbsp;</div>;
  }
};
