import React from 'react';
import moment from 'moment';
import { Translate } from 'react-localize-redux';

/**
 *
 * @param {object} momentObject
 * @returns {string}
 */
export const formatTimestamp = momentObject => {
  if (momentObject.isSame(moment(), 'days')) {
    return momentObject.fromNow();
  }
  if (momentObject.isSame(moment().subtract(1, 'days'), 'days')) {
    return <Translate id={'datetime.yesterday'} />;
  }
  return momentObject.format('DD-MM-YYYY');
};
/**
 *
 * @param {object} conversation
 * @returns {boolean}
 */
export const conversationHasNewMessages = conversation => {
  // never read
  /*if (conversation.meta.conversation.messages.last_read_timestamp === null) {
    return true;
  }
  // did read but timestamp of last message is later
  else if (
    parseInt(conversation.meta.conversation.messages.last_timestamp, 10) >
    parseInt(conversation.meta.conversation.messages.last_read_timestamp, 10)
  ) {
    return true;
  }*/
  // default
  return false;
};

/**
 *
 * @param html
 * @returns {{__html: *}}
 */
export const createMarkup = html => {
  return { __html: html };
};

/**
 *
 * @param message
 * @returns {*|string[]}
 */
export const cleanMessage = message => {
  return message
    .replace(/&quot;/g, '&')
    .replace(/<p>/g, '\n')
    .replace(/<\/p>/g, '\n')
    .replace(/<br\/>/g, '\n')
    .replace(/<br \/>/g, '\n')
    .replace(/<strong>/g, '')
    .replace(/<\/strong>/g, '')
    .replace(/\n+/g, '\n')
    .replace(/\n$/g, '')
    .replace(/^\n/g, '')
    .split('\n');
};

/**
 *
 * @param user
 * @returns {string}
 */
export const displayName = user => {
  return (
    (user.hasOwnProperty('first_name') ? user.first_name + ' ' : '') +
    (user.hasOwnProperty('last_name') ? user.last_name + ' ' : '')
  );
};
