import { call, put, takeEvery } from 'redux-saga/effects';
import { types as actionTypes } from '../reducers/reviews';
import { fetchReviews } from '../actions/reviews';
import { actions as reviewActions } from '../actions/reviews';

export function* reviewSagas() {
  yield takeEvery(actionTypes.FETCH_REVIEWS_START, fetchReviewsSaga);
}

export function* fetchReviewsSaga(args) {
  try {
    const reviews = yield call(fetchReviews, args.page);
    yield put(reviewActions.getReviewsSuccess(reviews));
  } catch (error) {
    yield put(reviewActions.getReviewsFailed(error));
  }
}
