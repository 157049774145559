import { connect } from 'react-redux';
import LoginForm from '../../forms/LoginForm';
import { actions } from '../../reducers/user';

const mapStateToProps = state => ({
  redirectUrl: state.user.login.redirectUrl,
  isSubmitting: state.user.login.isSubmitting,
  errors: state.user.login.errors,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: credentials => dispatch(actions.loginUser(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
