import React, { Component } from 'react';
import './css/Card.css';
import './css/Conversation.css';
import './css/Form.css';
import './css/Tabs.css';
import VisibleMessages from './containers/inbox/VisibleMessages';

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messagesByOffer: [],
    };
  }

  componentDidMount() {
    this.props.fetchMessages(this.props.uuid);
    if (window.location.search.indexOf('makeOffer') > -1) {
      const singleView = document.querySelector('.single-view-wrapper');
      window.scrollTo(0, singleView.offsetHeight);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.uuid !== prevProps.uuid) {
      this.props.fetchMessages(this.props.uuid);
    }
  }

  render() {
    return (
      <div className={'conversation hide-print'}>
        {this.props.messagesByOffer[this.props.uuid] && (
          <VisibleMessages
            messages={this.props.messagesByOffer[this.props.uuid]}
            offeruuid={this.props.uuid}
            hasNew={true}
            updateConversations={false}
            selectedConversationId={this.props.uuid}
          />
        )}
      </div>
    );
  }
}

export default Conversation;
