import React, { Component } from 'react';
import '../../css/Maps.css';
import GoogleMap from 'google-map-react';
import IconMaps from '../../icons/IconMaps';
import { translate } from '../../UtilsTranslation';

function createMapOptions(maps) {
  return {
    disableDefaultUI: true,
    styles: styledMapType,
  };
}

export class MagicMap extends Component {
  renderRoute(map, maps) {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();

    let _waypoints = [];
    for (let stop of this.props.stops) {
      _waypoints.push({
        location: `${stop.locality}, ${stop.administrative_area}, ${stop.country_code}`,
        stopover: true,
      });
    }

    const mapRequest = {
      destination: this.props.startLocation,
      origin: this.props.startLocation,
      travelMode: 'DRIVING',
      waypoints: _waypoints,
      // optimizeWaypoints: true,
    };

    directionsService.route(mapRequest, function(response, status) {
      if (status === 'OK') {
        // self.props.updateReport(response);
        directionsDisplay.setMap(map);
        directionsDisplay.setDirections(response);
      }
    });
  }
  getZoomCenter() {
    return { center: { lat: 52.55498, lng: 5.91721 }, zoom: 11 }; // default return, should be locale based
  }

  render() {
    const { center, zoom } = this.getZoomCenter();
    let readyRenderingMap = this.props.directionsReady;

    return (
      <div className={'visible-magic-map'}>
        <div className={'dynamic-maps-wrap'}>
          <div id={'map'} className={'render-maps'}>
            {readyRenderingMap ? (
              <GoogleMap
                bootstrapURLKeys={{ key: 'AIzaSyBwVhBOJxllD3rGUO0lLB6epMwKA6rXrDQ', language: '' }}
                center={center}
                zoom={zoom}
                options={createMapOptions}
                margin={[30, 30, 30, 30]}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => this.renderRoute(map, maps)}
              />
            ) : (
              <div className={'loader'}>
                <IconMaps spinning={true} />
                <span>{translate('route_planning.maps.loading')}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MagicMap;

const styledMapType = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#aad2e3',
      },
      {
        visibility: 'on',
      },
    ],
  },
];
