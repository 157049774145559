import React, { Component } from 'react';
import { Col } from '../../Grid';
import _get from 'lodash/get';
import { getProvinceAbbr } from '../../UtilsAddress';

class BundledItemAddress extends Component {
  render() {
    return (
      <>
        <Col xs="6">
          <div className="city-name">
            <span className="short">
              {`${this.getBundledStopLocality(this.props.transport, this.props.type)}, `}
              {this.getBundledStopArea(this.props.transport, this.props.type)}
            </span>
          </div>
        </Col>
      </>
    );
  }
  getBundledStopLocality(transport, type) {
    if (!transport.stops) {
      return '';
    }
    const index = type === 'pickup' ? 0 : _get(transport, 'stops', []).length - 1;
    return _get(transport, `stops[${index}].${type}.address.locality`, 'Unknown');
  }
  getBundledStopArea(transport, type) {
    if (!transport.stops) {
      return '';
    }
    const index = type === 'pickup' ? 0 : _get(transport, 'stops', []).length - 1;
    return getProvinceAbbr(_get(transport, `stops[${index}].${type}.address.administrative_area`, ''));
  }
}

export default BundledItemAddress;
