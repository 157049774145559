import * as yup from 'yup';
import { getTranslate } from 'react-localize-redux';
import { store } from '../../../index';

const SUFFIX = 'profile_page.validations';
const _t = key => getTranslate(store.getState().localize)(`${SUFFIX}.${key}`);

export const Schema = () => {
  return yup.object().shape({
    name: yup.string().required(_t('name')),
    about_us: yup.string(),
    first_name: yup.string().required(_t('first_name')),
    last_name: yup.string().required(_t('last_name')),
    email: yup
      .string()
      .email()
      .required(_t('email')),
    phone: yup.number().required(_t('phone')),
    line1: yup.string().required(_t('line1')),
    locality: yup.string().required(_t('locality')),
    postal_code: yup.string().required(_t('postal_code')),
    country: yup.string().required(_t('country')),
    coc_number: yup.number().required(_t('coc_number')),
    vat_number: yup.string().required(_t('vat_number')),
    IBAN: yup.string().required(_t('iban')),
    current_password: yup.string().min(8, _t('password_min')),
    new_password: yup.string().min(8, _t('new_password_min')),
  });
};
