import React from 'react';
import '../../css/Card-review.css';
import { missingImageSrc, anonymousTranslateKey } from '../../globals';
import { renderScoreAsStars } from '../../UtilsReviews';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import * as utilMessages from '../../UtilsMessages';

/**
 *
 * @returns {*}
 * @constructor
 */
export const CardReview = props => {
  return (
    <div className={'card card-review'}>
      <div className={'image-wrap'}>
        <div className={'image-position'}>
          <img
            alt={props.item.author_full_name || 'Anonymous'}
            src={props.item.author_profile_image ? props.item.author_profile_image : missingImageSrc}
          />
        </div>
      </div>
      <div className={'content-wrap'}>
        <div className={'card-review-meta'}>
          <div className={'card-review-author'}>
            {!props.item.author_full_name ? <Translate id={anonymousTranslateKey} /> : props.item.author_full_name}
          </div>
          <span className={'time'}>{utilMessages.formatTimestamp(moment(props.item.created_at))}</span>
        </div>
        <div>{props.item.body}</div>
        <div className={'card-review-scores'}>
          {typeof props.item.friendliness === 'number' && (
            <p>
              <span>
                <Translate id={'profile_page.review.friendliness'} />:
              </span>
              <span>{renderScoreAsStars(props.item.friendliness, 'friendliness')}</span>
            </p>
          )}
          {typeof props.item.communication === 'number' && (
            <p>
              <span>
                <Translate id={'profile_page.review.communication'} />:
              </span>
              <span>{renderScoreAsStars(props.item.communication, 'communication')}</span>
            </p>
          )}
          {typeof props.item.punctuality === 'number' && (
            <p>
              <span>
                <Translate id={'profile_page.review.punctuality'} />:
              </span>
              <span>{renderScoreAsStars(props.item.punctuality, 'punctuality')}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
