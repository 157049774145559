import React, { Component } from 'react';
import '../../css/Maps.css';
import LocationMarker from '../maps/LocationMarker';
import GoogleMap from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import IconTransportType3 from '../../icons/IconTransportType3';

function createMapOptions(maps) {
  return {
    disableDefaultUI: true,
    styles: styledMapType,
  };
}
export class RouteMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      maps: null,
    };
    this.renderedPolylines = [];
  }

  renderPolylines() {
    for (let polyline of this.renderedPolylines) {
      polyline.setMap(null);
    }
    if (this.state.map) {
      let keys = Object.keys(this.props.routePolylines);
      let colors = [
        '#0073fa',
        '#c0392b',
        '#27ae60',
        '#8e44ad',
        '#f39c12',
        '#d35400',
        'black',
        'pink',
        'brown',
        'lime',
        'yellow',
        'orange',
        'purple',
        'red',
        'blue',
        'green',
        'gray',
        '#0073fa',
        '#c0392b',
        '#d35400',
      ];
      for (let uuid in this.props.routePolylines) {
        const polyline = this.props.routePolylines[uuid];

        if (typeof polyline === 'undefined') {
          return null;
        }
        const coords = polyline.map(coords => {
          return { lat: coords.lat, lng: coords.lon };
        });
        let routePolyline = new this.state.maps.Polyline({
          path: coords,
          geodesic: true,
          strokeColor: colors[keys.indexOf(uuid) || 0],
          strokeOpacity: 1.0,
          strokeWeight: 4,
          zIndex: 10,
        });
        routePolyline.setMap(this.state.map);
        this.renderedPolylines.push(routePolyline);
      }
    }
  }
  getZoomCenter() {
    const defaultZoom = { center: { lat: 52.55498, lng: 5.91721 }, zoom: 8 };
    let sw_pos = defaultZoom.center; // south west position
    let ne_pos = defaultZoom.center; // north east position
    for (let uuid in this.props.routePolylines) {
      let routePolyline = this.props.routePolylines[uuid];
      if (typeof routePolyline !== 'undefined' && routePolyline.length > 0) {
        const pickup = routePolyline[0];
        const delivery = routePolyline.slice(-1)[0];
        sw_pos = {
          lat: Math.max.apply(null, [pickup.lat, delivery.lat, sw_pos.lat]),
          lng: Math.min.apply(null, [pickup.lon, delivery.lon, sw_pos.lng]),
        };
        ne_pos = {
          lat: Math.min.apply(null, [pickup.lat, delivery.lat, ne_pos.lat]),
          lng: Math.max.apply(null, [pickup.lon, delivery.lon, sw_pos.lng]),
        };
      }
    }
    // Set default to smallest screen size
    const size = {
      width: 500, // Map width in pixels
      height: 190, // Map height in pixels
    };
    return fitBounds({ ne: ne_pos, sw: sw_pos }, size);
  }

  handleGoogleApiLoaded(map, maps) {
    this.setState({
      map: map,
      maps: maps,
    });
  }
  render() {
    const { center, zoom } = this.getZoomCenter();
    let loading = this.props.readyToDraw;
    let readyToDraw = this.props.readyToDraw === true;
    let generatedMarkers = [];
    let keys = Object.keys(this.props.routePolylines);

    if (readyToDraw) {
      this.renderPolylines();
      for (let uuid in this.props.routePolylines) {
        let routePolyline = this.props.routePolylines[uuid];
        if (typeof routePolyline !== 'undefined' && routePolyline.length > 0) {
          const pickup = routePolyline[0];
          const delivery = routePolyline.slice(-1)[0];

          generatedMarkers.push(
            <LocationMarker
              key={`pl${keys.indexOf(uuid) + 1}`}
              direction={'pickup'}
              textLabel={keys.indexOf(uuid) + 1}
              index={keys.indexOf(uuid)}
              lat={pickup.lat}
              lng={pickup.lon}
            />
          );
          generatedMarkers.push(
            <LocationMarker
              key={`pn${keys.indexOf(uuid) + 2}`}
              direction={'delivery'}
              index={keys.indexOf(uuid)}
              textLabel={keys.indexOf(uuid) + 1}
              lat={delivery.lat}
              lng={delivery.lon}
            />
          );
        }
      }
    }

    return (
      <div className={'visible-overall-map'}>
        <div className={'full-height dynamic-maps-wrap'}>
          <div id={'map'} className={'render-maps'}>
            <span className={'loader'}>
              {/*<Translate id={'location.loading_maps'} />*/}
              <IconTransportType3 spinning={loading} />
              Feed me some fresh transport jobs
            </span>
            {readyToDraw && (
              <GoogleMap
                bootstrapURLKeys={{ key: 'AIzaSyBwVhBOJxllD3rGUO0lLB6epMwKA6rXrDQ', language: '' }}
                center={center}
                zoom={zoom + 2}
                options={createMapOptions}
                margin={[20, 20, 20, 20]}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => this.handleGoogleApiLoaded(map, maps)}
              >
                {generatedMarkers}
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RouteMap;

const styledMapType = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#aad2e3',
      },
      {
        visibility: 'on',
      },
    ],
  },
];
