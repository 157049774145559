import { types as offerTypes } from './offers';
import _get from 'lodash/get';

const defaultState = {
  isSubmitting: false,
  isFetching: false,
  errors: [],
  selectedTab: 'composeMessage',
  messagesByOffer: [],
  selectedChatSession: null,
  selectedConversationId: null,
  isPatching: false,
  messagesMarkedRead: [],
};

export const types = {
  FETCH_MESSAGES_START: 'FETCH_MESSAGES_START',
  FETCH_LIST_MESSAGES_START: 'FETCH_LIST_MESSAGES_START',
  SELECT_TAB: 'SELECT_TAB',
  PUT_SELECTED_MESSAGES: 'PUT_SELECTED_MESSAGES',
  SET_DRIVER_MESSAGES: 'SET_DRIVER_MESSAGES',
  FETCH_MESSAGES_SUCCESS: 'FETCH_MESSAGES_SUCCESS',
  FETCH_LIST_MESSAGES_STOP: 'FETCH_LIST_MESSAGES_STOP',
  FETCH_MESSAGES_ERROR: 'FETCH_MESSAGES_ERROR',
  MARK_MESSAGE_READ_START: 'MARK_MESSAGE_READ_START',
  MARK_MESSAGE_READ_SUCCESS: 'MARK_MESSAGE_READ_SUCCESS',
  TOGGLE_COMMENT_SUBMIT: 'TOGGLE_COMMENT_SUBMIT',
  SELECT_CONVERSATION: 'SELECT_CONVERSATION',
  DESELECT_CONVERSATION: 'DESELECT_CONVERSATION',
  OPEN_CHAT_SESSION: 'OPEN_CHAT_SESSION',
  UPDATE_CHAT_SESSION: 'UPDATE_CHAT_SESSION',
};

export const getSelectedTab = state => {
  return state.conversations.selectedTab;
};
export const getMessagesByOffer = state => {
  return state.conversations.messagesByOffer;
};
export const getSelectedConversationId = state => {
  return state.conversations.selectedConversationId;
};
export const getSelectedConversationMessages = state => {
  return _get(state.conversation, 'selectedChatSession', null);
};
export const getConversations = state => {
  return state.conversations.messagesByOffer[state.conversations.selectedConversationId];
};
export const getSelectedTransportJob = state => {
  return _get(state.conversations.messagesByOffer[state.conversations.selectedConversationId], 'job', null);
};

const conversations = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_MESSAGES_START:
      return {
        ...state,
        isFetching: true,
        isSubmitting: false,
      };
    case types.FETCH_LIST_MESSAGES_START:
      return {
        ...state,
        isFetching: true,
        isSubmitting: false,
      };
    case types.UPDATE_CHAT_SESSION:
      return {
        ...state,
        isFetching: false,
        isSubmitting: false,
      };
    case types.FETCH_LIST_MESSAGES_STOP:
      return {
        ...state,
        isFetching: false,
        isSubmitting: false,
      };
    case types.SELECT_TAB:
      return {
        ...state,
        selectedTab: action.tab,
      };
    case offerTypes.SELECT_OFFER:
      return {
        ...state,
        selectedTab: 'composeMessage',
      };
    case types.PUT_SELECTED_MESSAGES:
      return {
        ...state,
        messagesByOffer: {
          ...state.messagesByOffer,
          [action.payload.id]: {
            ...state.messagesByOffer[action.payload.id],
            items: action.payload.messages,
          },
        },
        selectedChatSession: action.payload.messages,
        isSubmitting: false,
        isPatching: false,
        isFetching: false,
      };
    case offerTypes.PUT_SELECTED_MESSAGES:
      return {
        ...state,
        messagesByOffer: {
          ...state.messagesByOffer,
          [action.payload.id]: {
            ...state.messagesByOffer[action.payload.id],
            items: action.payload.messages,
          },
        },
        selectedChatSession: action.payload.messages,
        isSubmitting: false,
        isPatching: false,
        isFetching: false,
      };
    case types.FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesByOffer: {
          ...state.messagesByOffer,
          [action.uuid]: action.payload,
        },
        messagesMarkedRead: state.messagesMarkedRead.filter(conversationId => conversationId !== action.id),
        isFetching: false,
        isSubmitting: false,
      };
    case types.FETCH_MESSAGES_ERROR:
      //@todo: handle error
      return {
        ...state,
        errors: state.errors.concat(action.error),
        isFetching: false,
        isSubmitting: false,
      };
    case types.MARK_MESSAGE_READ_START:
      return {
        ...state,
        isPatching: true,
      };
    case types.SET_DRIVER_MESSAGES:
      let messagesByDate = [];
      const messages = {
        ...state.messagesByOffer,
        [action.messages.id['@id']]: action.messages,
      };
      const sortedKeys = Object.keys(messages).sort(
        (a, b) => new Date(messages[b].job.created_at).getTime() - new Date(messages[a].job.created_at).getTime()
      );
      sortedKeys.map(key => (messagesByDate[key] = messages[key]));
      return {
        ...state,
        messagesByOffer: messagesByDate,
        isPatching: false,
        isSubmitting: false,
        isFetching: false,
      };
    case types.MARK_MESSAGE_READ_SUCCESS:
      return {
        ...state,
        messagesMarkedRead: [...state.messagesMarkedRead, action.id],
        isPatching: false,
      };
    case types.TOGGLE_COMMENT_SUBMIT:
      return {
        ...state,
        isSubmitting: !state.isSubmitting,
      };
    case types.SELECT_CONVERSATION:
      if (state.selectedConversationId !== action.id) {
        return {
          ...state,
          selectedConversationId: action.id,
          isFetching: true,
        };
      } else {
        return state;
      }
    case types.DESELECT_CONVERSATION:
      return {
        ...state,
        selectedConversationId: '',
        selectedChatSession: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default conversations;
