import React, { Component } from 'react';
import VisibleSidebar from './containers/preview/VisibleSidebar';
import VisibleTransportRequest from './containers/preview/VisibleTransportRequest';

class PreviewApp extends Component {
  componentDidMount() {
    document.querySelector('body').classList.add('bd-hide-menu');
  }
  componentWillUnmount() {
    document.querySelector('body').classList.remove('bd-hide-menu');
  }

  render() {
    return (
      <div className={'main-with-sidebar'}>
        <VisibleSidebar />
        <VisibleTransportRequest />
      </div>
    );
  }
}

export default PreviewApp;
