import React from 'react';
import '../css/IconStyle.css';

const IconCheckmark = () => {
  return (
    <svg className={'IconCheckmark'} width="11" height="9" viewBox="0 0 11 9">
      <path d="M1 5.438L4.115 8 10 1" />
    </svg>
  );
};

export default IconCheckmark;
