import React from 'react';
import '../css/Progress-bar.css';

export const ProgressBar = props => {
  return (
    <div className={'progress-bar'}>
      <div
        style={{ '--progress-position': 100 - (props.currentSection / (props.totalSections + 1)) * 100 + '%' }}
        className={'progress-bar-indicator progress-current-' + props.currentSection}
      />
    </div>
  );
};
