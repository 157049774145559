import React, { Component } from 'react';
import { TJAL_STATUS } from '../../globals';
import CardAuthor from '../../CardAuthor';
import VisibleConversation from '../../containers/planning/VisibleConversation';
import { Translate } from 'react-localize-redux';

class Driver extends Component {
  render() {
    return (
      <>
        {this.props.isOffer &&
          !this.props.isClaimedByOtherDriver &&
          this.props.status !== TJAL_STATUS.DELIVERED &&
          window.location.href.indexOf('delivered') < 0 &&
          ((<CardAuthor key={1} name={this.props.customerName} profilepic={this.props.customerImage} />),
          !this.props.bundled ? (
            <VisibleConversation key={2} />
          ) : (
            <p className={'deactive-chat-message'}>
              <Translate id={'offer.no_chat'} />
            </p>
          ))}
      </>
    );
  }
}

export default Driver;
