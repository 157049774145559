import React from 'react';
import '../css/IconStyle.css';

const IconElevator = () => {
  return (
    <svg height="484pt" viewBox="-19 0 484 484.00329" width="484pt" xmlns="http://www.w3.org/2000/svg">
      <path d="m399.445312 0h-352.871093c-12.382813-.0390625-24.269531 4.867188-33.015625 13.636719-8.742188 8.765625-13.621094 20.664062-13.5507815 33.046875v430.632812c-.0859375 1.777344.5742185 3.511719 1.8203125 4.777344 1.246094 1.269531 2.964844 1.960938 4.746094 1.90625h432.871093c1.777344.054688 3.5-.636719 4.746094-1.90625 1.246094-1.265625 1.90625-3 1.816406-4.777344v-430.632812c.074219-12.382813-4.804687-24.28125-13.550781-33.046875-8.742187-8.769531-20.628906-13.6757815-33.011719-13.636719zm-301.4375 470v-389h118v389zm132 0v-389h118v389zm202 0h-70v-396.0625c.074219-1.8125-.597656-3.578125-1.855468-4.890625-1.257813-1.308594-2.996094-2.046875-4.8125-2.046875h-264.664063c-1.816406 0-3.550781.738281-4.8125 2.046875-1.257812 1.3125-1.925781 3.078125-1.855469 4.890625v396.0625h-70v-423.316406c-.074218-8.671875 3.328126-17.007813 9.449219-23.152344 6.117188-6.140625 14.445313-9.574219 23.117188-9.53125h352.871093c8.671876-.042969 16.996094 3.390625 23.117188 9.53125 6.121094 6.144531 9.523438 14.480469 9.445312 23.152344zm0 0" />
      <path d="m401.960938 141.945312c-2.734376-2.734374-7.167969-2.734374-9.902344 0l-16.917969 16.921876c-2.734375 2.730468-2.734375 7.164062 0 9.898437s7.164063 2.734375 9.898437 0l4.972657-4.96875v28.203125c0 3.867188 3.132812 7 7 7 3.863281 0 7-3.132812 7-7v-28.207031l4.96875 4.972656c2.734375 2.734375 7.164062 2.734375 9.898437 0s2.734375-7.167969 0-9.902344zm0 0" />
      <path d="m408.980469 250.449219-4.972657 4.96875v-28.417969c0-3.867188-3.132812-7-7-7-3.863281 0-7 3.132812-7 7v28.417969l-4.96875-4.96875c-2.734374-2.734375-7.164062-2.734375-9.898437 0s-2.734375 7.164062 0 9.898437l16.917969 16.917969c2.734375 2.734375 7.164062 2.734375 9.898437 0l16.921875-16.917969c2.730469-2.734375 2.730469-7.164062 0-9.898437-2.734375-2.734375-7.167968-2.734375-9.902344 0zm0 0" />
    </svg>
  );
};

export default IconElevator;
