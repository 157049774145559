import React from 'react';

/**
 *
 * @param {Int} xs
 * @param {Int} sm
 * @param {Int} md
 * @param {Int} lg
 * @param {Int} xl
 * @param {String} extraClasses
 * @param {*} children
 * @returns {*}
 * @constructor
 */
const Col = ({ xs = 0, sm = 0, md = 0, lg = 0, xl = 0, extraClasses = '', children }) => {
  let gridClasses = ['col'];
  if (xs > 0) {
    gridClasses.push('col-' + xs);
  }
  if (sm > 0) {
    gridClasses.push('col-sm-' + sm);
  }
  if (md > 0) {
    gridClasses.push('col-md-' + md);
  }
  if (lg > 0) {
    gridClasses.push(' col-lg-' + lg);
  }
  if (xl > 0) {
    gridClasses.push(' col-xl-' + xl);
  }
  if (extraClasses.length > 0) {
    gridClasses.push(extraClasses);
  }
  return <div className={gridClasses.join(' ')}>{children}</div>;
};

/**
 *
 * @param {Boolean} fluid
 * @param children
 * @returns {*}
 * @constructor
 */
const Container = ({ fluid = false, children }) => {
  return <div className={fluid ? 'container-fluid' : 'container'}>{children}</div>;
};

/**
 *
 * @param {string} extraClasses
 * @param children
 * @returns {*}
 * @constructor
 */
const Row = ({ extraClasses, children }) => {
  return <div className={'row ' + (extraClasses ? extraClasses : '')}>{children}</div>;
};

export { Col, Container, Row };
