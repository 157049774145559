import React, { Component } from 'react';
import './css/Inbox.css';

import VisibleSidebar from './containers/inbox/VisibleSidebar';
import VisibleConversation from './containers/inbox/VisibleConversation';
import { connect } from 'react-redux';

class InboxApp extends Component {
  render() {
    return (
      <div className={'main-with-sidebar'}>
        <VisibleSidebar />
        <VisibleConversation />
      </div>
    );
  }
}

export default connect()(InboxApp);
