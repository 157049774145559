import { closeSingleView, removeOffer } from './index';
import { types as conversationsTypes } from '../../reducers/conversations';
import { types as offerTypes } from '../../reducers/offers';

export const setSelectedTab = tab => ({ type: conversationsTypes.SELECT_TAB, tab });
export const postGeoLocationForEta = stop_id => ({ type: offerTypes.POST_GEOLOCATION_FOR_ETA, stop_id });

export const removeFromSidebar = (id, domainName) => {
  return dispatch => {
    dispatch(removeOffer(id));
    dispatch(closeSingleView(domainName));
  };
};
