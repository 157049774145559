import { connect } from 'react-redux';
import SearchDetail from '../../components/search/SearchDetail';
import { closeSingleView, clearErrors } from '../../actions/search';
import { getUserDetails } from '../../reducers/user';
import {
  getSelectedTransportJob,
  getItemImages,
  isAllowedToMakeOffer,
  isBundled,
  isValidTransport,
  isPaid,
  isProgressLevelRequired,
  getItemCardElement,
  hasOwnOffer,
  getBundledStops,
  getRouteDistance,
  getCurrentStatus,
  isClaimedByOtherDriver,
} from '../../reducers/search';
import { getCountries } from '../../reducers/countries';

const mapStateToProps = state => {
  return {
    singleTransportJob: getSelectedTransportJob(state),
    isFetching: state.search.isFetching,
    user: getUserDetails(state),
    isAllowedToMakeOffer: isAllowedToMakeOffer(state),
    itemImages: getItemImages(state),
    bundled: isBundled(state),
    isAvailable: isValidTransport(state),
    progressLevelRequired: isProgressLevelRequired(state),
    itemCardElement: getItemCardElement(state),
    hasOffer: hasOwnOffer(state),
    status: getCurrentStatus(state),
    distance: getRouteDistance(state),
    claimedByOtherDriver: isClaimedByOtherDriver(state),
    isPaid: isPaid(state),
    errors: state.search.errors,
    isSubmitting: state.search.isSubmitting,
    countries: getCountries(state),
    isBundledDelivered: false,
    stops: getBundledStops(state),
  };
};

const mapDispatchToProps = dispatch => ({
  closeTransportJob: () => {
    dispatch(closeSingleView('search'));
  },
  clearErrors: () => {
    dispatch(clearErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDetail);
