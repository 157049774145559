import { connect } from 'react-redux';
import ConversationList from '../../components/inbox/ConversationList';
import {
  fetchListMessagesStart,
  selectConversation,
  deselectConversation,
  onConversationClick,
} from '../../actions/conversations';
import * as appUtils from '../../Utils';

const mapStateToProps = state => ({
  conversations: state.conversations.messagesByOffer,
  selectedChatSession: state.conversations.selectedChatSession,
  selectedConversationId: state.conversations.selectedConversationId,
  isFetching: state.conversations.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchConversations: () => dispatch(fetchListMessagesStart()),
  onConversationClick: id => dispatch(onConversationClick(id)),
  selectConversation: id => {
    dispatch(selectConversation(id));
    appUtils.activateSingleView();
  },
  deselectConversation: () => {
    dispatch(deselectConversation());
    appUtils.deActivateSingleView();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);
