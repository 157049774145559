import * as appUtils from '../../Utils';
import history from '../../History';
import { types as searchTypes } from '../../reducers/search';

const SITEPREFIX = appUtils.getPathForApp();

export const selectTransportJob = id => ({ type: searchTypes.SELECT_TRANSPORT_REQUEST, id });
export const deselectTransportJob = () => ({ type: searchTypes.DESELECT_TRANSPORT_REQUEST });

export const onTransportJobClick = (uuid, renderType) => {
  appUtils.activateSingleView();
  return dispatch => {
    dispatch(selectTransportJob(uuid));
    let link = SITEPREFIX + `/${renderType}/` + uuid;
    history.push(link);
  };
};

export const closeSingleView = () => {
  appUtils.deActivateSingleView();
  return dispatch => {
    dispatch(deselectTransportJob());
    let link = SITEPREFIX + '/preview/';
    history.push(link);
  };
};
