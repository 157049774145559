import axios from 'axios';
import _get from 'lodash/get';
import { apiBaseUri } from './Utils';

export const _CONFIGS = {
  ACCEPT_HEADER: 'application/ld+json',
  CONTENT_TYPE_HEADER: 'application/ld+json',
};

export const http = options => {
  const version = _get(options, 'version', 2);
  const headers = _get(options, 'headers', false);

  axios.defaults.withCredentials = true;

  return axios.create({
    baseURL: apiBaseUri(version),
    headers: {
      Accept: headers ? headers.accept : _CONFIGS.ACCEPT_HEADER,
      'Content-Type': headers ? headers.contentType : _CONFIGS.CONTENT_TYPE_HEADER,
    },
  });
};

export default http;
