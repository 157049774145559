import React, { Component } from 'react';
import * as appUtils from '../../Utils';
import history from '../../History';
import IconCross from '../../icons/IconCross';
import CardAuthor from '../../CardAuthor';
import BrengerLogo from '../../icons/BrengerLogo';
import { setDocumentTitle } from '../../Utils';
import { missingImageSrc } from '../../globals';
import CommentForm from '../../forms/CommentForm';
import moment from 'moment';
import PreviewItemCard from './PreviewItemCard';
import VisibleMessages from '../../containers/inbox/VisibleMessages';
import { Translate, withLocalize } from 'react-localize-redux';
import { Col, Row } from '../../Grid.js';
import _get from 'lodash/get';
import { TJAL_STATUS } from '../../globals';
import IconInfo from '../../icons/IconInfo';

const SITEPREFIX = appUtils.getPathForApp();

class ConversationDetail extends Component {
  render() {
    const offer = _get(this.props.conversations, 'job', null);
    const transport = _get(this.props.conversations, 'transport', null);
    const customer = _get(transport, 'pickups[0].contact', false);
    const itemSets = _get(this.props.conversations, 'items[0].item_set', null);
    const isBundled = _get(transport, 'bundled', false);
    const uuid = this.props.selectedConversationId;
    setDocumentTitle(
      this.props.selectedConversationId !== '' && offer !== null && customer
        ? customer.first_name + ' - ' + this.props.translate('inbox.title')
        : this.props.translate('inbox.title')
    );
    return (
      <main className={'single-view' + (uuid !== '' && uuid !== '/transport_job_account_links/' ? ' is-active' : '')}>
        {this.props.selectedConversationId !== '' && offer !== null && customer !== null && (
          <div className={'single-view-inner'}>
            {!isBundled ? (
              <>
                <div className={'inbox-header'}>
                  <Row>
                    <Col xs={'12'} lg={'4'}>
                      <CardAuthor name={customer.first_name + ' ' + customer.last_name} profilepic={missingImageSrc} />
                      <span className={'close-conversation'} onClick={() => this.props.closeConversation()}>
                        <IconCross />
                      </span>
                    </Col>
                    <Col xs={'12'} lg={'8'}>
                      {offer !== undefined &&
                        offer.status !== TJAL_STATUS.REJECTED &&
                        offer.status !== TJAL_STATUS.REVOKED_BY_CUSTOMER_CARE &&
                        offer.status !== TJAL_STATUS.REVOKED_BY_DRIVER && (
                          <div className={'transport-request-teaser'}>
                            <Row>
                              {
                                <PreviewItemCard
                                  item={transport}
                                  onItemClick={this.handleTeaserClick.bind(this)}
                                  isSelected={false}
                                  isOpen={false}
                                  date={moment()}
                                  renderType={'inbox'}
                                />
                              }
                            </Row>
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
                <Col xs={12}>
                  <h5>#{_get(this.props.conversations, 'transport.short_id', '')}</h5>
                  <br />
                  {this.props.selectedConversationId !== null && (
                    <VisibleMessages
                      messages={this.props.conversations.items}
                      offeruuid={this.props.selectedConversationId}
                      selectedConversationId={this.props.selectedConversationId}
                      hasNew={false}
                      updateConversations={true}
                    />
                  )}
                  <CommentForm
                    job={this.props.job}
                    account={this.props.account}
                    itemSets={itemSets}
                    uuid={this.props.selectedConversationId}
                    updateInbox={true}
                  />
                </Col>
              </>
            ) : (
              <div className={'deactive-chat-message'}>
                <IconInfo />
                <span>
                  <Translate id={'offer.no_chat'} />
                </span>
              </div>
            )}
          </div>
        )}
        <div className="not-active-view">
          <BrengerLogo />
          <p>
            <Translate id="inbox.detail.welcome" />
          </p>
        </div>
      </main>
    );
  }
  handleTeaserClick() {
    const id = this.props.selectedConversationId.replace('/transport_job_account_links/', '');
    const link = SITEPREFIX + '/planning/' + id;
    history.push(link);
  }
}

export default withLocalize(ConversationDetail);
