import React, { Component } from 'react';
import StatusLabel from '../../StatusLabel';
import { getSpecialIconsArray } from '../../Utils';
import RenderRequestSpecIcon from '../../icons/RenderRequestSpecIcon';

class BundledItemSpecs extends Component {
  render() {
    const icons = this.getSpecialIcons(this.props.transport);
    return (
      <>
        <div className={'request-details'}>
          <div className="column-1">
            <StatusLabel status={'bundled'} />
            <div className={'transport_icons'}>{icons}</div>
            {this.props.isBusiness && <StatusLabel status={'business'} />}
            {this.props.isPrepaid && <StatusLabel status={'paid_in_advance'} />}
          </div>
        </div>
      </>
    );
  }
  getSpecialIcons(transport) {
    const renderIcons = [];
    const icons = getSpecialIconsArray(transport);
    for (let i in icons) {
      renderIcons.push(<RenderRequestSpecIcon key={i} icondata={icons[i]} />);
    }
    return renderIcons;
  }
}

export default BundledItemSpecs;
