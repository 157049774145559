import { postNewOfferSuccess } from '../../actions/search';
import { connect } from 'react-redux';
import OfferForm from '../../forms/OfferForm';
import { getSelectedTransportJob, getSelectedTransportJobId } from '../../reducers/search';
import { postNewOfferStart } from '../../actions/search';
import { showModal } from '../../actions/modals';
import _get from 'lodash/get';
import { getUserDetails } from '../../reducers/user';

const mapStateToProps = state => {
  let selectedTransportJob = getSelectedTransportJob(state);
  let user = getUserDetails(state);
  return {
    uuid: getSelectedTransportJobId(state),
    transport_job: selectedTransportJob,
    clientname: _get(selectedTransportJob, 'pickups[0].contact.first_name', ''),
    errors: state.search.errors,
    username: `${_get(user, 'first_name', '')} ${_get(user, 'last_name', '')}`,
  };
};

const mapDispatchToProps = dispatch => ({
  postOffer: (message, transportJobId) => dispatch(postNewOfferStart(message, transportJobId)),
  showModal: props => dispatch(showModal(props)),
  onFormSuccess: offerId => dispatch(postNewOfferSuccess(offerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);
