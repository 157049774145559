import { connect } from 'react-redux';
import Filters from '../../components/routePlanner/Filters';
import { handleToggleFilters, handleSearchFilters } from '../../actions/search/Filters';

const mapStateToProps = (state, ownProps) => ({
  search: state.search,
  open: state.search.filtersOpen,
  resultCount: ownProps.resultCount,
});

const mapDispatchToProps = dispatch => ({
  handleToggleFilters: () => dispatch(handleToggleFilters()),
  handleSearchFilters: filterEvent => dispatch(handleSearchFilters(filterEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
