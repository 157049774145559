import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from '../../forms/DatePicker';
import IconCross from '../../icons/IconCross';
import { LocalityFilter } from './filters/LocalityFilter';
import Heading from '../../Heading';
import { Col, Row } from '../../Grid';
import { Button } from '../../Button';
import '../../css/Filter-list.css';
import '../../css/Form.css';
import { Translate } from 'react-localize-redux';

class Filters extends Component {
  render() {
    const todayDateString = moment().toDate();
    const tomorrowDateString = moment()
      .add(1, 'day')
      .toDate();
    return (
      <div className={'filter-list overlay' + (this.props.open ? ' is-open' : '')}>
        <div className={'filter-list-content'}>
          <div className={'filter-list-heading'}>
            <Heading size="h4">
              <Translate id={'search.filters.title'} />
            </Heading>
            <div className={'filter-list-close'} onClick={() => this.props.handleToggleFilters()}>
              <IconCross />
            </div>
          </div>
          <div className="filter-list-body">
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.size_header'} />:
                  </b>
                  {Object.keys(this.props.search.filters.size).map((item, index) => {
                    return (
                      <div key={index} className={'input-element type-check'}>
                        <input
                          id={'size_' + item}
                          name={'size_' + item}
                          type={'checkbox'}
                          onChange={event => this.props.handleSearchFilters(event)}
                          checked={this.props.search.filters.size[item]}
                        />
                        <label htmlFor={'size_' + item}>
                          <Translate id={'search.filters.size.' + item} />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.pickup_location_header'} />:
                  </b>
                  {Object.keys(this.props.search.filters.countryPickup).map((countryCode, index) => {
                    return (
                      <div key={index} className={'input-element type-check'}>
                        <input
                          id={'country_pickup_' + countryCode}
                          name={'country_pickup_' + countryCode}
                          type={'checkbox'}
                          onChange={event => this.props.handleSearchFilters(event)}
                          checked={this.props.search.filters.countryPickup[countryCode]}
                        />
                        <label htmlFor={'country_pickup_' + countryCode}>
                          <Translate id={'search.filters.country.' + countryCode} />
                        </label>
                        {this.props.search.filters.countryPickup[countryCode] &&
                          this.props.search.filters.regionPickup[countryCode] && (
                            <div
                              className={
                                'col ' +
                                (Object.keys(this.props.search.filters.regionPickup[countryCode]).some(
                                  k => this.props.search.filters.regionPickup[countryCode][k]
                                )
                                  ? 'region-filter-active'
                                  : '')
                              }
                            >
                              {Object.keys(this.props.search.filters.regionPickup[countryCode]).map(
                                (regionCode, index) => {
                                  return (
                                    <div key={index} className={'input-element type-check'}>
                                      <input
                                        id={'region_pickup_' + regionCode}
                                        name={'region_pickup_' + countryCode + '_' + regionCode}
                                        type={'checkbox'}
                                        onChange={event => this.props.handleSearchFilters(event)}
                                        checked={this.props.search.filters.regionPickup[countryCode][regionCode]}
                                      />
                                      <label htmlFor={'region_pickup_' + regionCode}>
                                        <Translate id={'search.filters.region.' + countryCode + '.' + regionCode} />
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-row">
                  <LocalityFilter
                    countryFilter={this.props.search.filters.countryDelivery}
                    regionFilter={this.props.search.filters.regionDelivery}
                    handleSearchFilters={this.props.handleSearchFilters}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.special_header'} />:
                  </b>
                  {Object.keys(this.props.search.filters.special).map((item, index) => {
                    return (
                      <div key={index} className={'input-element type-check'}>
                        <input
                          id={'special_' + item}
                          name={'special_' + item}
                          type={'checkbox'}
                          onChange={event => this.props.handleSearchFilters(event)}
                          checked={this.props.search.filters.special[item]}
                        />
                        <label htmlFor={'special_' + item}>
                          <Translate id={'search.filters.special.' + item} />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.date_header'} />:
                  </b>
                  <div className={'input-element type-text'}>
                    <Button size={'sm'} onClick={() => this.props.handleSearchFilters(todayDateString)}>
                      <Translate id={'datetime.today'} />
                    </Button>
                    <Button size={'sm'} onClick={() => this.props.handleSearchFilters(tomorrowDateString)}>
                      <Translate id={'datetime.tomorrow'} />
                    </Button>
                  </div>
                  <div className={'input-element type-text'}>
                    <DatePicker
                      min={moment().toDate()}
                      name={'date-picker'}
                      handleChange={this.props.handleSearchFilters.bind(this)}
                      value={this.props.search.filters.date}
                    />
                  </div>
                  {this.props.search.filters.date !== '' && (
                    <div className={'remove-date-filter'} onClick={() => this.props.handleSearchFilters('')}>
                      <IconCross />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.pickup_header'} />:
                  </b>
                  {Object.keys(this.props.search.filters.pickupSituation).map((item, index) => {
                    return (
                      <div key={index} className={'input-element type-check'}>
                        <input
                          id={'pickupSituation_' + item}
                          name={'pickupSituation_' + item}
                          type={'checkbox'}
                          onChange={event => this.props.handleSearchFilters(event)}
                          checked={this.props.search.filters.pickupSituation[item]}
                        />
                        <label htmlFor={'pickupSituation_' + item}>
                          <Translate id={'search.filters.pickup.' + item} />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.prepaid_header'} />:
                  </b>
                  <div className={'input-element type-check'}>
                    <input
                      id={'prepaid'}
                      name={'prepaid'}
                      type={'checkbox'}
                      onChange={event => this.props.handleSearchFilters(event)}
                      checked={this.props.search.filters.prepaid}
                    />
                    <label htmlFor={'prepaid'}>
                      <Translate id={'search.filters.prepaid_text'} />
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-row">
                  <b>
                    <Translate id={'search.filters.responded_header'} />:
                  </b>
                  <div className={'input-element type-check'}>
                    <input
                      id={'responded'}
                      name={'responded'}
                      type={'checkbox'}
                      onChange={event => this.props.handleSearchFilters(event)}
                      checked={!this.props.search.filters.responded}
                    />
                    <label htmlFor={'responded'}>
                      <Translate id={'search.filters.responded_text'} />
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="filter-list-footer filter-toggle-wrap">
            <Button buttonStyle={'secondary'} onClick={() => this.props.handleToggleFilters()}>
              <Translate id={'search.filters.show_results'} data={{ resultcount: this.props.resultCount }} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Filters;
