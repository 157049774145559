import React, { Component } from 'react';
import _get from 'lodash/get';
import { apiBaseUri } from './Utils';
import { Translate } from 'react-localize-redux';
import { TJAL_STATUS, TJ_STATUS } from './globals';

class ItemVolumes extends Component {
  getCubicSqrMeter(items) {
    let sum = 0;
    items.map(item => (sum += item.count * (item.width / 100) * (item.height / 100) * (item.length / 100)));
    return parseFloat(sum.toFixed(3));
  }
  render() {
    if (_get(this.props.item_sets[0], 'items[0]', false)) {
      let itemSetsAsString = [];
      this.props.item_sets[0].items.map((item, i) => {
        return itemSetsAsString.push(
          <div className={'item-set-row'} key={i} id={i}>
            <p key={i}>
              <span className={'volume-string'}>
                <strong>
                  {`(${item.count}X) ${item.length}x${item.width}x${item.height}cm`}
                  {item.weight > 0 ? `,  ${item.weight} kg` : ''}
                </strong>
              </span>
              {_get(item, 'job_image', false) && (
                <img className={'hide-print'} alt={'item set'} src={apiBaseUri(2) + '/user_data' + item.job_image} />
              )}
            </p>
          </div>
        );
      });
      if (
        this.props.status === TJAL_STATUS.DELIVERED ||
        this.props.status === TJAL_STATUS.ACCEPTED ||
        this.props.status === TJ_STATUS.DRIVER_CONFIRMED ||
        this.props.status === TJ_STATUS.DELIVER_CONFIRMED_BY_DRIVER ||
        this.props.status === TJ_STATUS.DELIVER_CONFIRMED_BY_CUSTOMER
      ) {
        this.props.item_sets[0].external_invoices.map(invoice => {
          itemSetsAsString.push(
            <p>
              <a key={'download_invoice'} href={_get(invoice, 'download_link', '#')} target={'_blank'}>
                <Translate id={'actions.download_invoices'} />
              </a>
            </p>
          );
          return itemSetsAsString;
        });
      }
      return (
        <div>
          {this.props.item_sets[0].title}
          <br />
          <br />
          <b>
            <Translate id={'actions.total_size'} />: {this.getCubicSqrMeter(this.props.item_sets[0].items)} m{' '}
            <sup>3</sup>
          </b>
          <br />
          <br />
          {itemSetsAsString}
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default ItemVolumes;
