import React, { Component } from 'react';
import VisibleSidebar from './containers/search/VisibleSidebar';
import VisibleTransportJob from './containers/search/VisibleTransportJob';

class SearchApp extends Component {
  render() {
    return (
      <div className={'main-with-sidebar'}>
        <VisibleSidebar />
        <VisibleTransportJob />
      </div>
    );
  }
}

export default SearchApp;
