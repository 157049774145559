import { connect } from 'react-redux';
import Details from '../../components/profile/sections/Details';
import { actions } from '../../reducers/user';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state, ownProps) => ({
  resultCount: ownProps.resultCount,
  translate: getTranslate(state.localize),
  user: ownProps.user,
  address: ownProps.address,
  countries: ownProps.countries,
  bankAccount: ownProps.bankAccount,
});

const mapDispatchToProps = dispatch => ({
  uploadProfileImage: file => dispatch(actions.uploadProfileImage(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
