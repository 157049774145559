import { connect } from 'react-redux';
import Messages from '../../Messages';
import { markMessageAsRead } from '../../actions/conversations';

const mapStateToProps = (state, ownProps) => {
  return {
    messages: ownProps.messages,
    hasNew: ownProps.hasNew,
    offeruuid: ownProps.offeruuid,
    updateConversations: ownProps.updateConversations,
    selectedConversationId: ownProps.selectedConversationId,
    markedAsRead: state.conversations.messagesMarkedRead,
    isPatching: state.conversations.isPatching,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMarkMessageAsRead: (offerId, messageId, updateConversations) =>
    dispatch(markMessageAsRead(offerId, messageId, updateConversations)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
