import { connect } from 'react-redux';
import RouteForm from '../../forms/PreferredRouteForm';
import { submitPreferredRouteFormStart } from '../../actions/preferredRoutes';
import { isSubmitting } from '../../reducers/preferredRoutes';

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(state.preferredRoutes),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(submitPreferredRouteFormStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteForm);
