import { connect } from 'react-redux';
import TabNavigation from '../../components/layout/TabNavigation';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state, ownProps) => ({
  translate: getTranslate(state.localize),
  navItems: ownProps.navItems,
  header: ownProps.header,
  withDoctitle: ownProps.withDoctitle,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TabNavigation);
