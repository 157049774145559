import React, { Component } from 'react';
import _get from 'lodash/get';
import VisibleMaps from '../../containers/maps/VisibleMaps';
import StaticMap from '../maps/StaticMap';

class RouteMap extends Component {
  render() {
    const transport = this.props.transport;
    const routeAvailable = _get(transport, 'route.points', false) && transport.route.points.length > 2;
    const uuid = _get(transport, '@id', '');
    return (
      <>
        {routeAvailable ? (
          <VisibleMaps single={transport} distance={this.props.distance} uuid={uuid} />
        ) : (
          <StaticMap single={transport} />
        )}
      </>
    );
  }
}

export default RouteMap;
