import React from 'react';
import '../css/Form.css';
import { Translate } from 'react-localize-redux';

class FileInput extends React.Component {
  render() {
    return (
      <div className={'input-element input-comp type-file'}>
        <div className={'file-upload-interaction'}>
          <label htmlFor={this.props.name}>
            <span className={'btn btn--primary'}>
              {this.props.label ? this.props.label : <Translate id={'form.fields.default.file.label'} />}
            </span>
          </label>
          {this.props.value && !this.props.error && (
            <div className={'display-value'}>
              <p>
                {this.props.selectedText ? (
                  this.props.selectedText
                ) : (
                  <Translate id={'form.fields.default.file.selected'} />
                )}
                : {this.props.value}
              </p>
            </div>
          )}
          {this.props.errors && (
            <div className={'error-message'}>
              <p>{this.props.errors}</p>
            </div>
          )}
        </div>

        <input accept={'png, gif, jpg, jpeg'} {...this.props} value={''} type={'file'} id={this.props.name} />
      </div>
    );
  }
}
export default FileInput;
