import React, { Component } from 'react';
import './css/StatusLabel.css';
import { Translate } from 'react-localize-redux';
import * as appUtils from './Utils';

let colors = {
  open: 'red',
  suspended: 'red',
  pending: 'yellow',
  waiting_for_datetime_periods: 'yellow',
  rejected: 'red',
  cancelled: 'red',
  deleted: 'red',
  unknown: 'yellow',
  business: 'black',
  default: 'green',
};

class StatusLabel extends Component {
  render() {
    let statusColor = appUtils.valueOrElse(this.props.status, colors, colors.default);
    return (
      <div className={'request-label request-label--' + statusColor + ' request-label-type--' + this.props.status}>
        <span>
          <Translate id={'status.' + this.props.status} />
        </span>
      </div>
    );
  }
}

export default StatusLabel;
