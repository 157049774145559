import React from 'react';
import { Button } from '../../Button';
import { Translate, withLocalize } from 'react-localize-redux';

const PromptModal = ({ closeModal, closeText, action, actionText, message, prompts }) => {
  let promptsUI = prompts.map((prompt, i) => {
    return (
      <li className={'input-row'} key={i} onClick={e => callback(e, i)}>
        <div className={'input-element type-check'}>
          <input type={'checkbox'} name={prompt.name} />
          <label>{prompt.text}</label>
        </div>
      </li>
    );
  });

  setTimeout(() => {
    const btn = document.querySelector('.prompt-btn');
    if (prompts.length !== 0) btn.setAttribute('disabled', 'disabled');
  }, 100);

  const callback = (e, index) => {
    let disabled = false;
    let prompts = e.currentTarget.parentNode.getElementsByTagName('input');
    const btn = document.querySelector('.prompt-btn');

    prompts[index].click();

    for (let input of prompts) if (!input.checked) disabled = true;

    if (!disabled) btn.removeAttribute('disabled');
    else btn.setAttribute('disabled', 'disabled');
  };

  const onClick = () => {
    action();
    closeModal();
  };

  return (
    <div className="modal-content prompt-modal">
      <div>
        <p>{message}</p>
      </div>
      <ul>{promptsUI}</ul>
      <div className={'btn-wrapper'}>
        <Button onClick={() => closeModal()} buttonStyle="tertiary">
          {closeText ? closeText : <Translate id="modals.confirm_modal.cancel" />}
        </Button>
        <Button extraClasses={'prompt-btn'} onClick={() => onClick()} buttonStyle={'primary'}>
          {actionText ? actionText : <Translate id="modals.confirm_modal.ok" />}
        </Button>
      </div>
    </div>
  );
};

export default withLocalize(PromptModal);
