import React, { PureComponent } from 'react';
import { Col } from '../../Grid.js';
import IconSmile from '../../icons/IconSmile';
import '../../css/OneItem.css';
import StatusLabel from '../../StatusLabel';
import * as appUtils from '../../Utils';
import { determineTimeDisplay } from '../../UtilsDatetime';
import ItemAddress from '../transport/ItemAddress';

class SearchItem extends PureComponent {
  render() {
    const transport = this.props.item;
    const rewards = this.getRewards(transport);
    const itemSizes = this.getItemSizes(transport);
    const uuid = transport['@id'].split('transport_jobs/')[1];
    return (
      <div
        className={'card one-item-container' + (this.props.isSelected ? ' is-selected' : '')}
        onClick={() => this.props.onItemClick(uuid)}
      >
        <div className={'one-item-inner type-search'} id={this.props.item['@id']}>
          <div className={'dropdown-toggle'}>
            <IconSmile />
          </div>
          <div className="row address-details address-pickup">
            <ItemAddress stop={transport.pickups[0]} />
            <Col xs="6" extraClasses={'date-time-col'}>
              <div className="date">{determineTimeDisplay(this.props.item, this.props.date, 'pickup')}</div>
            </Col>
          </div>
          <div className="row address-details address-delivery">
            <ItemAddress stop={transport.deliveries[0]} />
            <Col xs="6" extraClasses={'date-time-col'}>
              <div className="date">{determineTimeDisplay(this.props.item, this.props.date, 'delivery')}</div>
            </Col>
          </div>
          <div className={'request-details'}>
            <div className="column-1">
              <div className={'labels'}>{transport.business && <StatusLabel status={'business'} />}</div>
              <div className={'transport_icons'}>{appUtils.getSpecialIcons(transport)}</div>
            </div>
            <div className="column-2">
              {rewards && (
                <div className="request-details-price">
                  &euro; {rewards}
                  {transport.eligible_for_delivery && <StatusLabel status={'paid_in_advance'} />}
                </div>
              )}
            </div>
            <small className={'transport_item_sizes'} dangerouslySetInnerHTML={{ __html: itemSizes }} />
          </div>
        </div>
      </div>
    );
  }
  getItemSizes(transport) {
    return `(${transport.largest_item.count}<b>X</b>) ${transport.largest_item.length} <b>X</b> ${transport.largest_item.width} <b>X</b> ${transport.largest_item.height}`;
  }
  getRewards(transport) {
    return transport.hasOwnProperty('reward')
      ? appUtils.priceLayout(transport.total_payout ? transport.total_payout : transport.reward)
      : '0.00';
  }
}

export default SearchItem;
