import React from 'react';
import { displayAvailableDatetimePeriodForStop } from './../../UtilsDatetime';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <span>::: &nbsp;</span>);

const SortableStop = SortableElement(({ stop, startDatetime }) => {
  return (
    <>
      <li>
        <DragHandle />
        {(stop.type === 'pickup' ? '> ' : '< ') + stop.locality}{' '}
        <small>{displayAvailableDatetimePeriodForStop(stop, startDatetime)}</small>{' '}
        {stop.details &&
          `(${startDatetime
            .clone()
            .add(stop.details.arrival, 'minutes')
            .format('HH:mm')} - ${startDatetime
            .clone()
            .add(stop.details.departure, 'minutes')
            .format('HH:mm')})`}
        <br />
        <small>
          {stop.details && `Waiting: ${stop.details.waitingtime} min | Service: ${stop.details.servicetime} min `}
        </small>
      </li>
      <small>{stop.details && `Drive: ${stop.details.traveltime} min / ${stop.details.distance} KM`} </small>
    </>
  );
});

const SortableStopList = SortableContainer(({ startLocation, startDatetime, stops, optimizedRoute }) => {
  return (
    <ul>
      <li>
        Leave {startLocation} at {startDatetime.format('HH:mm')}
      </li>
      <small>
        {optimizedRoute.length > 0 && `Drive: ${optimizedRoute[0].traveltime} min / ${optimizedRoute[0].distance} KM`}
      </small>
      {stops.map((stop, index) => (
        <SortableStop key={`item-${index}`} index={index} stop={stop} startDatetime={startDatetime} />
      ))}
      <li>
        Arrive in {startLocation}{' '}
        {optimizedRoute.length > 0 &&
          `at ${startDatetime
            .clone()
            .add(optimizedRoute.slice(-1)[0].arrival, 'minutes')
            .format('HH:mm')}`}
      </li>
    </ul>
  );
});

export default SortableStopList;
