const defaultState = {
  isFetching: false,
  details: [],
  total: 0,
};

export const types = {
  FETCH_REVIEWS_START: 'FETCH_REVIEWS_START',
  FETCH_REVIEWS_ERROR: 'FETCH_REVIEWS_ERROR',
  FETCH_REVIEWS_SUCCESS: 'FETCH_REVIEWS_SUCCESS',
};

export const getReviews = state => {
  return state.reviews;
};

const reviews = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_REVIEWS_START:
      return {
        ...state,
        isFetching: true,
        details: action.reviews,
      };
    case types.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        details: action.reviews['hydra:member'],
        total: action.reviews['hydra:totalItems'],
      };
    case types.FETCH_REVIEWS_ERROR:
      return {
        ...state,
        isFetching: false,
        details: action.reviews,
        total: 0,
      };
    default:
      return state;
  }
};

export default reviews;
