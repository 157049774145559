import React, { Component } from 'react';
import '../../css/Sidebar.css';
import { Col } from '../../Grid';
import { Button } from '../../Button';
import VisibleSelect from '../../containers/forms/VisibleSelect';
import VisibleFilters from '../../containers/search/VisibleFilters';
import SearchItem from './SearchItem';
import SearchBundledItem from './SearchBundledItem';
import IconSmile from '../../icons/IconSmile';
import * as appUtils from '../../Utils';
import { Link } from 'react-router-dom';
import history from '../../History';
import { Translate, withLocalize } from 'react-localize-redux';
import _get from 'lodash/get';

import IconFilters from '../../icons/IconFilters';
import { getIdFromUrl } from '../../Utils';
const SITEPREFIX = appUtils.getPathForApp();

class SearchList extends Component {
  componentWillMount() {
    this.props.fetchTransportJobs();
  }
  componentDidMount() {
    // check if there is an id given as parameter
    let selectedUUID = getIdFromUrl(history, SITEPREFIX, this.props.renderType);
    if (selectedUUID !== '') {
      //id given as parameter, attempt to fetch corresponding offer from api
      this.props.selectTransportJob(selectedUUID);
    }
    //Upon navigating through forward/back, select/deselect according to new url
    window.onpopstate = () => {
      let selectedUUID = getIdFromUrl(history, SITEPREFIX, this.props.renderType);
      if (selectedUUID !== '') {
        this.props.selectTransportJob(selectedUUID);
      } else {
        this.props.deselectTransportJob();
      }
    };
  }
  render() {
    const sortOptions = ['newest', 'date', 'price'];
    return (
      <aside className={'sidebar view-search' + (this.props.selectedTransportJobId === '' ? '' : ' hide-print')}>
        <div className={'list'}>
          <div className={'dd-tabs sticky hide-print'}>
            <div className={'tabs-navigation'}>
              <Link className={'tab is-active'} to={SITEPREFIX + `/${this.props.renderType}`}>
                <Translate id="status.new" />
              </Link>
              <Link className={'tab'} to={SITEPREFIX + '/planning'}>
                <Translate id="planning.title" />
              </Link>
              <Link className={'tab'} to={SITEPREFIX + '/delivered'}>
                <Translate id="delivered.title" />
              </Link>
            </div>
          </div>
          <div>
            <Col xs="12" extraClasses={'filter-toggle-wrap hide-print'}>
              <Button buttonStyle={'primary'} onClick={() => this.props.handleToggleFilters()}>
                <Translate id={'search.list.open_filters'} /> <IconFilters />
              </Button>
            </Col>
            <Col xs="12" extraClasses="list-heading">
              <div className={'sort-list-wrap'}>
                <div className={'sort-list-header'}>
                  <Translate id={'search.list.sorting_header'} />
                </div>
                <VisibleSelect
                  name={'sortList'}
                  handleChange={this.props.handleSearchSorting}
                  defaultValue={this.props.sort}
                  options={sortOptions.map(item => {
                    return [item, 'search.list.sorting.' + item];
                  })}
                  selected={this.props.sort}
                />
              </div>
            </Col>
            {this.props.isFetching && (
              <Col xs="12">
                <IconSmile spinning={true} /> <Translate id={'search.list.loading'} />
              </Col>
            )}
            <section className={'list-section'}>
              {!this.props.filtersOpen &&
                this.props.transportJobs.map((transportJob, index) => {
                  const props = {
                    key: index,
                    item: transportJob,
                    onItemClick: this.props.onTransportJobClick,
                    isSelected: this.props.selectedTransportJobId === transportJob['@id'].split('transport_jobs/')[1],
                    date: transportJob.created_at,
                  };
                  return _get(transportJob, 'pickups[0].address.locality', false) &&
                    _get(transportJob, 'deliveries[0].address.locality', false) ? (
                    !transportJob.bundled ? (
                      <SearchItem {...props} />
                    ) : (
                      <SearchBundledItem {...props} />
                    )
                  ) : null;
                })}
            </section>
          </div>
          <VisibleFilters resultCount={this.props.transportJobs.length} />
        </div>
      </aside>
    );
  }
}

export default withLocalize(SearchList);
