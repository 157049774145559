import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { translate } from '../../UtilsTranslation';
import { Button } from '../../Button';
import IconArrow from '../../icons/IconArrow';
import * as Globals from '../../globals';
import * as appUtils from '../../Utils';
import FlashMessage from '../../FlashMessage';
import { Col, Row } from '../../Grid';
import Heading from '../../Heading';
import { SectionWrapper } from '../layout/SectionWrapper';

class HouseRules extends Component {
  componentDidMount() {
    appUtils.addEvent(window, 'scroll', this.checkIfReadRules.bind(this));
  }

  componentWillUnmount() {
    appUtils.removeEvent(window, 'scroll', this.checkIfReadRules.bind(this));
  }

  checkIfReadRules() {
    const document = window.document.documentElement;
    const scrollTop = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
    const height = window.document.height !== undefined ? window.document.height : window.document.body.offsetHeight;
    if (scrollTop + window.innerHeight + 100 < height) {
      return;
    }
    appUtils.removeEvent(window, 'scroll', this.checkIfReadRules.bind(this));
    this.props.setReadRules();
  }

  render() {
    let messages = [];
    for (let field in this.props.validations.entry) {
      messages[field] = React.createElement(
        FlashMessage,
        { message: this.props.validations.entry[field], type: 'error' },
        null
      );
    }
    return (
      <>
        <SectionWrapper
          background={'primary-light'}
          extraClasses={'mt-gtr-neg routes-info-wrapper'}
          centerContent={true}
        >
          <Row>
            <Col xs={12}>
              <Heading size={'h5'}>
                <Translate id={'onboarding.house_rules.title'} />
              </Heading>
              <div
                className={'house-rules-content'}
                dangerouslySetInnerHTML={{
                  __html: translate('onboarding.house_rules.intro'),
                }}
              />
            </Col>
          </Row>
        </SectionWrapper>
        <div className={'main-centered-content'}>
          <form className={'onboarding-form onboarding-form-account'}>
            <div className={'house-rules pt-1'}>
              <div
                className={'house-rules-content'}
                dangerouslySetInnerHTML={{
                  __html: translate('onboarding.house_rules.body', {
                    name: translate(Globals.driverAccountManager),
                    email: Globals.driverHelpDeskEmail,
                  }),
                }}
              />
              {messages['general']}
            </div>
            <div className={'form-bottom-actions'}>
              <div className={'main-centered-content'}>
                <Button
                  buttonStyle={'secondary block text-left'}
                  loading={this.props.loading}
                  disabled={!this.props.hasReadRules}
                  iconPos={'right'}
                  onClick={event => this.handleSubmit(event)}
                >
                  <Translate id={'onboarding.step.next'} />
                  <IconArrow />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit();
  }
}

export default HouseRules;
