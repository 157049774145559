import { connect } from 'react-redux';
import DeliveredList from '../../components/delivered/DeliveredList';
import { createSortedDayListFromOffers } from '../../actions/planning';
import {
  deselectDelivered,
  fetchDeliveredStart,
  onDeliveredClick,
  selectDelivered,
  toggleDeliveredFilters,
} from '../../actions/delivered';
import { getSelectedDeliveredId, isFetchingDelivered } from '../../reducers/delivered';
import * as appUtils from '../../Utils';

const storeDeliveredByDay = offers => {
  // Init variables
  let dataSegments = {};
  // What dates we have, today or newer (sorted from old to new)
  const sortedDayList = createSortedDayListFromOffers(offers, false);
  // Filter the data segments against the list of days we want to show
  for (let day of sortedDayList) {
    dataSegments[day] = offers.filter(item => {
      return item.delivery_commitments[0].committed_datetime_period.start.format('YYYYMMDD') === day;
    });
  }
  return dataSegments;
};

const mapStateToProps = state => {
  return {
    id: 'side-bar',
    dataSegments: storeDeliveredByDay(state.delivered.items),
    renderType: 'delivered',
    offers: state.delivered.items,
    selectedUUID: getSelectedDeliveredId(state),
    openItems: state.delivered.openItems,
    isFetching: isFetchingDelivered(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOffers: () => dispatch(fetchDeliveredStart()),
  onOfferClick: (id, sectionName, navigate) => dispatch(onDeliveredClick(id, navigate)),
  handleToggleFilters: () => dispatch(toggleDeliveredFilters()),
  selectOffer: id => dispatch(selectDelivered(id)),
  deselectOffer: () => {
    dispatch(deselectDelivered());
    appUtils.deActivateSingleView();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveredList);
