import React, { Component } from 'react';
import { setDocumentTitle } from '../../Utils';
import BrengerLogo from '../../icons/BrengerLogo';
import '../../css/Details.css';
import SingleViewInner from '../../SingleViewInner';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';

class DeliveryDetail extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.offer !== this.props.offer) {
      this.forceUpdate();
    }
    if (this.props.offer && prevProps.offer && this.props.offer['@id'] !== prevProps.offer['@id']) {
      // reset window
      window.scrollTo(0, 0);
      // reset wrapper (affects mobile view only)
      var singleView = document.querySelector('.single-view-wrapper');
      singleView.scrollTop = 0;
    }
  }
  render() {
    setDocumentTitle(
      //TODO: check selected first
      this.props.offer
        ? _get(this.props.offer, 'transport_job_details.title', this.props.translate('delivered.title'))
        : this.props.translate('delivered.title')
    );
    return (
      <main className={'single-view' + (this.props.offer ? ' is-active' : '')}>
        <div className={'single-view-wrapper'}>
          {this.props.offer && _get(this.props.offer, 'transport_job_details', false) && (
            <SingleViewInner
              {...this.props}
              errors={this.props.errors}
              single={this.props.offer}
              isFetching={this.props.isFetching}
              isOffer={true}
              closeDetails={this.props.closeDetails}
              user={this.props.user}
              userCanRespond={true}
              userIsRestricted={[]}
            />
          )}
          {this.props.isFetching && <div style={{ textAlign: 'center' }}>...</div>}
        </div>
        <div className="not-active-view">
          <BrengerLogo />
          <p>
            <Translate id={'planning.detail.welcome'} />
          </p>
        </div>
      </main>
    );
  }
}

export default DeliveryDetail;
