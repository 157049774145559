import * as appUtils from '../Utils';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { fetchUrl } from '../api/general';
import { types as conversationTypes } from '../reducers/conversations';
import { fetchConversationsError } from '../actions/inbox';
import {
  fetchMessagesError,
  fetchMessagesSuccess,
  selectConversation,
  fetchListMessagesStop,
  setSelectedMessages,
  setInboxMessages,
} from '../actions/conversations';
import history from '../History';
import { getAccountId } from '../reducers/user';
import http from '../UtilsHttp';

const SITEPREFIX = appUtils.getPathForApp();

export function* conversationSagas() {
  yield takeEvery(conversationTypes.FETCH_LIST_MESSAGES_START, fetchConversationsSaga);
  yield takeEvery(conversationTypes.OPEN_CHAT_SESSION, openChatSession);
  yield takeEvery(conversationTypes.SELECT_CONVERSATION, fetchConversation);
  yield takeEvery(conversationTypes.PUT_SELECTED_MESSAGES, activeSingleView);
  yield takeEvery(conversationTypes.FETCH_MESSAGES_START, fetchMessagesSaga);
}

export function* fetchConversationsSaga() {
  try {
    // first fetch all transport job account link
    let messages = yield call(fetchMessages);
    if (messages.data['hydra:member'].length === 0) {
      yield put(fetchListMessagesStop());
    } else {
      let sortedByDate = messages.data['hydra:member'].sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      yield all(sortedByDate.map(msg => call(fetchJobLinkMessages, msg)));
    }
  } catch (error) {
    fetchConversationsError(error);
  }
}

export function* activeSingleView(action) {
  yield delay(100);
  appUtils.activateSingleView();
}

export function* updateConversations(action) {
  yield call(fetchJobLinkMessages, action.payload.job);
}

export function* fetchConversation(action) {
  const account = yield select(getAccountId);
  try {
    const messages = yield call(fetchSingleJobMessages, account, action.id);
    yield put(setSelectedMessages(action.id, messages.data['hydra:member']));
  } catch (err) {
    console.log(err);
  }
}

export function fetchSingleJobMessages(account, job) {
  return http()
    .get(`${account}${job}/messages`)
    .then(resp => resp);
}

export function* fetchJobLinkMessages(msg) {
  try {
    let messages = [];
    const transportJobIRI = msg.transport_job_account_link.transport_job;
    const transportDetail = yield call(fetchTransportJob, transportJobIRI);
    const url = window.location.href;
    messages['items'] = [];
    messages['job'] = msg;
    messages['id'] = msg.transport_job_account_link;
    messages['status'] = msg['state'];
    messages['transport'] = transportDetail.data;
    yield put(setInboxMessages(messages));
    if (url.indexOf('messages/') > -1) {
      const selectedChat = url.split('messages/')[1];
      yield put(selectConversation('/transport_job_account_links/' + selectedChat));
    }
    if (url.indexOf('planning/') > -1) {
      const selectedChat = url.split('planning/')[1];
      yield put(selectConversation('/transport_job_account_links/' + selectedChat));
    }
  } catch (err) {
    console.log(err);
  }
}

export function fetchMessages() {
  return http()
    .get(`users/me/conversations`, { withCredentials: true })
    .then(resp => resp);
}

export function fetchTransportJob(id) {
  return http()
    .get(id, { withCredentials: true })
    .then(resp => resp);
}

export function* fetchMessagesSaga({ offerId }) {
  const key = '/transport_job_account_links/';
  const offerIRI = offerId.indexOf(key) > -1 ? offerId : key + offerId;
  try {
    const url = appUtils.createApiUrl(`${offerIRI}/messages`, 2);
    const response = yield call(fetchUrl, url);
    yield put(fetchMessagesSuccess(offerId, response['hydra:member']));
  } catch (error) {
    yield put(fetchMessagesError(error));
  }
}

export function* openChatSession({ id }) {
  appUtils.activateSingleView();
  try {
    const link = (SITEPREFIX + id).replace('transport_job_account_links', 'messages');
    yield all([call(fetchConversation, { id }), put(selectConversation(id))]);
    history.push(link);
  } catch (err) {
    console.log(err);
  }
}
