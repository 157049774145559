import { fork } from 'redux-saga/effects';
import { planningSagas } from './planning';
import { searchSagas } from './search';
import { userSaga } from './user';
import { deliveredSagas } from './delivered';
import { preferredRoutesSagas } from './preferredRoute';
import { conversationSagas } from './conversation';
import { inboxSagas } from './inbox';
import { reviewSagas } from './review';
import { LocalizationSaga } from './translations';

export function* rootSaga() {
  yield fork(planningSagas);
  yield fork(searchSagas);
  yield fork(deliveredSagas);
  yield fork(userSaga);
  yield fork(preferredRoutesSagas);
  yield fork(conversationSagas);
  yield fork(inboxSagas);
  yield fork(reviewSagas);
  yield fork(LocalizationSaga);
}
