import React from 'react';
import { Translate } from 'react-localize-redux';
import { Col, Row } from '../Grid';
import LocationAutocomplete from './LocationAutocomplete';
import { Button } from '../Button';

class PreferredRouteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      size: '',
      backForth: false,
      errors: [],
    };
  }
  render() {
    const disabled = this.state.from === '' || this.state.to === '' || this.props.isSubmitting;
    return (
      <form className={'preferred-route-form' + (this.props.isSubmitting ? ' is-loading' : '')}>
        <p>
          <b>
            <Translate id={'profile_page.preferred_routes.add_route'} />
          </b>
        </p>
        <Row>
          <Col xs={12} sm={10}>
            <div className={'grouped-autocomplete'}>
              <LocationAutocomplete
                extraInputProps={{ id: 'preferred_route_from' }}
                getValue={value => this.setState({ from: value })}
              />
              <LocationAutocomplete
                extraInputProps={{ id: 'preferred_route_to' }}
                getValue={value => this.setState({ to: value })}
              />
            </div>
          </Col>
          <Col xs={12} sm={2} extraClasses={'text-right'}>
            <Button
              buttonStyle={'primary'}
              disabled={disabled}
              loading={this.props.isSubmitting}
              onClick={event => {
                event.preventDefault();
                this.prepareRoutes();
              }}
            >
              <Translate id={'form.save'} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className={'input-row'}>
              <div className={'input-element type-check'}>
                <input
                  id="back_forth"
                  type={'checkbox'}
                  checked={this.state.backForth}
                  onChange={() => this.setState({ backForth: !this.state.backForth })}
                />
                <label htmlFor={'back_forth'}>
                  <Translate id={'profile_page.preferred_routes.back_forth'} />
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    );
  }

  prepareRoutes() {
    this.props.onSubmit({
      from_lat: this.state.from.address.latitude,
      from_lng: this.state.from.address.longitude,
      from_locality: this.state.from.address.locality,
      to_lat: this.state.to.address.latitude,
      to_lng: this.state.to.address.longitude,
      to_locality: this.state.to.address.locality,
      transport_size_class: this.state.size,
      from: this.state.from.address.locality,
      to: this.state.to.address.locality,
    });

    if (this.state.backForth) {
      this.props.onSubmit({
        to_lat: this.state.from.address.latitude,
        to_lng: this.state.from.address.longitude,
        to_locality: this.state.from.address.locality,
        from_lat: this.state.to.address.latitude,
        from_lng: this.state.to.address.longitude,
        from_locality: this.state.to.address.locality,
        transport_size_class: this.state.size,
        to: this.state.from.address.locality,
        from: this.state.to.address.locality,
      });
    }
  }
}
export default PreferredRouteForm;
