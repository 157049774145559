import React from 'react';
import '../css/IconStyle.css';

const IconOther = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 95 84" width="95" height="84">
      <g>
        <path
          d="M94.1,73L82,51.5c-0.5-1-1.8-1.3-2.7-0.8c-1,0.5-1.3,1.8-0.8,2.7l12.1,21.4c0.7,1.2,0.4,2.2,0,2.9
                c-0.8,1.3-2.4,2.2-4.3,2.2H8.7c-1.9,0-3.5-0.8-4.3-2.2c-0.4-0.7-0.7-1.7,0-2.9l12.1-21.4c0.5-1,0.2-2.2-0.8-2.7
                c-1-0.5-2.2-0.2-2.7,0.8L0.9,73c-1.2,2.2-1.2,4.7,0,6.9C2.4,82.4,5.4,84,8.7,84h77.7c3.3,0,6.3-1.6,7.7-4.2
                C95.3,77.7,95.4,75.2,94.1,73z"
        />
        <path
          d="M19.4,44.2c1,0.5,2.2,0.2,2.7-0.8L43.2,6.2C44.1,4.6,46,4,47.5,4c1.5,0,3.4,0.6,4.3,2.2l21.1,37.3c0.4,0.7,1,1,1.7,1
                c0.3,0,0.7-0.1,1-0.3c1-0.5,1.3-1.8,0.8-2.7L55.3,4.2C53.8,1.6,50.8,0,47.5,0c-3.3,0-6.3,1.6-7.8,4.2L18.6,41.5
                C18.1,42.5,18.4,43.7,19.4,44.2z"
        />
        <path d="M49.2,27.5c0-1.1-0.9-2-2-2s-2,0.9-2,2v27c0,1.1,0.9,2,2,2s2-0.9,2-2V27.5z" />
        <path d="M47.2,60.5c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2s2-0.9,2-2v-2C49.2,61.4,48.3,60.5,47.2,60.5z" />
      </g>
    </svg>
  );
};

export default IconOther;
