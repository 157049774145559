import { connect } from 'react-redux';
import { getDeliveredFilters } from '../../reducers/delivered';
import { submitDeliveredFilters, toggleDeliveredFilters } from '../../actions/delivered';
import DeliveredFilters from '../../components/delivered/DeliveredFilters';

const mapStateToProps = (state, ownProps) => ({
  filters: getDeliveredFilters(state),
  open: state.delivered.filtersOpen,
});

const mapDispatchToProps = dispatch => ({
  handleToggleFilters: () => dispatch(toggleDeliveredFilters()),
  submitFilters: filters => dispatch(submitDeliveredFilters(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveredFilters);
