import { types as actionTypes } from '../../reducers/reviews';
import * as appUtils from '../../Utils';
import { fetchGet } from '../../api/general';

export const actions = {
  getReviews: page => ({ type: actionTypes.FETCH_REVIEWS_START, page }),
  getReviewsFailed: reviews => ({ type: actionTypes.FETCH_REVIEWS_ERROR, reviews }),
  getReviewsSuccess: reviews => ({ type: actionTypes.FETCH_REVIEWS_SUCCESS, reviews }),
};

export const fetchReviews = page => {
  let url = appUtils.createApiUrl(`reviews?page=${page || 1}`, 2);
  return fetchGet(url)
    .then(response => response.json())
    .then(reviews => reviews);
};
