import React from 'react';
import IconSmile from './icons/IconSmile';

import './css/Button.css';

/**
 *
 * @param {string} buttonStyle
 * @param {string} size
 * @param {string} extraClasses
 * @param {boolean} loading
 * @param {string} href
 * @param {string} target
 * @param {boolean} disabled
 * @param {string} type
 * @param {function} onClick
 * @param {string} iconPos
 * @param children
 * @returns {*}
 * @constructor
 */
const Button = ({
  buttonStyle = 'primary',
  extraClasses = '',
  size,
  loading = false,
  href,
  target,
  disabled = false,
  type,
  onClick,
  iconPos,
  children,
}) => {
  const classNames =
    'btn btn--' +
    buttonStyle +
    (loading ? ' btn--loading' : '') +
    (size ? ' btn--size-' + size : '') +
    (iconPos ? ' btn--icon-' + iconPos : '') +
    (disabled ? ' btn--is-disabled' : '') +
    (extraClasses ? ' ' + extraClasses : '');
  if (href) {
    return (
      <a className={classNames} href={href} target={target} style={disabled ? { pointerEvents: 'none' } : {}}>
        {children}
        {loading && <IconSmile spinning={true} />}
      </a>
    );
  } else {
    return (
      <button className={classNames} onClick={onClick} disabled={disabled} type={type}>
        {children}
        {loading && <IconSmile spinning={true} />}
      </button>
    );
  }
};

export { Button };
