import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as appUtils from './Utils';
import './css/On-board.css';
import UserDetails from './components/onboard/UserDetails';
import AccountDetails from './components/onboard/AccountDetails';
import VehicleDetails from './components/onboard/VehicleDetails';
import PreferedRoutes from './components/onboard/PreferedRoutes';
import HouseRules from './components/onboard/HouseRules';
import ThankYou from './components/onboard/ThankYou';
import ProgressHeader from './components/layout/ProgressHeader';
import history from './History';
import { getIdFromUrl } from './Utils';
import { Schema } from './components/onboard/Schema';
import { actions as userActions } from './reducers/user';
import { isUserAvailable } from './reducers/user';
import { translate } from './UtilsTranslation';

const SITEPREFIX = appUtils.getPathForApp();
const SECTIONPREFIX = '/onboarding/step/';
const SECTIONTRANS = 'onboard.';

export const SECTIONS = {
  step_one: {
    name: SECTIONTRANS + 'step_one',
    path: SITEPREFIX + SECTIONPREFIX + '1',
  },
  step_two: {
    name: SECTIONTRANS + 'step_two',
    path: SITEPREFIX + SECTIONPREFIX + '2',
  },
  step_three: {
    name: SECTIONTRANS + 'step_three',
    path: SITEPREFIX + SECTIONPREFIX + '3',
  },
  step_four: {
    name: SECTIONTRANS + 'step_four',
    path: SITEPREFIX + SECTIONPREFIX + '4',
  },
  step_five: {
    name: SECTIONTRANS + 'step_five',
    path: SITEPREFIX + SECTIONPREFIX + '5',
  },
  thank_you: {
    name: SECTIONTRANS + 'thank_you',
    path: SITEPREFIX + SECTIONPREFIX + 'thank_you',
  },
  default: {
    name: SECTIONTRANS + 'step_one',
    path: SITEPREFIX + SECTIONPREFIX + '1',
  },
};
export const EXTRA_SERVICES = {
  blankets: false,
  dolly: false,
  handtruck: false,
  straps: false,
  tailgate: false,
};

class OnboardBrengerApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: 1,
      loading: false,
      disabled: true,
      isSubmitting: false,
      hasReadRules: false,
      validations: {
        entry: [],
        general: [],
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        id_card: '',
        new_password: '',
        timezone: 'Europe/Amsterdam',
        gender: 'unknown',
        phone: '',
        about_me: '',
        date_of_birth: '1980-01-01',
        profile_image: '',
      },
      address: {
        country: '/countries/64885959-2c2c-4f92-819d-923b541ad964', // default NL
        state: 'NULL',
        locality: '',
        postal_code: '',
        house_number: '',
        line1: '',
        line2: '',
      },
      vehicles: [],
      account: {
        account_type: 'business',
        entity_type: 'bv',
        about_us: '',
        name: '',
        phone: '',
        coc_number: '',
        vat_number: '',
      },
      bankAccount: {
        account_number: '',
        country: '/countries/64885959-2c2c-4f92-819d-923b541ad964', // default NL
        currency: 'EUR',
        account_holder_name: '',
        account_holder_type: 'business',
      },
      extra_services: {
        ...EXTRA_SERVICES,
      },
      routes: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRouteSubmit = this.onRouteSubmit.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.setInputFileData = this.setInputFileData.bind(this);
    this.onRouteDelete = this.onRouteDelete.bind(this);
    this.validationFailed = this.validationFailed.bind(this);
    this.addVehicle = this.addVehicle.bind(this);
    this.removeVehicle = this.removeVehicle.bind(this);
  }

  componentWillMount() {
    const step = getIdFromUrl(history, SITEPREFIX, 'onboarding/step');
    if (step > 1) {
      history.push(SITEPREFIX + '/onboarding/step/1');
    }
  }

  componentDidMount() {
    this.checkForActiveStep();
    window.onpopstate = () => {
      this.checkForActiveStep();
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.formErrors !== this.props.formErrors && this.props.formErrors.length > 0) {
      this.clearValidations();
      for (let formError of this.props.formErrors) {
        this.validationFailed(formError, 'general');
      }
    }
    if (this.props.isUserCreated === true && prevProps.isUserCreated !== true) {
      this.handleNavigation(true);
    }
  }

  onBlur(model, e) {
    const key = e.currentTarget.getAttribute('name');
    const value = e.currentTarget.value;

    this.validateSync(key, model);

    if (key === 'email') {
      this.isUserAvailable(value);
    }

    this.checkDisablity();
  }

  onChange(model, e) {
    const key = e.currentTarget.getAttribute('name');
    let value = e.currentTarget.value;

    if (model === 'extra_services') {
      const checkBox = e.currentTarget.parentNode;
      value = !checkBox.getElementsByTagName('input')[0].checked;
    }
    this.setState({
      [model]: Object.defineProperty(this.state[model], key, { value }),
    });
  }

  isUserAvailable(email) {
    if (email.length > 4) {
      isUserAvailable(email).then(userNameTaken => {
        if (!userNameTaken) {
          this.validationFailed(translate('form.fields.user.email.available'), 'email-available');
        } else {
          this.validationSuccess('email-available');
        }
      });
    }
  }

  onRouteSubmit(newRoute) {
    let tmp = this.state.routes;
    tmp.push(newRoute);
    this.setState({ routes: tmp });
  }

  onRouteDelete(route) {
    let tmp = this.state.routes;
    delete tmp[tmp.indexOf(route)];
    this.setState({ routes: tmp });
  }

  onSubmit() {
    const services = this.state.extra_services;
    const active_services = Object.keys(services).filter(item => services[item] === true);
    //todo move this to the store

    const data = {
      ...this.state.user,
      username: this.state.user.email,
      roles: ['ROLE_DRIVER'],
      account: {
        ...this.state.account,
        extra_services: active_services,
        bank_accounts: [this.state.bankAccount],
      },
      address: this.state.address,
      routes: this.state.routes,
      vehicles: this.state.vehicles,
    };

    this.props.onSubmit(data);
  }

  validateSync(key, model) {
    if (model === 'extra_services') return;
    try {
      Schema()
        .validateAt(key, this.state[model])
        .then(resp => resp.json())
        .catch(e => {
          if (e.errors) {
            this.setState({ loading: false });
            this.validationFailed(e.errors[0], key, model);
          } else {
            this.validationSuccess(key, model);
            this.checkDisablity();
          }
        });
    } catch (e) {}
  }

  validateSyncAll(model) {
    const data = this.state[model];
    for (let field in data) {
      this.validateSync(field, model);
    }
  }

  validationFailed(msg, param, model) {
    let newValidations = this.state.validations;
    newValidations.entry[param] = msg;
    // if email fails, clear success email message
    if (param === 'email') {
      this.validationSuccess('email-available');
    }
    this.setState({
      validations: newValidations,
    });
  }

  validationSuccess(param, model) {
    let validations = this.state.validations;
    if (param in validations.entry) {
      delete validations.entry[param];
      this.setState({
        validations: validations,
      });
    }
  }

  checkDisablity() {
    const data = this.getCurrentData();

    for (let field in data) {
      try {
        Schema().validateSyncAt(field, data);
      } catch (e) {
        this.setState({ disabled: true });
        return;
      }
    }
    this.setState({ disabled: false });
  }

  getCurrentData() {
    if (this.state.currentSection === 1) {
      return this.state['user'];
    } else {
      return {
        ...this.state.address,
        ...this.state.bankAccount,
        ...this.state.account,
      };
    }
  }

  checkForActiveStep() {
    const step = getIdFromUrl(history, SITEPREFIX, 'onboarding/step');
    if (step) {
      this.setState({ currentSection: parseInt(step) });
    }
  }

  clearValidations() {
    this.setState({ validations: { entry: [] } });
  }

  setInputFileData(event) {
    const field = event.currentTarget.getAttribute('name');
    const el = event.currentTarget;
    this.setState(
      {
        user: {
          ...this.state.user,
          [field]: el.files[0],
        },
      },
      () => {
        this.validateSync(field, 'user');
      }
    );
  }

  addVehicle(vehicle) {
    let currentVehicles = this.state.vehicles;
    currentVehicles.push({
      licensePlate: vehicle.licensePlate,
      make: vehicle.make,
      images: vehicle.image || [],
      model: vehicle.model,
      loading_weight_limit_kg: vehicle.loading_weight_limit_kg,
      loading_dimensions_width_cm: vehicle.loading_dimensions_width_cm,
      loading_dimensions_height_cm: vehicle.loading_dimensions_height_cm,
      loading_dimensions_length_cm: vehicle.loading_dimensions_length_cm,
    });
    this.setState({ vehicles: currentVehicles });
  }

  removeVehicle(idx) {
    let currentVehicles = this.state.vehicles;
    currentVehicles.splice(idx, 1);
    this.setState({ vehicles: currentVehicles });
  }

  initialStepChecks() {
    setTimeout(() => {
      if (this.state.currentSection === 1) {
        this.checkDisablity('user');
      }
      if (this.state.currentSection === 2) {
        this.checkDisablity('account');
        this.checkDisablity('address');
        this.checkDisablity('bankAccount');
      }
    }, 500);
  }

  setReadRules() {
    this.setState({
      hasReadRules: true,
    });
  }

  render() {
    return (
      <div className={'container-fluid'}>
        <ProgressHeader
          currentSection={this.state.currentSection}
          totalSections={Object.keys(SECTIONS).length - 2}
          handleNavigation={this.handleNavigation}
        />
        <Switch>
          <Route
            path={SECTIONS.step_one.path}
            render={props => (
              <UserDetails
                {...props}
                setInputFileData={this.setInputFileData}
                loading={this.state.loading}
                disabled={this.state.disabled}
                user={this.state.user}
                validations={this.state.validations}
                validationFailed={this.validationFailed}
                onChange={this.onChange}
                onBlur={this.onBlur}
                handleNavigation={this.handleNavigation}
              />
            )}
          />
          <Route
            path={SECTIONS.step_two.path}
            render={props => (
              <AccountDetails
                {...props}
                loading={this.state.loading}
                disabled={this.state.disabled}
                countries={this.props.countries}
                address={this.state.address}
                validations={this.state.validations}
                bankAccount={this.state.bankAccount}
                account={this.state.account}
                onChange={this.onChange}
                onBlur={this.onBlur}
                extra_services={this.state.extra_services}
                handleNavigation={this.handleNavigation}
              />
            )}
          />
          <Route
            path={SECTIONS.step_three.path}
            render={props => (
              <VehicleDetails
                {...props}
                loading={this.state.loading}
                disabled={this.state.disabled}
                validations={this.state.validations}
                vehicles={this.state.vehicles}
                addVehicle={this.addVehicle}
                removeVehicle={this.removeVehicle}
                onChange={this.onChange}
                onBlur={this.onBlur}
                country={this.props.countries.find(item => item['@id'] === this.state.address.country)}
                handleNavigation={this.handleNavigation}
              />
            )}
          />
          <Route
            path={SECTIONS.step_four.path}
            render={props => (
              <PreferedRoutes
                {...props}
                isSubmitting={this.state.isSubmitting}
                loading={this.state.loading}
                disabled={false}
                routes={this.state.routes}
                onRouteSubmit={this.onRouteSubmit}
                onRouteDelete={this.onRouteDelete}
                validations={this.state.validations}
                onChange={this.onChange}
                handleNavigation={this.handleNavigation}
              />
            )}
          />
          <Route
            path={SECTIONS.step_five.path}
            render={props => (
              <HouseRules
                {...props}
                loading={this.props.isSubmitting}
                hasReadRules={this.state.hasReadRules}
                setReadRules={this.setReadRules.bind(this)}
                validations={this.state.validations}
                onSubmit={this.onSubmit}
              />
            )}
          />
          <Route path={SECTIONS.thank_you.path} render={props => <ThankYou {...props} user={this.state} />} />
          <Redirect to={SECTIONS.default.path} />
        </Switch>
      </div>
    );
  }

  handleNavigation(forwards = true) {
    const currentSection = this.state.currentSection;
    let path = SECTIONS.default.path;

    this.clearValidations();
    this.setState({ loading: true, disabled: true });

    // make sure all validations passed for current step
    if (forwards) {
      if (currentSection === 1) {
        this.validateSyncAll('user');
      }
      if (currentSection === 2) {
        this.validateSyncAll('account');
        this.validateSyncAll('address');
        this.validateSyncAll('bankAccount');
      }
      if (currentSection === 3) {
        this.validateSyncAll('vehicles');
      }
    }

    setTimeout(() => {
      if (Object.values(this.state.validations.entry).length === 0) {
        if (forwards) {
          if (currentSection === 1) {
            path = SECTIONS.step_two.path;
          }
          if (currentSection === 2) {
            path = SECTIONS.step_three.path;
          }
          if (currentSection === 3) {
            path = SECTIONS.step_four.path;
          }
          if (currentSection === 4) {
            path = SECTIONS.step_five.path;
          }
          if (currentSection === 5) {
            path = SECTIONS.thank_you.path;
          }
          this.setState({
            loading: false,
            currentSection: currentSection + 1,
          });
        } else {
          if (currentSection === 2) {
            path = SECTIONS.step_one.path;
          }
          if (currentSection === 3) {
            path = SECTIONS.step_two.path;
          }
          if (currentSection === 4) {
            path = SECTIONS.step_three.path;
          }
          if (currentSection === 5) {
            path = SECTIONS.step_four.path;
          }
          this.setState({
            loading: false,
            currentSection: currentSection - 1,
          });
        }
        this.initialStepChecks();
        history.push(path);
      }
    }, 500);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    countries: state.countries.userChoices,
    user: state.user,
    isSubmitting: state.user.isSubmitting,
    formErrors: state.user.formErrors,
    isUserCreated: state.user.isUserCreated,
  };
};

const mapDispatchToProps = dispatch => ({
  loadNewUser: () => dispatch(userActions.loadNewUser()),
  onSubmit: data => dispatch(userActions.submitNewUserFormStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardBrengerApp);
