import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import _get from 'lodash/get';
import { Col } from '../../Grid';
import * as appUtils from '../../Utils';
import PlanningActions from './PlanningActions';
import '../../css/AddressActions.css';
import ExternalInvoices from './ExternalInvoices';
import Specs from './Specs';
import DateTime from './DateTime';
import ItemSet from './ItemSet';
import FlashMessage from '../../FlashMessage';
import { Button } from '../../Button';
import { translate } from '../../UtilsTranslation';
import IconCross from '../../icons/IconCross';
import { BUNDLED_STATUS } from '../../globals';

class StopDetails extends Component {
  render() {
    const transport = this.props.transport;
    const stop = this.getStopDetails(transport, this.props.type, this.props.stop);
    const state = _get(this.props.stop, `${this.props.type}.item_sets[0].state`, '');
    const isDelivered = this.isDelivered(state, this.props.type);
    const itemSets = this.props.stop ? stop.item_sets : transport.item_sets;
    const title = this.getStopTitle(transport, this.props.stop ? stop : null);
    const dateTimePeriods = this.props.getDateTimePeriods(transport, this.props.type, this.props.stop);
    const itemImages = transport.bundled ? this.getStopItemsImages(stop) : this.props.itemImages;
    return (
      <div className={'stop-details-view'}>
        <h3>
          <div>
            <Translate id={`transport_request.${this.props.type}`} />:
          </div>
          <Button
            buttonStyle={'tertiary'}
            extraClasses={'close-modal'}
            onClick={() => this.props.toggleStopDetails(false, this.props.type)}
          >
            <Translate id={'default.close'} /> <IconCross />
          </Button>
        </h3>

        <div className={'item-set-details-view row'}>
          {/* item dimensions rendering */}
          <Col xs={12}>
            <p>{title}</p>
            <ItemSet
              itemSets={itemSets}
              hideTotalSize={false}
              hideDescription={false}
              hideImages={false}
              itemImages={itemImages}
            />
          </Col>

          {/* stop contact address */}
          <Col xs={12}>
            <h4>
              <Translate id={'profile_page.details.address_details'} />:
            </h4>
            <p>{this.props.isOffer ? appUtils.getHouseAddress(stop.address) : null}</p>
            <p>{this.getAddress(stop.address, this.props.countries)}</p>
            {this.props.isOffer && (
              <p>
                <Translate id={'form.login.contact'} />: {this.getCustomerName(stop)}
              </p>
            )}
            {this.props.isOffer && <p>{_get(stop, 'contact.phone', '')}</p>}
            {!this.props.isOffer && (
              <p>
                <Translate id={'bundled.offer.info_available_after_claim'} />
              </p>
            )}
          </Col>

          {/* stop specs and requirements */}
          <Col xs={12}>
            <Specs stop={stop} />
          </Col>

          {/* available date time */}
          <Col xs={12} extraClasses={'datetime-periods'}>
            <h4>
              <Translate id={'bundled.essentials.time_period'} />:
            </h4>
            <DateTime dateTimePeriods={dateTimePeriods} />
          </Col>

          {/* stop address extra information */}
          {_get(stop, 'details.instructions', null) && (
            <Col xs="12" extraClasses="instructions-part">
              <h4>
                <Translate id={'bundled.essentials.extra_information'} />:
              </h4>
              <p>
                <strong>* {stop.details.instructions}</strong>
              </p>
            </Col>
          )}

          {/* external invoices for store and auctions */}
          <ExternalInvoices status={this.props.status} itemSets={itemSets} />

          {/* utility actions after claim job */}
          <PlanningActions {...this.props} stop={stop} />
          {this.props.errors && this.props.errors.length > 0 && (
            <FlashMessage type={'error'} message={String(this.props.errors[0])} />
          )}

          {/* confirm delivery per stop for bundled jobs */}
          {stop && transport.bundled && this.props.isOffer && window.location.pathname.indexOf('delivered') < 0 && (
            <Col xs={12}>
              <div className={'confirm-delivery-actions'}>
                {!isDelivered ? (
                  <Button
                    loading={this.props.isSubmitting}
                    disabled={this.props.isSubmitting}
                    type="submit"
                    buttonStyle="primary"
                    onClick={() => this.confirmDeliveryPerStop(stop)}
                  >
                    <Translate id={`transport_request.confirm_${this.props.type}`} />
                  </Button>
                ) : (
                  <FlashMessage message={translate('bundled.messages.already_confirmed')} type={'success'} />
                )}
              </div>
            </Col>
          )}
        </div>
      </div>
    );
  }
  isDelivered(state, type) {
    if (type === 'pickup') {
      return ![BUNDLED_STATUS.READY_FOR_PICKUP, BUNDLED_STATUS.PICKUP_CONFIRMED_BY_CUSTOMER].includes(state);
    }
    if (type === 'delivery') {
      return ![
        BUNDLED_STATUS.READY_FOR_PICKUP,
        BUNDLED_STATUS.PICKUP_CONFIRMED_BY_CUSTOMER,
        BUNDLED_STATUS.PICKUP_CONFIRMED_BY_DRIVER,
        BUNDLED_STATUS.PICKED_UP,
        BUNDLED_STATUS.DELIVERY_CONFIRMED_BY_CUSTOMER,
      ].includes(state);
    }
    return false;
  }
  getCustomerName(stop) {
    return `${_get(stop, 'contact.first_name', '')} ${_get(stop, 'contact.last_name', '')}`;
  }
  confirmDeliveryPerStop(stop) {
    const type = this.props.type;
    this.props.showModal({
      modalType: 'prompt',
      modalProps: {
        header: <Translate id={'modal.are_you_sure'} />,
        action: () => this.props.confirmDeliveryPerStop(stop, type),
        prompts: [
          {
            name: 'general',
            text: translate('modal.ok'),
          },
        ],
        message: [
          <span>
            *{' '}
            <b>
              <Translate id={'bundled.messages.confirm_delivery'} />
            </b>
          </span>,
        ],
        actionText: <Translate id={'bundled.messages.confirm_delivery_btn'} />,
      },
    });
  }

  getStopTitle(transport, stop = null) {
    if (stop) {
      const titles = stop.item_sets.map(itemSet => {
        if (itemSet.title !== null) {
          return itemSet.title;
        } else {
          return itemSet.items.map(item => item.title);
        }
      });
      return titles.join(',');
    }
    return _get(transport, 'item_sets[0].title', '');
  }

  getStopItemsImages(stop) {
    let images = [];
    stop.item_sets.map(itemSet => itemSet.items.map(item => item.job_image && images.push(item.job_image)));
    return images;
  }

  getStopDetails(transport, type, stop = null) {
    // when there is a stop means we're dealing with bundled job
    if (!stop) {
      return type === 'pickup' ? transport.pickups[0] : transport.deliveries[0];
    }
    return stop[this.props.type];
  }

  getAddress(address, countries) {
    let countryCode = _get(address, 'country', '');
    // if country was an IRI then fetch the country code from state
    if (countryCode.indexOf('countries/') > -1) {
      countryCode = this.getCountryCode(countries, countryCode);
    }
    return `${address.locality} ${address.administrative_area || ''} ${countryCode}`;
  }

  getCountryCode(countries, id) {
    if (countries && id) {
      const country = countries.filter(country => country['@id'] === id);
      if (country.length > 0) {
        return country[0].code;
      }
    }
    return '';
  }
}

export default StopDetails;
