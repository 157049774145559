import * as appUtils from '../Utils';
import { call, all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchTransportJobsError,
  fetchTransportJobsStart,
  fetchTransportJobsSuccess,
  postNewBundledOfferSuccess,
  postNewOfferSuccess,
  prepareTransportJobItem,
  scrollToSidebarItem,
  setBundledStops,
  toggleFetching,
  updateTransportJob,
} from '../actions/search';
import {
  getSelectedTransportJobId,
  getSelectedTransportJob,
  getTransportJobById,
  getCurrentTransportJobs,
} from '../reducers/search';
import { getUserDetails } from '../reducers/user';
import { types as searchTypes } from '../reducers/search';
import { showModal } from '../actions/modals';
import history from '../History';
import { getTranslateFromStore } from '../reducers';
import { getCountries } from '../reducers/countries';
import http from '../UtilsHttp';
import { TJ_STATUS, TJAL_STATUS } from '../globals';
import moment from 'moment';
import _get from 'lodash/get';
import { deselectOffer } from '../actions/planning';

// Listeners
export function* searchSagas() {
  yield takeEvery(searchTypes.FETCH_TRANSPORT_JOBS_START, fetchTransportJobsSaga);
  yield takeEvery(searchTypes.POST_NEW_BUNDLED_OFFER_SUCCESS, postNewOfferSuccessSaga);
  yield takeEvery(searchTypes.POST_NEW_OFFER_SUCCESS, postNewOfferSuccessSaga);
  yield takeEvery(searchTypes.POST_NEW_OFFER_START, postNewOfferStart);
  yield takeLatest(searchTypes.SELECT_TRANSPORT_JOB, fetchSingleTransportJob);
  yield takeLatest(searchTypes.SET_BUNDLED_STOP_DETAILS, updateTransportJobs);
  yield takeLatest(searchTypes.POST_NEW_BUNDLED_OFFER_START, postNewBundledOfferStart);
}

export function* postNewBundledOfferStart(action) {
  const user = yield select(getUserDetails);
  const translate = yield select(getTranslateFromStore);
  const transport = yield select(getSelectedTransportJob);
  try {
    // check the transport job is still available to claim
    if (transport.state !== TJ_STATUS.PUBLISHED && transport.state !== TJ_STATUS.DRIVER_FOUND) {
      yield put(fetchTransportJobsError(translate('search.messages.confirm.claim_failed')));
      return;
    }
    const offer = yield call(makeTransportAccountLink, {
      transport_job: 'transport_jobs/' + action.payload.transportJobId,
      driver_account: user.account['@id'],
      driver_user: user['@id'],
    });
    yield put(postNewBundledOfferSuccess(offer.data['@id']));
  } catch (err) {
    yield put(fetchTransportJobsError('Oopss! Something went wrong... Please try again!'));
  }
}

export function* postNewOfferStart(action) {
  try {
    const translate = yield select(getTranslateFromStore);
    const user = yield select(getUserDetails);
    const transport = yield select(getSelectedTransportJob);

    // check the transport job is still available to claim
    if (transport.state !== TJ_STATUS.PUBLISHED && transport.state !== TJ_STATUS.DRIVER_FOUND) {
      yield put(fetchTransportJobsError(translate('search.messages.confirm.claim_failed')));
      return;
    }

    // prepare req body, pickup and delivery commitments
    const data = {
      transport_job: 'transport_jobs/' + action.payload.transportJobId,
      driver_account: user.account['@id'],
      driver_user: user['@id'],
      pickup_commitments: [
        {
          pickup: transport.pickups[0]['@id'],
          committed_datetime_period: {
            start: moment
              .parseZone(action.payload.message.pickup.start)
              .utc()
              .format(),
            end: moment
              .parseZone(action.payload.message.pickup.end)
              .utc()
              .format(),
          },
        },
      ],
      delivery_commitments: [
        {
          delivery: transport.deliveries[0]['@id'],
          committed_datetime_period: {
            start: moment
              .parseZone(action.payload.message.delivery.start)
              .utc()
              .format(),
            end: moment
              .parseZone(action.payload.message.delivery.end)
              .utc()
              .format(),
          },
        },
      ],
      driver_message_body: action.payload.message.message,
      state: TJAL_STATUS.PENDING,
    };
    const offer = yield call(makeTransportAccountLink, data);
    yield put(postNewOfferSuccess(offer.data['@id']));
  } catch (err) {
    yield put(fetchTransportJobsError('Oopss! Something went wrong... Please try again!'));
  }
}

export function getTransportJobs() {
  return http()
    .get(`/users/me/transport_jobs`, { withCredentials: true })
    .then(resp => resp);
}

export function getSingleTransportJob(id) {
  return http()
    .get(`/transport_jobs/${id}`, { withCredentials: true })
    .then(resp => resp);
}

export function makeTransportAccountLink(data) {
  return http()
    .post(`/transport_job_account_links`, data, { withCredentials: true })
    .then(resp => resp);
}

// fetch bundled jobs stop details
export function* fetchBundledStops(transportJob, jobId, stop) {
  try {
    let pickup = null;
    let delivery = null;
    if (stop.pickup) {
      pickup = transportJob.pickups.filter(pickup => pickup['@id'] === stop.pickup)[0];
      // replace pickup item set details for the stop target
      pickup.item_sets.map(
        (itemSet, i) =>
          (pickup.item_sets[i] = transportJob.item_sets.filter(itemSetIdx => itemSetIdx['@id'] === itemSet)[0])
      );
    }
    if (stop.delivery) {
      delivery = transportJob.deliveries.filter(delivery => delivery['@id'] === stop.delivery)[0];
      // replace delivery item set details for the stop target
      delivery.item_sets.map(
        (itemSet, i) =>
          (delivery.item_sets[i] = transportJob.item_sets.filter(itemSetIdx => itemSetIdx['@id'] === itemSet)[0])
      );
    }
    // store the pickup and delivery stop object with more details
    yield put(
      setBundledStops(jobId, stop.index, {
        ...stop,
        pickup: pickup,
        delivery: delivery,
      })
    );
  } catch (err) {
    appUtils.logException('Oops, something wrong with the stop details fetching', err);
  }
}

export function* fetchBundledJobDetails(jobId) {
  try {
    const transportJob = yield select(getTransportJobById, jobId);
    if (transportJob.transport_route) {
      const req = IRI =>
        http()
          .get(IRI)
          .then(resp => resp);
      // fetch all stop details and available date-times
      const route = yield call(req, transportJob.transport_route);

      //order stops by index
      const stops = _get(route, 'data.stops', []).sort((a, b) => {
        return a.index > b.index ? 1 : -1;
      });

      // fetch pickup and delivery details per each stop
      yield all(stops.map(stop => call(fetchBundledStops, transportJob, jobId, stop)));
    }
  } catch (err) {
    console.log(err);
  }
}

// fetch all stops per single bundled job
export function* fetchSingleBundledStops(job) {
  try {
    const id = job['@id'].split('transport_jobs/')[1];
    yield call(fetchBundledJobDetails, id);
  } catch (err) {}
}

export function* fetchSingleTransportJob() {
  try {
    const trnasportJobs = yield select(getCurrentTransportJobs);
    const countries = yield select(getCountries);
    const selectedTransportJobId = yield select(getSelectedTransportJobId);
    if (trnasportJobs.length === 0) {
      return;
    }
    const request = yield call(getSingleTransportJob, selectedTransportJobId);
    const job = prepareTransportJobItem(request.data, countries);

    // update the current job with more details
    yield put(updateTransportJob(selectedTransportJobId, job));

    // if bundled then fetch the stops
    if (job.bundled) {
      yield put(toggleFetching(true));
      yield call(fetchSingleBundledStops, job);
    }
  } catch (err) {
    console.error(err);
  }
}

export function* fetchTransportJobsSaga() {
  try {
    const countries = yield select(getCountries);
    const selectedTransportJobId = yield select(getSelectedTransportJobId);
    const response = yield call(getTransportJobs);
    const transportJobs = response.data['hydra:member'].map(item => prepareTransportJobItem(item, countries));

    // first store all the initial jobs
    yield put(fetchTransportJobsSuccess(transportJobs));

    if (selectedTransportJobId) {
      yield call(fetchSingleTransportJob);
      yield put(toggleFetching(false));
      scrollToSidebarItem(selectedTransportJobId);
    }
  } catch (e) {
    console.log(e);
    yield put(fetchTransportJobsError(e));
  }
}

// restart the job in store to force update
// TODO: move this logic to the container
export function* updateTransportJobs() {
  const jobs = yield select(getCurrentTransportJobs);
  yield put(fetchTransportJobsSuccess([]));
  yield put(fetchTransportJobsSuccess(jobs));
}

export function* postNewOfferSuccessSaga(args) {
  try {
    const translate = yield select(getTranslateFromStore);
    const transportJob = yield select(getSelectedTransportJob);
    let jobLinkId = args.offerId;
    if (jobLinkId.indexOf('/transport_job_account_links/') > -1) {
      jobLinkId = args.offerId.replace('/transport_job_account_links/', '');
    }
    const link = appUtils.getPathForApp() + '/planning/' + jobLinkId;
    const modalProps = {
      header: translate('search.messages.confirm.header'),
      action: () => history.push(link),
      actionText: translate('search.messages.confirm.action'),
      message: translate('search.messages.confirm.message' + (transportJob.is_paid_in_advance ? '_claim' : '_offer')),
    };
    const modalType = 'confirm';
    yield all([put(deselectOffer()), put(showModal({ modalProps, modalType })), put(fetchTransportJobsStart())]);
  } catch (e) {}
}
