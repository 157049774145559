import * as appUtils from '../../Utils';
import moment from 'moment';
import history from '../../History';
import { types as searchTypes } from '../../reducers/search';
import { transformDatetimePeriodsToMoments } from '../../UtilsDatetime';
import _get from 'lodash/get';
import { PROGRESS_LEVELS } from '../../globals';

const SITEPREFIX = appUtils.getPathForApp();

export const fetchTransportJobsStart = () => ({ type: searchTypes.FETCH_TRANSPORT_JOBS_START });
export const fetchTransportJobsSuccess = payload => ({
  type: searchTypes.FETCH_TRANSPORT_JOBS_SUCCESS,
  payload,
});

export const postNewBundledOfferStart = transportJobId => ({
  type: searchTypes.POST_NEW_BUNDLED_OFFER_START,
  payload: { transportJobId },
});

export const postNewOfferStart = (message, transportJobId) => ({
  type: searchTypes.POST_NEW_OFFER_START,
  payload: { message, transportJobId },
});

export const updateTransportJob = (id, data) => ({ type: searchTypes.UPDATE_TRANSPORT_JOB, id, data });
export const postNewBundledOfferSuccess = offerId => ({ type: searchTypes.POST_NEW_BUNDLED_OFFER_SUCCESS, offerId });
export const postNewOfferSuccess = offerId => ({ type: searchTypes.POST_NEW_OFFER_SUCCESS, offerId });
export const fetchTransportJobsError = err => ({ type: searchTypes.FETCH_TRANSPORT_JOBS_ERROR, payload: err });
export const selectTransportJob = id => ({ type: searchTypes.SELECT_TRANSPORT_JOB, id });
export const deselectTransportJob = () => ({ type: searchTypes.DESELECT_TRANSPORT_JOB });
export const toggleSearchFilters = () => ({ type: searchTypes.TOGGLE_SEARCH_FILTERS });
export const setSearchFilter = filter => ({ type: searchTypes.SET_SEARCH_FILTER, filter });
export const setSearchSorting = sortType => ({ type: searchTypes.SET_SEARCH_SORTING, sortType });
export const clearErrors = () => ({ type: searchTypes.CLEAR_SEARCH_ERRORS });
export const toggleFetching = flag => ({ type: searchTypes.TOGGLE_FETCHING, flag });
export const setBundledStops = (transportJobId, index, stop) => ({
  type: searchTypes.SET_BUNDLED_STOP_DETAILS,
  payload: { transportJobId, index, stop },
});

export const prepareTransportJobItem = (apiTransportJobItem, countries) => {
  if (!apiTransportJobItem.hasOwnProperty('@id')) {
    return;
  }
  if (_get(apiTransportJobItem, 'pickups[0].address.country', false)) {
    const country = countries.filter(country => country['@id'] === apiTransportJobItem.pickups[0].address.country);
    apiTransportJobItem.pickups[0].address.country = country[0].code;
  }

  if (_get(apiTransportJobItem, 'deliveries[0].address.country', false)) {
    const country = countries.filter(country => country['@id'] === apiTransportJobItem.deliveries[0].address.country);
    apiTransportJobItem.deliveries[0].address.country = country[0].code;
  }

  //Convert datetime periods to moments
  if (_get(apiTransportJobItem, 'pickups[0].available_datetime_periods', false)) {
    apiTransportJobItem.pickups.forEach(pickup => {
      pickup.available_datetime_periods = transformDatetimePeriodsToMoments(pickup.available_datetime_periods);
    });

    if (apiTransportJobItem.pickups[0].available_datetime_periods.length === 0) {
      apiTransportJobItem.first_pickup = 'open options';
    } else {
      apiTransportJobItem.first_pickup = apiTransportJobItem.pickups[0].available_datetime_periods[0];
    }
  }
  if (_get(apiTransportJobItem, 'deliveries[0].available_datetime_periods', false)) {
    apiTransportJobItem.deliveries.forEach(delivery => {
      delivery.available_datetime_periods = transformDatetimePeriodsToMoments(delivery.available_datetime_periods);
    });
    if (apiTransportJobItem.deliveries[0].available_datetime_periods.length === 0) {
      apiTransportJobItem.first_delivery = 'open options';
    } else {
      apiTransportJobItem.first_delivery = apiTransportJobItem.deliveries[0].available_datetime_periods[0];
      apiTransportJobItem.last_delivery = apiTransportJobItem.deliveries[0].available_datetime_periods.slice().pop();
    }
  }
  apiTransportJobItem.created_at = moment(apiTransportJobItem.created_at);
  return apiTransportJobItem;
};

export const scrollToSidebarItem = id => {
  const item = document.getElementById(`/transport_jobs/${id}`);
  if (item !== null) {
    // Behaviour smooth is not supported by all browser, we could see it as a nice to have, or polyfill it later
    item.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
  }
};

export const onTransportJobClick = (uuid, renderType) => {
  appUtils.activateSingleView();
  return dispatch => {
    dispatch(selectTransportJob(uuid));
    let link = SITEPREFIX + `/${renderType}/` + uuid;
    history.push(link);
  };
};

export const userRestrictions = user => {
  let restrictions = [];

  if (user.is_suspended) {
    restrictions.push('suspended');
  }

  return restrictions;
};

export const userIsRestricted = (user, transportJob) => {
  const errors = [];
  const levels = Object.keys(PROGRESS_LEVELS);
  const jobLevel = _get(transportJob, 'minimum_progress_level_required', PROGRESS_LEVELS.bronze);

  if (!user.restrictions || !transportJob) {
    return errors;
  }

  const restrictions = user.restrictions;

  if (restrictions.includes('suspended')) {
    errors.push('suspended');
    return errors;
  }

  if (levels.indexOf(user.progress.level) < levels.indexOf(jobLevel)) {
    errors.push('limited');
  }

  return errors;
};

export const closeSingleView = () => {
  appUtils.deActivateSingleView();
  return dispatch => {
    dispatch(deselectTransportJob());
    let link = SITEPREFIX + '/search/';
    history.push(link);
  };
};
