import React from 'react';
import '../css/Offline-bar.css';
import { Translate } from 'react-localize-redux';

const TRY_AGAIN_DURATION = 30;

export class NetworkDetector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: TRY_AGAIN_DURATION,
      interval: null,
    };
  }
  componentWillMount() {
    this.props.setNetworkStatus('OFFLINE');
    this.setState({
      interval: window.setInterval(() => {
        const seconds = this.state.seconds - 1;
        this.setState({ seconds });
      }, 1000),
    });
  }
  componentWillUnmount() {
    this.props.setNetworkStatus('ONLINE');
    window.clearInterval(this.state.interval);
    this.setState({ interval: null });
  }
  componentDidUpdate(props, state) {
    if (state.seconds === 0) {
      this.setState({ seconds: TRY_AGAIN_DURATION });
    }
  }
  render() {
    return (
      <div className={'offline-bar'}>
        <Translate data={{ seconds: this.state.seconds }} id={'transport_request.offline_bar'} />
      </div>
    );
  }
}

export default NetworkDetector;
