import * as appUtils from '../../Utils';
import history from '../../History';
import { types as deliveredTypes } from '../../reducers/delivered';

const SITEPREFIX = appUtils.getPathForApp();

export const selectDelivered = id => ({ type: deliveredTypes.SELECT_DELIVERED, id });
export const deselectDelivered = () => ({ type: deliveredTypes.DESELECT_DELIVERED });
export const fetchDeliveredStart = () => ({ type: deliveredTypes.FETCH_DELIVERED_START });
export const fetchDeliveredSuccess = payload => ({ type: deliveredTypes.FETCH_DELIVERED_SUCCESS, payload });
export const fetchDeliveredError = () => ({ type: deliveredTypes.FETCH_DELIVERED_ERROR });
export const toggleDeliveredFilters = () => ({ type: deliveredTypes.TOGGLE_DELIVERED_FILTERS });
export const submitDeliveredFilters = filters => ({ type: deliveredTypes.SUBMIT_DELIVERED_FILTERS, filters });
export const setDeliveredFilter = (name, value) => ({ type: deliveredTypes.SET_DELIVERED_FILTER, name, value });
export const fetchInvoiceStart = id => ({ type: deliveredTypes.FETCH_INVOICE_START, id });
export const updateDeliveredJob = (id, data) => ({ type: deliveredTypes.UPDATE_DELIVERED_JOB, id, data });
export const setRoutingData = route => ({ type: deliveredTypes.SET_ROUTING_DATA, payload: route });
export const setSelectedJobDetails = job => ({ type: deliveredTypes.SET_SELECTED_JOB_DETAILS, payload: job });
export const setFetchingStatus = status => ({ type: deliveredTypes.SET_FETCHING_STATUS, payload: status });
export const clearErrors = () => ({ type: deliveredTypes.CLEAR_DELIVERED_ERRORS });

export const onDeliveredClick = (uuid, navigate = false) => {
  appUtils.activateSingleView();
  return (dispatch, getState) => {
    dispatch(selectDelivered(uuid));
    let link = SITEPREFIX + `/delivered/` + uuid;
    history.push(link);
  };
};

export const closeSingleView = () => {
  return dispatch => {
    dispatch(deselectDelivered());
    let link = SITEPREFIX + '/delivered/';
    history.push(link);
  };
};
