import { connect } from 'react-redux';
import ConversationDetail from '../../components/inbox/ConversationDetail';
import { fetchSingleInbox } from '../../actions/inbox';
import { selectConversation, closeConversation, updateChatSession } from '../../actions/conversations';
import {
  getConversations,
  getSelectedConversationId,
  getSelectedConversationMessages,
  getMessagesByOffer,
  getSelectedTransportJob,
} from '../../reducers/conversations';
import { getUserId } from '../../reducers/user';
import * as appUtils from '../../Utils';

const mapStateToProps = state => {
  return {
    selectedConversationId: getSelectedConversationId(state),
    selectedChatSession: getSelectedConversationMessages(state),
    messagesByOffer: getMessagesByOffer(state),
    conversations: getConversations(state),
    account: getUserId(state),
    job: getSelectedTransportJob(state),
  };
};

const mapDispatchToProps = dispatch => ({
  selectConversation: uuid => {
    dispatch(selectConversation(uuid));
    appUtils.activateSingleView();
  },
  fetchSingleInbox: uuid => dispatch(fetchSingleInbox(uuid)),
  closeConversation: () => {
    dispatch(closeConversation());
    appUtils.deActivateSingleView();
  },
  updateChatSession: job => dispatch(updateChatSession(job)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationDetail);
